import React,{useState} from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, MuiUploader, PhoneInput, SelectField,ColorPicker } from "../FormFields";
import { Accordion, Card } from "react-bootstrap";
import { Field } from "formik";
export default function ColorMarkup(props) {
 
    const {
        formField: {
            primarycolor,
            secondary,
            fontcolor,
            diamondmarkup,
            diamondfancymarkup,
            lab_diamondmarkup,
            lab_diamondfancymarkup,
            website_url,
            inquiry_email,
            inquiry_cc_email,
            noprice
        },
        values,
        setFieldValue
    } = props;

   
    return (
        <React.Fragment>
                <Grid justify="center" alignItems="center" spacing={3}>
                <Accordion className="ccmode_accordion mb-10" defaultActiveKey="1">
                    <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="1" key="1">
                        {props.language.SETTING_CCMODE_DESIGN_COLORS}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_FIRST_COLOR} *
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <ColorPicker
                                            name={primarycolor.name}
                                            label={primarycolor.label}
                                            fullWidth
                                            onChange={(e) => setFieldValue("primarycolor",e.target.value)}
                                            value={values.primarycolor}
                                        />
                                    </Grid>
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_SECOND_COLOR} *
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <ColorPicker
                                            name={secondary.name}
                                            label={secondary.label}
                                            fullWidth
                                            onChange={(e) => setFieldValue("secondary",e.target.value)}
                                            value={values.secondary}
                                        />
                                    </Grid>
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_FONT_COLOR} *
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <ColorPicker
                                            name={fontcolor.name}
                                            label={fontcolor.label}
                                            fullWidth
                                            onChange={(e) => setFieldValue("fontcolor",e.target.value)}
                                            value={values.fontcolor}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Accordion.Collapse>
                </Accordion>
                <Accordion className="ccmode_accordion mb-10" defaultActiveKey="2">
                    <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="2" key="2">
                        {props.language.SETTING_CCMODE_PRICE_MARKUP}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                        <Grid item xs={12} md={7}><>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            No Price
                                        </InputLabel>
                                    </Grid>
                                    
                                        <Grid item xs={8}>
                                        <Field className="largerCheckbox" align="left" size="large" type="checkbox" name={noprice.name} id={`${noprice.name}`} checked={values["noprice"]?values["noprice"].toString() === "1":false} value={values["noprice"]?values["noprice"]:""} onChange={() => values["noprice"] && values["noprice"].toString() === "1"?setFieldValue("noprice","0"):setFieldValue("noprice","1")}/>
                                        </Grid>
                                    
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                                </>
                            </Grid>
                        {values.naturaldiamond? <Grid item xs={12} md={7}><>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.CCMODE_SETUP_NATURAL_WHITE} *
                                        </InputLabel>
                                    </Grid>
                                    
                                        <Grid item xs={8}>
                                            <InputField
                                                name={diamondmarkup.name}
                                                label={props.language.CCMODE_SETUP_NATURAL_WHITE}
                                                fullWidth
                                                inputPropsEnd="%"
                                                disabled={values["noprice"]?values["noprice"].toString() === "1":false}
                                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                            />
                                        </Grid>
                                    
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                                </>
                            </Grid>:""}
                            {values.naturalfancydiamond? <Grid item xs={12} md={7}><>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.CCMODE_SETUP_NATURAL_FANCY} *
                                        </InputLabel>
                                    </Grid>
                                    
                                        <Grid item xs={8}>
                                            <InputField
                                                name={diamondfancymarkup.name}
                                                label={props.language.CCMODE_SETUP_NATURAL_FANCY}
                                                fullWidth
                                                inputPropsEnd="%"
                                                disabled={values["noprice"]?values["noprice"].toString() === "1":false}
                                                // onChange={(e) => setFieldValue("diamondfancymarkup",e.target.value)}
                                            />
                                        </Grid>
                                    
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                                </>
                            </Grid>:""}
                            {values.lgwhitediam? <Grid item xs={12} md={7}> <>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.CCMODE_SETUP_LAB_WHITE} *
                                        </InputLabel>
                                    </Grid>
                                    
                                        <Grid item xs={8}>
                                            <InputField
                                                name={lab_diamondmarkup.name}
                                                label={props.language.CCMODE_SETUP_LAB_WHITE}
                                                fullWidth
                                                inputPropsEnd="%"
                                                disabled={values["noprice"]?values["noprice"].toString() === "1":false}
                                                // onChange={(e) => setFieldValue("lab_diamondmarkup",e.target.value)}
                                            />
                                        </Grid>
                                    
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                                </>
                            </Grid>:""}
                            {values.lgfancydiam?<Grid item xs={12} md={7}><>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                        {props.language.CCMODE_SETUP_LAB_FANCY} * 
                                        </InputLabel>
                                    </Grid>
                                    
                                        <Grid item xs={8}>
                                            <InputField
                                                name={lab_diamondfancymarkup.name}
                                                label={props.language.CCMODE_SETUP_LAB_FANCY}
                                                fullWidth
                                                inputPropsEnd="%"
                                                disabled={values["noprice"]?values["noprice"].toString() === "1":false}
                                                // onChange={(e) => setFieldValue("lab_diamondfancymarkup",e.target.value)}
                                            />
                                        </Grid>
                                    
                                </Grid>
                                </>
                            </Grid>:""}
                        </Grid>
                    </Accordion.Collapse>
                </Accordion>
            </Grid>
        </React.Fragment>
    );
}
