import React, {useMemo,useState} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';

import StockAPI from "./StockAPI.json"
import { searchResultColumn } from './List/Columns'; 
// import GlobleFilter from './GlobleFilter';
import { useHistory } from "react-router-dom";
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowAltCircleRight, FaArrowCircleDown } from "react-icons/fa";
// import { CheckBox } from './CheckBox';
// import { Dropdown, Modal } from 'react-bootstrap';
// import { FaEllipsisV } from 'react-icons/fa'
// import  ExpandedDetails from "./ExpandedDetails"
import Loader from '../Loader';
export const ListResult = (props) => {

    
    const columns = useMemo(() => searchResultColumn(), []);
    const data = useMemo(()=> props.searchdata.data || [],[props.searchdata.data])
    const history = useHistory();
    function handelDetails(row){
        console.log(row.row.original)
        history.push({  pathname: '/api-frame-detail', state:row.row.original})
        window.scrollTo(0, 0)
    }
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 100 }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    // {
                    //     id:'selection',
                    //     Header:({ getToggleAllPageRowsSelectedProps }) =>(
                    //         <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                    //     ),
                    //     Cell: ({ row }) => (
                    //         <div>
                    //           <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                    //         </div>
                    //       ),
                    // },
                    // {
                    // Header: () => null,
                    // id: 'expander',
                    // Cell: ({ row }) => (
                    //     <span  {...row.getToggleRowExpandedProps()}>
                    //         {row.isExpanded ? <FaArrowCircleDown className="text-success "  title="Collapse"/> :  <FaArrowAltCircleRight className="text-primary" title="Expand"/>}
                    //     </span>
                    // ),
                    // },
                    ...columns,
                    {
                        Header: "Action",
                        className: "text-center align-middle",
                        Cell: (row) => {
                            return <><button className="btn btn-primary px-8 btn-square font-weight-bold" onClick={() => handelDetails(row)}>Details</button></>;
                        },
                    },
                ]
            })
        }
    )
    console.log(props.custompage,"props.custompageprops.custompage")
    return (
        <>
        {props.loader?<Loader/>:<div className='row mt-5'>
            <div className='col-12'>
                <div className="table-responsive w-100">
                    <table className="table table-striped table-hover custom_datatable" {...getTableProps()}>
                        <thead className="bg-gray-100">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(
                                        (column, i) => (
                                            <th key={i} className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                                <span className="text-secondary">
                                                    {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                </span>
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        {page.length?<tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row)
                                return(
                                    <>
                                        <tr {...row.getRowProps()} key={row.id}>
                                            {row.cells.map((cell,i) => {
                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                        {/* {row.isExpanded ? (
                                            <tr>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })} 
                                                </td>
                                            </tr>
                                        ) : null} */}
                                    </>
                                )
                            })}
                        </tbody>:<div className="text-center align-middle">No Record Found</div>}
                    </table>
                </div>
            </div>
            <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{props.custompage} of {props.searchdata && props.searchdata.total?Math.ceil(props.searchdata.total/100):0} {' '}</span>
                        <div>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => props.setCustomPage(1)} disabled={props.custompage === 1}><FaAngleDoubleLeft /></button>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => props.setCustomPage(props.custompage - 1)} disabled={props.custompage === 1}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={props.custompage}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value): 1
                                        // gotoPage(pageNumber)
                                        if(pageNumber > Math.ceil(props.searchdata.total/100) || pageNumber < 1){
                                            e.preventDefault()
                                            return;
                                        }
                                        props.setCustomPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {props.searchdata && props.searchdata.total?Math.ceil(props.searchdata.total/100):0}{'  '}
                            </span>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => props.setCustomPage(props.custompage + 1)} disabled={props.custompage >= Math.ceil(props.searchdata.total/100)}><FaAngleRight /></button>
                            <button type="button" className="btn btn-sm btn-light" onClick={()=>props.setCustomPage(Math.ceil(props.searchdata.total/100))} disabled={props.custompage >= Math.ceil(props.searchdata.total/100)}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:100</span>
                        </span>
                    </div>
                </div>
        </div>}
        </>
    )
}