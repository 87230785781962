import React from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, DiamTypeCheck ,DatePickerField, PhoneInput, SelectField,MultiSelect } from "../FormFields";

export default function DimondType(props) {
    const {
        formField: {
          naturaldiamond,
          naturalfancydiamond,
          lgwhitediam,
          lgfancydiam
        },
        setFieldValue,
        values
    } = props;
   
    return (
        <div style={{height:"57vh"}}>
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center">
                    {props.language.DIAMOND_FEED_IFRAMESETUP_CHOOSEDIAMOND}
            </Typography>
            <p className="mb-1 text-center">
                {props.language.CCMODE_SETUP_MSG1}
            </p>
            <p className="mb-5 text-center">
                {props.language.CCMODE_SETUP_MSG2}
            </p>
            <Grid container justify="center" alignItems="center" spacing={3}>
            {/* <MultiSelect
                name={wl_naturaldiamond.name}
                label={wl_naturaldiamond.label}
                data={state_data}
                fullWidth
            /> */}
                <Grid item xs={12} sm={7} align="center">
                    <InputLabel className="font-size-h6 font-weight-bold mt-10 text-dark">
                        {props.language.CCMODE_SETUP_NATURAL_DIAMOND}
                    </InputLabel>
                    <Grid item xs={12} md={7} align="center">
                      {/* <div> */}
                            <DiamTypeCheck
                                name={naturaldiamond.name}
                                label={naturaldiamond.label}
                                fullWidth
                                setFieldValue={setFieldValue}
                                values={values}
                            />
                            <DiamTypeCheck
                                name={naturalfancydiamond.name}
                                label={naturalfancydiamond.label}
                                fullWidth
                                setFieldValue={setFieldValue}
                                values={values}
                            />
                                {/* <span class="white_dia_icon"></span> */}
                      {/* </div> */}
                    </Grid>
                    <InputLabel className="font-size-h6 font-weight-bold mt-10 text-dark">
                        {props.language.CCMODE_SETUP_LAB_DIAMOND}
                    </InputLabel>
                    <Grid item xs={12} md={7} align="center">
                       {/* <div> */}
                            <DiamTypeCheck
                                name={lgwhitediam.name}
                                label={lgwhitediam.label}
                                fullWidth
                                setFieldValue={setFieldValue}
                                values={values}
                            />
                            <DiamTypeCheck
                                name={lgfancydiam.name}
                                label={lgfancydiam.label}
                                fullWidth
                                setFieldValue={setFieldValue}
                                values={values}
                            />
                       {/* </div> */}
                    </Grid>
                    
                </Grid>
            </Grid>
        </div>
    );
}