/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect,useState}from "react";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { FeedKycPending } from "./FeedKycPending";
import {FeedRequest,IFrameRequested,IFrameRejected,APIRejected,APIRequested,FeedApproved,IFramesetup,IFrameSuspended} from "./"
import ApiRequested from "../apiv3/ApiRequested";
import SubscriptionPage from "../apiv3/SubscriptionPage";
import {RulePage} from "./RulePage";
import { APISetUp } from "./APISetUp";
import { FeedRequestV3 } from "./FeedRequestV3";
export const DiamondFeed = (props) => {
    // console.log(customeriframeorapi.feed_request,"USERINFO")
    const [updatedata,setUpdateData] = useState(false)
    const [customeriframe,setCustomerIframe] = useState()
    const [customerapi,setCustomerAPI] = useState([])
    const [loader,setLoader] = useState(false)
    const [customeriframeorapi,setCustomerIframeorAPI] = useState()
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/fetchIframeorAPISetting`,
            headers: { "Content-Type": "application/json" },
            data: {
                "user_id":userData.CustomerId
            },
        }).then(supreq => {
            if(supreq && supreq.data && supreq.data.data){
                // setFeedLoader(false)
                setCustomerIframeorAPI(supreq.data.data)
            }
        })
        setLoader(true)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/fetchRules`,
            headers: { "Content-Type": "application/json" },
            data: {
                "user_id":userData.CustomerId,
            },
        }).then(apireq => {
            setLoader(false)
            if(apireq && apireq.data && apireq.data.data && apireq.data.data.length){
                // console.log(apireq.data.data,"APIIIIIII")
                setCustomerAPI(apireq.data.data)
            }
            else{
                setCustomerAPI([])
            }
        }).catch(error => {
            setLoader(false)
        })
    },[updatedata])
    
    return (
        <>
            {customeriframeorapi?<div className="card custom-card" id="API-request-page">
                {props.userinfo.kyc_status !== "KYC_PEND"?<div className={`card-body ${customeriframeorapi.feed_type === "API" && customeriframeorapi.api === 1 && customeriframeorapi.feed_request === 1 ? "p-0" : ""}`}>
                    {/* {customeriframe?
                    customeriframeorapi.api === 1?<FeedApproved {...props}/>:<IFrameSuspended/>:
                    <> */}
                    {customeriframeorapi.feed_request === 0?<FeedRequest userinfo={props.userinfo} language={props.language}/>:""}
                    {customeriframeorapi.feed_request === 3?<IFrameRequested feed_type={customeriframeorapi.feed_type} language={props.language}/>:""}
                    {customeriframeorapi.feed_request === 2?<IFrameRejected feed_type={customeriframeorapi.feed_type} language={props.language}/>:""}
                    {customeriframeorapi.feed_request === 1 && customeriframeorapi.api !== 1?<IFrameSuspended feed_type={customeriframeorapi.feed_type} language={props.language}/>:""}
                    {customeriframeorapi.feed_type === "IFrame Plug-in" && customeriframeorapi.feed_request === 1 && customeriframeorapi.api === 1 && !customeriframe?<IFramesetup {...props} language={props.language}/>:""}
                    {/* {customeriframeorapi.feed_type === "API" && customeriframeorapi.feed_request === 1 && customeriframeorapi.api === 1 && !customerapi?<APISetUp {...props} language={props.language}/>:""} */}
                    {customeriframe && customeriframeorapi.api === 1 && customeriframeorapi.feed_request === 1?<FeedApproved {...props}/>:""}
                    {customeriframeorapi.feed_type === "API" && customeriframeorapi.api === 1 && customeriframeorapi.feed_request === 1?<RulePage {...props} customerapi={customerapi} setUpdateData={setUpdateData} updatedata={updatedata} loader={loader} setCustomerAPI={setCustomerAPI} customeriframeorapi={customeriframeorapi}/>:""}
                    {/* </>
                    } */}
                   
                  
                </div >:<FeedKycPending/>}
            </div >:""}
        </>
    );
};
