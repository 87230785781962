import React, { useState,useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import DimondType from './Forms/DimondType';

import NaturalDiam from './Forms/NaturalDiam';
import LabDiam from './Forms/LabDiam';
import ColorMarkup from './Forms/ColorMarkup';
import FormSuccess from './FormSuccess/FormSuccess';
import { NODE_API_URL } from '../../../../env_config';
import validationSchema from './FormModel/validationSchema';
import kycFormModel from './FormModel/kycFormModel';
import {fetchData} from './FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';

import useStyles from './styles';

const steps = ['Choose Diamond', 'Natural Diamond Parameters', 'Lab-Grown Diamond Parameters', 'Color & Price Markup'];
const { formId, formField } = kycFormModel;

function _renderStepContent(step,setFieldValue,prev,setPrev,values,userinfo,selectallfields,selectallfieldslab,language) {
  switch (step) {
    case 0:
      return <DimondType formField={formField} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev} values={values} language={language}/>;
    case 1:
      return <NaturalDiam setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfields} language={language}/>;
    case 2:
      return <LabDiam setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfieldslab} language={language}/>;
    case 3:
      return <ColorMarkup formField={formField} values={values} setFieldValue={setFieldValue} userinfo={userinfo} language={language}/>;
      // return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}

export default function IFrameSetupFrom(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [initialValues,setInitialValues] = useState({})
  useEffect(() => {
    fetchData(props.userinfo).then(values => {
      // console.log(values,"fetchData")
      setInitialValues(values)
    })
  },[])
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log(values,"valuesvalues")
    actions.setSubmitting(false);
    let diamond_type = [values.naturaldiamond?1:0,values.naturalfancydiamond?1:0,values.lgwhitediam?1:0,values.lgfancydiam?1:0]
    // console.log(diamond_type,"diamond_type")
    const userData = JSON.parse(localStorage.getItem("userData"))

    const FinalValues = {
      ...values,
      diamond_type:diamond_type,
      "user_id":userData.CustomerId,
      fancycolor:values.diamondfancy_color,
      fancyintensity:values.diamondfancy_intensity,
      fancyovertone:values.diamondfancy_overtone,
      labfancycolor:values.lab_fancy_color,
      labfancyintensity:values.lab_fancy_intensity,
      labfancyovertone:values.lab_fancy_overtone,
      lab_flour:values.lab_flourselect,
      lab_shade:values.lab_shadeselect,
      secondarycolor:values.secondary,
      min_dollarperct:values.price_from,
      max_dollarperct:values.price_to,
      "embeded_code_option":"Separate",
      lab_media:values.lab_all_media,
      no_price:values.noprice
    }
    // console.log(values,"valuesvalues")
    // console.log(FinalValues,"FinalValues")
    axios({
      method: "POST",
      url: `${NODE_API_URL}/TESTMODE/insertIframeData`,
      headers: { "Content-Type": "application/json" },
      data: FinalValues,
  }).then(res => {
      if(res && res.data){
          // setData(res.data)
          // console.log( res.data,"NOICE")
          window.location.reload(true)
      }
  })
    // const formData = new FormData();
    // window.location.reload(true)
    // if(values.wl_primarycolor){
    //   formData.append("primarycolor", values.primarycolor)
    // }
    // if(values.n_fontcolor){
    //   formData.append("secondary", values.secondary)
    // }
    // if(values.lab_primary){
    //   formData.append("fontcolor", values.fontcolor)
    // }
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
 const [prev,setPrev] = useState(false)
  function _handleBack() {
    setActiveStep(activeStep - 1);
    setPrev(true)
  }
  const [selectallfields,setSelectAllFields] = useState(false)
  const [selectallfieldslab,setSelectAllFieldsLab] = useState(false)

  // console.log(selectallfields,"selectallfields")
  return (
    <React.Fragment>
      {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      <React.Fragment>
        {activeStep === steps.length ? (
          <FormSuccess />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values }) => (
              <Form id={formId}>
               {_renderStepContent(activeStep,setFieldValue,prev,setPrev,values,props.userinfo,selectallfields,selectallfieldslab,props.language)}
                <Grid className="mt-10 sticky-top bg-gray-100 " container justify={activeStep !== 0 ? "space-between" : "flex-end"} alignItems="center"  style={{bottom:"0"}}>
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={_handleBack} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">{props.language.PROFILE_PREVIOUS}</span >
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                      {activeStep === 1?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFields(true)}>{props.language.DIAMOND_FEED_IFRAMESETUP_SELECTALL}</Button>:""}
                        {activeStep === 2?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFieldsLab(true)}>{props.language.DIAMOND_FEED_IFRAMESETUP_SELECTALL}</Button>:""}
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? props.language.CCMODE_COMPLETE_SETUP : props.language.PROFILE_NEXT}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
