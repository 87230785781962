import React, { useState } from "react";
import { at } from 'lodash';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';



const ColorPicker1 = props => {


  return (
    // <Container>
      <span className="customColorPicker">
        <input className="colorPickerColorInput" type="color" {...props} />
        <input className="colorPickerTextInput" type="text" {...props} />
      </span>
    // </Container>
  );
};

export default function ColorPicker(props) {
  const { label, ...rest } = props;
  const [field, meta] = useField(props);
  // console.log(meta)
  const [color, setColor] = useState(meta.value);
  

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const handleInput = e => {
    setColor(e.target.value);
  };

  return (
    <>
          <ColorPicker1 onChange={handleInput} value={color} 
          {...rest} />
          {_renderHelperText()}
    </>
  );
}
