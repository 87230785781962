import React from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, DiamTypeCheck ,DatePickerField, PhoneInput, SelectField,MultiSelect } from "../FormFields";
import { Accordion, Card } from "react-bootstrap";


export default function DimondType(props) {

    const {
        formField: {
          naturaldiamond,
          naturalfancydiamond,
          lgwhitediam,
          lgfancydiam
        },
        setFieldValue,
        values
    } = props;
   
    return (
        <div style={{height:'50vh'}}>
          <Accordion className="ccmode_accordion mb-10" defaultActiveKey="1">
              <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="1" key="1">
                  {props.language.SETTING_DIAMOND_TYPE}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Grid container justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={7} align="center">
                        <InputLabel className="font-size-h6 font-weight-bold mt-10 text-dark">
                            {props.language.CCMODE_SETUP_NATURAL_DIAMOND}
                        </InputLabel>
                        <Grid item xs={12} md={7} align="center">
                          {/* <div> */}
                                <DiamTypeCheck
                                    name={naturaldiamond.name}
                                    label={naturaldiamond.label}
                                    fullWidth
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                                <DiamTypeCheck
                                    name={naturalfancydiamond.name}
                                    label={naturalfancydiamond.label}
                                    fullWidth
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                                    {/* <span class="white_dia_icon"></span> */}
                          {/* </div> */}
                        </Grid>
                        <InputLabel className="font-size-h6 font-weight-bold mt-10 text-dark">
                            {props.language.CCMODE_SETUP_LAB_DIAMOND}
                        </InputLabel>
                        <Grid item xs={12} md={7} align="center">
                          {/* <div> */}
                                <DiamTypeCheck
                                    name={lgwhitediam.name}
                                    label={lgwhitediam.label}
                                    fullWidth
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                                <DiamTypeCheck
                                    name={lgfancydiam.name}
                                    label={lgfancydiam.label}
                                    fullWidth
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                          {/* </div> */}
                        </Grid>
                        
                    </Grid>
                </Grid>
              </Accordion.Collapse>
          </Accordion>
        </div>
    );
}