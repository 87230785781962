import React, {useMemo,useState,useEffect} from 'react'
import { useLocation,useHistory } from "react-router";
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowAltCircleRight, FaArrowCircleDown,FaEllipsisV,FaColumns,FaChevronRight,FaChevronDown } from "react-icons/fa";
import {BsLightningFill } from "react-icons/bs";
import { Dropdown, Modal } from 'react-bootstrap';
// import StockAPI from "./StockAPI.json"
import { MockSearchResultColumn } from './searchResult/Columns'; 
// import GlobleFilter from '../tableFilter/GlobleFilter';
import { CheckBox } from '../tableFilter/CheckBox';
import  ExpandedDetails from "./searchResult/ExpandedDetails"
// import { ImagePopup } from "../widgets/tiles/ImagePopUp";
// import { VideoPopup } from "../widgets/tiles/VideoPopUp";
import { FETCH_WISHLIST } from "../../../env_config";
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError,CompareDiamond } from "../Popup";
import SearchTableSkeleton from './SearchTableSkeleton'
import axios from "axios";
import { makeStyles,Button,SwipeableDrawer } from '@material-ui/core';
import ModifiedSearchOffcanvas from './ModifiedSearchOffcanvas'
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from '../../_helpers';
import { Link } from "react-router-dom";
import { NODE_API_URL } from '../../../env_config';
import Excel from "exceljs";
import FileSaver from "file-saver"
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  });



export const SearchResult = ({conversionrate,symbol,language,isprofilecomplete,handleClick,symbolname,userinfo}) => {
    const location = useLocation();
    const history = useHistory()

    const classes = useStyles();
    const [swipeableDrawer, setSwipeableDrawer] = useState({
      right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setSwipeableDrawer({ ...swipeableDrawer, [side]: open });
      };

    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [totalcarat,setTotalCarat] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [totalourprice,setTotalOurPrice] = useState(0)
    const [diamondselected,setDiamondSelected] = useState([])
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopup,setBuyPopUp] = useState(false)
    const [comparePopup,setComparePopup] = useState(false)
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [StockAPI, setStockAPI] = useState(location.state.searchresult);
    const [updatewishlist,setUpdateWishlist] = useState()
    const [wishlistdata,setWishlistData] = useState([])
    const [loader,setLoader] = useState(true)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('OurPrice');
    const [modifymsg,setModifyMessage] = useState("")
    // const [selected, setSelected] = React.useState([]);
    // const isSelected = (name) => selectedFlatRows.indexOf(name) !== -1;
    const [dimondType,setDiamondType] =useState(StockAPI.length > 0 && StockAPI[0].diamond_type? StockAPI[0].diamond_type:"N")

    const stoneCount = useMemo(()=>  localStorage.getItem("stoneCount"), [])
    const userData = useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);

    const initialState = {
        sortBy: [
          {
            id: "OurPrice",
            desc: false
          }
        ],
      };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      function GetRatio(row) {
        let $ratioval
        if (row.C_Shape !== 'ROUND') {
            if (row.C_Length >= row.C_Width) {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else if (row.C_Length < row.C_Width) {
                $ratioval = (row.C_Width / row.C_Length).toFixed(2);
            } else if (row.C_Shape === 'HEART') {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else {
                $ratioval = '-';
            }
        } else {
            $ratioval = '-';
        }
        return $ratioval
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }
      
      function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
       stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }


    useEffect(() => {
        const finalData = {
            user_id:user.CustomerId
        }
        axios({
            method: "POST",
            url: FETCH_WISHLIST,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            if(res && res.data){
                // setData(res.data)
                // console.log(res.data,"res.data")
                setWishlistData(res.data[0])

            }
        }).catch(err => {
        })
        setStockAPI(location.state.searchresult)
    },[updatewishlist,location.state.searchresult])

    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    const handleClose = () => setCustomizeTable(false);
    const ChangeView = () => {
        history.push({
          pathname: "/search-grid",
        //   state: stableSort(StockAPI, getComparator(order, orderBy)),
          state: {
            searchresult:stableSort(StockAPI, getComparator(order, orderBy)),
            oldvalues:location.state.oldvalues
        }
              // state: decompress(res.data.ProductDetails),
          });
      }
      const modifiedSearch = () => {
        const searchLink = StockAPI[0].diamond_type === "L" ? "/labsearchdiamond" : "/searchdiamond" 
          history.push({
            pathname: searchLink,
            state: StockAPI,
                // state: decompress(res.data.ProductDetails),
            });
        }
        const CompareDiam = (selectedFlatRows) => {
            if(selectedFlatRows.length === 0){
                alert("Please Select Diamonds")
                return
            }
            if(selectedFlatRows.length === 1){
                alert("Please Select Minimum 2 Diamonds")
                return
            }
            if(selectedFlatRows.length > 4){
                alert("Only 4 diamonds allowed")
                return
            }
            let temptotalcarat = 0
            let temptotalinrcar = 0
            let temptotalourprice = 0
            let diamondcount = 0
           const selectedvalues = selectedFlatRows.map(value => {
                const finddata = StockAPI.find(val => value.original.id.toString() === val.id.toString())
                    diamondcount += 1
                    temptotalcarat += finddata.C_Weight
                    temptotalourprice += finddata.OurPrice
                    temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
                return {
                    ...finddata,
                    temptotalcarat:temptotalcarat,
                    temptotalinrcar:temptotalinrcar,
                    temptotalourprice:temptotalourprice
                }
            })
            setDiamondSelected(selectedvalues)
            setComparePopup(true)
            setDiamondcount(diamondcount)
            setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
            setBuyPopUpTotal(temptotalourprice)
          }
      const BuyDiamond = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        const selectedvalues = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.original.id.toString() === val.id.toString())
            // if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            // }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        setDiamondSelected(selectedvalues)
        setDiamondcount(diamondcount)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
      }
      const HoldDiamond = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.original.id.toString() === val.id.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setDiamondcount(diamondcount)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
      }
      const ExcelDownload = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            setSwalWarnOpen(true)
            setAlertMessage("Please Select at least One Record ...!!")
            return
        }
        // let exceldata = selectedFlatRows.map((value) => {
        //     console.log(value.original,"value.id")
        //     let measurement = ""
        //     if(value.original.C_Length){
        //         measurement += parseFloat(value.original.C_Length).toFixed(2)
        //     }
        //     if(value.original.C_Width){
        //         measurement += `*${parseFloat(value.original.C_Width).toFixed(2)}`
        //     }
        //     if(value.original.C_Depth){
        //         measurement += `*${parseFloat(value.original.C_Depth).toFixed(2)}`
        //     }
        //     let ExcelObj = {
        //         "Stone ID":`${value.original.diamond_type}-${value.original.id}`,
        //         "Shape":value.original.C_Shape,
        //         "Carat":parseFloat(value.original.C_Weight).toFixed(2),
        //         "Color":value.original.C_Color,
        //         "Clarity":value.original.C_Clarity,
        //         "Cut":value.original.C_Cut,
        //         "Pol":value.original.C_Polish,
        //         "Sym":value.original.C_Symmetry,
        //         "Flo":value.original.C_Fluorescence,
        //         "Measurement":measurement,
        //         "Rap Rate":value.original.raprate,
        //         "Dis %":parseFloat(value.original.discount_main).toFixed(2),
        //         "$/Ct":parseFloat(value.original.OurRate).toFixed(2),
        //         "Total $":parseFloat(value.original.OurPrice).toFixed(2),
        //         "Lab":value.original.Lab,
        //         "Report No":value.original.Certi_NO,
        //         "Depth":parseFloat(value.original.C_DefthP).toFixed(2),
        //         "Table":parseFloat(value.original.C_TableP).toFixed(2),
        //         "CA":parseFloat(value.original.Crn_Ag).toFixed(2),
        //         "CH":parseFloat(value.original.Crn_Ht).toFixed(2),
        //         "PA":parseFloat(value.original.Pav_Ag).toFixed(2),
        //         "PH":parseFloat(value.original.Pav_Dp).toFixed(2),
        //     }
        //     ExcelObj["Brown"] = value.original.brown
        //     ExcelObj["Green"] = value.original.green
        //     ExcelObj["Key To Sym"] = value.original.Key_Symbols
        //     ExcelObj["Eye Clean"] = value.original.EyeC
        //     ExcelObj["Country"] = value.original.country
        //     ExcelObj["Certificate URL"] = value.original.Lab === "IGI"
        //     ? `https://www.igi.org/viewpdf.php?r=${value.original.Certi_NO}`
        //     : value.original.Lab === "GIA"
        //     ? `https://www.gia.edu/report-check?reportno=${value.original.Certi_NO}`
        //     : value.original.Lab === "HRD"
        //     ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${value.original.Certi_NO}`
        //     : value.original.Lab === "GCAL"
        //     ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${value.original.Certi_NO}`
        //     : value.original.Certi_link
        //     ExcelObj["Image Url"] = value.original.aws_image
        //     ExcelObj["Video Url"] = value.original.video
        //     ExcelObj["Type"] = value.original.diamond_type === "L"?"Lab Grown":"Natural"
        //     return ExcelObj
        // })
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("My Sheet");
        worksheet.columns = [
            {header: 'Stone ID', key: 'Stone ID', width: 15},
            {header: 'Shape', key: 'Shape', width: 15}, 
            {header: 'Carat', key: 'Carat', width: 15},
            {header: 'Color', key: 'Color', width: 15},
            {header: 'Clarity', key: 'Clarity', width: 15}, 
            {header: 'Cut', key: 'Cut', width: 15},
            {header: 'Pol', key: 'Pol', width: 15},
            {header: 'Sym', key: 'Sym', width: 15}, 
            {header: 'Flo', key: 'Flo', width: 15},
            {header: 'Measurement', key: 'Measurement', width: 15},
            {header: 'Rap Rate', key: 'Rap Rate', width: 15}, 
            {header: 'Dis %', key: 'Dis %', width: 15},
            {header: '$/Ct', key: '$/Ct', width: 15},
            {header: 'Total $', key: 'Total $', width: 15}, 
            {header: 'Lab', key: 'Lab', width: 15},
            {header: 'Report No', key: 'Report No', width: 15},
            {header: 'Depth', key: 'Depth', width: 15}, 
            {header: 'Table', key: 'Table', width: 15},
            {header: 'CA', key: 'CA', width: 15},
            {header: 'CH', key: 'CH', width: 15}, 
            {header: 'PA', key: 'PA', width: 15},
            {header: 'PH', key: 'PH', width: 15},
            {header: selectedFlatRows[0].original.diamond_type === "L"?"Growth type":"Origin", key: selectedFlatRows[0].original.diamond_type === "L"?"Growth type":"Origin", width: 15}, 
            {header: 'Treatment', key: 'Treatment', width: 15},
            {header: 'Key To Sym', key: 'Key To Sym', width: 15},
            {header: 'Eye Clean', key: 'Eye Clean', width: 15}, 
            {header: 'Country', key: 'Country', width: 15},
            {header: 'Certificate URL', key: 'Certificate URL', width: 15},
            {header: 'Image Url', key: 'Image Url', width: 15}, 
            {header: 'Video Url', key: 'Video Url', width: 15},
            {header: 'Type', key: 'Type', width: 15},
           ]
           worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{ argb:'2830D4' }
            }
            worksheet.getRow(1).height = 26.4
            worksheet.getRow(1).font = {color: {argb: "FFFFFF"}};
           selectedFlatRows.map((value) => {
            let measurement = ""
            if(value.original.C_Length){
                measurement += parseFloat(value.original.C_Length).toFixed(2)
            }
            if(value.original.C_Width){
                measurement += `*${parseFloat(value.original.C_Width).toFixed(2)}`
            }
            if(value.original.C_Depth){
                measurement += `*${parseFloat(value.original.C_Depth).toFixed(2)}`
            }
            let ExcelObj = {
                "Stone ID":`${value.original.diamond_type}-${value.original.id}`,
                "Shape":value.original.C_Shape,
                "Carat":parseFloat(value.original.C_Weight).toFixed(2),
                "Color":value.original.C_Color,
                "Clarity":value.original.C_Clarity,
                "Cut":value.original.C_Cut,
                "Pol":value.original.C_Polish,
                "Sym":value.original.C_Symmetry,
                "Flo":value.original.C_Fluorescence,
                "Measurement":measurement,
                "Rap Rate":value.original.raprate,
                "Dis %":parseFloat(value.original.discount_main).toFixed(2),
                "$/Ct":parseFloat(value.original.OurRate).toFixed(2),
                "Total $":parseFloat(value.original.OurPrice).toFixed(2),
                "Lab":value.original.Lab,
                "Report No":value.original.Certi_NO,
                "Depth":parseFloat(value.original.C_DefthP).toFixed(2),
                "Table":parseFloat(value.original.C_TableP).toFixed(2),
                "CA":parseFloat(value.original.Crn_Ag).toFixed(2),
                "CH":parseFloat(value.original.Crn_Ht).toFixed(2),
                "PA":parseFloat(value.original.Pav_Ag).toFixed(2),
                "PH":parseFloat(value.original.Pav_Dp).toFixed(2),
            }
            ExcelObj[value.original.diamond_type === "L"?"Growth type":"Origin"] = value.original.brown
            ExcelObj["Treatment"] = value.original.green
            ExcelObj["Key To Sym"] = value.original.Key_Symbols
            ExcelObj["Eye Clean"] = value.original.EyeC
            ExcelObj["Country"] = value.original.country
            ExcelObj["Certificate URL"] = value.original.Lab === "IGI"
            ? `https://www.igi.org/viewpdf.php?r=${value.original.Certi_NO}`
            : value.original.Lab === "GIA"
            ? `https://www.gia.edu/report-check?reportno=${value.original.Certi_NO}`
            : value.original.Lab === "HRD"
            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${value.original.Certi_NO}`
            : value.original.Lab === "GCAL"
            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${value.original.Certi_NO}`
            : value.original.Certi_link
            ExcelObj["Image Url"] = value.original.aws_image
            ExcelObj["Video Url"] = value.original.video
            ExcelObj["Type"] = value.original.diamond_type === "L"?"Lab Grown":"Natural"
            worksheet.addRow(ExcelObj)
        })  
                let date = new Date() //10 May 2019, 3:30:20 PM
        date = new Date(date.getTime())
        
        let dateStr = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }) // 10/05/19

        let arr = dateStr.split("/")// [ '10', '05', '19' ]
        let d = arr[0]; //e.g. 10 
        let m = arr[1]; //e.g. 5 
        let y = arr[2]; //e.g. 19

        let timeStr = date.toLocaleTimeString("en-GB", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }) //
        let arr2 = timeStr.split(":") // 15:30:20
        let H = arr2[0]; //e.g. 15
        let i = arr2[1]; //e.g. 30
        let s = arr2[2]; //e.g. 20
        worksheet.eachRow(function (Row, rowNum) {
            /** Row.alignment not work */
            // Row.alignment = { horizontal: 'left' }
    
            Row.eachCell(function (Cell, cellNum) {
                /** cell.alignment not work */
                if (rowNum == 1) {
                    Cell.alignment = {
                        vertical: 'middle',
                        horizontal: 'center'
                    }
                }else{
                    Cell.alignment = {
                        vertical: 'middle',
                        horizontal: 'left'
                    }
                }
            })
        })
        
        workbook.xlsx.writeBuffer()
  .then(buffer => FileSaver.saveAs(new Blob([buffer]), `Stock${y}-${m}-${d}-${H}-${i}-${s}.xlsx`))
  .catch(err => {})
        // console.log(exceldata,"exceldata")
        // const worksheet = XLSX.utils.json_to_sheet(exceldata);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        // //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        // let date = new Date() //10 May 2019, 3:30:20 PM
        // date = new Date(date.getTime() + (330 * 60 * 1000))
        
        // let dateStr = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }) // 10/05/19

        // let arr = dateStr.split("/")// [ '10', '05', '19' ]
        // let d = arr[0]; //e.g. 10 
        // let m = arr[1]; //e.g. 5 
        // let y = arr[2]; //e.g. 19

        // let timeStr = date.toLocaleTimeString("en-GB", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }) //
        // let arr2 = timeStr.split(":") // 15:30:20
        // let H = arr2[0]; //e.g. 15
        // let i = arr2[1]; //e.g. 30
        // let s = arr2[2]; //e.g. 20
        // XLSX.writeFile(workbook, `${y}-${m}-${d}-${H}-${i}-${s}.xlsx`,{bgColor:"green"});
      }
    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <ExpandedDetails row={row.row.original} conversionrate={conversionrate} symbol={symbol} wishlistdata={wishlistdata} setUpdateWishlist={setUpdateWishlist} language={language} handleClick={handleClick} totalinrcar={totalinrcar} totalourprice={totalourprice} diamondselected={diamondselected} buypopup={buypopup} BuyDiamond={BuyDiamond} HoldDiamond={HoldDiamond} symbolname={symbolname}/>
            </>
        ),[StockAPI,wishlistdata]
      )
      useEffect(() => {
        if (StockAPI){
            setTimeout(function() { 
                setLoader(false)
            }, 1000);
        } else{
            setLoader(true)
        }
        
    }, [StockAPI]);

    const columns = useMemo(()=> MockSearchResultColumn(conversionrate,user,symbol,GetRatio,language),[StockAPI] )
    // const data = useMemo(()=> StockAPI,[])
    const RowExpanded = async(rowvalue) => {
        const getipadress = await axios.get("https://api.ipify.org")
        // console.log(getipadress.data,"getipadress")
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/expandAndDetailsLogAPI`,
            headers: { "Content-Type": "application/json" },
            data: {
                userid:userinfo.id,
                Certi_no:rowvalue.original.Certi_NO,
                C_Name:rowvalue.original.C_Name,
                type:"Expand",
                ip:getipadress.data
            },
        }).then(response => {
            // console.log(response.data,"responseExpand")
        })
    }
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        // setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{
                // globalFilter,
                pageIndex,
                pageSize,
                // selectedRowIds
            },
    } = useTable({
        columns,
        data:StockAPI,
        initialState
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    {
                    Header: () => null,
                    id: 'expander',
                    Cell: ({ row }) => (
                        <span  {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand" onClick={() => RowExpanded(row)}/>}
                        </span>
                    ),
                    },
                    ...columns,
                ]
            })
        }
    )
    useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.original.id.toString() === val.id.toString())
            if(finddata){
                temptotalcarat += finddata.C_Weight
                temptotalinrcar += (selectedFlatRows.length>0?finddata.OurRate/selectedFlatRows.length:0)
                temptotalourprice += finddata.OurPrice
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selectedFlatRows.length>0?(temptotalourprice/temptotalcarat * conversionrate):0)
        setTotalOurPrice(temptotalourprice * conversionrate)
    },[selectedFlatRows])
    
    return (
        <>
            {loader ? <SearchTableSkeleton /> :
                <>
                 {StockAPI.length > 0 ?
                    <div className={`card card-custom card-sticky gutter-b ${StockAPI.length > 0 && StockAPI[0].diamond_type === "L" ? "labTab" : "natTab"}`}>
                        <div className="card-header py-3 mb-3">
                            <div className="card-title align-items-start flex-column">
                                <div>
                                    <span className="card-label font-weight-bolder text-dark">{localStorage.getItem("stoneCount")}  {language.SEARCHLIST_DIAMONDSFOUND} </span>
                                    <span className="text-muted font-weight-bold font-size-sm">
                                        {language.SEARCHLIST_TOTALDIAMONDSELECTED}: {selectedFlatRows.length} {language.SEARCHLIST_DIAMONDS}  |  {language.SEARCHLIST_TOTALCARAT} :{totalcarat.toFixed(2)}  |  Total {symbol === "₹"?"INR":symbol}/CT: {symbol}{totalinrcar.toFixed(2)}  |  {language.SEARCHLIST_TOTALPAYABLE}: {symbol}{totalourprice.toFixed(2)}
                                    </span>
                                </div>
                            </div>
                            <div className="card-toolbar flex-md-nowrap">
                                {/* <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? BuyDiamond(selectedFlatRows) : DetailSemiAprrove() }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? HoldDiamond(selectedFlatRows) : DetailSemiAprrove() }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button> */}
                                {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? CompareDiam(selectedFlatRows) : DetailSemiAprrove() }>{language.DIAMOND_COMPARISION_BTN}</button> */}
                                {/* <button type="button" className="btn btn-light-primary mr-2 text-nowrap" onClick={(e) => modifiedSearch()}>{language.SEARCHLIST_MODIFYDIAMONDBTN}</button> */}
                                {/* {location.state.oldvalues?<button className="btn btn-light-primary mr-2 text-nowrap" onClick={toggleDrawer('right', true)}>{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""} */}
                                {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => ExcelDownload(selectedFlatRows) }>{language.DOWNLOAD_EXCEL}</button> */}
                                {/* <button type="button" className="btn btn-light-primary mr-2 text-nowrap" onClick={(e) => ChangeView()}><FaColumns /></button> */}
                                <SwipeableDrawer
                                    anchor="right"
                                    open={swipeableDrawer.right}
                                    onClose={toggleDrawer('right', false)}
                                    onOpen={toggleDrawer('right', true)}
                                >
                                    {/* {sideList('right')} */}
                                    <ModifiedSearchOffcanvas language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage}/>
                                </SwipeableDrawer>
                                <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
                                <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} /> 
                                <CompareDiamond onHide={() => setComparePopup(false)} show={comparePopup} diamondselected={diamondselected} language={language} symbol={symbol}/>
                                <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
                                <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
                                {/* <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                <div className="search_result_dropdown">
                                    <Dropdown >
                                        <Dropdown.Toggle size="sm" variant="clear">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Modal
                                        size="sm"
                                        show={CustomizeTable}
                                        onHide={() => setCustomizeTable(false)}
                                        aria-labelledby="Customize-table"
                                    >
                                        <Modal.Header className="px-5 py-3" closeButton>
                                            <Modal.Title id="Customize-table">
                                                Customize Columns
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-2 searchResult">
                                            <PerfectScrollbar
                                                options={perfectScrollbarOptions}
                                                className="scroll"
                                                style={{ maxHeight: "400px", position: "relative" }}
                                            >
                                            {/* <div  className="" > */}
                                                <div>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                        <span className="mr-2"></span>
                                                        Show All
                                                    </label> 
                                                </div>
                                                {
                                                    allColumns.map(column =>(
                                                        <div key={column.id} className={`${column.id}`}>
                                                            <label className="checkbox my-2 border p-2">
                                                                <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                <span className="mr-2"></span>
                                                                {column.Header}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            {/* </div> */}
                                            </PerfectScrollbar>
                                        </Modal.Body>
                                        <Modal.Footer className="px-5 py-3">
                                            {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                            <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-5 pb-0">
                            <div className="table-responsive custom-scrollbar w-100">
                                <table className="table table-striped table-hover custom_datatable" {...getTableProps()}>
                                    <thead className="custom_datatable_head" >
                                        {headerGroups.map(headerGroup =>(
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                { headerGroup.headers.map((column)=>(
                                                    <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        <span className="text-secondary">
                                                            {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row)
                                            return(
                                                <>
                                                    <tr {...row.getRowProps()} key={row.id}>
                                                        {row.cells.map((cell,i) => {
                                                            return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                        })}
                                                    </tr>
                                                    {row.isExpanded ? (
                                                        <tr>
                                                            <td className="p-0" colSpan={visibleColumns.length}>
                                                                {renderRowSubComponent({ row })} 
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                        <div className="card-footer border-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                                <div>
                                    <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                                    <span className="font-weight-bold text-muted">{'  '}
                                        <input
                                            className="form-control d-inline-block form-control-sm"
                                            type="number"
                                            value={pageIndex + 1}
                                            // defaultValue={pageIndex + 1}
                                            onChange={ e => { 
                                                const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                            style={{maxWidth:'70px'}}
                                        />
                                        {'  '}of{'  '} {pageOptions.length}{'  '}
                                    </span>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                                    <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                                </div>
                                <span className="d-flex align-items-center">
                                <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                                    <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                        {
                                            [10, 25, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>{pageSize}</option>
                                            ))
                                        }
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div> :
                    <div className="card custom-card gutter-b">
                        <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
                            <div className="text-center">
                                <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                                <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>{modifymsg}</strong></div>
                                <div class="mt-3 text-muted">Please Modify Your Search</div>
                                {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
                                <SwipeableDrawer
                                    anchor="right"
                                    open={swipeableDrawer.right}
                                    onClose={toggleDrawer('right', false)}
                                    onOpen={toggleDrawer('right', true)}
                                >
                                    {/* {sideList('right')} */}
                                    <ModifiedSearchOffcanvas language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage}/>
                                </SwipeableDrawer>
                                {/* {location.state.oldvalues?<button className="btn btn-primary mt-5 px-8" onClick={toggleDrawer('right', true)}>{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""} */}
                            </div>
                        </div>
                    </div>
                    }
                </>
            }
        </>
    )
}