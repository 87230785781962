import React, { useEffect , useState } from "react";
import { Field } from "formik";
import {toAbsoluteUrl} from "../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const Shape = () => {

    const ShapeData = ([
        {
            id: 1,
            shape:"round"
        },
        {
            id: 2,
            shape:"princess"
        },
        {
            id: 3,
            shape:"oval"
        },
        {
            id: 4,
            shape:"heart"
        },
        {
            id: 5,
            shape:"cushion"
        },
        {
            id: 6,
            shape:"emerald"
        },
        {
            id: 7,
            shape:"marquise"
        },
        {
            id: 8,
            shape:"cushion modified"
        },
        {
            id: 9,
            shape:"pear"
        },
        {
            id: 10,
            shape:"asscher"
        },
        {
            id: 11,
            shape:"radiant"
        },
        {
            id: 12,
            shape:"square radiant"
        },
        {
            id: 13,
            shape:"triangle"
        },
        {
            id: 14,
            shape:"trilliant"
        },
    ]);
    return (
        <>
        
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Shape</div>
                <div className="col-12">
                    {ShapeData.map((item) => (
                        <OverlayTrigger placement="top" key={item.id} overlay={<Tooltip id="tooltip" >{item.shape}</Tooltip>} >
                            <label className="search_btn_check">
                                <Field type="checkbox" name="Shape" id={`${item.shape}_shape`} value={item.shape}/>
                                {/* <span></span> */}
                                <div className="shapeBox selectBox">
                                    <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/${item.shape}.svg`)} />
                                </div>
                            </label>
                        </OverlayTrigger>
                    ))}
                </div>
            </div>
        </>
    );
};
