/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { GetCookies } from "../../_helpers/Cookies";
import { FaCog } from 'react-icons/fa';
// import {ApiDataNatural} from "./ApiDataTable/ApiDataNatural"
// import {ApiDataLab} from "./ApiDataTable/ApiDataLab"
import { ApiDataTable } from "./ApiDataTable/APIDataTable";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import {
    Paper,
    Tabs,
    Tab,
    Typography
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  import SelectFieldCurrency from "./ApiSetup/FormFields/SelectFieldCurrency";
  import { NODE_API_URL } from "../../../env_config";
  import axios from "axios";
  import { TextField} from '@material-ui/core';
  import { TaxPopUp } from "../Popup/TaxPopUp";
  import {
    FaAward,
    FaTrashAlt,
    FaRegEdit,
    FaSave,
    FaRegTimesCircle,
} from "react-icons/fa";
  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
  

export const RulePage = (props) => {
    const history = useHistory()
    console.log(props.customerapi,"customerapicustomerapi")
    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
      setValue(newValue);
    }
    const [inr,setInr] = useState(0)
    const [usd,setUsd] = useState(0)
    const [cad,setCad] = useState(0)
    const [aud,setAud] = useState(0)
    const [hkd,setHkd] = useState(0)
    const [cny,setCny] = useState(0)
    const [eur,setEur] = useState(0)
    const [gbp,setGbp] = useState(0)
    const [nzd,setNzd] = useState(0)
    const [jpy,setJpy] = useState(0)
    const [chf,setChf] = useState(0)
    const [currencyvalue,setCurrencyValue] = useState("")
    const [supplieron,setSupplierOn] = useState(null)
    const [taxname,setTaxName] = useState("")
    const [taxvalue,setTaxValue] = useState(0)
    const [edittax,setEditTax] = useState(false)
    const [updatestate,setUpdateState] = useState(false) 
    useEffect(() => {
        console.log("conversionratesuseEffect")
        GetCookies("conversionrates").then(response => {
            // console.log(response,"Conversionresponse")
            if(response && response[0]){
                const currencies = JSON.parse(response)
                // console.log(currencies,"currencies")
                setInr(Math.round((currencies[0].cur_inr)*100)/100)
                setUsd(Math.round(currencies[0].cur_usd*100)/100)
                setCad(Math.round(currencies[0].cur_cad*100)/100)
                setAud(Math.round(currencies[0].cur_aud*100)/100)
                setHkd(Math.round(currencies[0].cur_hkd*100)/100)
                setCny(Math.round(currencies[0].cur_cny*100)/100)
                setEur(Math.round(currencies[0].cur_eur*100)/100)
                setGbp(Math.round(currencies[0].cur_gbp*100)/100)
                setNzd(Math.round(currencies[0].cur_nzd*100)/100)
                setJpy(Math.round(currencies[0].cur_jpy*100)/100)
                setChf(Math.round(currencies[0].cur_chf*100)/100)

            }
        })
        setCurrencyValue(props.customeriframeorapi.api_currency)
        setTaxName(props.customeriframeorapi.api_taxname)
        setTaxValue(props.customeriframeorapi.api_taxvalue)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/fetchsupplierstep`,
            headers: { "Content-Type": "application/json" },
            data: {
                "user_id":props.userinfo.id,
                "api_id":props.userinfo.api_id
            },
        }).then(supreq => {
            if(supreq && supreq.data){
                if(supreq.data !== "Please Turn On Suppliers"){
                    setSupplierOn(supreq.data)

                }
            }
        })
    },[props.customerapi,updatestate,props.customeriframeorapi])
    const muti_currency_data = [
      {
        value: 'INR',
        label: `INR  - ${inr.toFixed(2)}`
      },
        {
          value: 'USD',
          label: `USD $ - ${usd.toFixed(2)}`
        },
        {
          value: 'CAD',
          label: `CAD $ - ${cad.toFixed(2)}`
        },
        {
          value: 'AUD',
          label: `AUD $ - ${aud.toFixed(2)}`
        },
        {
            value: 'HKD',
            label: `HKD $ - ${hkd.toFixed(2)}`
          },
          {
            value: 'CNY',
            label: `CNY ¥ - ${cny.toFixed(2)}`
          },
          {
            value: 'EUR',
            label: `EUR € - ${eur.toFixed(2)}`
          },
          {
              value: 'GBP',
              label: `GBP £ - ${gbp.toFixed(2)}`
            },
            {
              value: 'NZD',
              label: `NZD $ - ${nzd.toFixed(2)}`
            },
            {
              value: 'JPY',
              label: `JPY ¥ - ${jpy.toFixed(2)}`
            },
            {
              value: 'CHF',
              label: `CHF ₣ - ${chf.toFixed(2)}`
            }
      ];
      const SetTax = () => {
        setEditTax(false)
      }
      const CancelTax = () => {
        setEditTax(false)
        setUpdateState(!updatestate)
      }
      const SaveTax = () => {
        setEditTax(false)
        axios({
          method: "POST",
          url: `${NODE_API_URL}/TESTMODE/updateApiCurrencyAndTax`,
          headers: { "Content-Type": "application/json" },
          data: {
              "user_id":props.userinfo.id,
              "columnname":"tax",
              "api_taxname":taxname,
              "api_taxvalue":taxvalue
          },
      }).then(supreq => {
          if(supreq && supreq.data){
              if(supreq.data === "Data Updated Successfully"){
                setUpdateState(!updatestate)

              }
          }
      })
      }
      const UpdateCurrency = (value) => {
        console.log("UpdateCurrency")
        axios({
          method: "POST",
          url: `${NODE_API_URL}/TESTMODE/updateApiCurrencyAndTax`,
          headers: { "Content-Type": "application/json" },
          data: {
              "user_id":props.userinfo.id,
              "columnname":"currency",
              "api_currency":value
          },
      }).then(supreq => {
          if(supreq && supreq.data){
              if(supreq.data === "Data Updated Successfully"){
                setUpdateState(!updatestate)

              }
          }
      })
      }
     console.log(supplieron,"supplieron") 
     console.log(taxvalue,"taxvalue")
    return (
        <>
        <TaxPopUp show={edittax} onHide={() => CancelTax()} onClose={() => CancelTax(false)} responseOnOk={() => SaveTax()} setTaxName={setTaxName} setTaxValue={setTaxValue} taxname={taxname} taxvalue={taxvalue}/>
            <div className="card shadow-none card-custom">
                <div className="card-header">
                  <h3 className="card-title">Rule Page</h3>
                  <div className=" card-toolbar d-flex align-items-center">                            
                      <div className="">
                        <SelectFieldCurrency
                          name={"currency"}
                          label={"Select Currency"}
                          style={{minWidth: 200}}
                          data={muti_currency_data}
                          value={currencyvalue}
                          onChange={(e) => UpdateCurrency(e.target.value)}
                          disabled={true}
                        />
                      </div>
                      <div className="mr-2">
                            <TextField
                            variant="outlined"
                            type="text"
                            name={"Tax"}
                            label={"Tax(%)"}
                            style={{minWidth: 200}}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            // onChange={(e) => setTaxValue(e.target.value)}
                            disabled={true}
                            value={taxvalue && taxname?`Inclusive ${taxname} - ${taxvalue}%`:"Exclusive Tax"}
                          />
                          
                          {/* {!edittax?<button
                            className="btn btn-primary btn-sm"
                            type="button"
                            onClick={() => setEditTax(true)}
                          >
                            <FaRegEdit className="font-size-h5" />
                          </button>:""} */}
                          </div>
                      {props.userinfo.feed_type === "API" ? 
                        <button className="btn font-weight-bold btn-square btn-secondary px-8 mr-2" 
                          onClick={() => history.push({
                            pathname: "/settings",
                            state: "apisetting",
                            // state: decompress(res.data.ProductDetails),
                        })}><FaCog className="mr-2" />{props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_APISETTING}</button>:""}
                        {/* <button className="btn font-weight-bold btn-square btn-primary px-8 mr-2" 
                          onClick={() => history.push({
                            pathname: "/api-frame/naturalsearch",
                            state: "apisetting",
                            // state: decompress(res.data.ProductDetails),
                        })}>API IFrame</button> */}
                  </div>
              </div>
              <div className="card-body">  
                <ApiDataTable userinfo={props.userinfo} language={props.language} customerapi={props.customerapi} setUpdateData={props.setUpdateData} updatedata={props.updatedata} currencyvalue={currencyvalue} supplieron={supplieron} loader={props.loader} setCustomerAPI={props.setCustomerAPI}/>
              </div>
            </div>
        </>
    );
};
