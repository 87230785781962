import React from "react";
import { Formik, Form, Field } from "formik";
import { Basic } from "./ModifiedSearch/Basic";
import { EyeClean } from "./ModifiedSearch/EyeClean";
import { Preference } from "./ModifiedSearch/Preference";
import { Preference1 } from "./ModifiedSearch/Preference1";
import { Additional } from "./ModifiedSearch/Additional";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NODE_API_URL } from "../../../env_config";
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify"
// import { Backdrop, CircularProgress } from "@material-ui/core";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

function ModifiedSearchOffCanvasGrid(props) {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const history = useHistory()


    const enableLoading = () => {
        // setLoading(true);
        // setOpenLoader(true);
        setIsSubmitting(true)
    };

    const disableLoading = () => {
        // setLoading(false);
        // setOpenLoader(false);
        setIsSubmitting(false)
    };
    function handleData(values,oldvalues) {
        // enableLoading();
        props.setLoader(true)
    const userData = localStorage.getItem("userData")
    const user = JSON.parse(userData);
    const id = localStorage.getItem("C_id");
        let len = Object.keys(values).length;
        const temp = values;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${user.CustomerId}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${oldvalues.diamond_type}" }`;
        if(oldvalues.diamond_type === "N"){
            localStorage.setItem("modifysearch",JSON.stringify(values))
        }
        else{
            localStorage.setItem("modifysearchlab",JSON.stringify(values))
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/SearchCountFunctionality`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then(function(res) {
            localStorage.setItem("stoneCount", JSON.stringify(res.data.data))
            if ((res.data.error)){ 
                toast("Something Went Wrong!")
                props.setSwipeableDrawer('right', false)    
        props.setLoader(false)


            } else {
                if (JSON.stringify(res.data.data) === "0") {
                    props.setSwipeableDrawer('right', false)    
                    props.setStockAPI([])
                    disableLoading();
                    props.setLoader(false)
                    // toast.error("Result Count is 0")
                    props.setModifyMessage("0 Stones found please try again")
                    history.push({
                        pathname: "/search-grid",
                        state: {
                            searchresult:[],
                            oldvalues:oldvalues,
                            modifymsg:"0 Stones found please try again"
                        },
                            // state: decompress(res.data.ProductDetails),
                        });
                        window.location.reload(true)

                } else {
                    if (res.data.data > 5000) {
            localStorage.setItem("stoneCount", JSON.stringify(0))
            props.setSwipeableDrawer('right', false)    
            props.setStockAPI([])
            disableLoading();
            props.setLoader(false)
            // toast.error("Result Count Greater Than 5000")
            props.setModifyMessage(`${res.data.data} Stones found Please Narrow Your Search to 5000`)
            history.push({
                pathname: "/search-grid",
                state: {
                    searchresult:[],
                    oldvalues:oldvalues,
                    modifymsg:`${res.data.data} Stones found Please Narrow Your Search to 5000`
                },
                    // state: decompress(res.data.ProductDetails),
                });
                window.location.reload(true)
                    } else {
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/TESTMODE/SearchFunctionality`,
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: finalData,
                        })
                        .then(function(res) {
                            props.setSwipeableDrawer('right', false)    
                            props.setStockAPI(res.data.data)
                            disableLoading();
                            props.setLoader(false)
                            history.push({
                                pathname: "/search-grid",
                                state: {
                                    searchresult:res.data.data,
                                    oldvalues:oldvalues
                                },
                                    // state: decompress(res.data.ProductDetails),
                                });
                            window.location.reload(true)    
                        })
                        .catch(function(res) {
                            console.error(res);
                            console.error("finalData",finalData);
                        });
                    }
                } 
            }
        })
        .catch(function(res) {

        });
    }
    return (
        <>
            <div style={{ width: "380px" }}>
                <div className="row m-0 mt-5">
                    <div className="col-12 border-bottom d-flex justify-content-between align-items-center sticky-top bg-white">
                        <p className="font-size-h3 ml-5 font-weight-bold">
                            {props.language.MODIFY_SEARCH_EDIT_FILTER}
                        </p>
                        
                        <p className=" mr-5 font-weight-bold text-primary" onClick={() =>  props.diamond_type === "L"?localStorage.removeItem("modifysearchlab"):localStorage.removeItem("modifysearch")}>
                        {props.diamond_type === "L"?<a href='/labsearchdiamond/white'> {props.language.MODIFY_SEARCH_EDIT_CLEARALL}</a>:<a href='/searchdiamond/white'> {props.language.MODIFY_SEARCH_EDIT_CLEARALL}</a>}
                        </p>
                        
                    </div>
                    
                        <Formik
                            enableReinitialize={true}
                            initialValues={{}}
                            onSubmit={async (values, { setSubmitting }) => {
                                // enableLoading();
                                let oldvalues =values
                                Object.keys(values).forEach((key) => {
                                    if (
                                        values[key] === "" ||
                                        values[key] === null ||
                                        values[key].length === 0
                                    ) {
                                        delete values[key];
                                    }
                                });
                                setTimeout(() => {
                                    handleData(values,oldvalues);
                                }, 1000);
                            }}
                        >
                            {(formik, handleSubmit) => (
                                <>
                                    <Form className="w-100">
                                        <div className={`col-12 border-bottom d-flex justify-content-between align-items-center mt-5 modified-search-canvas ${props.diamond_type === "L"? "labTab" :"natTab" }`}>
                                            <PerfectScrollbar
                                                options={perfectScrollbarOptions}
                                                className="scroll w-100"
                                                style={{
                                                    maxHeight: "85vh",
                                                    position: "relative",
                                                }}
                                            >
                                                <div className="mb-8">
                                                    <Basic language={props.language} diamond_type={props.diamond_type} formik={formik} oldvalues={props.oldvalues}/>
                                                </div>
                                                <div className="mb-8">
                                                    <EyeClean language={props.language} formik={formik}/>
                                                </div>
                                                <div className="mb-8">
                                                    <Preference language={props.language} />
                                                </div>
                                                <div className="mb-8">
                                                    <Preference1 language={props.language}/>
                                                </div>
                                                <div className="mb-8">
                                                    <Additional language={props.language} diamond_type={props.diamond_type} formik={formik}/>
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                        <div className={`col-12 my-2 text-center ${props.diamond_type === "L"? "labTab" : "natTab" }`}>
                                            <button
                                                type="submit"
                                                className={`btn btn-primary px-10 font-weight-bold btn-square mr-2 btn-lg ${isSubmitting ? "cursor-na" : ""}`}
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                {isSubmitting
                                                    ? "Please wait..."
                                                    : props.language.MODIFY_SEARCH_EDIT_SEARCH}
                                                
                                            </button>
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    
                </div>
            </div>
        </>
    );
}                                   

export default ModifiedSearchOffCanvasGrid;
