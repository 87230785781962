import React, { useEffect } from "react";
import { Shape } from "./Basic/Shape"
import { Price } from "./Basic/Price";
import { Carat } from "./Basic/Carat";
import { Color } from "./Basic/Color";
import { Cut } from "./Basic/Cut";
import { Clarity } from "./Basic/Clarity";
import { FancyColor } from "./Basic/FancyColor";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";
export const Basic = ({formik,setSearchData,diamond_type,custompage,setLoader,sorting,fancy,userinfo}) => {
    useEffect(() => {
        let FinalObject = {
            page:custompage
        }
        console.log(formik.values,"formik.values")
        if(formik.values.Shape && formik.values.Shape.length){
            FinalObject["shape"] = formik.values.Shape
        }
        if(formik.values.Cut && formik.values.Cut.length){
            FinalObject["cut"] = formik.values.Cut
        }
        if(formik.values.Clarity && formik.values.Clarity.length){
            FinalObject["clarity"] = formik.values.Clarity
        }
        if(formik.values.Color && formik.values.Color.length){
            FinalObject["color"] = formik.values.Color
        }
        if(fancy){
            FinalObject["fancy_color_diamond"] = "YES"
        }
        if(formik.values.Fancy_Color && formik.values.Fancy_Color.length){
            //FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_color"] = formik.values.Fancy_Color
        }
        if(formik.values.Fancy_Color_Overtone && formik.values.Fancy_Color_Overtone.length){
            //FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_overtone"] = formik.values.Fancy_Color_Overtone
        }
        if(formik.values.Fancy_Color_Intensity && formik.values.Fancy_Color_Intensity.length){
            //FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_intensity"] = formik.values.Fancy_Color_Intensity
        }
        if(formik.values.Lab && formik.values.Lab.length){
            FinalObject["lab"] = formik.values.Lab
        }
        if(formik.values.Polish && formik.values.Polish.length){
            FinalObject["polish"] = formik.values.Polish
        }
        if(formik.values.Symmetry && formik.values.Symmetry.length){
            FinalObject["symmetry"] = formik.values.Symmetry
        }
        if(formik.values.Fluorescence_Intensity && formik.values.Fluorescence_Intensity.length){
            FinalObject["fluorescence"] = formik.values.Fluorescence_Intensity
        }
        if(formik.values.minCarat >= 0){
            FinalObject["min_carat"] = formik.values.minCarat
        }
        if(formik.values.maxCarat >= 0){
            FinalObject["max_carat"] = formik.values.maxCarat
        }
        if(formik.values.minPrice >= 0){
            FinalObject["total_price_from"] = formik.values.minPrice
        }
        if(formik.values.maxPrice >= 0){
            FinalObject["total_price_to"] = formik.values.maxPrice
        }

        if(formik.values.minDepth >= 0){
            FinalObject["depthmin"] = formik.values.minDepth
        }
        if(formik.values.maxDepth >= 0){
            FinalObject["depthmax"] = formik.values.maxDepth
        }
        if(formik.values.minTable >= 0){
            FinalObject["tablemin"] = formik.values.minTable
        }
        if(formik.values.maxTable >= 0){
            FinalObject["tablemax"] = formik.values.maxTable
        }
        if(formik.values.minRatio >= 0){
            FinalObject["ratiomin"] = formik.values.minRatio
        }
        if(formik.values.maxRatio >= 0){
            FinalObject["ratiomax"] = formik.values.maxRatio
        }
        if(sorting === "carat_desc"){
            FinalObject["caratfilter"] = "dsc"
        }
        if(sorting === "carat_asc"){
            FinalObject["caratfilter"] = "asc"
        }
        if(sorting === "price_desc"){
            FinalObject["pricefilter"] = "dsc"
        }
        if(sorting === "price_asc"){
            FinalObject["pricefilter"] = "asc"
        }
        if(sorting === "clarity_desc"){
            FinalObject["clarityfilter"] = "dsc"
        }
        if(sorting === "clarity_asc"){
            FinalObject["clarityfilter"] = "asc"
        }
        if(sorting === "color_desc"){
            FinalObject["colorfilter"] = "dsc"
        }
        if(sorting === "color_asc"){
            FinalObject["colorfilter"] = "asc"
        }
        setLoader(true)
        if(userinfo && userinfo.api_test_key && userinfo.id){
            axios({
                method: "POST",
                url: `${NODE_API_URL}/TESTMODE/getTestToken`,
                headers: { 
                    "Content-Type": "application/json",
                 },
                data:{
                    apikey:userinfo.api_test_key,
                    user_id:userinfo.id
                } 
            }).then(tokendata => {
                console.log(tokendata,"tokendatatokendata")
                if(tokendata && tokendata.data){
                    console.log(tokendata.data,"tokendata.data")
                    if(diamond_type === "N"){
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/feed/naturaldiamonds`,
                            headers: { 
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${tokendata.data}`
                             },
                            data: FinalObject,
                        }).then(res => {
                            setLoader(false)
                            if(res && res.data && res.data.success){
                                setSearchData(res.data)
                            }
                            else{
                                setSearchData([])
                            }
                        }).catch(err => {
                            setLoader(false)
                        })
                    }
                    if(diamond_type === "L"){
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/feed/labgrowndiamonds`,
                            headers: { 
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${tokendata.data}`
                             },
                            data: FinalObject,
                        }).then(res => {
                            setLoader(false)
                            if(res && res.data && res.data.success){
                                setSearchData(res.data)
                            }
                            else{
                                setSearchData([])
                            }
                        }).catch(err => {
                            setLoader(false)
                        })
                    }
                }
            })
        }
    },[formik.values,custompage,diamond_type,sorting,fancy])
       return (
        <>
           <div className="row m-0">
                <div className="col-md-4">
                    <Shape formik={formik} />
                </div>
                <div className="col-md-4">
                    <Price formik={formik} />
                </div>
                <div className="col-md-4">
                    <Carat formik={formik} />
                </div>
                <div className="col-md-4">
                    {!fancy?<Color formik={formik} />:""}
                </div>
                <div className="col-md-4">
                    <Cut formik={formik} />
                </div>
                <div className="col-md-4">
                    <Clarity formik={formik} />
                </div>
                    {fancy?<FancyColor formik={formik} />:""}
            </div>
        </>
    );
};
