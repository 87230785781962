import React from "react";
import { Field } from "formik";

export const Location = ({language}) => {
    const locationData = ([
        {
            id: 1,
            location:"INDIA"
        },
        {
            id: 2,
            location:"HONG KONG"
        },
        {
            id: 3,
            location:"USA"
        },
        {
            id: 4,
            location:"UAE"
        },
        {
            id: 5,
            location:"UK"
        },
        {
            id: 6,
            location:"ISRAEL"
        },
        {
            id: 7,
            location:"BELGIUM"
        },
        {
            id: 7,
            location:"OTHER"
        }
    ]);
    const DeliveryData = ([
        {
            id: 0,
            delivery:"Select"
        },
        {
            id: 1,
            delivery:"1 to 2 days"
        },
        {
            id: 2,
            delivery:"2 to 3 days"
        },
        {
            id: 3,
            delivery:"3 to 4 days"
        }
    ]);

    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">{language.LOCATION_LOCATION}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        {/* <div className="col-lg-8">
                            <label htmlFor="">LOCATION</label><br />
                            <Field as="select" className="form-control" name="location">
                                {locationData.map((item) => (
                                    <option key={item.id} value={item.location}>{item.location}</option>
                                ))}
                            </Field>
                        </div> */}
                        <div className="col-md-9 d-flex flex-wrap">
                            <label className="col-12 p-0" htmlFor="">LOCATION</label>
                            {locationData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Location" id={`${item.location}_loc`} value={item.location}/>
                                    <div className="flourBox selectBox">
                                        <span>{item.location}</span>
                                    </div>
                                </label>
                            ))}
                        </div>
                        {/* <div className="col-md-3">
                            <label htmlFor="">ESTIMATE SHIPPING IN DAYS</label><br />
                            <Field as="select" className="form-control" name="delivery">
                                {DeliveryData.map((item) => (
                                    <option key={item.id} value={item.delivery}>{item.delivery}</option>
                                ))}
                            </Field>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};