import React, { useMemo } from "react";
import StockAPI from "./StockAPI.json"
import { GridCard } from "./Grid/GridCard";
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowAltCircleRight, FaArrowCircleDown } from "react-icons/fa";
import Loader from "../Loader";
export const Grid = (props) => {
    // const [data, setData] = React.useState(StockAPI);
    const data = useMemo(()=> props.searchdata.data || [],[props.searchdata.data])
    return (
        <>
        {props.loader?<Loader/>:<div>
            <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{props.custompage} of {props.searchdata && props.searchdata.total?Math.ceil(props.searchdata.total/100):0} {' '}</span>
                        <div>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => props.setCustomPage(1)} disabled={props.custompage === 1}><FaAngleDoubleLeft /></button>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => props.setCustomPage(props.custompage - 1)} disabled={props.custompage === 1}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={props.custompage}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value): 1
                                        // gotoPage(pageNumber)
                                        if(pageNumber > Math.ceil(props.searchdata.total/100) || pageNumber < 1){
                                            e.preventDefault()
                                            return;
                                        }
                                        props.setCustomPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {props.searchdata && props.searchdata.total?Math.ceil(props.searchdata.total/100):0}{'  '}
                            </span>
                            <button type="button" className="btn btn-sm btn-light mr-2" onClick={() => props.setCustomPage(props.custompage + 1)} disabled={props.custompage >= Math.ceil(props.searchdata.total/100)}><FaAngleRight /></button>
                            <button type="button" className="btn btn-sm btn-light" onClick={()=>props.setCustomPage(Math.ceil(props.searchdata.total/100))} disabled={props.custompage >= Math.ceil(props.searchdata.total/100)}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Data Per page:100</span>
                        </span>
                    </div>
                </div>
        {data.length?<div className="row mt-5">
            {data.map((val) => {
                return (
                    <GridCard
                        key={val.id}
                        {...val}
                    />
                );
            })}
        </div>:<div className="text-center mt-5">No Record Found</div>}
        </div>}
        </>
    );
};
