import React, { useEffect, useMemo ,useState} from "react";
import { useLocation } from "react-router-dom";
import {Modal,Button} from "react-bootstrap";
import {toAbsoluteUrl} from "../../_helpers";
import { BsArrowLeftShort } from "react-icons/bs";
import axios from "axios"
import { NODE_API_URL } from "../../../env_config";
import VideoIframe from "../detailPage/VideoIframe";
export const ProductDetailMain = (props) => {
    const location = useLocation();
    
    const [data,setData] = useState(location.state)
    // const data = useMemo(()=> location.state,[location.state])
    useEffect(() => {
        if(props && props.userinfo && props.userinfo.api_test_key && props.userinfo.id){
            axios({
                method: "POST",
                url: `${NODE_API_URL}/TESTMODE/getTestToken`,
                headers: { 
                    "Content-Type": "application/json",
                 },
                data:{
                    apikey:props.userinfo.api_test_key,
                    user_id:props.userinfo.id
                } 
            })
            .then(tokendata => {
                if(tokendata && tokendata.data){
                    const FinalObject = {
                        Certi_NO:location.state.CERTIFICATE_NO
                    }
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/feed/diamonddetail`,
                        headers: { 
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${tokendata.data}`
                         },
                        data: FinalObject,
                    }).then(res => {
                        // console.log(res)
                        if(res && res.data && res.data.success){
                            setData(res.data.data)
                        }
                    })
                }
            })
        }
    },[location.state])
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
 
    console.log(data,"datadata")
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }
  
    const openVideoModal = () => setShowVideo(true);
    
    function openHeartModal() {
        setShowHeartImg(true)
    }
    function openAssetModal() {
        setShowAssetImg(true)
    }
    function openArrowModal() {
        setShowArrowImg(true)
    }

    function certificateLink(){
        if (data.LAB === "IGI"){
            const  certificate_link = `https://www.igi.org/viewpdf.php?r=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.Certificate_link){
            const certificate_link = data.Certificate_link
            return certificate_link
        }
    }


    return (
        <>
            {data ? 
                <div className={`container product_detail ${
                        data.diamond_type === "N" ? "natTab" : "labTab"
                    }`}>
                    <div className="card card-custom card-sticky gutter-b">
                        <div className="card-body">
                            <div className="row">
                                {/* data: {JSON.stringify(data)} */}
                                <div className="col-12">
                                    <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> go to result</span>
                                </div>
                                <div className="col-md-6 d-flex">
                                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                                        <li className="img_thumb">
                                            {data.IMAGE ? (
                                                <img
                                                    className="img thumb"
                                                    alt={data.SHAPE}
                                                    src={data.IMAGE}
                                                />
                                            ) : (
                                                <img
                                                    className="img thumb"
                                                    alt="shape"
                                                    
                                                    src={toAbsoluteUrl(
                                                        `/media/shape/${data.SHAPE.toLowerCase()}-no.png`
                                                    )}
                                                />
                                            )}
                                        </li>
                                        {data.VIDEO  ? (
                                            <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                                                {/* <FaVideo className="font-size-sm text-primary cursor-pointer"  /> */}
                                                {/* <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/heart-no.png`)}/> */}
                                            </li>
                                        ):('')}
                                        {data.HEART_IMAGE ? (
                                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                            
                                            </li>
                                            
                                        ):('')}
                                        {data.ARROW_IMAGE ? (
                                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                                                
                                            </li>
                                        ):('')}
                                        {data.ASSET_IMAGE ? (
                                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                                                
                                            </li>
                                        ):('')}
                                    </ul>
                                    <div className="img_box mt-4">
                                        {data.IMAGE ? (
                                            <img
                                                className="img-fluid"
                                                alt={data.SHAPE}
                                                src={data.IMAGE}
                                            />
                                        ) : (
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${data.SHAPE.toLowerCase()}-no.png`
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-12 my-3 d-flex justify-content-lg-start align-items-center">
                                            <span className="font-size-sm text-primary mr-4">
                                                <a
                                                    id={data.CERTIFICATE_NO}
                                                    href={certificateLink()}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="font-size-sm text-primary"
                                                >
                                                    {data.LAB}-{data.CERTIFICATE_NO}
                                                </a>
                                            </span>
                                            {/* <div className="font-size-sm mr-1 text-dark-50">Stock No: <div className="font-size-sm text-dark-50 font-weight-bold d-inline-block">{data.diamond_type === "N" ? "N" :"L"}-{data.id ? data.id : "-"}</div></div> */}
                                        </div>
                                        <div className="col-12 mt-4">
                                            <span className="text-dark font-weight-bold text-capitalize font-size-h2">
                                            <span className="pr-2">{data.LAB}</span>
                                                <span className="pr-2">
                                                    {parseFloat(data.CARAT).toFixed(2)}
                                                </span>
                                                <span className="pr-2">{data.SHAPE}</span>
                                                { (!data.COLOR) ?
                                                    (
                                                        <>
                                                            <span className="pr-2">{data.FANCY_INTENSITY}</span>
                                                            <span className="pr-2">{data.FANCY_OVERTONE}</span>
                                                            <span className="pr-2">{data.FANCY_COLOR}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="pr-2">{data.COLOR}</span>
                                                        </>
                                                    )
                                                }
                                                <span className="pr-2">{data.CLARITY}</span>
                                                <span className="pr-2">{data.CUT}</span>
                                                <span className="pr-2">{data.POLISH}</span>
                                                <span className="pr-2">{data.SYMMETRY}</span>
                                                <span className="pr-2">{data.FLUORESCENCE}</span>
                                            </span>
                                        </div>
                                        <div className="col-12 d-flex align-items-end mt-5">
                                            <span>
                                                <div className="font-size-sm text-dark-50">$/CT {parseFloat(data.PRICE_PER_CTS).toLocaleString()}</div>
                                                <div className="font-size-h1 font-weight-bold text-primary">${parseFloat(data.TOTAL_PRICE).toLocaleString()}</div>
                                            </span>
                                        </div>
                                        
                                        <div className="col-12 mt-5">
                                            <span className="d-flex align-items-center font-weight-bold">
                                                <div className="text-dark mr-1">M</div>
                                                <div className="text-primary ml-2">  {parseFloat(data.LENGTH).toFixed(2)} x {parseFloat(data.WIDTH).toFixed(2)} x {parseFloat(data.DEPTH).toFixed(2)}  </div>
                                            </span>
                                        </div>
                                        <div className="col-12">
                                            <span className="d-flex align-items-center font-weight-bold">
                                                <div className="text-dark mr-1">T</div>
                                                <div className="text-primary ml-2">  {data.TABLE_PER ? <>{parseFloat(data.TABLE_PER).toFixed(2)}%</> : "-"}  </div>
                                            </span>
                                        </div>
                                        <div className="col-12">
                                            <span className="d-flex align-items-center font-weight-bold">
                                                <div className="text-dark mr-1">D</div>
                                                <div className="text-primary ml-2">  {data.DEPTH_PER ? <>{parseFloat(data.DEPTH_PER).toFixed(2)}%</> : "-"}  </div>
                                            </span>
                                        </div>
                                        {data.RATIO ? (
                                            <div className="col-12">
                                                <span className="d-flex align-items-center font-weight-bold">
                                                    <div className="text-dark mr-1">R</div>
                                                    <div className="text-primary ml-2">  {data.RATIO && data.RATIO !== '-' ? <>{parseFloat(data.RATIO).toFixed(2)}</> : "-"}  </div>
                                                </span>
                                            </div>
                                        ):('')}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-custom card-sticky gutter-b">
                        <div className="card-header justify-content-center mb-3">
                            <div className="card-title m-0">
                                <span className="card-label text-dark">
                                    DESCRIPTION
                                </span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {/* data: {JSON.stringify(data)} */}
                                <div className="col-md-6">
                                    <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> Diamond Details </div>
                                    {/* <div className="row mb-1">
                                        <div className="col-6"> Availability </div>
                                        <div className="col-6 font-weight-bolder"> {data.Availability ? data.Availability : "-"} </div>
                                    </div> */}
                                    {/* <div className="row mb-1">
                                        <div className="col-6"> Stock ID </div>
                                        <div className="col-6 font-weight-bolder"> {data.diamond_type === "N" ? "N" :"L"}-{data.id ? data.id : "-"} </div>
                                    </div> */}
                                    <div className="row mb-1">
                                        <div className="col-6"> Shape </div>
                                        <div className="col-6 font-weight-bolder">  {data.SHAPE ? data.SHAPE : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6"> Carat </div>
                                        <div className="col-6 font-weight-bolder">  {data.CARAT ? parseFloat(data.CARAT).toFixed(2) : "-"} </div>
                                    </div>
                                    { (data.COLOR !=="fancy") ? (
                                        <div className="row mb-1">
                                            <div className="col-6"> Color </div>
                                            <div className="col-6 font-weight-bolder">  {data.COLOR ? data.COLOR : "-"} </div>
                                        </div>
                                    ):('')}
                                
                                    <div className="row mb-1">
                                        <div className="col-6"> Clarity </div>
                                        <div className="col-6 font-weight-bolder">  {data.CLARITY ? data.CLARITY : "-"} </div>
                                    </div>
                                    { (data.CUT) ? (
                                        <div className="row mb-1">
                                            <div className="col-6"> Cut </div>
                                            <div className="col-6 font-weight-bolder">  {data.CUT ? data.CUT : "-"} </div>
                                        </div>
                                    ):('')}
                                    <div className="row mb-1">
                                        <div className="col-6"> Polish </div>
                                        <div className="col-6 font-weight-bolder">  {data.POLISH ? data.POLISH : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6"> Symmetry </div>
                                        <div className="col-6 font-weight-bolder">  {data.SYMMETRY ? data.SYMMETRY : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6"> Fluorescence</div>
                                        <div className="col-6 font-weight-bolder">  {data.FLUORESCENCE ? data.FLUORESCENCE : "-"} </div>
                                    </div>
                                    
                                    <div className="row mb-1">
                                        <div className="col-6">Lab</div>
                                        <div className="col-6 font-weight-bolder">  {data.LAB ? data.LAB : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Certificate No.</div>
                                        <div className="col-6 font-weight-bolder">
                                            <a
                                                id={data.CERTIFICATE_NO}
                                                href={certificateLink()}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="font-size-sm text-primary"
                                            >
                                                {data.CERTIFICATE_NO}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Location</div>
                                        <div className="col-6 font-weight-bolder">  {data.COUNTRY ? data.COUNTRY : "-"} </div>
                                    </div>
                                    {/* <div className="row mb-1">
                                        <div className="col-6">Shipping Days</div>
                                        <div className="col-6 font-weight-bolder">  {data.ShippingDays ? <>{data.ShippingDays} Days</> : "-"} </div>
                                    </div> */}
                                </div>
                                <div className="col-md-6"> 
                                    { (!data.COLOR) ? (
                                        <>
                                            <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary">Fancy Color Details </div>
                                            <div className="row mb-1">
                                                <div className="col-6">Intensity</div>
                                                <div className="col-6 font-weight-bolder">  {data.FANCY_INTENSITY ? data.FANCY_INTENSITY : "-"} </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-6">Overtone</div>
                                                <div className="col-6 font-weight-bolder">  {data.FANCY_OVERTONE ? data.FANCY_OVERTONE : "-"} </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-6">Color</div>
                                                <div className="col-6 font-weight-bolder">  {data.FANCY_COLOR ? data.FANCY_COLOR : "-"} </div>
                                            </div>
                                        </>
                                    ):('')}
                                
                                    <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> Parameter Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Measurement</div>
                                        <div className="col-6 font-weight-bolder">  {data.LENGTH} x {data.WIDTH} x {data.DEPTH}  </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Table %</div>
                                        <div className="col-6 font-weight-bolder">  {data.TABLE_PER ? `${data.TABLE_PER}%` : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Depth %</div>
                                        <div className="col-6 font-weight-bolder">  {data.DEPTH_PER ? `${data.DEPTH_PER}%` : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Crown</div>
                                        <div className="col-6 font-weight-bolder">  {data.CROWNHEIGHT ? `${data.CROWNHEIGHT}%` : "-"}, {data.CROWNANGLE ? `${data.CROWNANGLE}°` : "-"}  </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Pavilion</div>
                                        <div className="col-6 font-weight-bolder">  {data.PAVILIONHEIGHT ? `${data.PAVILIONHEIGHT}%` : "-"}, {data.PAVILIONANGLE ? `${data.PAVILIONANGLE}°` : "-"}  </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Girdle</div>
                                        <div className="col-6 font-weight-bolder">   {data.Gridle ? data.Gridle : "-"}  </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Girdle Thick</div>
                                        <div className="col-6 font-weight-bolder">   {data.Girdle_Thick ? data.Girdle_Thick : "-"}  </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Girdle Thin</div>
                                        <div className="col-6 font-weight-bolder">   {data.Girdle_Thin ? data.Girdle_Thin : "-"}  </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Culet</div>
                                        <div className="col-6 font-weight-bolder">   {data.Cutlet ? data.Cutlet : "-"}  </div>
                                    </div>
                                    {data.diamond_type === "L" ? 
                                        <>
                                            { (data.GrowthType) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> Growth Type </div>
                                                    <div className="col-6 font-weight-bolder">  {data.GrowthType ? data.GrowthType : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (data.TREATMENT) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> Treatment </div>
                                                    <div className="col-6 font-weight-bolder">  {data.TREATMENT ? data.TREATMENT : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (data.ORIGIN) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> Origin </div>
                                                    <div className="col-6 font-weight-bolder">  {data.ORIGIN ? data.ORIGIN : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (data.BRAND) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> Brand </div>
                                                    <div className="col-6 font-weight-bolder">  {data.BRAND ? data.BRAND : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (data.TREATMENT) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> Treatment </div>
                                                    <div className="col-6 font-weight-bolder">  {data.TREATMENT ? data.TREATMENT : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                    <VideoIframe
                        showVideo={showVideo}
                        handleClose={handleClose}
                        src={data.VIDEO}
                    />

                    <Modal
                        show={showHeartImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.HEART_IMAGE} alt={data.SHAPE} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={showArrowImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.ARROW_IMAGE} alt={data.SHAPE} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={showAssetImg}
                        onHide={handleClose}
                        size="lg"
                    >
                        <Modal.Body className="text-center">
                            <img src={data.ASSET_IMAGE} alt={data.SHAPE} className="img-fluid" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                            >
                            Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> 
            : ""}
        </>
    );
};
