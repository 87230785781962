import React from "react";
import { Field } from "formik";
// import { Slider,makeStyles } from "@material-ui/core";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";


export const Color =({formik}) =>{

    const whitecolorData = ([
        {
            id: 0,
            wcolor:"D"
        },
        {
            id: 1,
            wcolor:"E"
        },
        {
            id: 2,
            wcolor:"F"
        },
        {
            id: 3,
            wcolor:"G"
        },
        {
            id: 4,
            wcolor:"H"
        },
        {
            id: 5,
            wcolor:"I"
        },
        {
            id: 6,
            wcolor:"J"
        },
        {
            id: 7,
            wcolor:"K"
        },
        {
            id: 8,
            wcolor:"L"
        },
        {
            id: 9,
            wcolor:"M"
        },
        {
            id: 10,
            wcolor:"N"
        },
        {
            id: 11,
            wcolor:"OP"
        },
        {
            id: 12,
            wcolor:"QR"
        },
        {
            id: 13,
            wcolor:"ST"
        },
        {
            id: 14,
            wcolor:"UV"
        },
        {
            id: 15,
            wcolor:"WX"
        },
        {
            id: 16,
            wcolor:"YZ"
        }
    ]);
    const ResetColor = () => {
        // formik.setFieldValue("Fancy_Color",[])
        // formik.setFieldValue("Fancy_Color_Overtone",[])
        // formik.setFieldValue("Fancy_Color_Intensity",[])
    }
    return (
        <>
            <div className="row mt-7" onClick={() => ResetColor()}>
                <div className="col-12 font-size-lg font-weight-bold mb-3">Color</div>
                <div className="col-12" >
                    {whitecolorData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Color" id={`${item.wcolor}_wcolor`} value={item.wcolor}/>
                            <div className="wColorBox selectBox">
                                <span>{item.wcolor}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
}

// export const Color = ({formik}) => {
//     const classes = useStyles();
//     const [minColor, setMinColor] = useState(0);
//     const [maxColor, setMaxColor] = useState(100);
//     const [colorRangeValue, setColorRangeValue] = useState([0, 100]);
//     const minColorRange = 0;
//     const maxColorRange = 100;

//     useEffect(() => {
//         formik.setFieldValue("minColor", minColor);
//         formik.setFieldValue("maxColor", maxColor);
//     }, []);

//     const marks = [
//         {
//           value: 0,
//           label: 'D',
//         },
//         {
//           value: 10,
//           label: 'E',
//         },
//         {
//           value: 20,
//           label: 'F',
//         },
//         {
//           value: 30,
//           label: 'G',
//         },
//         {
//             value: 40,
//             label: 'H',
//         },
//         {
//             value: 50,
//             label: 'I',
//         },
//         {
//             value: 60,
//             label: 'J',
//         },
//         {
//             value: 70,
//             label: 'K',
//         },
//         {
//             value: 80,
//             label: 'L',
//         },
//         {
//             value: 90,
//             label: 'M',
//         },
//         {
//             value: 100,
//             label: 'N',
//         },
//       ];

//     const handleColorRangeChange = (event, newValue) => {
//         setMinColor(newValue[0]);
//         setMaxColor(newValue[1]);
//         setColorRangeValue(newValue);
//         formik.setFieldValue("minColor", newValue[0]);
//         formik.setFieldValue("maxColor", newValue[1]);
//     };

//     return (
//         <>
//             <div className="row m-0">
//                 <div className="col-12 font-size-lg font-weight-bold mb-3">Color</div>
//                 <div className="col-12">
//                     <div className="row m-0">
//                         <div className="col-md-12">
//                             <Slider
//                                 getAriaLabel={() => "Color Range"}
//                                 value={colorRangeValue}
//                                 onChange={handleColorRangeChange}
//                                 step={10}
//                                 // valueLabelDisplay="auto"
//                                 marks={marks}
//                                 // valueLabelDisplay="on"
//                                 // getAriaValueText={colorRangeValue}
//                                 min={minColorRange}
//                                 max={maxColorRange}
//                                 color="secondary"
//                             />
//                         </div>
//                     </div>
//                     {/* <div className="row">
//                         <div className="col-md-12 d-flex justify-content-between">
//                             <TextField
//                                 className={classes.TextField}
//                                 margin="dense"
//                                 name="minColor"
//                                 variant="outlined"
//                                 InputLabelProps={{ shrink: true }}
//                                 value={colorRangeValue[0]}
//                                 color="secondary"
//                                 inputProps={{
//                                     style: {
//                                       padding: 5,
//                                       textAlign:"center"
//                                     }
//                                  }}
//                                 onChange={(e) => {
//                                     setMinColor(Number(e.target.value));
//                                     setColorRangeValue([
//                                         Number(e.target.value),
//                                         colorRangeValue[1],
//                                     ]);
//                                     formik.setFieldValue("minColor", Number(e.target.value));
//                                 }}
//                             />
                                
//                             <TextField
//                                 className={classes.TextField}
//                                 margin="dense"
//                                 variant="outlined"
//                                 name="maxColor"
//                                 InputLabelProps={{ shrink: true }}
//                                 value={ colorRangeValue[1]}
//                                 color="secondary"
//                                 inputProps={{
//                                     style: {
//                                       padding: 5,
//                                       textAlign:"center"
//                                     }
//                                  }}
//                                 onChange={(e) => {
//                                     setMaxColor(Number(e.target.value));
//                                     setColorRangeValue([
//                                         colorRangeValue[0],
//                                         Number(e.target.value),
//                                     ]);
//                                     formik.setFieldValue("maxColor", Number(e.target.value));
//                                 }}
//                             />
//                         </div>
//                     </div> */}
//                 </div>
//             </div>
//         </>
//     );
// };
