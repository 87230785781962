import React, { useState, useEffect } from "react";
import { Slider, TextField, MenuItem,makeStyles } from "@material-ui/core";
import { Field } from "formik";
import axios from "axios";
import { NODE_API_URL} from "./../../../../../env_config";

const useStyles = makeStyles(theme => ({
    menuPaper: {
      maxHeight: '150px'
    }
  }));

export const SieveSize = ({ language, formik }) => {
    const classes = useStyles();
    const [sieveSizeData, setSieveSizeData] =useState([])
    const [seiveBox, setSeiveBox] = useState(true);
    const [sizeBox, setSizeBox] = useState(false);
    const [minSieve, setMinSieve] = useState(1);
    const [maxSieve, setMaxSieve] = useState(73);
    const [minSize, setMinSize] = useState(1);
    const [maxSize, setMaxSize] = useState(73);
    const minSiveRange = 1;
    const maxSiveRange = 73;
    const minSizeRange = 1;
    const maxSizeRange = 73;
    const [sieveRangeValue, setSieveRangeValue] = useState([1, 73]);
    const [sizeRangeValue, setSizeRangeValue] = useState([1, 73]);


    useEffect(() => {
        formik.setFieldValue("range_value_from", minSieve);
        formik.setFieldValue("range_value_to", maxSieve);

        axios({
            method: "POST",
            url: `${NODE_API_URL}/parcel/sieveOrsize`,
            headers: { "Content-Type": "application/json" },
            // data: values,
        })
        .then(function(res) {
        //   console.log(res)
        if(res && res.data){
            setSieveSizeData(res.data.data)
        }
        })
        .catch(function(res) {
            console.log("error",res)
        });
       
    },[]);

    const handleSieveRangeChange = (event, newValue) => {
        setMinSieve(newValue[0]);
        setMaxSieve(newValue[1]);
        setSieveRangeValue(newValue);
        setMinSieve(newValue[0]);
        setMaxSieve(newValue[1]);
        setSizeRangeValue(newValue);
        formik.setFieldValue("range_value_from", newValue[0]);
        formik.setFieldValue("range_value_to", newValue[1]);
        // console.log("newValue",newValue)
    };

    // const handleSizeRangeChange = (event, newValue) => {
    //     setMinSieve(newValue[0]);
    //     setMaxSieve(newValue[1]);
    //     setSizeRangeValue(newValue);
    //     formik.setFieldValue("minSize", newValue[0]);
    //     formik.setFieldValue("maxSize", newValue[1]);
    //     formik.setFieldValue("minSieve", "");
    //     formik.setFieldValue("maxSieve", "");
    // };

    const seiveBoxFn = () => {
        setSeiveBox(true);
        setSizeBox(false);
        // formik.setFieldValue("minSize", "");
        // formik.setFieldValue("maxSize", "");
        // formik.setFieldValue("minSieve", minSieve);
        // formik.setFieldValue("maxSieve", maxSieve);
    };

    const sizeBoxFn = () => {
        setSeiveBox(false);
        setSizeBox(true);
        // formik.setFieldValue("minSize", minSize);
        // formik.setFieldValue("maxSize", maxSize);
        // formik.setFieldValue("minSieve", "");
        // formik.setFieldValue("maxSieve", "");
    };

    // var sieve_from_options = [
    //     { value: 1, label: "+00000" },
    //     { value: 2, label: "+0000" },
    //     { value: 3, label: "+000.5" },
    //     { value: 4, label: "+000" },
    //     { value: 5, label: "+00.5" },
    //     { value: 6, label: "+00" },
    //     { value: 7, label: "+0.5" },
    //     { value: 8, label: "+0" },
    //     { value: 9, label: "+0.050" },
    //     { value: 10, label: "+1" },
    //     { value: 11, label: "+1.25" },
    //     { value: 12, label: "+1.5" },
    //     { value: 13, label: "+1.75" },
    //     { value: 14, label: "+2" },
    //     { value: 15, label: "+2.25" },
    //     { value: 16, label: "+2.5" },
    //     { value: 17, label: "+2.75" },
    //     { value: 18, label: "+3" },
    //     { value: 19, label: "+3.25" },
    //     { value: 20, label: "+3.5" },
    //     { value: 21, label: "+3.75" },
    //     { value: 22, label: "+4" },
    //     { value: 23, label: "+4.25" },
    //     { value: 24, label: "+4.5" },
    //     { value: 25, label: "+4.75" },
    //     { value: 26, label: "+5" },
    //     { value: 27, label: "+5.25" },
    //     { value: 28, label: "+5.5" },
    //     { value: 29, label: "+5.75" },
    //     { value: 30, label: "+6" },
    //     { value: 31, label: "+6.25" },
    //     { value: 32, label: "+6.5" },
    //     { value: 33, label: "+6.75" },
    //     { value: 34, label: "+7" },
    //     { value: 35, label: "+7.25" },
    //     { value: 36, label: "+7.5" },
    //     { value: 37, label: "+7.75" },
    //     { value: 38, label: "+8" },
    //     { value: 39, label: "+8.25" },
    //     { value: 40, label: "+8.5" },
    //     { value: 41, label: "+8.75" },
    //     { value: 42, label: "+9" },
    //     { value: 43, label: "+9.25" },
    //     { value: 44, label: "+9.5" },
    //     { value: 45, label: "+9.75" },
    //     { value: 46, label: "+10" },
    //     { value: 47, label: "+10.25" },
    //     { value: 48, label: "+10.5" },
    //     { value: 49, label: "+10.75" },
    //     { value: 50, label: "+11" },
    //     { value: 51, label: "+11.25" },
    //     { value: 52, label: "+11.5" },
    //     { value: 53, label: "+11.75" },
    //     { value: 54, label: "+12" },
    //     { value: 55, label: "+12.25" },
    //     { value: 56, label: "+12.5" },
    //     { value: 57, label: "+12.75" },
    //     { value: 58, label: "+13" },
    //     { value: 59, label: "+13.25" },
    //     { value: 60, label: "+13.5" },
    //     { value: 61, label: "+13.75" },
    //     { value: 62, label: "+14" },
    //     { value: 63, label: "+14.25" },
    //     { value: 64, label: "+14.5" },
    //     { value: 65, label: "+14.75" },
    //     { value: 66, label: "+15" },
    //     { value: 67, label: "+15.5" },
    //     { value: 68, label: "+16" },
    //     { value: 69, label: "+16.5" },
    //     { value: 70, label: "+17" },
    //     { value: 71, label: "+17.5" },
    //     { value: 72, label: "+18" },
    //     { value: 73, label: "+18.5" },
    // ];

    // var sieve_to_options = [
    //     { value: 1, label: "0000" },
    //     { value: 2, label: "000.5" },
    //     { value: 3, label: "000" },
    //     { value: 4, label: "00.5" },
    //     { value: 5, label: "00" },
    //     { value: 6, label: "0.5" },
    //     { value: 7, label: "0" },
    //     { value: 8, label: "0.050" },
    //     { value: 9, label: "1" },
    //     { value: 10, label: "1.25" },
    //     { value: 11, label: "1.5" },
    //     { value: 12, label: "1.75" },
    //     { value: 13, label: "2" },
    //     { value: 14, label: "2.25" },
    //     { value: 15, label: "2.5" },
    //     { value: 16, label: "2.75" },
    //     { value: 17, label: "3" },
    //     { value: 18, label: "3.25" },
    //     { value: 19, label: "3.5" },
    //     { value: 20, label: "3.75" },
    //     { value: 21, label: "4" },
    //     { value: 22, label: "4.25" },
    //     { value: 23, label: "4.5" },
    //     { value: 24, label: "4.75" },
    //     { value: 25, label: "5" },
    //     { value: 26, label: "5.25" },
    //     { value: 27, label: "5.5" },
    //     { value: 28, label: "5.75" },
    //     { value: 29, label: "6" },
    //     { value: 30, label: "6.25" },
    //     { value: 31, label: "6.5" },
    //     { value: 32, label: "6.75" },
    //     { value: 33, label: "7" },
    //     { value: 34, label: "7.25" },
    //     { value: 35, label: "7.5" },
    //     { value: 36, label: "7.75" },
    //     { value: 37, label: "8" },
    //     { value: 38, label: "8.25" },
    //     { value: 39, label: "8.5" },
    //     { value: 40, label: "8.75" },
    //     { value: 41, label: "9" },
    //     { value: 42, label: "9.25" },
    //     { value: 43, label: "9.5" },
    //     { value: 44, label: "9.75" },
    //     { value: 45, label: "10" },
    //     { value: 46, label: "10.25" },
    //     { value: 47, label: "10.5" },
    //     { value: 48, label: "10.75" },
    //     { value: 49, label: "11" },
    //     { value: 50, label: "11.25" },
    //     { value: 51, label: "11.5" },
    //     { value: 52, label: "11.75" },
    //     { value: 53, label: "12" },
    //     { value: 54, label: "12.25" },
    //     { value: 55, label: "12.5" },
    //     { value: 56, label: "12.75" },
    //     { value: 57, label: "13" },
    //     { value: 58, label: "13.25" },
    //     { value: 59, label: "13.5" },
    //     { value: 60, label: "13.75" },
    //     { value: 61, label: "14" },
    //     { value: 62, label: "14.25" },
    //     { value: 63, label: "14.5" },
    //     { value: 64, label: "14.75" },
    //     { value: 65, label: "15" },
    //     { value: 66, label: "15.5" },
    //     { value: 67, label: "16" },
    //     { value: 68, label: "16.5" },
    //     { value: 69, label: "17" },
    //     { value: 70, label: "17.5" },
    //     { value: 71, label: "18" },
    //     { value: 72, label: "18.5" },
    //     { value: 73, label: "19" },
    // ];

    // var size_from_options = [
    //     { value: 1, label: "+0.7" },
    //     { value: 2, label: "+0.8" },
    //     { value: 3, label: "+0.85" },
    //     { value: 4, label: "+0.9" },
    //     { value: 5, label: "+0.95" },
    //     { value: 6, label: "+1" },
    //     { value: 7, label: "+1.05" },
    //     { value: 8, label: "+1.1" },
    //     { value: 9, label: "+1.125" },
    //     { value: 10, label: "+1.15" },
    //     { value: 11, label: "+1.175" },
    //     { value: 12, label: "+1.2" },
    //     { value: 13, label: "+1.225" },
    //     { value: 14, label: "+1.25" },
    //     { value: 15, label: "+1.275" },
    //     { value: 16, label: "+1.3" },
    //     { value: 17, label: "+1.325" },
    //     { value: 18, label: "+1.35" },
    //     { value: 19, label: "+1.375" },
    //     { value: 20, label: "+1.4" },
    //     { value: 21, label: "+1.425" },
    //     { value: 22, label: "+1.45" },
    //     { value: 23, label: "+1.475" },
    //     { value: 24, label: "+1.5" },
    //     { value: 25, label: "+1.525" },
    //     { value: 26, label: "+1.55" },
    //     { value: 27, label: "+1.575" },
    //     { value: 28, label: "+1.6" },
    //     { value: 29, label: "+1.65" },
    //     { value: 30, label: "+1.7" },
    //     { value: 31, label: "+1.75" },
    //     { value: 32, label: "+1.8" },
    //     { value: 33, label: "+1.85" },
    //     { value: 34, label: "+1.9" },
    //     { value: 35, label: "+1.95" },
    //     { value: 36, label: "+2" },
    //     { value: 37, label: "+2.05" },
    //     { value: 38, label: "+2.1" },
    //     { value: 39, label: "+2.15" },
    //     { value: 40, label: "+2.2" },
    //     { value: 41, label: "+2.25" },
    //     { value: 42, label: "+2.3" },
    //     { value: 43, label: "+2.35" },
    //     { value: 44, label: "+2.4" },
    //     { value: 45, label: "+2.45" },
    //     { value: 46, label: "+2.5" },
    //     { value: 47, label: "+2.55" },
    //     { value: 48, label: "+2.6" },
    //     { value: 49, label: "+2.65" },
    //     { value: 50, label: "+2.7" },
    //     { value: 51, label: "+2.75" },
    //     { value: 52, label: "+2.8" },
    //     { value: 53, label: "+2.85" },
    //     { value: 54, label: "+2.9" },
    //     { value: 55, label: "+2.95" },
    //     { value: 56, label: "+3" },
    //     { value: 57, label: "+3.05" },
    //     { value: 58, label: "+3.1" },
    //     { value: 59, label: "+3.15" },
    //     { value: 60, label: "+3.2" },
    //     { value: 61, label: "+3.25" },
    //     { value: 62, label: "+3.3" },
    //     { value: 63, label: "+3.35" },
    //     { value: 64, label: "+3.4" },
    //     { value: 65, label: "+3.45" },
    //     { value: 66, label: "+3.5" },
    //     { value: 67, label: "+3.6" },
    //     { value: 68, label: "+3.7" },
    //     { value: 69, label: "+3.8" },
    //     { value: 70, label: "+3.9" },
    //     { value: 71, label: "+4" },
    //     { value: 72, label: "+4.1" },
    //     { value: 73, label: "+4.2" },
    // ];

    // var size_to_options = [
    //     { value: 1, label: "0.8" },
    //     { value: 2, label: "0.85" },
    //     { value: 3, label: "0.9" },
    //     { value: 4, label: "0.95" },
    //     { value: 5, label: "1" },
    //     { value: 6, label: "1.05" },
    //     { value: 7, label: "1.1" },
    //     { value: 8, label: "1.125" },
    //     { value: 9, label: "1.15" },
    //     { value: 10, label: "1.175" },
    //     { value: 11, label: "1.2" },
    //     { value: 12, label: "1.225" },
    //     { value: 13, label: "1.25" },
    //     { value: 14, label: "1.275" },
    //     { value: 15, label: "1.3" },
    //     { value: 16, label: "1.325" },
    //     { value: 17, label: "1.35" },
    //     { value: 18, label: "1.375" },
    //     { value: 19, label: "1.4" },
    //     { value: 20, label: "1.425" },
    //     { value: 21, label: "1.45" },
    //     { value: 22, label: "1.475" },
    //     { value: 23, label: "1.5" },
    //     { value: 24, label: "1.525" },
    //     { value: 25, label: "1.55" },
    //     { value: 26, label: "1.575" },
    //     { value: 27, label: "1.6" },
    //     { value: 28, label: "1.65" },
    //     { value: 29, label: "1.7" },
    //     { value: 30, label: "1.75" },
    //     { value: 31, label: "1.8" },
    //     { value: 32, label: "1.85" },
    //     { value: 33, label: "1.9" },
    //     { value: 34, label: "1.95" },
    //     { value: 35, label: "2" },
    //     { value: 36, label: "2.05" },
    //     { value: 37, label: "2.1" },
    //     { value: 38, label: "2.15" },
    //     { value: 39, label: "2.2" },
    //     { value: 40, label: "2.25" },
    //     { value: 41, label: "2.3" },
    //     { value: 42, label: "2.35" },
    //     { value: 43, label: "2.4" },
    //     { value: 44, label: "2.45" },
    //     { value: 45, label: "2.5" },
    //     { value: 46, label: "2.55" },
    //     { value: 47, label: "2.6" },
    //     { value: 48, label: "2.65" },
    //     { value: 49, label: "2.7" },
    //     { value: 50, label: "2.75" },
    //     { value: 51, label: "2.8" },
    //     { value: 52, label: "2.85" },
    //     { value: 53, label: "2.9" },
    //     { value: 54, label: "2.95" },
    //     { value: 55, label: "3" },
    //     { value: 56, label: "3.05" },
    //     { value: 57, label: "3.1" },
    //     { value: 58, label: "3.15" },
    //     { value: 59, label: "3.2" },
    //     { value: 60, label: "3.25" },
    //     { value: 61, label: "3.3" },
    //     { value: 62, label: "3.35" },
    //     { value: 63, label: "3.4" },
    //     { value: 64, label: "3.45" },
    //     { value: 65, label: "3.5" },
    //     { value: 66, label: "3.6" },
    //     { value: 67, label: "3.7" },
    //     { value: 68, label: "3.8" },
    //     { value: 69, label: "3.9" },
    //     { value: 70, label: "4" },
    //     { value: 71, label: "4.1" },
    //     { value: 72, label: "4.2" },
    //     { value: 73, label: "4.3" },
    // ];


    return (
        <>
       {/* { console.log(sieveRangeValue)} */}
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-12 mt-5 mb-10 d-flex justify-content-between align-items-center">
                    <div>
                        <label className="search_btn_check">
                            <Field
                                type="radio"
                                name="sieveSize"
                                checked={seiveBox}
                                onClick={(e) => seiveBoxFn()}
                            />
                            <div className="parcelBox selectBox">
                                <span>Sieve</span>
                            </div>
                        </label>
                        <label className="search_btn_check">
                            <Field
                                type="radio"
                                name="sieveSize"
                                checked={sizeBox}
                                onClick={(e) => sizeBoxFn()}
                            />
                            <div className="parcelBox selectBox">
                                <span>Size(MM)</span>
                            </div>
                        </label>
                    </div>
                    {seiveBox ? (
                        <div className="font-weight-bold"> Size(MM) : {minSize} to {maxSize} </div>
                        ) : (
                        <div className="font-weight-bold"> Seive : {minSieve} to {maxSieve} </div>
                    )}
                </div>
                {seiveBox ? (
                    <div className="col-12 ">
                        <div className="row">
                            <div className="col-md-12">
                                <Slider
                                    getAriaLabel={() => "Sieve range"}
                                    value={sieveRangeValue}
                                    onChange={handleSieveRangeChange}
                                    // valueLabelDisplay="auto"
                                    // getAriaValueText={sieveRangeValue}
                                    min={minSiveRange}
                                    max={maxSiveRange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between">
                                <TextField
                                    select
                                    margin="dense"
                                    SelectProps={{
                                        MenuProps: {
                                            classes: { paper: classes.menuPaper }
                                        }
                                    }}
                                    name="minSieve"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[0]}
                                    onChange={(e) => {
                                        setMinSieve(Number(e.target.value));
                                        setSieveRangeValue([
                                            Number(e.target.value),
                                            sieveRangeValue[1],
                                        ]);
                                        // console.log("from seive",Number(e.target.value))
                                        formik.setFieldValue("range_value_from", Number(e.target.value));
                                    }}
                                >
                                    {sieveSizeData.length !== 0 ? sieveSizeData.map((option) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                        >
                                            +{option.sieve_from}
                                        </MenuItem>
                                     )) : <MenuItem value={"00000"} > +00000 </MenuItem> }
                                </TextField>
                                <TextField
                                    select
                                    margin="dense"
                                    variant="outlined"
                                    SelectProps={{
                                        MenuProps: {
                                            classes: { paper: classes.menuPaper }
                                        }
                                    }}
                                    name="maxSieve"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[1]}
                                    onChange={(e) => {
                                        setMaxSieve(Number(e.target.value));
                                        setSieveRangeValue([
                                            sieveRangeValue[0],
                                            Number(e.target.value),
                                        ]);
                                        // console.log("to seive",Number(e.target.value))
                                        formik.setFieldValue("range_value_to", Number(e.target.value));
                                    }}
                                >
                                    {sieveSizeData.length !==0 ? sieveSizeData.map((option) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                        >
                                            {option.sieve_to}
                                        </MenuItem>
                                     )) : <MenuItem value={19} > 19 </MenuItem> }
                                </TextField>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {sizeBox ? (
                    <div className="col-12 ">
                        <div className="row">
                            <div className="col-md-12">
                                <Slider
                                    getAriaLabel={() => "Size range"}
                                    value={sieveRangeValue}
                                    onChange={handleSieveRangeChange}
                                    min={minSizeRange}
                                    max={maxSizeRange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between">
                                <TextField
                                    select
                                    SelectProps={{
                                        MenuProps: {
                                            classes: { paper: classes.menuPaper }
                                        }
                                    }}
                                 //   MenuProps={{ classes: { paper: classes.menuPaper } }}
                                    margin="dense"
                                    name="minSize"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[0]}
                                    onChange={(e) => {
                                        setMinSize(Number(e.target.value));
                                        setSieveRangeValue([
                                            Number(e.target.value),
                                            sieveRangeValue[1],
                                        ]);
                                        // console.log("from mm",Number(e.target.value))
                                        formik.setFieldValue("range_value_from", Number(e.target.value));
                                    }}
                                >
                                    {sieveSizeData.length !==0 ? sieveSizeData.map((option) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                        >
                                            +{option.mm_from}
                                        </MenuItem>
                                    )) : <MenuItem value={0.7} > +0.7 </MenuItem> }
                                </TextField>
                                <TextField
                                    select
                                    margin="dense"
                                    variant="outlined"
                                    name="maxSize"
                                    SelectProps={{
                                        MenuProps: {
                                            classes: { paper: classes.menuPaper }
                                        }
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "10px" }}
                                    value={sieveRangeValue[1]}
                                    onChange={(e) => {
                                        setMaxSize(Number(e.target.value));
                                        setSieveRangeValue([
                                            sieveRangeValue[0],
                                            Number(e.target.value),
                                        ]);
                                        // console.log("to mm",Number(e.target.value))
                                        formik.setFieldValue("range_value_to", Number(e.target.value));
                                    }}
                                >
                                    {sieveSizeData.length !==0 ? sieveSizeData.map((option) => (
                                        <MenuItem
                                            key={option.sieve_mm_id}
                                            value={option.sieve_mm_id}
                                        >
                                            {option.mm_to}
                                        </MenuItem>
                                    )) : <MenuItem value={4.3} > 4.3 </MenuItem> }
                                </TextField>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};
