import React,{useState,useEffect} from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, DatePickerField, PhoneInput, SelectField } from "../FormFields";
import { Accordion, Card } from "react-bootstrap";
import { GetCookies } from "../../../../_helpers/Cookies";


export default function MarkupCurrency(props) {
    const {
        formField: {
            muti_currency,
            diamondmarkup,
            diamondfancymarkup,
            lab_diamondmarkup,
            lab_diamondfancymarkup
        },
        setFieldValue,
        values
    } = props;
    // console.log(values,"BALUESSSSS")
    const [inr,setInr] = useState(0)
    const [usd,setUsd] = useState(0)
    const [cad,setCad] = useState(0)
    const [aud,setAud] = useState(0)
    const [hkd,setHkd] = useState(0)
    const [cny,setCny] = useState(0)
    const [eur,setEur] = useState(0)
    const [gbp,setGbp] = useState(0)
    const [nzd,setNzd] = useState(0)
    const [jpy,setJpy] = useState(0)
    const [chf,setChf] = useState(0)
    useEffect(() => {
        GetCookies("conversionrates").then(response => {
            // console.log(response,"Conversionresponse")
            if(response && response[0]){
                const currencies = JSON.parse(response)
                // console.log(currencies,"currencies")
                setInr(Math.round(currencies[0].cur_inr*100)/100)
                setUsd(Math.round(currencies[0].cur_usd*100)/100)
                setCad(Math.round(currencies[0].cur_cad*100)/100)
                setAud(Math.round(currencies[0].cur_aud*100)/100)
                setHkd(Math.round(currencies[0].cur_hkd*100)/100)
                setCny(Math.round(currencies[0].cur_cny*100)/100)
                setEur(Math.round(currencies[0].cur_eur*100)/100)
                setGbp(Math.round(currencies[0].cur_gbp*100)/100)
                setNzd(Math.round(currencies[0].cur_nzd*100)/100)
                setJpy(Math.round(currencies[0].cur_jpy*100)/100)
                setChf(Math.round(currencies[0].cur_chf*100)/100)
            }
        })
    },[])
    // console.log(inr,usd,"inr,usd")
    const muti_currency_data = [
      {
        value: 'USD',
        label: `USD $ - ${usd.toFixed(2)}`
      },
      {
        value: 'CAD',
        label: `CAD $ - ${cad.toFixed(2)}`
      },
      {
        value: 'AUD',
        label: `AUD $ - ${aud.toFixed(2)}`
      },
      {
          value: 'HKD',
          label: `HKD $ - ${hkd.toFixed(2)}`
        },
        {
          value: 'CNY',
          label: `CNY ¥ - ${cny.toFixed(2)}`
        },
        {
          value: 'EUR',
          label: `EUR € - ${eur.toFixed(2)}`
        },
        {
            value: 'GBP',
            label: `GBP £ - ${gbp.toFixed(2)}`
          },
          {
            value: 'NZD',
            label: `NZD $ - ${nzd.toFixed(2)}`
          },
          {
            value: 'JPY',
            label: `JPY ¥ - ${jpy.toFixed(2)}`
          },
          {
            value: 'CHF',
            label: `CHF ₣ - ${chf.toFixed(2)}`
          }
    ];
    return (
        <React.Fragment>
            
            <Accordion className="ccmode_accordion  my-10" defaultActiveKey="2">
                <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="2" key="2">
                  {props.language.SETTING_CCMODE_PRICE_MARKUP}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Grid container justify="center" alignItems="center" spacing={3} className="mb-10">
                      <Grid item xs={12} md={7}>
                          <SelectField
                              name={muti_currency.name}
                              label={props.language.CCMODE_SETUP_SELECT_CURRENCY}
                              fullWidth
                              data={muti_currency_data}
                          />
                      </Grid>
                      {values.wl_naturaldiamond?<Grid item xs={12} md={7}>
                    <InputField
                        name={diamondmarkup.name}
                        label={props.language.CCMODE_SETUP_NATURAL_WHITE}
                        fullWidth
                        onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                    />
                </Grid>:""}
                {values.wl_naturalfancydiamond?<Grid item xs={12} md={7}>
                    <InputField
                        name={diamondfancymarkup.name}
                        label={props.language.CCMODE_SETUP_NATURAL_FANCY}
                        fullWidth
                        onChange={(e) => setFieldValue("diamondfancymarkup",e.target.value)}
                    />
                </Grid>:""}
                {values.wl_lgwhitediam?<Grid item xs={12} md={7}>
                    <InputField
                        name={lab_diamondmarkup.name}
                        label={props.language.CCMODE_SETUP_LAB_WHITE}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_diamondmarkup",e.target.value)}
                    />
                </Grid>:""}
                {values.wl_lgfancydiam?<Grid item xs={12} md={7}>
                    <InputField
                        name={lab_diamondfancymarkup.name}
                        label={props.language.CCMODE_SETUP_LAB_FANCY}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_diamondfancymarkup",e.target.value)}
                    />
                </Grid>:""}
                  </Grid>
                </Accordion.Collapse>
            </Accordion>
        </React.Fragment>
    );
}
