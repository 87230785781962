import React,{useState} from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, MuiUploader, PhoneInput, SelectField,ColorPicker } from "../FormFields";
import { Accordion, Card } from "react-bootstrap";
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import { BASE_URL } from "../../../../../env_config";
export default function ThemeFrom(props) {
 
    const {
        formField: {
            white_label_logo,
            wl_primarycolor,
            n_fontcolor,
            lab_primary,
            lab_fontcolor,
        },
        values,
        setCompanyLogo,
        setFieldValue
    } = props;

   
    return (
        <React.Fragment>
            <Accordion className="ccmode_accordion  my-10" defaultActiveKey="3">
                <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="3" key="3">
                    {props.language.SETTING_CCMODE_THEME_SETTING}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                    <Grid container justify="center" alignItems="center" spacing={3} className="mb-10">
                        <Grid item xs={12} sm={7}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div>
                                        <InputLabel className="font-size-h6 font-weight-bold">
                                            {props.language.CCMODE_SETUP_THEME_SET_UPLOAD_LOGO}
                                        </InputLabel>
                                        <MuiUploader
                                            name={white_label_logo.name}
                                            label={white_label_logo.label}
                                            fullWidth
                                            // setThirdFile={setThirdFile}
                                            filefield={"white_label_logo"}
                                            setFieldValue={setFieldValue}
                                            setCompanyRegName={white_label_logo}
                                            setCompanyLogo={setCompanyLogo}
                                            values={values}
                                        />
                                    </div>
                                    {values.white_label_logo ? 
                                        <OverlayTrigger
                                            placement="bottom"
                                            className="text-capitalize"
                                            overlay={<Tooltip id="tooltip">{values.white_label_logo}</Tooltip>}
                                        >
                                            <a href={process.env.REACT_APP_ENV === "production"?`https://www.diamondsoncall.com/assets/white_label/${values.white_label_logo}`:`${BASE_URL}/assets/white_label/${values.white_label_logo}`} target="_blank" rel="noreferrer" className="font-weight-bold float-right">{props.language.SETTING_CCMODE_VIEW_LOGO}</a>
                                        </OverlayTrigger>
                                    : "" }
                                </div>
                            </div>
                        </Grid>
                        {values.wl_naturaldiamond || values.wl_naturalfancydiamond?<><Grid item xs={12} md={7}>
                    <ColorPicker
                        name={wl_primarycolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_NATURAL}
                        fullWidth
                        onChange={(e) => setFieldValue("wl_primarycolor",e.target.value)}
                        value={values.wl_primarycolor}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <ColorPicker
                        name={n_fontcolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_NATURAL_FONT}
                        fullWidth
                        onChange={(e) => setFieldValue("n_fontcolor",e.target.value)}
                        value={values.n_fontcolor}
                    />
                </Grid></>:""}
                {values.wl_lgwhitediam || values.wl_lgfancydiam?<><Grid item xs={12} md={7}>
                    <ColorPicker
                        name={lab_primary.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_LAB}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_primary",e.target.value)}
                        value={values.lab_primary}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <ColorPicker
                        name={lab_fontcolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_LAB_FONT}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_fontcolor",e.target.value)}
                        value={values.lab_fontcolor}
                    />
                </Grid></>:""}
                    </Grid>
                </Accordion.Collapse>
            </Accordion>
        </React.Fragment>
    );
}
