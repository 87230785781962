import React from "react";
import { Field } from "formik";


export const Fluorescence =({formik}) =>{

    const flourData = ([
        {
            id: 1,
            value:"NON",
            flour:"NONE"
        },
        {
            id: 2,
            value:"FNT",
            flour:"FAINT"
        },
        {
            id: 3,
            value:"VSLT",
            flour:"VERY SLIGHT"
        },
        {
            id: 4,
            value:"SLT",
            flour:"SLIGHT"
        },
        {
            id: 5,
            value:"MED",
            flour:"MEDIUM"
        },
        {
            id: 6,
            value:"STG",
            flour:"STRONG"
        },
        {
            id: 7,
            value:"VST",
            flour:"VERY STRONG"
        }
    ]);
    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Fluorescence</div>
                <div className="col-12" >
                    {flourData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Fluorescence_Intensity" id={`${item.flour}_flour`} value={item.value}/>
                            <div className="wFluorBox selectBox">
                                <span>{item.flour}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
}