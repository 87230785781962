import React from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, DiamTypeCheck ,DatePickerField, PhoneInput, SelectField } from "../FormFields";
import { Accordion, Card } from "react-bootstrap";

export default function DimondType(props) {
    const {
        formField: {
            wl_naturaldiamond,
            wl_lgwhitediam,
            wl_naturalfancydiamond,
            wl_lgfancydiam
        },
        setFieldValue,
        values
    } = props;
   
    return (
        <React.Fragment>
            <Accordion className="ccmode_accordion mb-10" defaultActiveKey="1">
                <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="1" key="1">
                    {props.language.SETTING_CCMODE_DESIGN_COLORS}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                        <Grid item xs={12} sm={7} align="center">
                            <InputLabel className="font-size-h6 font-weight-bold text-dark">
                                {props.language.DASHBOARD_SEARCH_NATURAL_DIAMOND}
                            </InputLabel>
                            <Grid item xs={12} md={7} align="center">
                            {/* <div> */}
                                    <DiamTypeCheck
                                        name={wl_naturaldiamond.name}
                                        label={wl_naturaldiamond.label}
                                        fullWidth
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                    <DiamTypeCheck
                                        name={wl_naturalfancydiamond.name}
                                        label={wl_naturalfancydiamond.label}
                                        fullWidth
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                        {/* <span class="white_dia_icon"></span> */}
                            {/* </div> */}
                            </Grid>
                            <InputLabel className="font-size-h6 font-weight-bold mt-10 text-dark">
                                {props.language.CCMODE_SETUP_LAB_DIAMOND}
                            </InputLabel>
                            <Grid item xs={12} md={7} align="center">
                            {/* <div> */}
                                    <DiamTypeCheck
                                        name={wl_lgwhitediam.name}
                                        label={wl_lgwhitediam.label}
                                        fullWidth
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                    <DiamTypeCheck
                                        name={wl_lgfancydiam.name}
                                        label={wl_lgfancydiam.label}
                                        fullWidth
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                            {/* </div> */}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Accordion.Collapse>
            </Accordion>
        </React.Fragment>
    );
}