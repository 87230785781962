import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';

import StockAPI from "./StockAPI.json"
import { MockShortListColumn } from './APIColumns'; 
import { CheckBox ,GlobleFilter } from '../../tableFilter';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaChevronRight, FaChevronDown} from "react-icons/fa";
// import { CheckBox } from './CheckBox';
import { Dropdown, Modal } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
import ApiDataSkeleton from './ApiDataSkeleton'
// import  {ExpandedDetails} from "./Expand/ExpandedDetails"
import axios from 'axios';
import { NODE_API_URL } from '../../../../env_config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SwalWarn } from '../../Popup';
import { useHistory } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
export const ApiDataTable = (props) => {
    const history = useHistory()
    const [swalopen,setSwalOpen] = useState(false)
    const [deleteObj,setDeleteObj] = useState(null)
    // console.log(props.userinfo.api_id,"ApiDataNatural")
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    // const renderRowSubComponent = React.useCallback(
    //     (row) => (
    //         <>
    //             <ExpandedDetails row={row}/>
    //         </>
    //     ),[]
    //   )
    const [update,setUpdate] = useState()
    const [stockexpire,setStockExpire] = useState([])
    // console.log(stockexpire,"stockexpire")
    // const data = useMemo(()=> StockAPI,[])
    const [erpireddate,setExpiredDate] = useState([])
    const [countloader,setCountLoader] = useState(false)
    const [data,setData] = useState([])
    // useEffect(() => {
    //     const userData = JSON.parse(localStorage.getItem("userData"))
    //     const finalData = {
    //         "diamond_type":"N"
    //     }
    //     axios({
    //         method: "POST",
    //         url: `${NODE_API_URL}/TESTMODE/fetchSupplierFeed`,
    //         headers: { "Content-Type": "application/json" },
    //         data: finalData,
    //     }).then(res => {
    //         if(res && res.data && res.data.data){
    //             // setData(res.data.data)
    //             axios({
    //                 method: "POST",
    //                 url: `${NODE_API_URL}/TESTMODE/fetchSupplierRequest`,
    //                 headers: { "Content-Type": "application/json" },
    //                 data: {
    //                     "user_id":props.userinfo.id
    //                 },
    //             }).then(supreq => {
    //                 if(supreq && supreq.data && supreq.data.data){
    //                     // setData(res.data.data)
    //                     let merged = [];

    //                     // for (let i = 0; i < supreq.data.data.length; i++) {
    //                     //     merged.push({
    //                     //         ...supreq.data.data[i],
    //                     //         ...(res.data.data.find((itmInner) => itmInner.id === supreq.data.data[i].supplier_id))
    //                     //     }
    //                     //     );
    //                     // }
    //                     for (let i = 0; i < res.data.data.length; i++) {
    //                         const fetcheddata = {...(supreq.data.data.find((itmInner) => itmInner.supplier_id === res.data.data[i].id && itmInner.api_id === props.userinfo.api_id))} 
    //                         // console.log(fetcheddata,"fetcheddata")
    //                         merged.push({
    //                             ...res.data.data[i],
    //                             ...fetcheddata
    //                         }
    //                         );
    //                     }
    //                     // console.log(merged,"merged")
    //                     merged = merged.filter(value => {
    //                         if(value.supplier_name){
    //                             return value
    //                         }
    //                     })
    //                     setData(merged,"merged")
    //                 }
    //             })
    //         }
    //     })
    // },[update])
    // // console.log(data,"CCMODEDATAAA")
    // useEffect(() => {
    //     let finaloutput = []
    //     let promises = []
    //     console.log(props.customerapi,"props.customerapi")
    //     axios({
    //         method: "POST",
    //         url: `${NODE_API_URL}/TESTMODE/fetchSupplierRequest`,
    //         headers: { "Content-Type": "application/json" },
    //         data: {
    //             "user_id":props.userinfo.id
    //         },
    //     }).then(supreq => {
    //         if(supreq && supreq.data && supreq.data.data){
    //             for(let i = 0;i < props.customerapi.length; i++){
    //                 let diamondtype = "N"
    //                 let naturaldiamond = 0
    //                 let naturalfancydiamond = 0
    //                 let lgwhitediam = 0
    //                 let lgfancydiam = 0
    //                 if(props.customerapi[i].naturaldiamond){
    //                   diamondtype = "N"
    //                   naturaldiamond = 1
    //                 }
    //                 if(props.customerapi[i].naturalfancydiamond){
    //                   diamondtype = "N"
    //                   naturalfancydiamond = 1
    //                 }
    //                 if(props.customerapi[i].labdiamond){
    //                   diamondtype = "L"
    //                   lgwhitediam = 1
    //                 }
    //                 if(props.customerapi[i].labfancydiamond){
    //                   diamondtype = "L"
    //                   lgfancydiam = 1
    //                 }
    //                 let FinalObject = {
    //                     "user_id":props.userinfo.id,
    //                     "api_id":props.userinfo.api_id,  
    //                   diamond_type:diamondtype,
    //                   naturaldiamond:naturaldiamond,
    //                   naturalfancydiamond:naturalfancydiamond,
    //                   labdiamond:lgwhitediam,
    //                   labfancydiamond:lgfancydiam,
    //                   rule_name:props.customerapi[i].rulename,
    //                   user_id:props.userinfo.id,
    //                   suppliers:props.customerapi[i].suppliers
    //                 }
    //                 if(props.customerapi[i].markupoption === "Dollar"){
    //                   FinalObject["markupdollar"] = parseFloat(props.customerapi[i].markup)
    //                 }
    //                 else{
    //                   FinalObject["markupperc"] = parseFloat(props.customerapi[i].markup)
    //                   FinalObject["currency"] = props.customerapi[i].multi_currency
    //                 }
    //                 if(diamondtype === "N"){
    //                     if(props.customerapi[i].shape){
    //                         FinalObject["shape"] = props.customerapi[i].shape.split(',')
        
    //                     }
    //                     if(props.customerapi[i].cut){
    //                         FinalObject["cut"] = props.customerapi[i].cut.split(',')
        
    //                     }
    //                     if(props.customerapi[i].clarity){
    //                         FinalObject["clarity"] = props.customerapi[i].clarity.split(',')
        
    //                     }
    //                     if(props.customerapi[i].min_carat >= 0){
    //                         FinalObject["min_carat"] = parseFloat(props.customerapi[i].min_carat)
        
    //                     }
    //                     if(props.customerapi[i].max_carat >= 0){
    //                         FinalObject["max_carat"] = parseFloat(props.customerapi[i].max_carat)
        
    //                     }
    //                     if(props.customerapi[i].lab){
    //                         FinalObject["lab"] = props.customerapi[i].lab.split(',')
        
    //                     }
    //                     if(props.customerapi[i].symmetry){
    //                         FinalObject["symmetry"] = props.customerapi[i].symmetry.split(',')
        
    //                     }
    //                     if(props.customerapi[i].fluorescence){
    //                         FinalObject["fluorescence"] = props.customerapi[i].fluorescence.split(',')
        
    //                     }
    //                     if(props.customerapi[i].polish){
    //                         FinalObject["polish"] = props.customerapi[i].polish.split(',')
        
    //                     }
    //                     if(props.customerapi[i].milky){
    //                         FinalObject["milky"] = props.customerapi[i].milky.split(',')
        
    //                     }
    //                     if(props.customerapi[i].eyeclean){
    //                         FinalObject["eyeclean"] = props.customerapi[i].eyeclean.split(',')
        
    //                     }
    //                     if(props.customerapi[i].shade){
    //                         FinalObject["shade"] = props.customerapi[i].shade.split(',')
        
    //                     }
    //                     if(props.customerapi[i].min_dollarperct >= 0){
    //                         FinalObject["min_dollarperct"] = parseFloat(props.customerapi[i].min_dollarperct)
        
    //                     }
    //                     if(props.customerapi[i].max_dollarperct >= 0){
    //                         FinalObject["max_dollarperct"] = parseFloat(props.customerapi[i].max_dollarperct)
        
    //                     }
    //                     if(props.customerapi[i].total_price_from >= 0){
    //                         FinalObject["total_price_from"] = parseFloat(props.customerapi[i].total_price_from)
        
    //                     }
    //                     if(props.customerapi[i].total_price_to >= 0){
    //                         FinalObject["total_price_to"] = parseFloat(props.customerapi[i].total_price_to)
        
    //                     }
    //                     if(props.customerapi[i].media){
    //                         FinalObject["media"] = props.customerapi[i].media.split(',')
        
    //                     }
    //                     if(props.customerapi[i].minlength >= 0){
    //                         FinalObject["minlength"] = parseFloat(props.customerapi[i].minlength)
        
    //                     }
    //                     if(props.customerapi[i].maxlength >= 0){
    //                         FinalObject["maxlength"] = parseFloat(props.customerapi[i].maxlength)
        
    //                     }
    //                     if(props.customerapi[i].minwidth >= 0){
    //                         FinalObject["minwidth"] = parseFloat(props.customerapi[i].minwidth)
        
    //                     }
    //                     if(props.customerapi[i].maxwidth >= 0){
    //                         FinalObject["maxwidth"] = parseFloat(props.customerapi[i].maxwidth)
        
    //                     }
    //                     if(props.customerapi[i].minheight >= 0){
    //                         FinalObject["minheight"] = parseFloat(props.customerapi[i].minheight)
        
    //                     }
    //                     if(props.customerapi[i].maxheight >= 0){
    //                         FinalObject["maxheight"] = parseFloat(props.customerapi[i].maxheight)
        
    //                     }
    //                     if(props.customerapi[i].tablemin >= 0){
    //                         FinalObject["tablemin"] = parseFloat(props.customerapi[i].tablemin)
        
    //                     }
    //                     if(props.customerapi[i].tablemax >= 0){
    //                         FinalObject["tablemax"] = parseFloat(props.customerapi[i].tablemax)
        
    //                     }
    //                     if(props.customerapi[i].depthmin >= 0){
    //                         FinalObject["depthmin"] = parseFloat(props.customerapi[i].depthmin)
        
    //                     }
    //                     if(props.customerapi[i].depthmax >= 0){
    //                         FinalObject["depthmax"] = parseFloat(props.customerapi[i].depthmax)
        
    //                     }
    //                     if(props.customerapi[i].ratiomin >= 0){
    //                         FinalObject["ratiomin"] = parseFloat(props.customerapi[i].ratiomin)
        
    //                     }
    //                     if(props.customerapi[i].ratiomax >= 0){
    //                         FinalObject["ratiomax"] = parseFloat(props.customerapi[i].ratiomax)
        
    //                     }
    //                     if(props.customerapi[i].crheightmin >= 0){
    //                         FinalObject["crheightmin"] = parseFloat(props.customerapi[i].crheightmin)
        
    //                     }
    //                     if(props.customerapi[i].crheightmax >= 0){
    //                         FinalObject["crheightmax"] = parseFloat(props.customerapi[i].crheightmax)
        
    //                     }
    //                     if(props.customerapi[i].cranglemin >= 0){
    //                         FinalObject["cranglemin"] = parseFloat(props.customerapi[i].cranglemin)
        
    //                     }
    //                     if(props.customerapi[i].cranglemax >= 0){
    //                         FinalObject["cranglemax"] = parseFloat(props.customerapi[i].cranglemax)
        
    //                     }
    //                     if(props.customerapi[i].pavheightmin >= 0){
    //                         FinalObject["pavheightmin"] = parseFloat(props.customerapi[i].pavheightmin)
        
    //                     }
    //                     if(props.customerapi[i].pavheightmax >= 0){
    //                         FinalObject["pavheightmax"] = parseFloat(props.customerapi[i].pavheightmax)
        
    //                     }
    //                     if(props.customerapi[i].pavanglemin >= 0){
    //                         FinalObject["pavanglemin"] = parseFloat(props.customerapi[i].pavanglemin)
        
    //                     }
    //                     if(props.customerapi[i].pavanglemax >= 0){
    //                         FinalObject["pavanglemax"] = parseFloat(props.customerapi[i].pavanglemax)
        
    //                     }
    //                     if(props.customerapi[i].brand){
    //                         FinalObject["brand"] = props.customerapi[i].brand.split(',')
        
    //                     }
    //                     if(props.customerapi[i].origin){
    //                         FinalObject["origin"] = props.customerapi[i].origin.split(',')
        
    //                     }
    //                     if(props.customerapi[i].treatment){
    //                         FinalObject["treatment"] = props.customerapi[i].treatment.split(',')
        
    //                     }
    //                     if(props.customerapi[i].keytosymbol){
    //                         FinalObject["keytosymbol"] = props.customerapi[i].keytosymbol.split(',')
        
    //                     }
    //                     if(naturaldiamond){
    //                         if(props.customerapi[i].color){
    //                             FinalObject["color"] = props.customerapi[i].color.split(',')
        
    //                         }
    //                     }
    //                     else{
    //                         if(props.customerapi[i].diamondfancy_color){
    //                             FinalObject["fancy_color"] = props.customerapi[i].diamondfancy_color.split(',')
        
    //                         }
    //                         if(props.customerapi[i].diamondfancy_intensity){
    //                             FinalObject["fancy_intensity"] = props.customerapi[i].diamondfancy_intensity.split(',')
        
    //                         }
    //                         if(props.customerapi[i].diamondfancy_overtone){
    //                             FinalObject["fancy_overtone"] = props.customerapi[i].diamondfancy_overtone.split(',')
                                
    //                         }
    //                     }
    //                 }
    //                 else{
    //                   if(props.customerapi[i].lab_shape){
    //                     FinalObject["shape"] = props.customerapi[i].lab_shape.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_cut){
    //                     FinalObject["cut"] = props.customerapi[i].lab_cut.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_clarity){
    //                     FinalObject["clarity"] = props.customerapi[i].lab_clarity.split(',')
        
    //                   } 
    //                   if(props.customerapi[i].lab_min_carat >= 0){
    //                     FinalObject["min_carat"] = parseFloat(props.customerapi[i].lab_min_carat)
        
    //                   } 
    //                   if(props.customerapi[i].lab_max_carat >= 0){
    //                     FinalObject["max_carat"] = parseFloat(props.customerapi[i].lab_max_carat)
        
    //                   }
    //                   if(props.customerapi[i].lab_lab){
    //                     FinalObject["lab"] = props.customerapi[i].lab_lab.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_symmetry){
    //                     FinalObject["symmetry"] = props.customerapi[i].lab_symmetry.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_fluorescence){
    //                     FinalObject["fluorescence"] = props.customerapi[i].lab_fluorescence.split(',')
    //                   }
    //                   if(props.customerapi[i].lab_polish){
    //                     FinalObject["polish"] = props.customerapi[i].lab_polish.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_milky){
    //                     FinalObject["milky"] = props.customerapi[i].lab_milky.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_eyeclean){
    //                     FinalObject["eyeclean"] = props.customerapi[i].lab_eyeclean.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_shade){
    //                     FinalObject["shade"] = props.customerapi[i].lab_shade.split(',')
        
    //                   }
    //                   if(props.customerapi[i].lab_min_dollarperct >= 0){
    //                     FinalObject["min_dollarperct"] = parseFloat(props.customerapi[i].lab_min_dollarperct)
        
    //                   }
    //                   if(props.customerapi[i].lab_max_dollarperct >= 0){
    //                     FinalObject["max_dollarperct"] = parseFloat(props.customerapi[i].lab_max_dollarperct)
        
    //                   }
    //                   if(props.customerapi[i].lab_total_price_from >= 0){
    //                     FinalObject["total_price_from"] = parseFloat(props.customerapi[i].lab_total_price_from)
        
    //                   }
    //                   if(props.customerapi[i].lab_total_price_to >= 0){
    //                     FinalObject["total_price_to"] = parseFloat(props.customerapi[i].lab_total_price_to)
        
    //                   }
    //                   if(props.customerapi[i].lab_media){
    //                     FinalObject["media"] = props.customerapi[i].lab_media.split(',')
        
    //                   }
    //                   if(props.customerapi[i].labminlength >= 0){
    //                     FinalObject["minlength"] = parseFloat(props.customerapi[i].labminlength)
        
    //                   }
    //                   if(props.customerapi[i].labmaxlength >= 0){
    //                     FinalObject["maxlength"] = parseFloat(props.customerapi[i].labmaxlength)
        
    //                   }
    //                   if(props.customerapi[i].labminwidth >= 0){
    //                     FinalObject["minwidth"] = parseFloat(props.customerapi[i].labminwidth)
        
    //                   }
    //                   if(props.customerapi[i].labmaxwidth >= 0){
    //                     FinalObject["maxwidth"] = parseFloat(props.customerapi[i].labmaxwidth)
        
    //                   }
    //                   if(props.customerapi[i].labminheight >= 0){
    //                     FinalObject["minheight"] = parseFloat(props.customerapi[i].labminheight)
        
    //                   }
    //                   if(props.customerapi[i].labmaxheight >= 0){
    //                     FinalObject["maxheight"] = parseFloat(props.customerapi[i].labmaxheight)
        
    //                   }
    //                   if(props.customerapi[i].labtablemin >= 0){
    //                     FinalObject["tablemin"] = parseFloat(props.customerapi[i].labtablemin)
        
    //                   }
    //                   if(props.customerapi[i].labtablemax >= 0){
    //                     FinalObject["tablemax"] = parseFloat(props.customerapi[i].labtablemax)
        
    //                   }
    //                   if(props.customerapi[i].labdepthmin >= 0){
    //                     FinalObject["depthmin"] = parseFloat(props.customerapi[i].labdepthmin)
        
    //                   }
    //                   if(props.customerapi[i].labdepthmax >= 0){
    //                     FinalObject["depthmax"] = parseFloat(props.customerapi[i].labdepthmax)
        
    //                   }
    //                   if(props.customerapi[i].labratiomin >= 0){
    //                     FinalObject["ratiomin"] = parseFloat(props.customerapi[i].labratiomin)
        
    //                   }
    //                   if(props.customerapi[i].labratiomax >= 0){
    //                     FinalObject["ratiomax"] = parseFloat(props.customerapi[i].labratiomax)
        
    //                   }
    //                   if(props.customerapi[i].labcrheightmin >= 0){
    //                     FinalObject["crheightmin"] = parseFloat(props.customerapi[i].labcrheightmin)
        
    //                   }
    //                   if(props.customerapi[i].labcrheightmax >= 0){
    //                     FinalObject["crheightmax"] = parseFloat(props.customerapi[i].labcrheightmax)
        
    //                   }
    //                   if(props.customerapi[i].labcranglemin >= 0){
    //                     FinalObject["cranglemin"] = parseFloat(props.customerapi[i].labcranglemin)
        
    //                   }
    //                   if(props.customerapi[i].labcranglemax >= 0){
    //                     FinalObject["cranglemax"] = parseFloat(props.customerapi[i].labcranglemax)
        
    //                   }
    //                   if(props.customerapi[i].labpavheightmin >= 0){
    //                     FinalObject["pavheightmin"] = parseFloat(props.customerapi[i].labpavheightmin)
        
    //                   }
    //                   if(props.customerapi[i].labpavheightmax >= 0){
    //                     FinalObject["pavheightmax"] = parseFloat(props.customerapi[i].labpavheightmax)
        
    //                   }
    //                   if(props.customerapi[i].labpavanglemin >= 0){
    //                     FinalObject["pavanglemin"] = parseFloat(props.customerapi[i].labpavanglemin)
        
    //                   }
    //                   if(props.customerapi[i].labpavanglemax >= 0){
    //                     FinalObject["pavanglemax"] = parseFloat(props.customerapi[i].labpavanglemax)
        
    //                   }
    //                     // FinalObject["brand"] = props.customerapi[i].brand.split(',')
    //                     if(props.customerapi[i].laborigin){
    //                         FinalObject["origin"] = props.customerapi[i].laborigin.split(',')
        
    //                     }
    //                     if(props.customerapi[i].labtreatment){
    //                         FinalObject["labtreatment"] = props.customerapi[i].labtreatment.split(',')
        
    //                     }
    //                     if(props.customerapi[i].labkeytosymbol){
    //                         FinalObject["keytosymbol"] = props.customerapi[i].labkeytosymbol.split(',')
        
    //                     }
    //                   if(lgwhitediam){
    //                     if(props.customerapi[i].lab_color){
    //                         FinalObject["color"] = props.customerapi[i].lab_color.split(',')
        
    //                     }
    //                   }
    //                   else{
    //                     if(props.customerapi[i].lab_fancy_color){
    //                         FinalObject["fancy_color"] = props.customerapi[i].lab_fancy_color.split(',')
        
    //                     }
    //                     if(props.customerapi[i].lab_fancy_intensity){
    //                         FinalObject["fancy_intensity"] = props.customerapi[i].lab_fancy_intensity.split(',')
        
    //                     }
    //                     if(props.customerapi[i].lab_fancy_overtone){
    //                         FinalObject["fancy_overtone"] = props.customerapi[i].lab_fancy_overtone.split(',')
        
    //                     }
    //                   }
    //                 }
    //                 console.log(FinalObject,"FinalObject")
    //                 promises.push(
    //                     axios({
    //                         method: "POST",
    //                         url: `${NODE_API_URL}/TESTMODE/fetchonsuppliers`,
    //                         headers: { "Content-Type": "application/json" },
    //                         data: FinalObject,
    //                     }).then(res => {
    //                     if(res && res.data && res.data.data){
    //                         let count = 0
    //                     for (let i = 0; i < res.data.data.length; i++) {
    //                         // if(res.data.data[i].whitetotal){
    //                         //     count += res.data.data[i].whitetotal
    //                         // }
    //                         // if(res.data.data[i].fancytotal){
    //                         //     count += res.data.data[i].fancytotal
    //                         // }
    //                         const fetcheddata = {...(supreq.data.data.find((itmInner) => itmInner.supplier_id === res.data.data[i].id && itmInner.api_id === props.userinfo.api_id))} 
                            
    //                         let mergedobj = {
    //                             ...res.data.data[i],
    //                             ...fetcheddata
    //                         }
    //                         if(mergedobj.whitetotal && mergedobj.api_on_off === 1){
    //                             count += mergedobj.whitetotal
    //                         }
    //                         if(mergedobj.fancytotal && mergedobj.api_on_off === 1){
    //                             count += mergedobj.fancytotal
    //                         }
    //                     }
    //                         console.log(count,"countcount",res.data.data)    
    //                         finaloutput.push({
    //                             ...props.customerapi[i],
    //                             diamondcount:count
    //                         })
    //                     }
    //                 }))
        
    //             }
    //             Promise.all(promises).then(() => setExpiredDate(finaloutput));
    //         }
    //     })
    // },[props.customerapi])
    console.log(erpireddate,"erpireddate")
    const columns = useMemo(()=> MockShortListColumn(history,props,setDeleteObj,setSwalOpen,erpireddate,setCountLoader,countloader),[props.updatedata,props.supplieron,props.currencyvalue,erpireddate,countloader,props.customerapi] )

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data:props.customerapi
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    // {
                    //     id:'selection',
                    //     Header:({ getToggleAllPageRowsSelectedProps }) =>(
                    //         <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                    //     ),
                    //     Cell: ({ row }) => (
                    //         <div>
                    //           <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                    //         </div>
                    //       ),
                    // },
                    // {
                    // Header: () => null,
                    // id: 'expander',
                    // Cell: ({ row }) => (
                    //     <span  {...row.getToggleRowExpandedProps()}>
                    //         {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  row.original.ShortListedStones?<FaChevronRight className="text-primary" title="Expand"/>:""}
                    //     </span>
                    // ),
                    // },
                    ...columns,
                ]
            })
        }
    )
    const [apistatus,setApiStatus] = useState()
    const TurnOnorOffAll = (api_on_off) => {
        setSwalOpen(true)
        setApiStatus(api_on_off)
        // axios({
        //     method: "POST",
        //     url: `${NODE_API_URL}/TESTMODE/turnOnorOffAll`,
        //     headers: { "Content-Type": "application/json" },
        //     data: {
        //         user_id:props.userinfo.id,
        //         api_id:props.userinfo.api_id,
        //         type:"Natural",
        //         api_on_off:api_on_off
        //     },
        // }).then(supreq => {
        //     if(supreq && supreq.data){
        //         if(supreq.data){
        //         setUpdate(supreq.data)
        //         if(api_on_off === 1){
        //             toast("Your All Feed Turn on",{
        //                 position: "top-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //                 theme: "light",
        //                 })
        //         }else{
        //             toast("Your All Feed Turn Off",{
        //                 position: "top-right",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //                 theme: "light",
        //                 })
        //         }
        //         }
        //     }
        // })
    }
    const FinalOk = (api_on_off) => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/turnOnorOffAll`,
            headers: { "Content-Type": "application/json" },
            data: {
                user_id:props.userinfo.id,
                api_id:props.userinfo.api_id,
                type:"Natural",
                api_on_off:api_on_off
            },
        }).then(supreq => {
            if(supreq && supreq.data){
                if(supreq.data){
                setUpdate(supreq.data)
                setSwalOpen(false)

                if(api_on_off === 1){
                    toast.success("Your All Feed Turn on")
                }else{
                    toast.error("Your All Feed Turn Off")
                }
                }
            }
        })
    }
    const NewRule = () => {
        if(!props.currencyvalue){
            toast.error("Please Select Currency")
            return
        }
        if(!props.supplieron){
            toast.error("Please Turn ON Suppliers from Supplier Management page")
            return
        }
        // history.push('/create-rule')
        history.push({
            pathname: "/create-rule",
            state: {
                currencyvalue:props.currencyvalue
            },
                // state: decompress(res.data.ProductDetails),
            });
    }
    const DeleteRule = (data) => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/deleteRules`,
            headers: { "Content-Type": "application/json" },
            data: deleteObj,
        }).then(res => {
            if(res && res.data){
                props.setUpdateData(!props.updatedata)
                setDeleteObj(null)
                setSwalOpen(false)
            }
        })
    }
    return (
        <>
        {props.loader?<ApiDataSkeleton/>:<div>
          <SwalWarn show={swalopen} message={"Are you sure want to delete Rule?"} onHide={() => setSwalOpen(false)} onClose={() => setSwalOpen(false)} responseOnOk={() => DeleteRule()}/> 
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div><GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} /></div>
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2" style={{height:"400px", overflow:"auto"}}>
                                    <div  className="p-3" >
                                        <label className="checkbox my-2 border p-2">
                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                            <span className="mr-2"></span>
                                            Show All
                                        </label> 
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* <div className="search_result_dropdown">
                        <button type="button" class="btn btn-primary btn-sm" onClick={() => NewRule()}> <FaPlus className='mr-1'/>New Rule</button>
                        <button type="button" class="btn btn-primary btn-sm mr-1" onClick={() => window.open("https://malukatest.diazoom.com/", "_blank")}>API Frame</button>
                    </div> */}
                </div>
                <div className="col-12 pt-7 pb-0 mt-3">
                    <div className="table-responsive w-100">
                        <table className="table table-striped table-hover" {...getTableProps()}>
                            <thead className="custom_datatable_head" >
                                {headerGroups.map(headerGroup =>(
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        { headerGroup.headers.map((column)=>(
                                            <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="text-secondary">
                                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.length?page.map((row) => {
                                    prepareRow(row)
                                    return(
                                        <>
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        </>
                                    )
                                }):<tr><td className="text-center bg-gray-200" colSpan="100%">No Rule Created</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {pageOptions.length}{'  '}
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                            <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                            <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}