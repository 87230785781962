import React,{useState,useEffect} from "react";
import { Grid, Typography, InputLabel, } from "@material-ui/core";
import { InputField,  SelectField } from "../FormFields";
import { Accordion, Card } from "react-bootstrap";
import { GetCookies } from "../../../../_helpers/Cookies";

export default function AdditionalSetting(props) {
    const [inr,setInr] = useState(0)
    const [usd,setUsd] = useState(0)
    const multi_currency_data = [
        {
          value: 'USD',
          label: `USD (${usd.toFixed(2)})`
        },
        {
          value: 'INR',
          label: `INR (${(inr + 0.25).toFixed(2)})`
        }
      ];
      const multi_language_data = [
        {
          value: 'en',
          label: `English`
        },
        {
          value: 'hi',
          label: `Hindi`
        }
      ];
    const {
        formField: {
            multi_currency,
            multi_language,
            website_url,
            inquiry_email,
            inquiry_cc_email
        },
        values,
        setFieldValue
    } = props;
    useEffect(() => {
        GetCookies("conversionrates").then(response => {
            // console.log(response,"Conversionresponse")
            if(response && response[0]){
                const currencies = JSON.parse(response)
                // console.log(currencies,"currencies")
                setInr(Math.round(currencies[0].cur_inr*100)/100)
                setUsd(Math.round(currencies[0].cur_usd*100)/100)
            }
        })
    },[])
   
    return (
        <React.Fragment>
            <Grid justify="center" alignItems="center" spacing={3}>
                <Accordion className="ccmode_accordion mb-10" defaultActiveKey="1">
                    <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="1" key="1">
                        {props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_WEBSITE_SETTING}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.PROFILE_WEBSITE} *
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputField
                                            name={website_url.name}
                                            label={website_url.label}
                                            fullWidth
                                            disabled={true}
                                            // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                        </Grid>
                    </Accordion.Collapse>
                </Accordion>
            </Grid>
            <Grid justify="center" alignItems="center" spacing={3}>
                <Accordion className="ccmode_accordion mb-10" defaultActiveKey="3">
                    <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="3" key="3">
                        {props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_EMAIL_SETTING}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.PROFILEVIEW_EMAIL} 
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputField
                                            name={inquiry_email.name}
                                            label={props.language.PROFILEVIEW_EMAIL}
                                            fullWidth
                                            disabled={true}
                                            // onChange={(e) => setFieldValue("lab_diamondmarkup",e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            {props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_CCEMAIL}  
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputField
                                            name={inquiry_cc_email.name}
                                            label={props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_CCEMAIL}
                                            fullWidth
                                            // onChange={(e) => setFieldValue("lab_diamondfancymarkup",e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Accordion.Collapse>
                </Accordion>
            </Grid>
            {/* <Grid justify="center" alignItems="center" spacing={3}>
                <Accordion className="ccmode_accordion mb-10" defaultActiveKey="4">
                    <Accordion.Toggle className="mb-10" as={Card.Header} eventKey="4" key="4">
                        Currency & Language Setting
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                        <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            Multi Currency 
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SelectField
                                            name={multi_currency.name}
                                            label={multi_currency.label}
                                            fullWidth
                                            data={multi_currency_data}
                                            // onChange={(e) => setFieldValue("lab_diamondmarkup",e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <hr className="w-100 m-0 mt-8" />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={4}>
                                        <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                            Multi Language 
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SelectField
                                            name={multi_language.name}
                                            label={multi_language.label}
                                            fullWidth
                                            data={multi_language_data}
                                            // onChange={(e) => setFieldValue("lab_diamondfancymarkup",e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Accordion.Collapse>
                </Accordion>
            </Grid> */}
        </React.Fragment>
    );
}
