import React  from "react";
import { Grid, Typography, InputLabel } from "@material-ui/core";
import { InputField, MultiSelect,CustomCheckbox,MediaAssets} from "../FormFields";


export default function NaturalDiam(props) {
    // console.log(props.values,"NaturalDiamValues")
    const diamondshape_data = [
      {
        value: 'Round',
        label: 'Round'
      },
      {
        value: 'Princess',
        label: 'Princess'
      },
      {
        value: 'Emerald',
        label: 'Emerald'
      },
      {
        value: 'Asscher',
        label: 'Asscher'
      },
      {
        value: 'Oval',
        label: 'Oval'
      },
      {
        value: 'Radiant',
        label: 'Radiant'
      },
      {
        value: 'Square Radiant',
        label: 'Square Radiant'
      },
      {
        value: 'Pear',
        label: 'Pear'
      },
      {
        value: 'Heart',
        label: 'Heart'
      },
      {
        value: 'Trilliant',
        label: 'Trilliant'
      },
      {
        value: 'Marquise',
        label: 'Marquise'
      },
      {
        value: 'Cushion',
        label: 'Cushion'
      },
      {
        value: 'Cushion Modified',
        label: 'Cushion Modified'
      },
      {
        value: 'Triangle',
        label: 'Triangle'
      }
    ];
    const diamondcolor_data = [
        {
            value:"D",
            label:"D"
        },
        {
            value:"E",
            label:"E"
        },
        {
            value:"F",
            label:"F"
        },
        {
            value:"G",
            label:"G"
        },
        {
            value:"H",
            label:"H"
        },
        {
            value:"I",
            label:"I"
        },
        {
            value:"J",
            label:"J"
        },
        {
            value:"K",
            label:"K"
        },
        {
            value:"L",
            label:"L"
        },
        {
            value:"M",
            label:"M"
        },
        {
            value:"N",
            label:"N"
        },
        {
            value:"OP",
            label:"OP"
        },
        {
            value:"QR",
            label:"QR"
        },
        {
            value:"ST",
            label:"ST"
        },
        {
            value:"UV",
            label:"UV"
        },
        {
            value:"WX",
            label:"WX"
        },
        {
            value:"YZ",
            label:"YZ"
        }
    ];
    const diamondfancy_color_data = [
      {
          value:"Yellow",
          label:"Yellow"
      },
      {
          value:"Pink",
          label:"Pink"
      },
      {
          value:"Blue",
          label:"Blue"
      },
      {
          value:"Red",
          label:"Red"
      },
      {
          value:"Green",
          label:"Green"
      },
      {
          value:"Purple",
          label:"Purple"
      },
      {
          value:"Orange",
          label:"Orange"
      },
      {
          value:"Violet",
          label:"Violet"
      },
      {
          value:"Gray",
          label:"Gray"
      },
      {
          value:"Black",
          label:"Black"
      },
      {
          value:"Brown",
          label:"Brown"
      },
      {
          value:"Champagne",
          label:"Champagne"
      },
      {
          value:"Cognac",
          label:"Cognac"
      },
      {
          value:"Chameleon",
          label:"Chameleon"
      },
      {
          value:"White",
          label:"White"
      }
    ];
    const diamondfancy_intensity_data = [
      {
          value:"Faint",
          label:"Faint"
      },
      {
          value:"Very Light",
          label:"Very Light"
      },
      {
          value:"Light",
          label:"Light"
      },
      {
          value:"Fancy Light",
          label:"Fancy Light"
      },
      {
          value:"Fancy",
          label:"Fancy"
      },
      {
          value:"Fancy Dark",
          label:"Fancy Dark"
      },
      {
          value:"Fancy Intense",
          label:"Fancy Intense"
      },
      {
          value:"Fancy Vivid",
          label:"Fancy Vivid"
      },
      {
          value:"Fancy Deep",
          label:"Fancy Deep"
      }
    ];
    const diamondfancy_overtone_data = [
        {
            value:"None",
            label:"None"
        },
        {
            value:"Yellow",
            label:"Yellow"
        },
        {
            value:"Yellowish",
            label:"Yellowish"
        },
        {
            value:"Pink",
            label:"Pink"
        },
        {
            value:"Pinkish",
            label:"Pinkish"
        },
        {
            value:"Blue",
            label:"Blue"
        },
        {
            value:"Blueish",
            label:"Blueish"
        },
        {
            value:"Red",
            label:"Red"
        },
        {
            value:"Reddish",
            label:"Reddish"
        },
        {
            value:"Green",
            label:"Green"
        },
        {
            value:"Greenish",
            label:"Greenish"
        },
        {
            value:"Purple",
            label:"Purple"
        },
        {
            value:"Purplish",
            label:"Purplish"
        },
        {
            value:"Orange",
            label:"Orange"
        },
        {
            value:"Orangy",
            label:"Orangy"
        },
        {
            value:"Violet",
            label:"Violet"
        },
        {
            value:"Violetish",
            label:"Violetish"
        },
        {
            value:"Gray",
            label:"Gray"
        },
        {
            value:"Grayish",
            label:"Grayish"
        },
        {
            value:"Black",
            label:"Black"
        },
        {
            value:"Brown",
            label:"Brown"
        },
        {
            value:"Brownish",
            label:"Brownish"
        },
        {
            value:"Champagne",
            label:"Champagne"
        },
        {
            value:"Cognac",
            label:"Cognac"
        },
        {
            value:"Chameleon",
            label:"Chameleon"
        },
        {
            value:"White",
            label:"White"
        }
    ];
    const diamondclarity_data = [
        {
            value:"FL",
            label:"FL"
        },
        {
            value:"IF",
            label:"IF"
        },
        {
            value:"VVS1",
            label:"VVS1"
        },
        {
            value:"VVS2",
            label:"VVS2"
        },
        {
            value:"VS1",
            label:"VS1"
        },
        {
            value:"VS2",
            label:"VS2"
        },
        {
            value:"SI1",
            label:"SI1"
        },
        {
            value:"SI2",
            label:"SI2"
        },
        {
            value:"SI3",
            label:"SI3"
        },
        {
            value:"I1",
            label:"I1"
        },
        {
            value:"I2",
            label:"I2"
        },
        {
            value:"I3",
            label:"I3"
        }
    ];

    const lab_val_data = [
        {
            value:"GIA",
            label:"GIA"
        },
        {
            value:"IGI",
            label:"IGI"
        },
        {
            value:"HRD",
            label:"HRD"
        },
        {
            value:"AGS",
            label:"AGS"
        }
    ];
    
    const cuts_data = [
        {
            value:"EX",
            label:"Excellent"
        },
        {
            value:"VG",
            label:"Very Good"
        },
        {
            value:"GD",
            label:"Good"
        },
        {
            value:"FR",
            label:"Fair"
        },
        {
            value:"PR",
            label:"Poor"
        },
        {
            value:"ID",
            label:"IDEAL"
        }
    ];

    const polishes_data = [
        {
            value:"EX",
            label:"Excellent"
        },
        {
            value:"VG",
            label:"Very Good"
        },
        {
            value:"GD",
            label:"Good"
        },
        {
            value:"FR",
            label:"Fair"
        },
        {
            value:"PR",
            label:"Poor"
        }
    ];

    const flour_data = [
        {
            value:"None",
            label:"None"
        },
        {
            value:"V. Slight",
            label:"V. Slight"
        },
        {
            value:"Faint",
            label:"Faint"
        },
        {
            value:"Slight",
            label:"Slight"
        },
        {
            value:"Medium",
            label:"Medium"
        },
        {
            value:"Strong",
            label:"Strong"
        },
        {
            value:"V. Strong",
            label:"V. Strong"
        }
    ];

    const shade_data = [
        {
            value:"None",
            label:"None"
        },
        {
            value:"Brown",
            label:"Brown"
        },
        {
            value:"Green",
            label:"Green"
        },
        {
            value:"Grey",
            label:"Grey"
        },
        {
            value:"Black",
            label:"Black"
        },
        {
            value:"Pink",
            label:"Pink"
        },
        {
            value:"Blue",
            label:"Blue"
        },
        {
            value:"Light Brown",
            label:"Light Brown"
        },
        {
            value:"Light Green",
            label:"Light Green"
        },
        {
            value:"Mix Tinge",
            label:"Mix Tinge"
        }
    ];

    const milky_data = [
        {
            value:"NO MILKY",
            label:"No Milky"
        },
        {
            value:"LIGHT MILKY",
            label:"Light Milky"
        },
        {
            value:"MILKY",
            label:"Milky"
        }
    ];

    const eyeclean_data = [
        {
            value:"YES",
            label:"Yes"
        },
        {
            value:"NO",
            label:"No"
        }
    ];

    const media_data = [
        {
            value:"0",
            label:"All Media"
        },
        {
            value:"1",
            label:"Only Video"
        },
        {
            value:"2",
            label:"Only Image"
        },
        {
            value:"3",
            label:"Both Image and Videos"
        },
        {
            value:"4",
            label:"Image or Video"
        }
    ];
    const {
        formField: {
            diamondshape,
            carat_from,
            carat_to,
            diamondcolor,
            diamondfancy_color,
            diamondfancy_intensity,
            diamondfancy_overtone,
            diamondclarity,
            lab_val,
            cuts,
            polishes,
            symmetries,
            diamondflour,
            diamondshade,
            milky,
            eyeclean,
            price_from,
            price_to,
            total_price_from,
            total_price_to,
            media
        },
        values,
        setFieldValue
    } = props;

    return (
        <React.Fragment>
            {/* <p class="text-center font-weight-bold text-muted">STEP 2 OF 3</p> */}
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center">
                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL}
            </Typography>
            <p className="mb-1 text-center">
            {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURALMSG1}
            </p>
            <p className="mb-5 text-center">
            {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURALMSG2}
            </p>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_DIAMONDSHAPE}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelect 
                                name={diamondshape.name}
                                label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_DIAMONDSHAPE}
                                fullWidth
                                data={diamondshape_data}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGE}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={carat_from.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <InputField
                                        name={carat_to.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                {values.naturaldiamond?
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                    {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_COLORLESS_DIAMOND} 
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <MultiSelect 
                                    name={diamondcolor.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_COLORLESS_DIAMOND}
                                    fullWidth
                                    data={diamondcolor_data}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                />
                            </Grid>
                        </Grid>
                        <hr className="w-100 m-0 mt-8" />
                    </Grid>
                :""}
                {values.naturalfancydiamond?
                    <Grid item xs={12} md={7}>
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                    {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCYCOLOR} 
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <MultiSelect 
                                    name={diamondfancy_color.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_COLOR}
                                    fullWidth
                                    data={diamondfancy_color_data}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_color",e.target.value)}
                                />
                                <MultiSelect 
                                    name={diamondfancy_intensity.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_INTENSITY}
                                    fullWidth
                                    data={diamondfancy_intensity_data}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_intensity_data",e.target.value)}
                                />
                                <MultiSelect 
                                    name={diamondfancy_overtone.name}
                                    label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_OVERTONE}
                                    fullWidth
                                    data={diamondfancy_overtone_data} 
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <hr className="w-100 m-0 mt-8" />
                    </Grid>
                :""}
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CLARITY}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelect 
                                name={diamondclarity.name}
                                label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CLARITY}
                                fullWidth
                                data={diamondclarity_data} 
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_LAB}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={lab_val.name}
                                label={lab_val.label}
                                data={lab_val_data}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CUT}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={cuts.name}
                                label={cuts.label}
                                data={cuts_data}
                                classes={cuts.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_POLISH}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={polishes.name}
                                label={polishes.label}
                                data={polishes_data}
                                classes={polishes.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_SYMM}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={symmetries.name}
                                label={symmetries.label}
                                data={polishes_data}
                                classes={symmetries.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                            {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FLOUR}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelect 
                                name={diamondflour.name}
                                label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FLOUR}
                                fullWidth
                                data={flour_data} 
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.MUI_SHADE} 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <MultiSelect 
                                name={diamondshade.name}
                                label={props.language.MUI_SHADE}
                                fullWidth
                                data={shade_data} 
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                    // onChange={(e) => setFieldValue("diamondfancy_overtone",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.MUI_MILKY} 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={milky.name}
                                label={props.language.MUI_MILKY}
                                data={milky_data}
                                classes={milky.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.MUI_EYECLEAN} 
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCheckbox
                                name={eyeclean.name}
                                label={props.language.MUI_EYECLEAN}
                                data={eyeclean_data}
                                classes={eyeclean.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                            {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_DOLPRICERANGE}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={price_from.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                <InputField
                                        name={price_to.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_TOTALPRICERANGE}*
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputField
                                        name={total_price_from.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGEFROM}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputField
                                        name={total_price_to.name}
                                        label={props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_CARATRANGETO}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                {props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_MEDIA_ASSETS}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <MediaAssets
                                    name={media.name}
                                    label={media.label}
                                    data={media_data}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    // classes={eyeclean.classes}
                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                />  
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <hr className="w-100 m-0 mt-8" /> */}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
