import React from "react";
import { Field } from "formik";



export const Symmetry =({formik}) =>{

    const SymmetryData = ([
        {
            id: 1,
            symmetry:"ID"
        },
        {
            id: 2,
            symmetry:"EX"
        },
        {
            id: 3,
            symmetry:"VG"
        },
        {
            id: 4,
            symmetry:"GD"
        },
        {
            id: 5,
            symmetry:"FR"
        },
        {
            id: 6,
            symmetry:"PR"
        }
    ]);

    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Symmetry</div>
                <div className="col-12" >
                    {SymmetryData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Symmetry" id={`${item.symmetry}_symmetry`} value={item.symmetry}/>
                            <div className="wColorBox selectBox">
                                <span>{item.symmetry}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
}