import React from "react";
import { Field } from "formik";


export const Lab =({formik}) =>{

    const LabData = ([
        {
            id: 1,
            lab:"GIA"
        },
        {
            id: 2,
            lab:"IGI"
        },
        {
            id: 3,
            lab:"HRD"
        },
        {
            id: 4,
            lab:"OTHER"
        }
    ]);

    return (
        <>
            <div className="row m-0 mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Lab</div>
                <div className="col-12" >
                    {LabData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                            <div className="wLabBox selectBox">
                                <span>{item.lab}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
}