import React from "react";
import { Field } from "formik";



export const Cut =({formik}) =>{

    const CutData = ([
        {
            id: 1,
            cut:"ID"
        },
        {
            id: 2,
            cut:"EX"
        },
        {
            id: 3,
            cut:"VG"
        },
        {
            id: 4,
            cut:"GD"
        },
        {
            id: 5,
            cut:"FR"
        },
        {
            id: 6,
            cut:"PR"
        }
    ]);

    return (
        <>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Cut</div>
                <div className="col-12" >
                    {CutData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Cut" id={`${item.cut}_cut`} value={item.cut}/>
                            <div className="wColorBox selectBox">
                                <span>{item.cut}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
}