import React ,{useState,useEffect} from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@material-ui/core";

export default function DiamTypeCheck(props) {
    const { label, ...rest } = props;
    const [field, meta, helper] = useField(props);
    // console.log(meta.error,"ERRORRRRRMETA")
    const [border,setBorder] = useState("")
    useEffect(() => {
        if(meta.error){
            setBorder("border-danger")
        }else{
            setBorder("")
        }
    },[meta.error])
    const { setValue } = helper;
    function _renderHelperText() {
        const [touched, error] = at(meta, "touched", "error");
        if (touched && error) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    function _onChange(e) {
    // console.log(props,"FIELDESSSS")
    // console.log(props.values[`${props.name}`],"NAMESSSSS")
    if(props.values[`${props.name}`]){
    props.setFieldValue(props.name,'')
    }
    else{
    props.setFieldValue(props.name,props.name)

    }
    }
    // console.log(props.name,"PVALLLL")
    // console.log(props.values,"VALUESSSSS")
    // console.log(props.values[`${props.name}`],"FINALLLL")
    return (
       <>
       <label className="table_cell_checkmark1 multitab_diam " >
            <div className={`text-center mt-2 multitab_diam_box naturaldiamond checkbox ${border}`}>
              <div
                  className={`${
                      props.name === "naturaldiamond" ? "white_dia_icon" 
                      : props.name === "naturalfancydiamond" ? "fancy_dia_icon" 
                      : props.name === "lgwhitediam" ? "lab_dia_icon" 
                      : props.name === "lgfancydiam" ? "lab_fancy_dia_icon" : ""
                    }`}
                ></div>
                <input type="checkbox" name={props.name} value={field.checked} id={props.name} className="check_box_all"  checked={meta.value} onChange={_onChange} error={meta.touched && meta.error && true}/>
                <span className="checkmark1"></span>
                <p className="m-0 mt-2">{label}</p>
            </div>
        </label>
       </>
    );
}
