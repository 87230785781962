/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { FaPhoneAlt } from 'react-icons/fa';
import { toAbsoluteUrl } from "../../../../_helpers";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown(props) {
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  // const { user } = useSelector((state) => state.auth);
  const userData = React.useMemo(() => localStorage.getItem("userData"), []);
  const user = JSON.parse(userData);
  // console.log("props",props)
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="symbol symbol-35 symbol-light-primary symbol-circle">
            <span className="symbol-label font-size-h5 font-weight-bold">
              <i className="flaticon2-user text-primary"></i>
            </span>
            {/* <i className="symbol-badge symbol-badge-bottom bg-success"></i> */}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div
            className="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top justify-content-between"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <span className="btn btn-md btn-icon bg-white-o-15 mr-4">
              <i className="fas fa-cogs text-success"></i>
            </span>
            <h4 className="text-white text-capitalize m-0 mr-3 text-right">
              {/* {console.log("user",user.CompanyName)} */}
              {user.CompanyName ? user.CompanyName : ""}
              <span className="text-white-50 small text-capitalize m-0 d-block">{props.userinfo.fname ? props.userinfo.fname  : ""} {props.userinfo.lname ? props.userinfo.lname : ""}</span>
              <span className="text-white-50 small text-capitalize m-0 d-block">
                <div className="align-self-center mr-2 d-block d-md-none"> <FaPhoneAlt className="mr-1" /> <span className="font-weight-bold">{props.language.TOP_BAR_TOLLFREE}:</span>
                  {props.userinfo.country === "Australia" ? <a href="tel:+611800841842" className="text-muted text-hover-warning font-weight-bold">+61 1800 841 842</a> 
                      ? props.userinfo.country === "Hong Kong" : <a href="tel:+85235655670"  className="text-muted text-hover-warning font-weight-bold">+852 3565 5670</a>
                      ? props.userinfo.country === "India" : <a href="tel:+919106558044"  className="text-muted text-hover-warning font-weight-bold">+91 91065 58044</a>
                      ? props.userinfo.country === "Singapore" : <a href="tel:+6560185200"  className="text-muted text-hover-warning font-weight-bold">+65 6018 5200</a>
                      ? props.userinfo.country === "United Kingdom" : <a href="tel:+441617795441"  className="text-muted text-hover-warning font-weight-bold">+44 161 779 5441</a>
                      ? props.userinfo.country === "United States" || props.userinfo.country === "Canada" : <a href="tel:+13322397300"  className="text-muted text-hover-warning font-weight-bold">+1 (332) 239-7300</a>
                      :<a href="tel:+13322397300" className="text-muted text-hover-warning font-weight-bold">+1 (332) 239-7300</a>}
                </div>
              </span>
            </h4>
            
          </div>
        <div className="navi navi-spacer-x-0 pt-2">
          {/* <Link to="/profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  {props.language.SETTING_MYPROFILE}
                </div>
                <div className="text-muted">
                  {props.language.SETTING_PROFILE_SETTING}
                </div>
              </div>
            </div>
          </Link> */}
          <Link to="/settings" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="fas fa-cog text-primary" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  {props.language.SETTING_SETTING}
                </div>
                <div className="text-muted">
                  {props.language.SETTING_ACCOUNT_SETTING}
                </div>
              </div>
            </div>
          </Link>
         <div className="navi-footer border-top px-8 py-5">
            <span></span>
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              {props.language.SETTING_SIGNOUT}
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}