import React, {  useState } from "react";
import {
    Modal,
    Button,
} from "react-bootstrap";
import { CircularProgress } from '@material-ui/core';

export const MainImagePopup = (props) => {
    const buttonStyles = {
        position: "absolute",
        top: "10px",
        right: "10px"
      };
    const [show, setShow] = useState(false);
   
    const handleClose = () => setShow(false);
    const [imageload,setImageLoad] = useState(false)
    return (
        <>
            <Modal
                show={props.mainimageclick}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Body>
                {imageload ? "" : <div>
            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              marginTop:"50"
            }}>
              <CircularProgress
                      // style={{marginLeft: '40%'}}
              />
              <h6>Please Wait...</h6>
            </div>
          </div>}
          <div style={{textAlign:"center"}}>
                    <img src={props.Image} alt={props.Shape} height='500px' width='500px' onLoad={() => setImageLoad(true)}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => props.setMainImageClick(false)}
                        style={buttonStyles}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
