/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from 'axios';
import { NODE_API_URL } from "../../../env_config";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { toAbsoluteUrl } from "../../_helpers";
// import {
//     FaCalendarAlt,
//     FaUserCircle,
//     FaUndo,
//     FaBookOpen,
//     FaCopy,
// } from "react-icons/fa";
// import { GiCutDiamond } from "react-icons/gi";
// import DiamJournyStep from "./DiamJournyStep";
function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const ExpandedDetails = (data) => {
    const [expandData, setData] = React.useState(data.data);
    const PrintPerforma = () => {
        if(data.data.perfoma_no){
            const userData = JSON.parse(localStorage.getItem("userData"))
            const finalData = {
                "user_id":userData.CustomerId,
                "id":data.data.perfoma_no,
                "conform_good_id":data.data.conform_good_id
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/TESTMODE/proformaDetailpage`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then(res => {
                if(res && res.data){
                    if(process.env.REACT_APP_ENV === "production"){
                        window.open(`https://diamondsoncall.online/assets/pdf_invoice/${res.data.invoicelink}`, "PRINT")
                    }
                    else{
                    window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${res.data.invoicelink}`, "PRINT")
            
                    }
                }
            }).catch(err => {

            })
        }
    }
    return (
        <div className="bg-gray-300 confirmExpand p-5 border">
            <div className="bg-white p-5 border">
                <div className="row border-bottom">
                    <div className="col-md-6 d-flex align-items-center flex-wrap">
                        <span className="font-size-h2 font-weight-bolder d-flex align-items-center">
                            #{expandData.conform_good_id}
                            <div
                                className={`label ${ expandData.Dimond_Status === "Order Approve" ? "label-primary" 
                                            : expandData.Dimond_Status === "Pending" ? "label-warning" 
                                            : expandData.Dimond_Status === "Ready For Invoice" ? "label-success" 
                                            : expandData.Dimond_Status === "Ready to Ship" ? "label-success"
                                            : expandData.Dimond_Status === "Rejected" ? "label-danger"
                                            : expandData.Dimond_Status === "QC Rejected" ? "label-danger"
                                            : expandData.Dimond_Status === "QC Review" ? "label-warning"
                                            : expandData.Dimond_Status === "QC Approved" ? "label-success"
                                            : ""
                                        } label-inline ml-2`}
                                >
                                {expandData.Dimond_Status}
                            </div>
                            {/* {expandData.perfoma_no ? <a href="#" className="label label-light-info label-inline label-sm small ml-2" > Proforma generated </a>: ""}  */}
                            {expandData.perfoma_no ? <a onClick={() => data.RouteToProforma({query:{perfoma_no:expandData.perfoma_no,conform_good_id:expandData.conform_good_id}},data.proformadata)} className="label label-light-info label-inline label-sm small">Pro Forma Inv No {expandData.perfoma_created_at}</a> : ""}
                        </span>
                        <br />
                        <div className="text-muted small col-12 p-0">
                            Order Date: {expandData.created_date}
                            {/* <FaBookOpen className="ml-1" />{" "}
                            <span className="text-primary font-font-weight-bolder cursor-pointer ml-1">
                                Read all comments
                            </span> */}
                        </div>
                    </div>
                    <div className="text-md-right col-md-6">
                       {expandData.perfoma_no ? <button className="px-8 btn btn-primary" onClick={() => PrintPerforma()}>Print Proforma</button>: ""} 
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center">DIAMOND DETAILS</th>
                                    {/* <th className="text-center">TAG</th> */}
                                    <th className="text-center"> LOCATION <br /> DELIVERY IN DAYS </th>
                                    <th className="text-center">DISC</th>
                                    {/* <th className="text-center">INR RATE</th> */}
                                    <th align="center" className="text-right pr-8">
                                        Our Price(USD) <br />
                                        USD/CT
                                    </th>
                                    {/* <th align="center" className="text-right pr-8">
                                        Our Price(INR) <br />
                                        INR/CT
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">
                                        <div className=" text-left">
                                            <div className={`mb-1 ${expandData.diamond_type === "N"? "natTab": "labTab"}`}>
                                                <span className={`${ expandData.diamond_type === "N" ? expandData.C_Color === "fancy" ? "F" : "W" : expandData.C_Color === "fancy" ? "LF": "L"}_diamond mr-3 small`} >
                                                    {expandData.diamond_type === "N" ? expandData.C_Color === "fancy" ? "F" : "W" : expandData.C_Color === "fancy" ? "LF" : "L"}
                                                </span>
                                                <span className="text-primary small">{expandData.Lab}-{expandData.Certi_NO}
                                                </span>
                                                <span className={`text-primary small ml-2`}>
                                                    {expandData.diamond_type === "N" ? "N" : "L"}-{expandData.Stock_NO}
                                                </span>
                                            </div>
    
                                            <span className="text-dark font-weight-bold d-block">
                                                {expandData.C_Shape}{" "}
                                                {expandData.C_Weight}{" "}
                                                {expandData.C_Color === "fancy" ? (
                                                    <>
                                                        {expandData.f_intensity}{" "}
                                                        {expandData.f_overtone}{" "}
                                                        {expandData.f_color}{" "}
                                                    </>
                                                ) : (
                                                    <>{expandData.C_Color}</>
                                                )}
                                                {expandData.C_Clarity}{" "}
                                                {expandData.C_Cut}{" "}
                                                {expandData.C_Polish}{" "}
                                                {expandData.C_Symmetry}{" "}
                                                {expandData.C_Fluorescence}
                                            </span>
                                        </div>
                                    </td>
                                    {/* <td className="text-center">
                                        <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">
                                            NO SHADE
                                        </div>
                                        <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">
                                            EYECLEAN
                                        </div>
                                        <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">
                                            MILKY
                                        </div>
                                    </td> */}
                                    <td className="text-center">{expandData.country}<br />7 days</td>
                                    <td className={`text-center ${(expandData.discount_main >= 0) ? 'text-success' : 'text-danger'}`}> {Number(expandData.discount_main).toFixed(2)}%</td>
                                    {/* <td className="text-center">{expandData.cur_inr ? parseFloat(expandData.cur_inr).toFixed(2) : ""}</td> */}
                                    <td className="text-right">
                                        <span className="font-weight-bold text-primary">  ${expandData["our_price(USD)"]}</span><br />
                                        <span className="text-muted">$/CT {expandData["USD/Ct"]}</span>
                                    </td>
                                    {/* <td className="text-right">
                                        <span className="font-weight-bold text-primary"> ₹{expandData["OurPrice(INR)"]}</span > <br />
                                        <span className="text-muted"> ₹/CT {expandData["INR/Ct"]}</span>
                                    </td> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"col-12 font-weight-bold"}>
                        {expandData.customer_comment ? <> <span className="font-weight-bold">Buy Comment :</span >{expandData.customer_comment}</>: ""} <br />
                        {expandData.hold_comments ? <> <span className="font-weight-bold">Hold Comment :</span >{expandData.hold_comments}</>: ""}
                    </div>
    
                    {/* <div className="col-12">
                        <div className="row">
                            <div className="col-12 bg-gray-200 py-3 font-weight-bolder mt-5">
                                Diamond Journey
                            </div>
                            <div className="col-12 mt-5">
                                <DiamJournyStep />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-12">
                        <div className="row">
                            <div className="col-12 bg-gray-200 py-3 d-flex justify-content-between mt-5">
                                <span className="font-weight-bolder">
                                    Payment History
                                </span>
                                <span className="font-weight-bold text-primary cursor-pointer">
                                    About Payment Method
                                </span>
                            </div>
                            <div className="col-md-8 mt-4">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-center">TRANSACTION NO</th>
                                            <th className="text-center">PAYMENT METHOD</th>
                                            <th className="text-center">PAYER NAME</th>
                                            <th className="text-center">AMOUNT AUTHORISED</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center ">P-MF-SBIMF-C-270519-336961{" "}<FaCopy className="ml-2 text-secondary" />
                                            </td>
                                            <td className="text-center">Credit Card</td>
                                            <td className="text-center">Mansi Patel</td>
                                            <td className="text-success text-center">$12,345.78</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 mt-8">
                                <table className="table table-bordered table-stripped">
                                    <thead>
                                        <tr>
                                            <th className="text-center text-dark">Date Time</th>
                                            <th className="text-center text-dark">Description</th>
                                            <th className="text-center text-dark">Status</th>
                                            <th className="text-center text-dark">Comments</th>
                                        </tr>
                                    </thead>
    
                                    <tbody>
                                        <tr>
                                            <td className="text-center">27 May 2019, 09:55 PM</td>
                                            <td className="text-center">Proforma Generated</td>
                                            <td className="text-success text-center">Done</td>
                                            <td className="text-center">Transaction No. #12345678955</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">27 May 2019, 09:55 PM</td>
                                            <td className="text-center">Wire Received</td>
                                            <td className="text-success text-center">Done</td>
                                            <td className="text-center">Transaction No. #12345678955</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-12">
                        <div className="row">
                            <div className="col-12 bg-gray-200 py-3 font-weight-bolder mt-5">
                                Other Diamonds In this Order
                            </div>
                            <div className="col-12 mt-5">
                                <div className="col-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-center"> DIAMOND DETAILS</th>
                                                <th className="text-center">TAG</th>
                                                <th className="text-center">LOCATION<br />DELIVERY IN DAYS
                                                </th>
                                                <th className="text-center">DISC</th>
                                                <th className="text-center">$/CT</th>
                                                <th className="text-center">TOTAL </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">
                                                    <div className=" text-left">
                                                        <div className={`mb-1 ${expandData.diamond_type ==="N" ? "natTab" : "labTab" }`} >
                                                            <span className={`${expandData.diamond_type ==="N"? expandData.C_Color ==="fancy"? "F": "W": expandData.C_Color ==="fancy"? "LF": "L"}_diamond mr-3 small`}>
                                                                {expandData.diamond_type === "N"  ? expandData.C_Color === "fancy" ? "F" : "N" : expandData.C_Color === "fancy" ? "LF" : "L"} </span>
                                                            <span className="text-primary small">{expandData.Lab}-{expandData.Certi_NO}</span>
                                                            <span className={`text-primary small ml-2`}>{expandData.diamond_type ==="N"? "N": "L"}-{expandData.id}</span>
                                                        </div>
    
                                                        <span className="text-dark font-weight-bold d-block">
                                                            {expandData.C_Shape}
                                                            {expandData.C_Weight}
                                                            {expandData.C_Color ==="fancy" ? (
                                                                <>
                                                                    {
                                                                        expandData.f_intensity
                                                                    }{" "}
                                                                    {
                                                                        expandData.f_overtone
                                                                    }{" "}
                                                                    {
                                                                        expandData.f_color
                                                                    }{" "}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        expandData.C_Color
                                                                    }
                                                                </>
                                                            )}
                                                            {expandData.C_Clarity}{" "}
                                                            {expandData.C_Cut}{" "}
                                                            {expandData.C_Polish}{" "}
                                                            {expandData.C_Symmetry}{" "}
                                                            {
                                                                expandData.C_Fluorescence
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">NO SHADE</div>
                                                    <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1"> EYECLEAN </div>
                                                    <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1"> MILKY </div>
                                                </td>
                                                <td className="text-center"> India <br />1 - 2days </td>
                                                <td className="text-center"> {expandData.discount_main} </td>
                                                <td className="text-center"> ${expandData["INR/Ct"]} </td>
                                                <td className="text-center"> ${expandData["OurPrice(INR)"]} </td>
                                                <td className="text-center">
                                                    <button className="btn btn-light-primary font-weight-bold px-5">View Details</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
