import React,{useEffect} from "react";
import { Field } from "formik";

export const Shortcut = ({language,formik}) => {
    // useEffect(() => {
    //     function removeItem(arr, value) {
    //         return arr.filter(val => {
    //             if(val !== value){
    //                 return val
    //             }
    //         })
    //       }
    //       function removeDuplicates(arr) {
    //         return arr.filter((item,
    //             index) => arr.indexOf(item) === index);
    //     }
    //     if(formik.values["Polish"] && formik.values["Cut"] && formik.values["Symmetry"] && formik.values["Fluorescence_Intensity"] && formik.values["Polish"].includes("EX") && formik.values["Cut"].includes("EX") && formik.values["Symmetry"].includes("EX") && formik.values["Fluorescence_Intensity"].includes("NON")){
    //         console.log("3EXNON PART")
           
    //         formik.setFieldValue("shortcut",formik.values["shortcut"]?removeDuplicates(removeItem([...formik.values["shortcut"],"3EXNON"],"3EX")):["3EXNON"])
    //         // formik.setFieldValue("shortcut",formik.values["shortcut"]?removeItem(formik.values["shortcut"],"3EX"):[])

    //     }
    //     else if(formik.values["Polish"] && formik.values["Cut"] && formik.values["Symmetry"] && formik.values["Polish"].includes("EX") && formik.values["Cut"].includes("EX") && formik.values["Symmetry"].includes("EX")){
    //         console.log("3EX PART")
           
    //         formik.setFieldValue("shortcut",formik.values["shortcut"]?removeDuplicates(removeItem([...formik.values["shortcut"],"3EX"],"3EXNON")):["3EX"])
    //         // formik.setFieldValue("shortcut",formik.values["shortcut"]?removeItem(formik.values["shortcut"],"3EXNON"):[])

    //         }
    //     else{
    //         console.log("ELSE PART")
    //         if(formik.values["shortcut"]){
    //             console.log(formik.values["shortcut"],"0")
    //             let removeitems = removeItem(formik.values["shortcut"],"3EX")
    //             console.log(removeitems,"1")
    //             removeitems = removeItem(removeitems,"3EXNON")
    //             console.log(removeitems,"2")
    //             formik.setFieldValue("shortcut",removeitems)

    //         }
                    
    //                 // formik.setFieldValue("shortcut",formik.values["shortcut"]?removeItem(formik.values["shortcut"],"3EXNON"):[])

    //     }
    // },[formik.values["Polish"],formik.values["Cut"],formik.values["Symmetry"],formik.values["Fluorescence_Intensity"]])
    useEffect(() => {
        let polish = formik.values["Polish"]
        let cut = formik.values["Cut"]
        let symmetry = formik.values["Symmetry"]
        let shortcut = formik.values["shortcut"]
        let flourintensity = formik.values["Fluorescence_Intensity"]
        let eyeclean = formik.values["eyeclean"]
                function removeItem(arr, value) {
            return arr.filter(val => {
                if(val !== value){
                    return val
                }
            })
          }
          function removeDuplicates(arr) {
            return arr.filter((item,
                index) => arr.indexOf(item) === index);
        }
        if(polish && cut && symmetry && flourintensity && polish.includes("EX") && cut.includes("EX") && symmetry.includes("EX") && flourintensity.includes("NON")){
            shortcut.push("3EXNON")
            shortcut = removeDuplicates(shortcut)
            formik.setFieldValue("shortcut",shortcut?shortcut:["3EXNON"])
        }
        if(polish && cut && symmetry && polish.includes("EX") && cut.includes("EX") && symmetry.includes("EX")){
            shortcut.push("3EX")
            shortcut = removeDuplicates(shortcut)
            formik.setFieldValue("shortcut",shortcut?shortcut:["3EX"])
        }
        if(polish && cut && symmetry && polish.includes("VG") && cut.includes("VG") && symmetry.includes("VG")){
            shortcut.push("3VG+")
            shortcut = removeDuplicates(shortcut)
            formik.setFieldValue("shortcut",shortcut?shortcut:["3VG+"])
        }
        if(eyeclean && eyeclean.includes("YES")){
            shortcut.push("100% EYECLEAN")
            shortcut = removeDuplicates(shortcut)
            formik.setFieldValue("shortcut",shortcut?shortcut:["100% EYECLEAN"])
        }
        if(!polish || polish.length === 0){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EX")
                shortcut = removeItem(shortcut,"3EXNON")
                shortcut = removeItem(shortcut,"3VG+")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(polish && polish.length > 0 && !polish.includes("EX")){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EX")
                shortcut = removeItem(shortcut,"3EXNON")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(polish && polish.length > 0 && !polish.includes("VG")){
            if(shortcut){
                shortcut = removeItem(shortcut,"3VG+")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(!cut || cut.length === 0){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EX")
                shortcut = removeItem(shortcut,"3EXNON")
                shortcut = removeItem(shortcut,"3VG+")


            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(cut && cut.length > 0 && !cut.includes("EX")){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EX")
                shortcut = removeItem(shortcut,"3EXNON")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(cut && cut.length > 0 && !cut.includes("VG")){
            if(shortcut){
                shortcut = removeItem(shortcut,"3VG+")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(!symmetry || symmetry.length === 0){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EX")
                shortcut = removeItem(shortcut,"3EXNON")
                shortcut = removeItem(shortcut,"3VG+")


            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(symmetry && symmetry.length > 0 && !symmetry.includes("EX")){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EX")
                shortcut = removeItem(shortcut,"3EXNON")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(symmetry && symmetry.length > 0 && !symmetry.includes("VG")){
            if(shortcut){
                shortcut = removeItem(shortcut,"3VG+")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(!flourintensity || flourintensity.length === 0){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EXNON")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(flourintensity && flourintensity.length > 0 && !flourintensity.includes("NON")){
            if(shortcut){
                shortcut = removeItem(shortcut,"3EXNON")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(!eyeclean || eyeclean.length === 0){
            if(shortcut){
                shortcut = removeItem(shortcut,"100% EYECLEAN")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
        if(eyeclean && eyeclean.length > 0 && !eyeclean.includes("YES")){
            if(shortcut){
                shortcut = removeItem(shortcut,"100% EYECLEAN")

            }
            formik.setFieldValue("shortcut",shortcut?shortcut:[])
        }
    },[formik.values["Polish"],formik.values["Cut"],formik.values["Symmetry"],formik.values["Fluorescence_Intensity"],formik.values["eyeclean"]])
    const ShortcutData = ([
        {
            id: 1,
            shortcut:"3EX"
        },
        {
            id: 2,
            shortcut:"3EXNON"
        },
        {
            id: 3,
            shortcut:"3VG+"
        },
        {
            id: 5,
            shortcut:"100% EYECLEAN"
        }
    ]);
    const SetShortcut = (value) => {

    //    formik.setFieldValue("shortcut","") 
    function removeItem(arr, value) {
        return arr.filter(val => {
            if(val !== value){
                return val
            }
        })
      }
      function removeDuplicates(arr) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }
       if(value === "3EX"){
        if(formik.values["shortcut"] && formik.values["shortcut"].includes("3EX")){
            if(!formik.values["shortcut"].includes("3EXNON")){
                formik.setFieldValue("Polish",formik.values["Polish"]?removeItem(formik.values["Polish"],"EX"):[]) 
                formik.setFieldValue("Cut",formik.values["Cut"]?removeItem(formik.values["Cut"],"EX"):[]) 
                formik.setFieldValue("Symmetry",formik.values["Symmetry"]?removeItem(formik.values["Symmetry"],"EX"):[])
            }
        }
        else{
            formik.setFieldValue("Polish",formik.values["Polish"]?removeDuplicates([...formik.values["Polish"],"EX"]):["EX"]) 
            formik.setFieldValue("Cut",formik.values["Cut"]?removeDuplicates([...formik.values["Cut"],"EX"]):["EX"]) 
            formik.setFieldValue("Symmetry",formik.values["Symmetry"]?removeDuplicates([...formik.values["Symmetry"],"EX"]):["EX"]) 
        }
       }
       else if(value === "3EXNON"){
        if(formik.values["shortcut"] && formik.values["shortcut"].includes("3EXNON")){
            if(!formik.values["shortcut"].includes("3EX")){
                formik.setFieldValue("Polish",formik.values["Polish"]?removeItem(formik.values["Polish"],"EX"):[]) 
            formik.setFieldValue("Cut",formik.values["Cut"]?removeItem(formik.values["Cut"],"EX"):[]) 
            formik.setFieldValue("Symmetry",formik.values["Symmetry"]?removeItem(formik.values["Symmetry"],"EX"):[])
            }    
            formik.setFieldValue("Fluorescence_Intensity",formik.values["Fluorescence_Intensity"]?removeItem(formik.values["Fluorescence_Intensity"],"NON"):[])
        }
        else{
            formik.setFieldValue("Polish",formik.values["Polish"]?removeDuplicates([...formik.values["Polish"],"EX"]):["EX"]) 
            formik.setFieldValue("Cut",formik.values["Cut"]?removeDuplicates([...formik.values["Cut"],"EX"]):["EX"]) 
            formik.setFieldValue("Symmetry",formik.values["Symmetry"]?removeDuplicates([...formik.values["Symmetry"],"EX"]):["EX"]) 
            formik.setFieldValue("Fluorescence_Intensity",formik.values["Fluorescence_Intensity"]?removeDuplicates([...formik.values["Fluorescence_Intensity"],"NON"]):["NON"])
        }    
       }
       else if(value === "3VG+"){
        if(formik.values["shortcut"] && formik.values["shortcut"].includes("3VG+")){
            formik.setFieldValue("Polish",formik.values["Polish"]?removeItem(formik.values["Polish"],"VG"):[]) 
            formik.setFieldValue("Cut",formik.values["Cut"]?removeItem(formik.values["Cut"],"VG"):[]) 
            formik.setFieldValue("Symmetry",formik.values["Symmetry"]?removeItem(formik.values["Symmetry"],"VG"):[])
        }
        else{
            formik.setFieldValue("Polish",formik.values["Polish"]?removeDuplicates([...formik.values["Polish"],"VG"]):["VG"]) 
            formik.setFieldValue("Cut",formik.values["Cut"]?removeDuplicates([...formik.values["Cut"],"VG"]):["VG"]) 
            formik.setFieldValue("Symmetry",formik.values["Symmetry"]?removeDuplicates([...formik.values["Symmetry"],"VG"]):["VG"]) 
        }   
       }
       else if(value === "100% EYECLEAN"){
        if(formik.values["shortcut"] && formik.values["shortcut"].includes("100% EYECLEAN")){
            formik.setFieldValue("eyeclean",formik.values["eyeclean"]?removeItem(formik.values["eyeclean"],"YES"):[]) 
        }
        else{
            formik.setFieldValue("eyeclean",formik.values["eyeclean"]?removeDuplicates([...formik.values["eyeclean"],"YES"]):["YES"])             
        }    
       }
    }

    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">{language.SHORTCUT_SHORTCUT}</p>
                </div>
                <div className="col-lg-10">
                    {ShortcutData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="shortcut" id={`${item.shortcut}_shortcut`} value={item.shortcut} onClick={(e) =>SetShortcut(e.target.value)}/>
                            <div className="shortcutBox selectBox">
                                <span>{item.shortcut}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};