import React, { useState ,useEffect} from 'react';
import {
  // Stepper,
  // Step,
  // StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import DimondType from '../../ccmode/CcmodeSetting/FormEdit/DimondType';
import MarkupCurrency from '../../ccmode/CcmodeSetting/FormEdit/MarkupCurrency';
import ThemeFrom from '../../ccmode/CcmodeSetting/FormEdit/ThemeFrom';
import LoginFrom from '../../ccmode/CcmodeSetting/FormEdit/LoginFrom';
// import ReviewOrder from './ReviewOrder';
// import FormSuccess from '../../ccmode/CcmodeSetting/FormSuccess/FormSuccess';
import { NODE_API_URL } from '../../../../env_config';
import validationSchemaEdit from '../../ccmode/CcmodeSetting/FormModel/validationSchemaEdit';
import kycFormModel from '../../ccmode/CcmodeSetting/FormModel/kycFormModel';
import {fetchData} from '../../ccmode/CcmodeSetting/FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';
import { SwalSuccess } from '../../Popup';
import useStyles from '../../ccmode/CcmodeSetting/styles';
import { Alert } from 'react-bootstrap';
const steps = [];
const { formId, formField } = kycFormModel;

// function _renderStepContent(step,setFieldValue,prev,setPrev,values,) {
//   switch (step) {
//     case 0:
//       return <DimondType formField={formField} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev}/>;
//     case 1:
//       return <MarkupCurrency setFieldValue={setFieldValue} formField={formField} values={values} />;
//     case 2:
//       return <ThemeFrom formField={formField} values={values} setFieldValue={setFieldValue}/>;
//     case 3:
//       return <LoginFrom formField={formField} values={values} setFieldValue={setFieldValue}/>;
//       // return <ReviewOrder />;
//     default:
//       return <div>Not Found</div>;
//   }
// }

export default function Ccmodesetting(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaEdit[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [companylogo,setCompanyLogo] = useState()
  const [initialValues,setInitialValues] = useState({})
  const [openpopup,setOpenPopUp] = useState(false)
  useEffect(() => {
    fetchData(props.userinfo.mail).then(values => {
      // console.log(values,"fetchData")
      setInitialValues(values)
    })
  },[])
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log("Submit")
  }

  function _handleSubmit(values, actions) {
    // console.log(values,"valuesvalues")
    actions.setSubmitting(false);
    const formData = new FormData();
    if(companylogo){
      formData.append("comapny_logo", companylogo[0])
    }
    if(values.white_label_logo){
      formData.append("white_label_logo", values.white_label_logo)
    }
    if(values.muti_currency){
      formData.append("Currency", values.muti_currency)
    }
    if(values.password){
      formData.append("Password", values.password)
    }
    if(props.userinfo.id){
      formData.append("CustomerId", props.userinfo.id)
    }
    if(values.wl_naturaldiamond){
      formData.append("NaturalDiamondType", 1)
    }
    if(values.wl_naturalfancydiamond){
      formData.append("NaturalFancyDiamondType", 1)
    }
    if(values.wl_lgwhitediam){
      formData.append("LabDiamondType", 1)
    }
    if(values.wl_lgfancydiam){
      formData.append("LabFancyDiamondType", 1)
    }

    if(values.wl_primarycolor){
      formData.append("NaturalPrimaryColor", values.wl_primarycolor)
    }
    if(values.n_fontcolor){
      formData.append("NaturalFontColor", values.n_fontcolor)
    }
    if(values.lab_primary){
      formData.append("LabPrimaryColor", values.lab_primary)
    }
    if(values.lab_fontcolor){
      formData.append("LabFontColor", values.lab_fontcolor)
    }

    if(values.diamondmarkup){
      formData.append("MarkUpNatural", values.diamondmarkup)
    }
    if(values.diamondfancymarkup){
      formData.append("MarkUpNaturalFancy", values.diamondfancymarkup)
    }
    if(values.lab_diamondmarkup){
      formData.append("MarkUpLab", values.lab_diamondmarkup)
    }
    if(values.lab_diamondfancymarkup){
      formData.append("MarkUpLabFancy", values.lab_diamondfancymarkup)
    }
    axios({
      method: "POST",
      url: `${NODE_API_URL}/TESTMODE/setupFormFirstTimeSave`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
    }).then(res => {
        // console.log(res,"RESPONSEEEE")
        actions.setSubmitting(false);
        if(res.data.success){
          // setActiveStep(activeStep + 1);
          // alert("Form Submitted")
          setOpenPopUp(true)
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          setTimeout(()=>{
            setOpenPopUp(false)
          },5000)
        }
    }).catch(err => {
      actions.setSubmitting(false);
    })
  }
//  const [prev,setPrev] = useState(false)
  // function _handleBack() {
  //   setActiveStep(activeStep - 1);
  //   setPrev(true)
  // }

  return (
    <React.Fragment>
          {openpopup?
            <div>
              <Alert variant="success">
                  Your Client-Centric Mode Setting Updated Successfully..
              </Alert>
              <Alert variant="success">
                  Please login again in CC-Mode to see the changes..
              </Alert>
            </div>
          :""}
      {/* {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )} */}
      <React.Fragment>
      <div className="row">
        <div className="col-12 mb-4 setting-title">{props.language.SETTING_CCMODECAPS}</div>
          <div className="col-12">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values }) => (
              <Form id={formId}>
                {/* {_renderStepContent(activeStep,setFieldValue,prev,setPrev,values)} */}
                <DimondType formField={formField} setFieldValue={setFieldValue} values={values} language={props.language}/>
                <MarkupCurrency setFieldValue={setFieldValue} formField={formField} values={values} language={props.language}/>
                <ThemeFrom formField={formField} values={values} setFieldValue={setFieldValue} setCompanyLogo={setCompanyLogo} language={props.language}/>
                <LoginFrom formField={formField} values={values} setFieldValue={setFieldValue} userinfo={props.userinfo} language={props.language}/>

                <Grid className="mt-10 sticky-top" container justify={"flex-end"} alignItems="center"  style={{bottom:"0px"}}>
                  {/* {activeStep !== 0 && (
                    <Button color="primary" onClick={_handleBack} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">Previous</span >
                    </Button>
                  )} */}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {props.language.SETTING_PASSWORD_SAVE}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
          </div>
      </div>
      </React.Fragment>
    </React.Fragment>
  );
}
