import React, {  useState } from "react";
import {
    Modal,
    Button,
} from "react-bootstrap";
import { BsFillCameraVideoFill, } from "react-icons/bs";
import VideoIframe from "./VideoIframe";
export const VideoPopup = (props) => {
    const [show, setShow] = useState(false);
   
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <BsFillCameraVideoFill className="cursor-pointer text-primary mx-2 text-center"  onClick={() => handleShow()}/>
            {/* <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <iframe title="Video" src={props.WLDiamondType === "N" ? `https://pro360video.com/video.php?refno=${props.Certi_No}` : `https://pro360video.com/labgrown.php?refno=${props.Certi_No}` } height='450px' width='100%' frameborder='0'></iframe>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <VideoIframe
            showVideo={show}
            handleClose={handleClose}
            src={props.WLDiamondType === "N" ? `https://pro360video.com/video.php?refno=${props.Certi_No}` : `https://pro360video.com/labgrown.php?refno=${props.Certi_No}` }            />
        </>
    );
};
