import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import SVG from "react-inlinesvg";
import { ImagePopup } from "../../../widgets/tiles/ImagePopUp";
import { VideoPopup } from "../../../widgets/tiles/VideoPopUp";
import { FaAward } from "react-icons/fa";

function total(x) {
    let total = parseFloat(x.O_Rate * x.CARAT).toFixed(2);
    return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const searchResultColumn = () => [
    
    {
        Header: "Shape",
        accessor: "SHAPE",
        className: "text-center align-middle",
        sortMethod: (a, b) => a.localeCompare(b),
        Cell: (row) => (
            <div className="d-flex align-items-center justify-content-center">
                <SVG
                    width="22px"
                    height="22px"
                    src={toAbsoluteUrl(
                        `/media/shape/svg_shape/${row.row.original.SHAPE.toLowerCase()}.svg`
                    )}
                />
                <div className="ml-2">{row.row.original.SHAPE}</div>
            </div>
        ),
    },
    {
        Header: "Media" ,
        accessor: (d) => (
            <>
                <div className="d-flex justify-content-center align-items-center">
                    {d.IMAGE ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Image</Tooltip>}
                            >
                                <ImagePopup
                                    Image={d.IMAGE}
                                    Shape={d.SHAPE}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.IMAGE}><BsCardImage/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block"  style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.VIDEO ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Video</Tooltip>}
                            >
                                <VideoPopup
                                    ProductId={d.id}
                                    WLDiamondType={d.diamond_type}
                                    Certi_No={d.CERTIFICATE_NO}
                                    video={d.VIDEO}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.VIDEO}><BsFillCameraVideoFill/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block" style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.CERTIFICATE_NO ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">Certificate</Tooltip>
                                }
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        d.LAB === "IGI"
                                            ? `https://www.igi.org/viewpdf.php?r=${d.CERTIFICATE_NO}`
                                            : d.LAB === "GIA"
                                            ? `https://www.gia.edu/report-check?reportno=${d.CERTIFICATE_NO}`
                                            : d.LAB === "HRD"
                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.CERTIFICATE_NO}`
                                            : d.LAB === "GCAL"
                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.CERTIFICATE_NO}`
                                            : d.Certi_link
                                    }
                                >
                                    <FaAward />
                                </a>
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block" style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: "Carat",
        accessor: "CARAT",
        // sortMethod: (a, b) => Number(a) - Number(b),
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{parseFloat(row.value).toFixed(2)}</>;
        },
        sortType: (rowA, rowB) => {
            if (rowB.original.CARAT > rowA.original.CARAT) return 1;
            if (rowA.original.CARAT > rowB.original.CARAT) return -1;
        }
    },
    {
        Header: "Color",
        accessor: "COLOR",
        minwidth: 50,
        className: "text-center align-middle",
        sortMethod: (a, b) => a.localeCompare(b),
        Cell: (row) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {!row.row.original.COLOR ? (
                                <>
                                    {row.row.original.FANCY_INTENSITY}{" "}{row.row.original.FANCY_OVERTONE}{" "}{row.row.original.FANCY_COLOR}
                                </>
                            ) : (
                                row.row.original.COLOR
                            )}
                        </Tooltip>
                    }
                >
                    <span
                        className="d-inline-block text-truncate text-dark"
                        style={{ maxWidth: "80px" }}
                    >
                        {!row.row.original.COLOR ? (
                            <>
                                {row.row.original.FANCY_INTENSITY}{" "}{row.row.original.FANCY_OVERTONE}{" "}{row.row.original.FANCY_COLOR}{" "}
                            </>
                        ) : (
                            row.row.original.COLOR
                        )}
                    </span>
                </OverlayTrigger>
            </>
        ),
    },
    {
        Header: "Clarity",
        accessor: "CLARITY",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Cut",
        accessor: "CUT",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Polish",
        accessor: "POLISH",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Symmetry",
        accessor: "SYMMETRY",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Fluo.",
        accessor: "FLUORESCENCE",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    // {
    //     Header: "LAB",
    //     accessor: "LAB",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return <>{row.value ? row.value : "-"}</>;
    //     },
    // },
    // {
    //     Header: "Certificate",
    //     accessor: "Certi_NO",
    //     className: "text-center align-middle",
    //     Cell: (row) => {
    //         return (
    //             <>
    //                 <a
    //                     target="_blank"
    //                     rel="noreferrer"
    //                     className="text-primary mx-2 text-left"
    //                     href={
    //                         row.row.original.LAB === "IGI"
    //                             ? `https://www.igi.org/viewpdf.php?r=${row.row.original.Certi_NO}`
    //                             : row.row.original.LAB === "GIA"
    //                             ? `https://www.gia.edu/report-check?reportno=${row.row.original.Certi_NO}`
    //                             : row.row.original.LAB === "HRD"
    //                             ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.row.original.Certi_NO}`
    //                             : row.row.original.LAB === "GCAL"
    //                             ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.row.original.Certi_NO}`
    //                             : row.row.original.Certi_link
    //                     }
    //                 >
    //                     {row.row.original.Certi_NO}
    //                 </a>
    //             </>
    //         )
    //     },
    // },

    // {
    //     Header: "disc %",
    //     className: "text-right align-middle pr-5",
    //     accessor: (row) => (
    //         <>
    //            <span className={`${discount(row) >= 0  ? 'text-success' : 'text-danger'} pl-2`}> {discount(row)}</span>
    //         </>
    //     ),
    //     disableSortBy: true,
    //     sortMethod: (a, b) => Number(a) - Number(b),
    // },
    // {
    //     Header: "$/CT",
    //     className: "text-right align-middle pr-5",
    //     accessor: "O_Rate",
    //     sortMethod: (a, b) => Number(a) - Number(b),
    //     Cell: (row) => (
    //         <>
    //             {
    //                 <span> ${parseFloat(row.value).toFixed(2) }</span>
    //             }
    //         </>
    //     ),
    // },
    {
        Header: "Total",
        accessor: "TOTAL_PRICE",
        className: "text-right align-middle pr-5",
        accessor: (d) => {
            return (
                <>
                  ${d.TOTAL_PRICE}
                </>
            );
        },
        disableSortBy: true,
        
        // sortMethod: (a, b) => Number(a)- Number(b)
        // Cell: (row) => {
        //     return (
        //         <>
        //            ${parseFloat(row.O_Rate * row.CARAT).toFixed(2) }
        //         </>
        //     );
        // },
    },
    {
        Header: "LAB",
        accessor: "LAB",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
];
