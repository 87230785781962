/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Formik, Form ,Field} from "formik";
import { useHistory ,useLocation} from "react-router-dom";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core/";
import PropTypes from "prop-types";
import axios from "axios";
import languages from '../../_helpers/languages.json'
import { FaSearch } from "react-icons/fa";
import { SEARCH_LOG, SEARCH_COUNT, FETCH_DIAMOND ,NODE_API_URL} from "./../../../env_config";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { GetCookies } from "../../_helpers/Cookies";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import { BasicLab } from "./searchcomponent/BasicLab";
import { Tinge } from "./searchcomponent/Tinge";
import { Preference } from "./searchcomponent/Preference";
import { Preference1 } from "./searchcomponent/Preference1";
import { AdditionalLab } from "./searchcomponent/AdditionalLab";

export const LabSearchDiamond = ({language,userinfo}) => {
    const location = useLocation()
    const [open, setOpen] = React.useState(false);
    const [openLoader, setOpenLoader] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [zeroSuccessOpen, setZeroSuccessOpen] = React.useState(false);
    const [filterCriteria, setFilterCriteria] = React.useState(false);
    const [finalFetchData, setFinalFetchData] = React.useState([]);
    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    function handleClose(event, reason) {
        setOpen(false);
        setZeroSuccessOpen(false);
        setFilterCriteria(false);
    }

    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        setIsSubmitting(true)
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        setIsSubmitting(false)
    };

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };

    const history = useHistory();
    // const [user, setUser] = React.useState([]);
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [initialvalues,setInitialValues] = React.useState({})
    React.useEffect(() => {
        let modifysearch = JSON.parse(localStorage.getItem("modifysearchlab"))
        if(modifysearch && modifysearch.diamond_type === "L"){
            setInitialValues(modifysearch)
        }
    }, []);
    const [value, setValue] = React.useState(window.glob || 0);
    const [data, setData] = React.useState([]);
    const [stoneCount, setStoneCount] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const id = React.useMemo(() => localStorage.getItem("C_id"), []);

    function handleChange(event, newValue) {
        window.glob = newValue;
        setValue(newValue);
    }
    function handelStoneCountError() {
        setOpen(true);
        disableLoading();
    }
    function handelStoneCountWarn() {
        setZeroSuccessOpen(true);
        disableLoading();
    }
    function handelFilterCriteria() {
        setFilterCriteria(true);
        disableLoading();
    }
    function handelRest(newValue) {
        setTimeout(() => {
            localStorage.removeItem("modifysearchlab")
            window.location.reload(true);
            window.glob = newValue;
        }, 500);
    }

  
    function handleData(values,oldvalues) {
        window.search = values;
        let len = Object.keys(values).length;
        const temp = values;
        if(temp.Certi_No){
            temp.Certi_No = temp.Certi_No.replaceAll("N","").replaceAll("L","").replaceAll("-","")
        }
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": ${user.CustomerId}, "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "L" }`;
        localStorage.setItem("modifysearchlab",JSON.stringify({...values,diamond_type:"L"}))
        /*
        Old Code
        axios({
            method: "POST",
            url: SEARCH_COUNT,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then(function(res) {
            setStoneCount(JSON.stringify(res.data.StoneCount));
           
        //    console.log( "abc",(res.data.Message).includes("Filter criteria is compulsory for Stone search") )
        if ((res.data.Message).includes("Filter criteria is compulsory for Stone search")){ 
            handelFilterCriteria()
        } else {
            if (JSON.stringify(res.data.StoneCount) === "0") {
                setZeroSuccessOpen(true);
                disableLoading();
            } else {
                setZeroSuccessOpen(false);
                if (res.data.StoneCount > 5000) {
                    handelStoneCountError();
                } else {
                    axios({
                        method: "POST",
                        url: FETCH_DIAMOND,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: finalData,
                    })
                    .then(function(res) {
                        setData([ ...data, ...res.data.ProductDetails, ]);
                        console.log(res.data.ProductDetails, "WWWWWWW");
                        // StoreDiamondDB([...res.data.ProductDetails])
                        // diamondsearch.updateState()
                        // setData([...data, decompress(...res.data.ProductDetails)]);
                        disableLoading();
                        history.push({
                            pathname: "/search-result",
                            state: res.data.ProductDetails,
                                // state: decompress(res.data.ProductDetails),
                            });
                    })
                    .catch(function(res) {
                        console.error(res);
                    });
                }
            }
        }
            // res.data.StoneCount === null
            //     ? handelStoneCountWarn()
            //     : console.log("");
            res.data.StoneCount < 5000 && res.data.StoneCount > "0"
                ? setSuccessOpen(true)
                : setSuccessOpen(false);
        })
        .catch(function(res) {
            disableLoading();
        });
        End Old Code
        */
        axios({
            method: "POST",
            url: `${NODE_API_URL}/TESTMODE/SearchCountFunctionality`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
        .then(function(res) {
            setStoneCount(JSON.stringify(res.data.data));

           if ((res.data.error)){ 
                handelFilterCriteria()
            } else {
                if (JSON.stringify(res.data.data) === "0") {
                    setZeroSuccessOpen(true);
                    disableLoading();
                } else {
                    setZeroSuccessOpen(false);
                    if (res.data.data > 5000) {
                        handelStoneCountError();
                    } else {
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/TESTMODE/SearchFunctionality`,
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: finalData,
                        })
                        .then(function(res) {
                            setData([ ...data, ...res.data.data]);
                            // disableLoading();
                            history.push({
                                pathname: "/search-result",
                                state: {
                                    searchresult:res.data.data,
                                    oldvalues:oldvalues
                                },
                                    // state: decompress(res.data.ProductDetails),
                                });
                        })
                        .catch(function(res) {
                            console.error(res);
                            console.error("finalData",finalData);
                        });
                    }
                } 
            }
            // res.data.StoneCount === null
            //     ? handelStoneCountWarn()
            //     : console.log("");
            res.data.data < 5000 && res.data.data > 0
                ? setSuccessOpen(true)
                : setSuccessOpen(false);
        })
        .catch(function(res) {
            disableLoading();
        });
        setFinalFetchData(finalData);
    }
    React.useEffect(
        () => window.localStorage.setItem("fetchData", finalFetchData),
        [finalFetchData]
    );

    React.useEffect(
        () => window.localStorage.setItem("stoneCount", stoneCount),
        [stoneCount]
    );

    return (
        <>
            <div className="row labTab">
                <div className="col-12">
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialvalues}
                        onSubmit={async (values, { setSubmitting }) => {
                            enableLoading();
                            let oldvalues = values
                            oldvalues.diamond_type = "L"
                            Object.keys(values).forEach((key) => {
                                if (
                                    values[key] === "" ||
                                    values[key] === null ||
                                    values[key].length === 0
                                ) {
                                    delete values[key];
                                }
                            });
                            setTimeout(() => {
                                handleData(values,oldvalues);
                            }, 1000);
                        }}
                    >
                        {(formik, handleSubmit) => (
                            <>
                                <Form>
                                    <div className="card card-custom card-sticky gutter-b">
                                        <div className="card-header">
                                            <div className="card-title">{language.SEARCHDIAMOND_LABGROWN}</div>
                                            {userinfo.v_customer === "1" || userinfo.v_customer === "2" || userinfo.v_customer === "3"?<div className="card-toolbar">
                                                <div className="form-group mb-0 w-100">
                                                    <div className="input-group">
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            style={{width:"185px"}}
                                                            name="Certi_No"
                                                            id="Certi_No"
                                                            placeholder="certificate number search"
                                                            autoComplete="off"
                                                        />
                                                        <div className="input-group-append"><button className="input-group-text bg-whtite"><FaSearch /></button></div>
                                                    </div>
                                                </div>
                                            </div>:""}
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 mb-10">
                                                    <BasicLab formik={formik} language={language} option={location.option}/>
                                                </div>
                                                <div className="col-12 mb-10">
                                                    <Tinge language={language}/>
                                                </div>
                                                <div className="col-12 mb-10">
                                                    <Preference language={language}/>
                                                </div>
                                                <div className="col-12 mb-10">
                                                    <Preference1 language={language}/>
                                                </div>
                                                <div className="col-12 mb-10">
                                                    <AdditionalLab language={language} formik={formik}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="card-footer bg-white labTab text-center sticky-top"
                                            style={{ bottom: "0" }}
                                        >
                                            <button
                                                type="submit"
                                                className={`btn btn-success px-10 font-weight-bold btn-square mr-2 btn-lg ${isSubmitting ? "cursor-na" : ""}`}
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                {isSubmitting
                                                    ? "Please wait..."
                                                    : "Search"}
                                            </button>
                                                <Backdrop
                                                   sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                    open={openLoader}
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Backdrop>
                                            <button
                                                type="reset"
                                                onClick={handelRest}
                                                disabled={isSubmitting}
                                                className={`btn font-weight-bold btn-square btn-danger btn-lg px-10 ${isSubmitting ? "cursor-na" : ""}`}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message={`${stoneCount} Stones found Please Narrow Your Search to 5000`}
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`${stoneCount} Stones found`}
                />
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={zeroSuccessOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="warning"
                    message={`0 Stones found please try again`}
                />
            </Snackbar>
            
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={filterCriteria}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message={`Filter criteria is compulsory for Stone search`}
                />
            </Snackbar>
        </>
    );
};
