import React from "react";
import { Field } from "formik";


export const Polish =({formik}) =>{

    const PolishData = ([
        {
            id: 1,
            polish:"ID"
        },
        {
            id: 2,
            polish:"EX"
        },
        {
            id: 3,
            polish:"VG"
        },
        {
            id: 4,
            polish:"GD"
        },
        {
            id: 5,
            polish:"FR"
        },
        {
            id: 6,
            polish:"PR"
        }
    ]);

    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Polish</div>
                <div className="col-12" >
                    {PolishData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Polish" id={`${item.polish}_polish`} value={item.polish}/>
                            <div className="wClarityBox selectBox">
                                <span>{item.polish}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
}