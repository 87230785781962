import React from "react";

export default function Loader(){
    return(
        <div className="col-12 text-center">
            <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
            </div>
    )
}