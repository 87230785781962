import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const FeedTerms = (props) => {
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onHide();
    };
    const responseClose = (props) => {
        props.responseOnOk()
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                // backdrop="static"
                keyboard={false}
                // centered
            >
                <Modal.Header>
                    <Modal.Title>{props.language.CCMODE_REQ_TNC}</Modal.Title>
                    <button type="button" className="close" onClick={() => closeModel()}><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll"
                        style={{ maxHeight: "500px", position: "relative" }}
                    >
                        <div id="terms-body" className="row m-0">
                            <div className="col-md-12">
                                <p className="bold">{props.language.CCMODE_REQ_TNC1}</p>
                                <h4 className="bold">{props.language.CCMODE_REQ_TNC2}</h4>
                                <p>{props.language.CCMODE_REQ_TNC3}</p>
                                <p>{props.language.CCOMDE_REQ_TNC4}</p>
                                <p>{props.language.CCOMDE_REQ_TNC5}</p>
                                <p>{props.language.CCOMDE_REQ_TNC6}</p>
                                <p>{props.language.CCOMDE_REQ_TNC7}</p>
                                <p>{props.language.CCOMDE_REQ_TNC8}</p>
                                <p>{props.language.CCOMDE_REQ_TNC9}</p>

                                <h4>{props.language.CCOMDE_REQ_TNC10}</h4>
                                <p>{props.language.CCOMDE_REQ_TNC11}</p>
                                <p>{props.language.CCOMDE_REQ_TNC12}</p>

                                <h4>{props.language.CCOMDE_REQ_TNC13}</h4>
                                <p dangerouslySetInnerHTML={{__html: props.language.CCOMDE_REQ_TNC14}}></p>
                                <p>{props.language.CCMODE_REQ_TNC15}</p>

                                <h4>{props.language.CCMODE_REQ_TNC16}</h4>
                                <p>{props.language.CCMODE_REQ_TNC17}</p>

                                <h4>{props.language.CCMODE_REQ_TNC18}</h4>
                                <p>{props.language.CCMODE_REQ_TNC19}</p>
                                <h5>{props.language.CCMODE_REQ_TNC20}</h5>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC21}</li>
                                    <li>{props.language.CCMODE_REQ_TNC22}</li>
                                    <li>{props.language.CCMODE_REQ_TNC23}</li>
                                    <li>{props.language.CCMODE_REQ_TNC24}</li>
                                </ul>

                                <h5>{props.language.CCMODE_REQ_TNC25}</h5>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC26}</li>
                                    <li>{props.language.CCMODE_REQ_TNC27}</li>
                                </ul>

                                <h5>{props.language.CCMODE_REQ_TNC28}</h5>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC29}</li>
                                </ul>

                                <h5>{props.language.CCMODE_REQ_TNC30}</h5>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC31}</li>
                                </ul>

                                <h5>{props.language.CCMODE_REQ_TNC32}</h5>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC33}</li>
                                    <li>{props.language.CCMODE_REQ_TNC34}</li>
                                    <li>{props.language.CCMODE_REQ_TNC35}</li>
                                </ul>

                                <h5>{props.language.CCMODE_REQ_TNC36}</h5>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC37}</li>
                                </ul>
                                <h5>{props.language.CCMODE_REQ_TNC38}</h5>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC39}</li>
                                    <li>{props.language.CCMODE_REQ_TNC40}</li>
                                </ul>
                                
                                <h4>{props.language.CCMODE_REQ_TNC41}</h4>
                                <p>{props.language.CCMODE_REQ_TNC42}</p>
                                <p>{props.language.CCMODE_REQ_TNC43}</p>
                                <ul className="mb-3">
                                    <li>{props.language.CCMODE_REQ_TNC44}</li>
                                    <li>{props.language.CCMODE_REQ_TNC45}</li>
                                    <li>{props.language.CCMODE_REQ_TNC46}</li>
                                    <li>{props.language.CCMODE_REQ_TNC47}</li>
                                    <li>{props.language.CCMODE_REQ_TNC48}</li>
                                    <li>{props.language.CCMODE_REQ_TNC49}</li>
                                    <li>{props.language.CCMODE_REQ_TNC50}</li>
                                    <li>{props.language.CCMODE_REQ_TNC51}</li>
                                    <li>{props.language.CCMODE_REQ_TNC52}</li>
                                    <li>{props.language.CCMODE_REQ_TNC53}</li>
                                    <li>{props.language.CCMODE_REQ_TNC54}</li>
                                    <li>{props.language.CCMODE_REQ_TNC55}</li>
                                    <li>{props.language.CCMODE_REQ_TNC56}</li>
                                    <li>{props.language.CCMODE_REQ_TNC57}</li>
                                    <li>{props.language.CCMODE_REQ_TNC58}</li>
                                    <li>{props.language.CCMODE_REQ_TNC59}</li>
                                    <li>{props.language.CCMODE_REQ_TNC60}</li>
                                    <li>{props.language.CCMODE_REQ_TNC61}</li>
                                    <li>{props.language.CCMODE_REQ_TNC62}</li>
                                    <li>{props.language.CCMODE_REQ_TNC63}</li>
                                    <li>{props.language.CCMODE_REQ_TNC64}</li>
                                    <li>{props.language.CCMODE_REQ_TNC65}</li>
                                </ul>
                                <h4>{props.language.CCMODE_REQ_TNC66}</h4>
                                <h5>{props.language.CCMODE_REQ_TNC67}</h5>
                                <p>{props.language.CCMODE_REQ_TNC68}</p>
                                <p>{props.language.CCMODE_REQ_TNC69}</p>

                                <h5>{props.language.CCMODE_REQ_TNC70}</h5>
                                <p>{props.language.CCMODE_REQ_TNC71} </p>

                                <h5>{props.language.CCMODE_REQ_TNC72}</h5>
                                <p>{props.language.CCMODE_REQ_TNC73}</p>

                                <h4>{props.language.CCMODE_REQ_TNC74}</h4>
                                <p>{props.language.CCMODE_REQ_TNC75}</p>
                                <p>{props.language.CCMODE_REQ_TNC76}</p>
                                <p>{props.language.CCMODE_REQ_TNC77}</p>
                                
                                <h4>{props.language.CCMODE_REQ_TNC78}</h4>
                                <p>{props.language.CCMODE_REQ_TNC79}</p>
                                
                                <h4>{props.language.CCMODE_REQ_TNC80}</h4>
                                <p>{props.language.CCMODE_REQ_TNC81} </p>

                                <h4>{props.language.CCMODE_REQ_TNC82}</h4>
                                <p>{props.language.CCMODE_REQ_TNC83}</p>

                                <h4>{props.language.CCMODE_REQ_TNC84}</h4>
                                <p>{props.language.CCMODE_REQ_TNC85} </p>
                                <p>{props.language.CCMODE_REQ_TNC86}</p>

                                <h4>{props.language.CCMODE_REQ_TNC87}</h4>
                                <p>{props.language.CCMODE_REQ_TNC88}</p>                        
                                <p>{props.language.CCMODE_REQ_TNC89}</p>
                                <p>{props.language.CCMODE_REQ_TNC90}</p>

                                <h4>{props.language.CCMODE_REQ_TNC91}</h4>
                                <p>{props.language.CCMODE_REQ_TNC92} </p>

                                <h4>{props.language.CCMODE_REQ_TNC93}</h4>
                                <p>{props.language.CCMODE_REQ_TNC94}</p>

                                <h4>{props.language.CCMODE_REQ_TNC95}</h4>
                                <p>{props.language.CCMODE_REQ_TNC96}</p>

                                <h4>{props.language.CCMODE_REQ_TNC97}</h4>
                                <p>{props.language.CCMODE_REQ_TNC98}</p>

                                <h4>{props.language.CCMODE_REQ_TNC99}</h4>
                                <p>{props.language.CCMODE_REQ_TNC100} </p>

                                <h4>{props.language.CCMODE_REQ_TNC101}</h4>
                                <p>{props.language.CCMODE_REQ_TNC102}</p>

                                <h4>{props.language.CCMODE_REQ_TNC103}</h4>
                                <p>{props.language.CCMODE_REQ_TNC104}</p>

                                <h4>{props.language.CCMODE_REQ_TNC105}</h4>
                                <p>{props.language.CCMODE_REQ_TNC106}</p>

                                <h4>{props.language.CCMODE_REQ_TNC107}</h4>
                                <p>{props.language.CCMODE_REQ_TNC108}</p>
                                <p><h5>DIAMONDS ON CALL LIMITED</h5>
                                UNIT E-F, 8/F, 8 Hart Avenue,<br/>
                                Tsim Sha Tsui,<br/>
                                Kowloon, Hong Kong <br/>
                                +852 4644 4677<br/>
                                info@diamondsoncall.com</p>
                            </div>
                        </div>
                    </PerfectScrollbar>
                </Modal.Body>
            </Modal>
        </div>
    );
};
