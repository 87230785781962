/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState} from "react";
import { Formik, Form } from "formik";
import { Basic } from "./searchComponent/Basic";
import { Advanced } from "./searchComponent/Advanced";
import{ Button,Collapse} from 'react-bootstrap';
import { FiPlusCircle,FiMinusCircle } from 'react-icons/fi';
import { FaList,FaPlus,FaMinus } from 'react-icons/fa';
import { BsFillGridFill } from 'react-icons/bs';
import { Tabs, Tab, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { ListResult } from "./SearchResult/ListResult";
import { Grid } from "./SearchResult/Grid";
import { useEffect } from "react";
import Sorting from "./Sorting";
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const Search = (props) => {
    const [advOpen,setAdvOpen] = useState(false)
    const [filterOpen,setFilterOpen] = useState(true)
    const [value, setValue] = React.useState(0); // for tab
    const [searchdata,setSearchData] = useState([])
    const [diamond_type,setDiamondType] = useState()
    const [custompage,setCustomPage] = useState(1)
    const [loader,setLoader] = useState(false)
    const [sorting,setSorting] = useState("")
    const [fancy,setFancy] = useState(false)
    console.log(sorting,"sorting")
    useEffect(() => {
        if(window.location.pathname === "/api-frame/naturalsearch"){
            setDiamondType("N")
        }
        if(window.location.pathname === "/api-frame/labgrownsearch"){
            setDiamondType("L")
        }
    },[])
    console.log(diamond_type,"diamond_type")
    function handleTabChange(event, newValue) {
        setValue(newValue);
    }
    console.log(searchdata,"searchdatasearchdata")
    return (
        <div className="container searchDemo">
            <div className="card card-custom card-sticky gutter-b">
                <div className="card-header minHeight" style={{ height: " 49px!important"}} >
                    <div className="card-title">
                        {diamond_type === "N"?"Natural Diamonds":"Labgrown Diamonds"}
                    </div>
                    <div className="card-toolbar">
                        <Button
                        className={diamond_type === "N"?`btn-primary btn-sm font-weight-bolder`:`btn-light-primary btn-sm font-weight-bolder`}
                        onClick={() => window.location.replace('/api-frame/naturalsearch')}
                        >
                            Natural
                        </Button>
                        <Button
                        className={diamond_type === "L"?`btn-primary btn-sm font-weight-bolder`:`btn-light-primary btn-sm font-weight-bolder`}
                        onClick={() => window.location.replace('/api-frame/labgrownsearch')}
                        >
                            Lab Grown
                        </Button>
                        <Button
                        className={fancy?"btn-primary btn-sm font-weight-bolder":"btn-light-primary btn-sm font-weight-bolder"}
                        onClick={() => setFancy(!fancy)}
                        >
                            Fancy
                        </Button>
                        <Button
                            onClick={() => setFilterOpen(!filterOpen)}
                            aria-controls="filters"
                            aria-expanded={filterOpen}
                            className="btn-light-primary btn-icon btn-sm font-weight-bolder"
                        >
                            {!filterOpen ? <FaPlus className="font-size-lg" /> : <FaMinus className="font-size-lg" />} 
                        </Button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <Collapse in={filterOpen}>
                                <div id="filters">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{}}
                                        onSubmit={async (values, { setSubmitting }) => {
                                            // enableLoading();
                                            Object.keys(values).forEach((key) => {
                                                if ( values[key] === "" || values[key] === null || values[key].length === 0 ) {
                                                    delete values[key];
                                                }
                                            });
                                            // setTimeout(() => {
                                            //     handleNaturalData(values);
                                            // }, 1000);
                                        }}
                                    >
                                        {(formik, isSubmitting, handleSubmit) => (
                                            <>
                                                <Form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <Basic formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader} sorting={sorting} fancy={fancy} userinfo={props.userinfo}/>
                                                        </div>
                                                        <div className="col-12 mt-10 text-center position-relative">
                                                            <div className="btn-seprator">
                                                                <Button
                                                                    onClick={() => setAdvOpen(!advOpen)}
                                                                    aria-controls="advancedFilter"
                                                                    aria-expanded={advOpen}
                                                                    className="btn-light-primary btn-sm btn-pill font-weight-bolder"
                                                                >
                                                                    Advanced Filters {advOpen ? <FiMinusCircle className="ml-2 font-size-lg" /> : <FiPlusCircle className="ml-2 font-size-lg" />} 
                                                                </Button>
                                                            </div>
                                                            <div className="custom-seprator"></div>
                                                        </div>
                                                        <div className="col-12 ">
                                                            <Collapse in={advOpen}>
                                                                <div className="mt-5" id="advancedFilter">
                                                                    <Advanced formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader}/>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        
                                                    </div>
                                                </Form>
                                            </>
                                        )}
                                    </Formik>
                                </div>
                            </Collapse>
                        </div>
                        <div className="col-12 bg-gray-100 d-flex justify-content-between py-3 mt-10">
                            <div>
                                <div className="border px-5 py-2 bg-primary-o-20 font-size-lg text-primary font-weight-bold border border-primary">
                                    Result: {searchdata && searchdata.total?searchdata.total:0}
                                </div>
                            </div>
                            {/* Sorting Start*/}
                            <Sorting setSorting={setSorting} sorting={sorting}/>
                            {/* Sorting End */}
                            <div className="search-result">
                                
                                <Tabs value={value} indicatorColor="secondary" textColor="secondary" onChange={handleTabChange}>
                                    
                                    <Tab icon={<BsFillGridFill className="font-size-h5" />}  aria-label="grid"/>
                                    <Tab icon={<FaList className="font-size-h5" />} aria-label="list"/>
                                </Tabs>
                                {/* <Paper position="static" className="shadow-none">
                                </Paper> */}
                            </div>
                        </div>
                        <div className="col-12 search-result-list">
                            {value === 0 && (
                                <TabContainer className="p-0">
                                    {/* <ListResult searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage}/> */}
                                    <Grid searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage} loader={loader}/>
                                </TabContainer>
                            )}
                            {value === 1 && (
                                <TabContainer>
                                    {/* <Grid searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage}/> */}
                                    <ListResult searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage} loader={loader}/>
                                </TabContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
