import React from "react";
import { Field } from "formik";

export const Metrics = ({language}) => {
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">{language.SEARCHDIAMOND_PREFERENCE_METRICS}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>RATIO</label>
                                <div className="input-group">
                                <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Ratio"
                                        min="0"
                                        step="0.01"
                                        id="Min_Ratio"
                                        placeholder="From"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Ratio"
                                        min="0"
                                        step="0.01"
                                        id="Max_Ratio"
                                        placeholder="To"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                        <label>IDEAL RATIO</label>
                            <Field  as="select" className="form-control" name="idealRatio">
                                <option value="select">select</option>
                                <option value="opt1">option 1</option>
                            </Field>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};
