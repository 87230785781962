/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaCog } from 'react-icons/fa';
import {ApiDataNatural} from "./ApiDataTable/ApiDataNatural"
import {ApiDataLab} from "./ApiDataTable/ApiDataLab"
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import {
    Paper,
    Tabs,
    Tab,
    Typography
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  
  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
  

export const FeedApproved = (props) => {
    const history = useHistory()

    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
      setValue(newValue);
    }


    return (
        <>
        {props.userinfo.feed_type === "API" && props.userinfo.api_live_mode !== 1?<Alert variant="danger">
                Once Testing is completed, Please contact to your sales administrator to Activate your Live API Key.
                </Alert>:""}
            <div className="row">
                <div className="col-12 d-md-flex align-items-center pb-5 justify-content-between border-bottom">
                
                    <h3 className="mb-0">{props.language.DIAMOND_FEED_CENTER}</h3>
                    {props.userinfo.feed_type === "IFrame Plug-in"?<button className="btn font-weight-bold btn-square btn-secondary px-10" 
                    onClick={() => history.push({
                        pathname: "/settings",
                        state: "pluginsetting",
                        // state: decompress(res.data.ProductDetails),
                    })}><FaCog className="mr-2" />{props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_IFRAMESETTING}</button>:""}
                    {props.userinfo.feed_type === "API"?<button className="btn font-weight-bold btn-square btn-secondary px-10" 
                    onClick={() => history.push({
                        pathname: "/settings",
                        state: "apisetting",
                        // state: decompress(res.data.ProductDetails),
                    })}><FaCog className="mr-2" />{props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_APISETTING}</button>:""}
                </div>
            </div>
            <div className="row align-content-start position-relative" >
                <div className="border-bottom col-12 d-flex justify-content-center">  
                    <Paper position="static" className="shadow-none border-bottom">
                        <Tabs value={value} indicatorColor="primary" textColor="primary" className="API_data" onChange={handleTabChange}>
                            <Tab label={props.language.DIAMOND_FEED_NATURAL_LISTING} />
                            <Tab label={props.language.DIAMOND_FEED_LAB_LISTING}  />
                        </Tabs>
                    </Paper>
                </div>
                <div className="col-12">  
                    {value === 0 && (
                        <TabContainer >
                            <ApiDataNatural userinfo={props.userinfo} language={props.language}/>
                        </TabContainer>
                      )}
                      {value === 1 && (
                        <TabContainer >
                            <ApiDataLab userinfo={props.userinfo} language={props.language}/>
                        </TabContainer>
                      )}
                </div>
            </div>
        </>
    );
};
