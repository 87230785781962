/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useState,useRef} from 'react'
import { InvoiceDetailTable } from "../inoviceDetail/InvoiceDetailTable"
import jsPDF from "jspdf";
import axios from 'axios';
import {
    Paper,
    Tabs,
    Tab,
    Typography
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  import { useLocation } from 'react-router-dom';
import { NODE_API_URL } from '../../../../env_config';
import ProformaSkeleton from './ProformaSkeleton'
import { BsArrowLeftShort,BsLightningFill } from "react-icons/bs";

  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

export const ProformaDetails = (props) => {
    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
        setValue(newValue);
    }
    const location = useLocation()
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const [totalinr,setTotalInr] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [totalcar,setTotalCar] = useState(0)
    const [ymdHms,setYmdHms] = useState("")
    const [performadata,setPerformaData] = useState()
    const [cgst,setCGST] = useState(0)
    const [sgst,setSGST] = useState(0)
    const [tax,setTAX] = useState(0)
    const [chargetax,setChargeTax] = useState(0)
    useEffect(() => {

        if(location.state.query){
            const userData = JSON.parse(localStorage.getItem("userData"))
            const finalData = {
                "user_id":userData.CustomerId,
                "id":location.state.query.perfoma_no,
                "conform_good_id":location.state.query.conform_good_id
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/TESTMODE/proformaDetailpage`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then(res => {
                if(res && res.data){
                    // console.log(res.data,"DATATATAA")
                    setPerformaData(res.data)
                    setChargeTax((((res.data.convenience_fee + res.data.discount_extra_order) * 18)/100))
                    if(props.userinfo.state === "maharashtra"){
                        setCGST(((res.data.diamond_price_inr * 0.75)/100))
                        setSGST(((res.data.diamond_price_inr * 0.75)/100)) 
        
                    }else{
                        setTAX(((res.data.diamond_price_inr * 1.5)/100))
                    }
                }
            }).catch(err => {

            })
        }
        else if(location.state.diamonddetail){
            let totalcarat = 0
            let totalinr = 0
            location.state.diamonddetail.map(values => {
                totalinr += parseFloat(values["OurPrice(INR)"])
                totalcarat += parseFloat(values["C_Weight"])
            })
            let totalinrcar = Math.round(totalinr/totalcarat * 100)/100
            setTotalInr(totalinr)
            setTotalInrCar(totalinrcar)
            setTotalCar(totalcarat)
            let date = new Date(2019, 4, 10, 15, 30, 20) //10 May 2019, 3:30:20 PM
            let dateStr = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" }) // 10/05/19
    
            let arr = dateStr.split("/")// [ '10', '05', '19' ]
            let d = arr[0]; //e.g. 10 
            let m = arr[1]; //e.g. 5 
            let y = arr[2]; //e.g. 19
    
            let timeStr = date.toLocaleTimeString("en-GB", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }) //
            let arr2 = timeStr.split(":") // 15:30:20
            let H = arr2[0]; //e.g. 15
            let i = arr2[1]; //e.g. 30
            let s = arr2[2]; //e.g. 20
    
            let ymdHms = y + m + d + H + i + s;
            setYmdHms(ymdHms)
        }
    // const formData = new FormData();

        // axios({
        //     method: "POST",
        //     url: NODE_API_URL + "/TESTMODE/insertPerfoma",
        //     headers: { "Content-Type": "application/json" },
        //     data: {
        //       "user_id":userinfo.id
        //     },
        //   }).then((res) => {
        //     if (res && res.data && res.data.success) {
        //         // console.log(res.data.data.isprofileapproved,"success")
        //         setProfileInfo(res.data.data)
        //     }
        //   });
    },[])
    const converttoyhmd = (newdate) => {
        let date = new Date(newdate) //10 May 2019, 3:30:20 PM
        let dateStr = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" }) // 10/05/19

        let arr = dateStr.split("/")// [ '10', '05', '19' ]
        let d = arr[0]; //e.g. 10 
        let m = arr[1]; //e.g. 5 
        let y = arr[2]; //e.g. 19

        let timeStr = date.toLocaleTimeString("en-GB", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }) //
        let arr2 = timeStr.split(":") // 15:30:20
        let H = arr2[0]; //e.g. 15
        let i = arr2[1]; //e.g. 30
        let s = arr2[2]; //e.g. 20

        let ymdHms =  y + m + d + H + i + s;;
        // setYmdHms(ymdHms)
        return ymdHms
    }
//     const handleGeneratePdf = () => {
//       const doc = new jsPDF({
//         format: "a4",
//         unit: "px"
//       });
  
//       // Adding the fonts
//       doc.setFont("Inter-Regular", "normal");
  
//       doc.html(`
//       <html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css" integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn" crossorigin="anonymous">
//       <style>
//       @font-face {font-family: "Product Sans";src:  url("' . $base . 'nassets/fontsProductSans-Regular.woff") format("woff");font-weight: 500;font-style: normal;font-display: swap;}
//       .inv_main_title{font-size:32px;padding-right:13px;font-weight: 600;color: #eeb23d;letter-spacing: 3px;}
//       .inv_header_border{border-bottom: 3px solid #eeb23d!important;}
//       .inv_icon_img{margin-right: -15px;}
//       .inv_hr{border-top: 2px solid #eeb23d;margin-bottom:5px;margin-top:5px;}
//       .table td{border:none;}
//       .inv_num_title{background-color: #002173!important;color: #fff;padding: 3px 25px;letter-spacing: 1px;border-radius: 25px;display: inline-block;line-height:normal;margin-bottom:15px;}
//       .inv_diam_detail_table{margin: 0 auto;width: 96%;}
//       .inv_diam_detail_table_footer{margin: 0 auto;width: 96%;}
//       table tr.inv_diam_detail_table_footer_tr td{background-color: #e7e7e7;padding-top: 4px;padding-bottom: 4px;border-top: 1px solid #cbcbcb;border-bottom: 1px solid #cbcbcb;line-height:normal;}
//       table .inv_footer td{background-color: #002173;color:#fff;line-height:normal;font-size:12px;font-weight:bolder;}
//       table tr.inv_total_bottom td{background-color: #eeb23d;color: #252f6d;padding: 0.45rem 0.75rem;}
//       p{line-height:normal;}
//       table tr.inv_diam_detail_header > td {background-color: #002173 !important;color:#ffffff;text-align:center;font-weight:700;border-right: 1px solid #ffffff;font-size:11px;padding: 0.45rem;vertical-align: center;}
//       table.inv_diam_detail_table  tr:last-child td:first-child {border-top-left-radius: 10px;}
//       table.inv_diam_detail_table  tr:last-child td:last-child {border-top-right-radius: 10px;}
//       table.inv_diam_detail_table .tbody td{font-size: 10px;padding: 0.3rem 0.55rem 0.10rem 0.55rem;border-bottom: 1px solid #cbcbcb;line-height:normal;}
//       .inv_other span{line-height: 1;display: inline-block;}
//       tr.inv_terms td{font-size:9px;line-height:normal;}
//       @page {margin: 0px 0px 0px 0px !important;padding: 0px 0px 0px 0px !important;}
//   </style>
//   </head><body class="inv_body">
//   <table class="m-0">
//       <tr> 
//           <td clss="py-0">
//               <table class="table inv_header_border m-0" >
//                   <tr>
//                       <td class="pb-1">
//                           <img class="pl-3" src="' . $base . 'nassets/pdf/logo_font_blue.png" alt="logo" width="200">
//                           <p class="pl-1 pt-2 mb-0" style="font-size:11px;">CE5040 BHARAT DIAMOND BOURSE,Bandra Kurla Complex, Bandra East,Mumbai, Maharashtra 400051, INDIA</p>
//                       </td>
//                       <td class="pb-1 text-right">
//                           <span class="inv_main_title">PROFORMA INVOICE</span>
//                       </td>
//                   </tr>
//               </table>
//           </td>
//       </tr>
//       <tr >
//           <td class="py-0" style="border:none;">
//               <table class="table mb-0">
//                   <tr class="">
//                       <td class="py-1" style="width: 33.33%;">
//                           <b class="pl-3" style="font-size:14px;">Invoice To :</b>
//                           <table class="m-0">
//                               <tr>
//                                   <td class="pl-4 p-0 mb-1" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_building.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.address},${props.userinfo.city}, ${props.userinfo.state}, ${props.userinfo.country}-${props.userinfo.com_zip}</span>
//                                   </td>
//                               </tr>
//                               <tr>
//                                   <td class="pl-4 p-0 mb-1" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_mail.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.mail}</span>
//                                   </td>
//                               </tr>
//                               <tr>
//                                   <td class="pl-4 p-0 mb-1" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_call.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.mobile}</span>
//                                   </td>
//                               </tr>
//                               <tr>
//                                   <td class="pl-4 p-0" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_user.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.cname}</span>
//                                   </td>
//                               </tr>
//                           </table>
//                       </td>
//                       <td class="py-1" style="width: 33.33%;" >
//                           <b class="pl-3" style="font-size:14px;">Ship To :</b>
//                           <table class="m-0">
//                               <tr>
//                                   <td class="pl-4 p-0 mb-1" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_building.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.address},${props.userinfo.city}, ${props.userinfo.state}, ${props.userinfo.country}-${props.userinfo.com_zip}</span>
//                                   </td>
//                               </tr>										
//                               <tr>
//                                   <td class="pl-4 p-0 mb-1" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_mail.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.mail}</span>
//                                   </td>
//                               </tr>
//                               <tr>
//                                   <td class="pl-4 p-0 mb-1" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_call.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.mobile}</span>
//                                   </td>
//                               </tr>
//                               <tr>
//                                   <td class="pl-4 p-0" style="border:none;font-size:11px;">
//                                       <img height="11" class="inv_icon_img" src="' . $base . 'nassets/pdf/inv_user.png" alt="building">
//                                       <span class="ml-4 d-inline-block">${props.userinfo.cname}</span>
//                                   </td>
//                               </tr>
//                           </table>
//                       </td>
//                       <td style="width: 33.33%;" class="pr-4 py-1">
//                           <p class="inv_num_title "><b>INVOICE : </b> INRPER - ' . $invoice_number . '</p>
//                           <p style="font-size:12px;line-height:normal;margin-bottom:2px;"><b>DATE : </b>' . date('Y-m-d') . '</p>
//                           <p style="font-size:12px;line-height:normal;margin:0px;"><b>DUE DATE : </b>------</p>
//                           <hr class="inv_hr" />
//                       </td>
//                   </tr>
//               </table>
//           </td>
//       </tr>
//       <tr>
//           <td class="py-0">
//               <div style="min-height: 400px;">  
//                   <table class="table inv_diam_detail_table mb-0">
//                       <tr class="inv_diam_detail_header" >
//                           <td align="middel" style="width: 3%;">SN.</td>
//                           <td style="width: 48%;">Descriptions of Goods</td>
//                           <td style="width: 8%;">HS Code</td>
//                           <td style="width: 8%;">No of Pcs</td>
//                           <td style="width: 6%;">Carats</td>
//                           <td style="width: 12%;">Per Carat(INR)</td>
//                           <td style="width: 13%;">Amount(INR)</td>
//                       </tr>
//                       </table>
//               </div>
//               <table class="table inv_diam_detail_table_footer mb-0"style="font-size:12px;">
//                   <tr class="inv_diam_detail_table_footer_tr">
//                       <td style="width: 3%;text-align:center"></td>
//                       <td style="width: 35%;"><b>Total</b></td>
//                       <td style="width: 10%;text-align:center"></td>
//                       <td style="width: 12%;text-align:right"><b>' . $i .' Pcs</b></td>
//                       <td style="width: 12%;text-align:center"><b>'.$pdftotalcarat.' Ct</b></td>
//                       <td style="width: 2%;text-align: right;"></td>
//                       <td style="width: 18%;text-align: right;"><b>Rs ' . number_format($loopplusamt,2) . '</b></td>
//                   </tr>
//               </table>
//           </td>
//       </tr>
//       <tr>
//           <td class="py-1">
//               <table class="table inv_other mb-0">
//                   <tr>
//                       <td class="pl-4 py-1" style="width: 33.33%;font-size:11px;">
//                           <p class="mb-1 font-weight-bolder" style="font-size:15px;">Payment info:</p >
//                           <p class="m-0"><b>Bank Name : </b>ICICI Bank</p>
//                           <p class="m-0"><b>Account Name : </b>Diamonds On Call LLP</p>
//                           <p class="m-0"><b>Account Number : </b>121705002101</p>
//                           <p class="m-0"><b>Bank Address : </b>Bharat Diamonds Bourse Branch,<br/><span class="pl-5 ml-5"><span class="ml-4">G-Block, Tower-5, E-1, BKC, Branda, Mumbai - 400051.</span></span></p>
//                           <p class="m-0"><b>IFSC Code : </b>ICIC0001217</p>
//                       </td>
//                       <td class="py-1" style="width: 30.33%;font-size:11px;">
//                           <p class="m-0"><b>Ship Via: </b>'.$shipping.'</p>
//                       </td>
//                       <td class="py-1 pr-3" style="width: 33.33%;">
//                           <table class="table mb-2" style="font-size:12px;">';
//                               if (strtolower($getstate) == 'maharashtra') {
//                                   $html .= '<tr>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0">CGST(0.75%):</h6></td>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0"><span style="font-family:sans-serif;">&#8377;</span>'.number_format($cgst,2).'</h6></td>
//                                   </tr>
//                                   <tr>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0">SGST (0.75%):</h6></td>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0"><span style="font-family:sans-serif;">&#8377;</span>'.number_format($sgst,2).'</h6></td>
//                                   </tr>';
//                               }else{
//                                   $html .= '<tr>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0">IGST (1.5%):</h6></td>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0"><span style="font-family:sans-serif;">&#8377;</span>'.number_format($tax,2).'</h6></td>
//                                   </tr>';
  
//                               }
//                               $html .= '<tr>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0">Shipping Charge:</h6></td>
//                                       <td class="w-50 px-0 text-right pb-1 pt-0"><h6 class="font-weight-bold m-0"><span style="font-family:sans-serif;">&#8377;</span>'.number_format($shipping_charge,2).'</h6></td>
//                                   </tr>
//                                   <tr>
//                                   <td class="w-50 px-0 text-right py-0"><h6 class="font-weight-bold m-0">'.$label.':</h6></td>
//                                   <td class="w-50 px-0 text-right py-0"><h6 class="font-weight-bold m-0">'.$discount_extra_orders.'</h6></td>
//                               </tr>
//                           </table>
//                           <table class="table mb-1" style="font-size:14px;">
//                               <tr class="inv_total_bottom">
//                                   <td class="w-50 text-right"><h6 class="font-weight-bold m-0">Total:</h6></td>
//                                   <td class="w-50 text-right"><h6 class="font-weight-bold m-0">Rs '.$finalamt.'</h6></td>
//                               </tr>
//                           </table>
//                           <table class="table m-0">
//                               <tr style="font-size:12px;">
//                                   <td style="" class="text-right py-1 px-0"><p class="m-0 font-weight-bolder">In words: ' . $wordnumber . '</p></td>
//                                   <td  class="text-right py-1 px-0"><p class="m-0"></p></td>
//                               </tr>
//                           </table>
//                       </td>
//                   </tr>
//               </table>
//           </td>
//       </tr>
//       <tr>
//           <td class="py-0">
//               <table class="table m-auto" style="width: 97%;">
//                   <tr>
//                       <td class="p-1">
//                           <p style="font-size:15px;font-weight:bolder;margin:0px;">Terms & Conditions</p>
//                       </td>
//                   </tr>
//                   <tr class="inv_terms">
//                       <td class="pt-0">
//                           <b style="font-size:11px"> Declaration :</b><br />						
//                           The diamonds herein invoiced have been purchased from legitimate sources not involved in  funding conflict and are in compliance  with  United  Nations  Resolutions. The Seller here  by guarantees  that  these  diamonds  are  conflict  free,  based on personal knowledge and/or written guarantees provided by suppliers of these diamonds.<br />								
//                           <b style="font-size:11px">Conditions of Sale:</b><br />
//                           1) The goods sold to you will be on the  assumption  that  the funds  paid to us are not,  in  whole or  in part, directly or indirectly, the  proceeds  of  any illegal activity and that the products that we are selling to you will  not be  used, in whole or in part, directly or indirectly, for any illegal activity.<br /> 								
//                           2) The Seller reserves the right to seek identification of the source of the funds received. Please note that you, the  Customer,  are responsible  for  compliance  with  all  laws  and  regulations  in  relation to export of these  products from Hong Kong and for compliance with all laws and regulations in relation to the  import of  these products  into  your  jurisdiction including, but not limited to, tax, customs, laws, and regulations.<br />
//                           3) Customer will bear full responsibility for all goods received by a third party on Customer&#8217;s behalf. <br />								
//                           4) The above-mentioned goods remain the property of  DIAMONDS ON CALL LIMITED.  until  full  payment  is  received.  Full  payment  only,  transfers  the  ownership.  If the  Purchaser  takes delivery of goods prior to full payment, he will be considered not as owner; in such case, Purchaser remains fully liable for any loss or damage.	<br />							
//                           5) The Company expressly disclaims any obligation or liability for  any  payments  made  on  the  basis  of  any  fraudulent  e-mail  or  verbal/telephonic  communication.  You  are  requested  to  send  the  payments  as  per instructions  specified  on this  invoice. The Company will not be liable for any expense / loss / damage  of  whatsoever nature,  whether  direct  or  indirect,  incidental  or  consequential,  in  connection  with the payments made erroneously.<br />
//                       </td>
//                   </tr>
//               </table>
//               <table class="table mt-2 mb-0">
//                   <tr style="font-size:13px">
//                       <td class="w-50 text-left pb-0 pl-3">
//                           <span class="d-inline-block">
//                               <hr class="ml-0 mb-1" style="border-top:1.5px solid #002173;width:200px;"/>
//                               <span> Buyer Signature</span>
//                           </span>
//                       </td>
//                       <td class="w-50 text-right pb-0 pl-3">
//                           <span class="d-inline-block">
//                               <hr class="mr-0 mb-1" style="border-top:1.5px solid #002173;width:200px;"/>
//                               <span> Authorised Signature</span>
//                           </span>
//                       </td>
//                   </tr>
//                   <tr>
//                       <td class="text-center pt-0 pb-2" colspan="2" style="font-size:12px">
//                           <p style="color:#002173;margin:0;">Thank you for your business with DIAMONDS ON CALL.</p>
//                       </td>
//                   </tr>
//                   <tr>
//                       <td class="text-center p-0" colspan="2">
//                           <table class="table inv_footer mb-0">
//                               <tr>
//                                   <td class="text-left pl-3 py-1" style="width:33.33%">www.diamondsoncall.com</td>
//                                   <td class="text-center py-1" style="width:33.33%">info@diamondsoncall.com</td>
//                                   <td class="text-right pr-3 py-1" style="width:33.33%">+85 3565 5670</td>
//                               </tr>
//                           </table>
//                       </td>
//                   </tr>
//               </table>
//           </td>
//       </tr>
//   </table>
//   </body></html>
//       `, {
//         async callback(doc) {
//           await doc.save("document");
//         },
//         x: 15,
//         y: 15,
//         width: 400, //target width in the PDF document
//         windowWidth: 1000
//       });
//     };
    const handleGeneratePdf = () => {
        if(process.env.REACT_APP_ENV === "production"){
            window.open(`https://diamondsoncall.online/assets/pdf_invoice/${performadata.invoicelink}`, "_blank")
        }
        else{
        window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${performadata.invoicelink}`, "_blank")

        }
    }
    const SendEmail = () => {
        if(performadata && performadata.expand && performadata.expand.length > 0){
            let confirmgoodids = performadata.expand.map(value => {
                return value.Certi_NO
            })
            const userData = JSON.parse(localStorage.getItem("userData"))
            const finalData = {
                "user_id":userData.CustomerId,
                "perfoma_no":location.state.query.perfoma_no,
                "conform_good_id":location.state.query.conform_good_id.split(',')
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/TESTMODE/emailPerfoma`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then(res => {
                if(res && res.data){
                    if(res.data.msg === "mail sent"){
                        alert("Mail Sent")
                    }
                }
            }).catch(err => {

            })
        }
    }
    // const calculateGST = () => {
    //     console.log(props.userinfo,"INSIDE calculateGST")
    //     let cgst = 0
    //     let sgst = 0
    //     let tax = 0
    //     if(props.userinfo.state === "maharashtra"){
    //         let cgst = (totalinr * 0.75)/100
    //         let sgst = (totalinr * 0.75)/100 
    //         return {
    //             cgst:cgst,
    //             sgst:sgst
    //         }
    //     }else{
    //         return {
    //             tax:(totalinr * 1.5)/100
    //         }
    //     }
    // }
    return (
        <>
        
        {performadata? <>
            <div className="row bg-white m-0 border-left-primary mb-5">
                <div className="col-12 px-5 py-2">
                    <span className="mb-0 font-size-h2 font-weight-bolder d-block tex-dark">{props.language.PROFORMA_DETAIL_DOCLLP}</span>
                    {/* <span className="text-muted">This page hase been enhanced for printing</span > */}
                </div>
            </div>
            <div className="card custom-card gutter-b">
                <div className="card-header py-3 px-5 d-flex justify-content-between align-items-center border-bottom-0">
                    <div className="card-title mb-0 ">
                        <span className="font-size-h2 font-weight-bolder d-block">{props.language.PROFORMA_DETAIL_PROFORMA_INVOICE_DETAILS}: #{converttoyhmd(performadata.performadate)}</span>
                        <span className="text-muted d-block">{props.language.PROFORMA_DETAIL_PROFORMA_INVOICED_ON} {performadata.performadate}</span >
                    </div>
                    <div className="card-toolbar">
                    <span className="btn btn-light-primary mr-3"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> {props.language.BACK}</span>

                        <button className="btn btn-light-primary mr-3" onClick={SendEmail}>{props.language.PROFORMA_DETAIL_SEND_EMAIL}</button>
                        <button className="btn btn-light-primary" onClick={handleGeneratePdf}>{props.language.PROFORMA_DETAIL_SEND_PDF}</button>
                    </div>
                </div>
                <div className="card-body p-0 pb-8">
                    <Paper position="static" className="shadow-none border-bottom">
                        <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                            <Tab label={props.language.PROFORMA_DETAIL_DETAILS} />
                            {/* <Tab label="History" disabled /> */}
                            {/* <Tab label="Additional Details"/> */}
                        </Tabs>
                    </Paper>
                     {value === 0 && (
                        <TabContainer >
                            <div className="row">
                            <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_SHIPPING_ADDRESS}</div>
                                        <div className="text-capitalize">
                                           {props.userinfo.shipping_address ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  props.userinfo.shipping_address.replace(/[']/g, '')} } />: '-'}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="font-size-h4 font-weight-bold  mb-2">{props.language.PROFORMA_DETAIL_BILLING_ADDRESS}</div>
                                        <div className="text-capitalize">
                                           {props.userinfo.billing_address ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  props.userinfo.billing_address.replace(/[']/g, '')} } />: props.userinfo.shipping_address ?  <div dangerouslySetInnerHTML={ {__html:  props.userinfo.shipping_address.replace(/[']/g, '')} } />: '-'}
                                        </div>
                                    </div>
                                <div className="col-md-3">
                                    <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_LOGISTICS_INFORMATION}</div>
                                    <div className="">{props.language.PROFORMA_DETAIL_CARRIER} :{performadata.shipping_address}</div>
                                    {/* <div className="">{props.language.PROFORMA_DETAIL_TRACKING} :N/A</div> */}

                                    {/* <a href='#' target="_blank" rel='referal'>{props.language.PROFORMA_DETAIL_TRACK_YOUR_PACKAGE}</a> */}
                                </div>
                                <div className="col-md-3">
                                    <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_ORDER_SUMMARY}</div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>{props.language.PROFORMA_DETAIL_DIAMONDS}:</span>
                                            <span>{performadata.totalItem} {props.language.PROFORMA_DETAIL_PCS}</span>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>{props.language.SEARCHLIST_TOTALCARAT}:</span>
                                            <span>{numberWithCommas(performadata.totalCAR)}</span>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>{props.language.SEARCHGRID_TOTAL} $/CT:</span>
                                            <span>${numberWithCommas(performadata.diamond_price_inr/performadata.totalCAR)}</span>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <span>Total Price:</span>
                                            <span>${numberWithCommas(performadata.diamond_price_inr)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabContainer>
                    )}
                    {value === 1 && (
                        <TabContainer >
                            2
                        </TabContainer>
                    )}
                </div>
            </div>

            <div className="card custom-card gutter-b">
                <div className="card-header py-5 border-bottom-0">
                    <div className="card-title mb-0 font-size-h4 font-weight-bolder">{props.language.PROFORMA_DETAIL_DIAMONDS_INFORMATION}</div>
                </div>
                <div className="card-body px-2 p-0">
                    <InvoiceDetailTable diamonddetail={performadata.expand} language={props.language}/>
                    <div className="row p-5 mt-5">
                        <div className="col-md-9">
                            {/* <div className="font-size-h4 font-weight-bold mb-2">NOTES</div>
                            <div>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            </div> */}
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>USD/CT:</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(performadata.diamond_price_inr/performadata.totalCAR)}</span>
                                </div>
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                        <span className='font-size-h5 font-weight-bold'>{props.language.INVOICE_DIAMOND_PRICE}:</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(performadata.diamond_price_inr)}</span>
                                        </div>
                                        {performadata.discount_extra_order?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{performadata.discount_extra_order>0?props.language.INVOICE_EXTRA_CHARGES:props.language.INVOICE_DISCOUNT}</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(performadata.discount_extra_order)}</span>
                                </div>:""}
                                        {performadata.shippingcharges?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.REQ_DIAMOND_CONVINIENCE_FEE}:</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(performadata.shippingcharges)}</span>
                                </div>:""}
                                        

                                {/* {cgst?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_CGST}(0.75%):</span>
                                    <span className='font-size-h5 font-weight-bold'>₹{numberWithCommas(cgst)}</span>
                                </div>:""}
                                {sgst?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_SGST}(0.75%):</span>
                                    <span className='font-size-h5 font-weight-bold'>₹{numberWithCommas(sgst)}</span>
                                </div>:""} */}
                                {/* {tax?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_IGST}(1.5%):</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(tax)}</span>
                                </div>:""}
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.PROFORMA_DETAIL_CHARGE_TAX}(18%):</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(chargetax)}</span>
                                </div> */}
                                {performadata.vip_discount?<div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}:</span>
                                    <span className='font-size-h5 font-weight-bold text-danger'>$-{numberWithCommas(parseFloat(performadata.vip_discount))}</span>
                                </div>:""}
                                <div className="col-12 mb-3 d-flex justify-content-between">
                                    <span className='font-size-h5 font-weight-bold'>{props.language.REQ_TOTAL_DIAMOND_PRICE}:</span>
                                    <span className='font-size-h5 font-weight-bold'>${numberWithCommas(parseFloat(performadata.total_diamond_price))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card custom-card-gutter-b">
                <div className="card-body">
                   <div className="row">
                        <div className="col-md-4">
                            <div className="font-size-h4 font-weight-bold mb-2">{props.language.PROFORMA_DETAIL_BANK_DETAILS}</div>
                            {performadata.perfoma_country === "IND-EXP"?<div className="font-weight-bold font-size-lg">
                                <div>Bank Account Number : 121705002101</div>
                                <div>Bank Name : ICICI BANK</div>
                                <div>Bank Address : Bharat Diamond Bourse , BKC Mumbai,400051, MH, INDIA </div>
                                <div>AD CODE NO. 6391864-2910009</div>
                                <div>IFSC - ICIC0001217 SWIFT CODE -ICICINBBCTS</div>
                            </div>:<div className="font-weight-bold font-size-lg">
                                <div>Account Name : Diamonds On Call India LLP</div>
                                <div>Bank Account Number : 39051002000</div>
                                <div>Bank Name : CITI BANK (HONG KONG) LIMITED</div>
                                <div>Bank Address : Citi Tower, 83 Hoi Bun Road, One Bay East, Kwun Tong</div>
                                <div>SWIFT Code : CITIHKAXXXX</div>
                                <div>INTERMEDIARY BANK : Citi Bank New York</div>
                            </div>}
                        </div >
                        <div className="col-md-8">
                            <div className="font-size-h4 font-weight-bold mb-3">{props.language.PROFORMA_DETAIL_TERMS}</div>
                            <div className="font-weight-bold font-size-lg mb-3">
                                {props.language.PROFORMA_DETAIL_TERMS1}
                            </div>
                            <div className="font-weight-bold font-size-lg mb-3">
                                {props.language.PROFORMA_DETAIL_TERMS2}
                            </div>
                            <div className="font-weight-bold font-size-lg mb-3">
                                {props.language.PROFORMA_DETAIL_TERMS3}
                            </div>
                        </div>
                   </div >
                </div>
            </div>
            </>:<ProformaSkeleton /> }
        </>
    )
}