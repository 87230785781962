import React from "react";
import { Field } from "formik";

export const Measurement = ({language}) => {
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 font-weight-bold mb-0">{language.MUI_MEASUREMENT}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label className="text-uppercase">Length</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Length"
                                        min="0"
                                        step="0.01"
                                        id="Min_Length"
                                        placeholder="From"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">mm</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Length"
                                        min="0"
                                        step="0.01"
                                        id="Max_Length"
                                        placeholder="To"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">mm</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>WIDTH</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Width"
                                        min="0"
                                        step="0.01"
                                        id="Min_Width"
                                        placeholder="From"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">mm</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Width"
                                        min="0"
                                        step="0.01"
                                        id="Max_Width"
                                        placeholder="To"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">mm</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>HEIGHT</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Heigth"
                                        min="0"
                                        step="0.01"
                                        id="Min_Heigth"
                                        placeholder="From"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">mm</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Heigth"
                                        min="0"
                                        step="0.01"
                                        id="Max_Heigth"
                                        placeholder="To"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">mm</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
