/* eslint-disable */
import * as Yup from 'yup';
import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    rulename,

    naturaldiamond,
    naturalfancydiamond,
    lgwhitediam,
    lgfancydiam,
    diamondshape,
    carat_from,
    carat_to,
    diamondcolor,
    diamondfancy_color,
    diamondfancy_intensity,
    diamondfancy_overtone,
    diamondclarity,
    lab_val,
    cuts,
    diamondflour,
    polishes,
    milky,
    eyeclean,
    symmetries,
    diamondshade,
    price_from,
    price_to,
    total_price_from,
    total_price_to,
    media,

    lab_shape,
    lab_carat_from,
    lab_carat_to,
    lab_color,
    lab_fancy_color,
    lab_fancy_intensity,
    lab_fancy_overtone,
    lab_clarity,
    lab_lab,
    lab_cuts,
    lab_polishes,
    lab_symmetries,
    lab_flourselect,
    lab_shadeselect,
    lab_milky,
    lab_eyeclean,
    lab_min_dollarperct,
    lab_max_dollarperct,
    lab_total_price_from,
    lab_total_price_to,
    lab_all_media,

    primarycolor,
    secondary,
    fontcolor,
    diamondmarkup,
    diamondfancymarkup,
    lab_diamondmarkup,
    lab_diamondfancymarkup,
    website_url,
    inquiry_email,
    inquiry_cc_email,
    markupvalue,
    markupoption,
    suppliers,
    caratpricemarkups
  }
} = kycFormModel;

const numbersonly =/^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$/
const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/
const range = /^(?!0+(?:\.0+)?$)\d?\d(?:\.\d\d?)?$/
// export default [
//   Yup.object().shape({
//     [rulename.name]: Yup.string().required(`${rulename.requiredErrorMsg}`),
//     // [naturalfancydiamond.name]: Yup.string(),
//     // [lgwhitediam.name]: Yup.string(),
//     // [lgfancydiam.name]: Yup.string(),
//   }), 
//   Yup.object().shape({
//     [naturaldiamond.name]: Yup.string(),
//     [naturalfancydiamond.name]: Yup.string(),
//     [lgwhitediam.name]: Yup.string(),
//     [lgfancydiam.name]: Yup.string(),
//   }) .test(
//     'naturaldiamond',
//     null,
//     (obj) => {
//       console.log(obj,"OBJJSJSJSSJ")
//       if ( obj.naturaldiamond || obj.naturalfancydiamond || obj.lgwhitediam || obj.lgfancydiam ) {
//         return true; // everything is fine
//       }
//       const keys = ['naturaldiamond', 'naturalfancydiamond','lgwhitediam','lgfancydiam']

//       const errors = keys.map((key) => {
  
//           return new Yup.ValidationError(
//             `${key} is empty`,
//             null,
//             key
//           )
//       }).filter(Boolean)
//       console.log(errors,"ValidationError")
//       console.log(obj.naturaldiamond,"naturaldiamond")
//       console.log(obj.naturalfancydiamond,"naturalfancydiamond")
//       console.log(obj.lgwhitediam,"lgwhitediam")
//       console.log(obj.lgfancydiam,"lgfancydiam")
//       return new Yup.ValidationError(errors);
//     }
//   ),
//   Yup.object().shape({
//     // [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
//     // [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
//     // [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
//     // // [diamondcolor.name]: Yup.array().min(1,`${diamondcolor.requiredErrorMsg}`).required(`${diamondcolor.requiredErrorMsg}`),
//     // // [diamondfancy_color.name]: Yup.array().min(1,`${diamondfancy_color.requiredErrorMsg}`).required(`${diamondfancy_color.requiredErrorMsg}`),
//     // // [diamondfancy_intensity.name]: Yup.array().min(1,`${diamondfancy_intensity.requiredErrorMsg}`).required(`${diamondfancy_intensity.requiredErrorMsg}`),
//     // // [diamondfancy_overtone.name]: Yup.array().min(1,`${diamondfancy_overtone.requiredErrorMsg}`).required(`${diamondfancy_overtone.requiredErrorMsg}`),
//     // [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
//     // [lab_val.name]: Yup.array().min(1,`${lab_val.requiredErrorMsg}`).required(`${lab_val.requiredErrorMsg}`),
//     // // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
//     // [symmetries.name]: Yup.array().min(1,`${symmetries.requiredErrorMsg}`).required(`${symmetries.requiredErrorMsg}`),
//     // [diamondflour.name]: Yup.array().min(1,`${diamondflour.requiredErrorMsg}`).required(`${diamondflour.requiredErrorMsg}`),
//     // [polishes.name]: Yup.array().min(1,`${polishes.requiredErrorMsg}`).required(`${polishes.requiredErrorMsg}`),
//     // // [milky.name]: Yup.array().min(1,`${milky.requiredErrorMsg}`).required(`${milky.requiredErrorMsg}`),
//     // // [eyeclean.name]: Yup.array().min(1,`${eyeclean.requiredErrorMsg}`).required(`${eyeclean.requiredErrorMsg}`),
//     // // [diamondshade.name]: Yup.array().min(1,`${diamondshade.requiredErrorMsg}`).required(`${diamondshade.requiredErrorMsg}`),
//     // [price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [total_price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [total_price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
//     // [media.name]: Yup.string().required(`${media.requiredErrorMsg}`),  


//   }),
//   Yup.object().shape({
//     // [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
//     // [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
//     // [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
//     // // [lab_color.name]: Yup.array().min(1,`${lab_color.requiredErrorMsg}`).required(`${lab_color.requiredErrorMsg}`),
//     // // [lab_fancy_color.name]: Yup.array().min(1,`${lab_fancy_color.requiredErrorMsg}`).required(`${lab_fancy_color.requiredErrorMsg}`),
//     // // [lab_fancy_intensity.name]: Yup.array().min(1,`${lab_fancy_intensity.requiredErrorMsg}`).required(`${lab_fancy_intensity.requiredErrorMsg}`),
//     // // [lab_fancy_overtone.name]: Yup.array().min(1,`${lab_fancy_overtone.requiredErrorMsg}`).required(`${lab_fancy_overtone.requiredErrorMsg}`),
//     // [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
//     // [lab_lab.name]: Yup.array().min(1,`${lab_lab.requiredErrorMsg}`).required(`${lab_lab.requiredErrorMsg}`),
//     // // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
//     // [lab_polishes.name]: Yup.array().min(1,`${lab_polishes.requiredErrorMsg}`).required(`${lab_polishes.requiredErrorMsg}`),
//     // [lab_symmetries.name]: Yup.array().min(1,`${lab_symmetries.requiredErrorMsg}`).required(`${lab_symmetries.requiredErrorMsg}`),
//     // [lab_flourselect.name]: Yup.array().min(1,`${lab_flourselect.requiredErrorMsg}`).required(`${lab_flourselect.requiredErrorMsg}`),
//     // // [lab_shadeselect.name]: Yup.array().min(1,`${lab_shadeselect.requiredErrorMsg}`).required(`${lab_shadeselect.requiredErrorMsg}`),
//     // // [lab_milky.name]: Yup.array().min(1,`${lab_milky.requiredErrorMsg}`).required(`${lab_milky.requiredErrorMsg}`),
//     // // [lab_eyeclean.name]: Yup.array().min(1,`${lab_eyeclean.requiredErrorMsg}`).required(`${lab_eyeclean.requiredErrorMsg}`),
//     // [lab_min_dollarperct.name]: Yup.string().required(`${lab_min_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [lab_max_dollarperct.name]: Yup.string().required(`${lab_max_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [lab_total_price_from.name]: Yup.string().required(`${lab_total_price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     // [lab_total_price_to.name]: Yup.string().required(`${lab_total_price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
//     // [lab_all_media.name]: Yup.string().required(`${lab_all_media.requiredErrorMsg}`),  


//   }),
//   Yup.object().shape({
//     // [markupvalue.name]: Yup.string().required(`${markupvalue.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
//     [markupoption.name]: Yup.string().required(`${markupoption.requiredErrorMsg}`),
//     // [suppliers.name]: Yup.array().min(1,`${suppliers.requiredErrorMsg}`).required(`${suppliers.requiredErrorMsg}`)
//   }),
// ];

export default function ValidationSchema(values,customerapi,allrules){
  let validationarray = [
    Yup.object().shape({
      [rulename.name]: Yup.string().required(`${rulename.requiredErrorMsg}`),
      // [naturalfancydiamond.name]: Yup.string(),
      // [lgwhitediam.name]: Yup.string(),
      // [lgfancydiam.name]: Yup.string(),
    }).test(
          'rulename',
          null,
          (obj) => {
            const ValidateRule = (string,rules) => {
              let ruleexist = false
              for(let i = 0; i < rules.length; i++){
                console.log(rules[i],"rules[i].rulename")
                if(rules[i].rule_name === string){
                  ruleexist = true
                }
              }
              return ruleexist
            }
            const validaterule = ValidateRule(obj.rulename,allrules)
            console.log(validaterule,"validaterule")
            if(validaterule){
              return new Yup.ValidationError("Rule Name Already Exists!",true,"rulename");
            }
          }
        ), 
    Yup.object().shape({
      [naturaldiamond.name]: Yup.string(),
      [naturalfancydiamond.name]: Yup.string(),
      [lgwhitediam.name]: Yup.string(),
      [lgfancydiam.name]: Yup.string(),
    }) .test(
      'naturaldiamond',
      null,
      (obj) => {
        console.log(obj,"OBJJSJSJSSJ")
        if ( obj.naturaldiamond || obj.naturalfancydiamond || obj.lgwhitediam || obj.lgfancydiam ) {
          return true; // everything is fine
        }
        const keys = ['naturaldiamond', 'naturalfancydiamond','lgwhitediam','lgfancydiam']
  
        const errors = keys.map((key) => {
    
            return new Yup.ValidationError(
              `${key} is empty`,
              null,
              key
            )
        }).filter(Boolean)
        console.log(errors,"ValidationError")
        console.log(obj.naturaldiamond,"naturaldiamond")
        console.log(obj.naturalfancydiamond,"naturalfancydiamond")
        console.log(obj.lgwhitediam,"lgwhitediam")
        console.log(obj.lgfancydiam,"lgfancydiam")
        return new Yup.ValidationError(errors);
      }
    ),
    Yup.object().shape({
      // [markupvalue.name]: Yup.string().required(`${markupvalue.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      [markupoption.name]: Yup.string().required(`${markupoption.requiredErrorMsg}`),
      [caratpricemarkups.name]: Yup.array().min(1,`${caratpricemarkups.requiredErrorMsg}`).required(`${caratpricemarkups.requiredErrorMsg}`)
    }),
    Yup.object().shape({
      // [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
      // [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
      // [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
      // // [lab_color.name]: Yup.array().min(1,`${lab_color.requiredErrorMsg}`).required(`${lab_color.requiredErrorMsg}`),
      // // [lab_fancy_color.name]: Yup.array().min(1,`${lab_fancy_color.requiredErrorMsg}`).required(`${lab_fancy_color.requiredErrorMsg}`),
      // // [lab_fancy_intensity.name]: Yup.array().min(1,`${lab_fancy_intensity.requiredErrorMsg}`).required(`${lab_fancy_intensity.requiredErrorMsg}`),
      // // [lab_fancy_overtone.name]: Yup.array().min(1,`${lab_fancy_overtone.requiredErrorMsg}`).required(`${lab_fancy_overtone.requiredErrorMsg}`),
      // [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
      // [lab_lab.name]: Yup.array().min(1,`${lab_lab.requiredErrorMsg}`).required(`${lab_lab.requiredErrorMsg}`),
      // // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
      // [lab_polishes.name]: Yup.array().min(1,`${lab_polishes.requiredErrorMsg}`).required(`${lab_polishes.requiredErrorMsg}`),
      // [lab_symmetries.name]: Yup.array().min(1,`${lab_symmetries.requiredErrorMsg}`).required(`${lab_symmetries.requiredErrorMsg}`),
      // [lab_flourselect.name]: Yup.array().min(1,`${lab_flourselect.requiredErrorMsg}`).required(`${lab_flourselect.requiredErrorMsg}`),
      // // [lab_shadeselect.name]: Yup.array().min(1,`${lab_shadeselect.requiredErrorMsg}`).required(`${lab_shadeselect.requiredErrorMsg}`),
      // // [lab_milky.name]: Yup.array().min(1,`${lab_milky.requiredErrorMsg}`).required(`${lab_milky.requiredErrorMsg}`),
      // // [lab_eyeclean.name]: Yup.array().min(1,`${lab_eyeclean.requiredErrorMsg}`).required(`${lab_eyeclean.requiredErrorMsg}`),
      // [lab_min_dollarperct.name]: Yup.string().required(`${lab_min_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      // [lab_max_dollarperct.name]: Yup.string().required(`${lab_max_dollarperct.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      // [lab_total_price_from.name]: Yup.string().required(`${lab_total_price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
      // [lab_total_price_to.name]: Yup.string().required(`${lab_total_price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
      // [lab_all_media.name]: Yup.string().required(`${lab_all_media.requiredErrorMsg}`),  
  
  
    }),
  ];
  let diamondparamsvalidation = Yup.object().shape({
    // [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
    // [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
    // [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99'),
    // // [diamondcolor.name]: Yup.array().min(1,`${diamondcolor.requiredErrorMsg}`).required(`${diamondcolor.requiredErrorMsg}`),
    // // [diamondfancy_color.name]: Yup.array().min(1,`${diamondfancy_color.requiredErrorMsg}`).required(`${diamondfancy_color.requiredErrorMsg}`),
    // // [diamondfancy_intensity.name]: Yup.array().min(1,`${diamondfancy_intensity.requiredErrorMsg}`).required(`${diamondfancy_intensity.requiredErrorMsg}`),
    // // [diamondfancy_overtone.name]: Yup.array().min(1,`${diamondfancy_overtone.requiredErrorMsg}`).required(`${diamondfancy_overtone.requiredErrorMsg}`),
    // [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
    // [lab_val.name]: Yup.array().min(1,`${lab_val.requiredErrorMsg}`).required(`${lab_val.requiredErrorMsg}`),
    // // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
    // [symmetries.name]: Yup.array().min(1,`${symmetries.requiredErrorMsg}`).required(`${symmetries.requiredErrorMsg}`),
    // [diamondflour.name]: Yup.array().min(1,`${diamondflour.requiredErrorMsg}`).required(`${diamondflour.requiredErrorMsg}`),
    // [polishes.name]: Yup.array().min(1,`${polishes.requiredErrorMsg}`).required(`${polishes.requiredErrorMsg}`),
    // // [milky.name]: Yup.array().min(1,`${milky.requiredErrorMsg}`).required(`${milky.requiredErrorMsg}`),
    // // [eyeclean.name]: Yup.array().min(1,`${eyeclean.requiredErrorMsg}`).required(`${eyeclean.requiredErrorMsg}`),
    // // [diamondshade.name]: Yup.array().min(1,`${diamondshade.requiredErrorMsg}`).required(`${diamondshade.requiredErrorMsg}`),
    // [price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
    // [price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
    // [total_price_from.name]: Yup.string().required(`${price_from.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),
    // [total_price_to.name]: Yup.string().required(`${price_to.requiredErrorMsg}`).matches(numbersonly, 'Only numeric allowed'),  
    // [media.name]: Yup.string().required(`${media.requiredErrorMsg}`),  


  })
  if(values.naturaldiamond){
    console.log(customerapi,"customerapicustomerapi")
    // customerapi = customerapi.filter(val => val.naturaldiamond && val.naturaldiamond.toString() === values.naturaldiamond.toString())
    diamondparamsvalidation = Yup.object().shape({
      [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
      [diamondcolor.name]: Yup.array().min(1,`${diamondcolor.requiredErrorMsg}`).required(`${diamondcolor.requiredErrorMsg}`),
      [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
      // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
      [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
      [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
    }).test(
      'overlap',
      null,
      (obj) => {
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.cuts && obj.cuts.length && Array.isArray(obj.cuts)){
              FinalObject["cut"] = obj.cuts.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(obj.symmetries && obj.symmetries.length && Array.isArray(obj.symmetries)){
              FinalObject["symmetry"] = obj.symmetries.toString()
            }
            if(obj.diamondflour && obj.diamondflour.length && Array.isArray(obj.diamondflour)){
              FinalObject["fluorescence"] = obj.diamondflour.toString()
            }
            if(obj.polishes && obj.polishes.length && Array.isArray(obj.polishes)){
              FinalObject["polish"] = obj.polishes.toString()
            }
            if(obj.milky && obj.milky.length && Array.isArray(obj.milky)){
              FinalObject["milky"] = obj.milky.toString()
            }
            if(obj.eyeclean && obj.eyeclean.length && Array.isArray(obj.eyeclean)){
              FinalObject["eyeclean"] = obj.eyeclean.toString()
            }
            if(obj.diamondshade && obj.diamondshade.length && Array.isArray(obj.diamondshade)){
              FinalObject["shade"] = obj.diamondshade.toString()
            }
            if(obj.price_from){
              FinalObject["min_dollarperct"] = parseFloat(obj.price_from)
            }
            if(obj.price_to){
              FinalObject["max_dollarperct"] = parseFloat(obj.price_to)
            }
            if(obj.total_price_from){
              FinalObject["total_price_from"] = parseFloat(obj.total_price_from)
            }
            if(obj.total_price_to){
              FinalObject["total_price_to"] = parseFloat(obj.total_price_to)
            }
            if(obj.media && obj.media.length && Array.isArray(obj.media)){
              FinalObject["media"] = obj.media.toString()
            }
            if(obj.measurementlengthfrom){
              FinalObject["minlength"] = parseFloat(obj.measurementlengthfrom)
            }
            if(obj.measurementlengthto){
            FinalObject["maxlength"] = parseFloat(obj.measurementlengthto)              
            }
            if(obj.measurementwidthfrom){
            FinalObject["minwidth"] = parseFloat(obj.measurementwidthfrom)
            }
            if(obj.measurementwidthto){
              FinalObject["maxwidth"] = parseFloat(obj.measurementwidthto)
            }
            if(obj.measurementheightfrom){
              FinalObject["minheight"] = parseFloat(obj.measurementheightfrom)
            }
            if(obj.measurementheightto){
              FinalObject["maxheight"] = parseFloat(obj.measurementheightto)
            }
            if(obj.tablefrom){
              FinalObject["tablemin"] = parseFloat(obj.tablefrom)
            }
            if(obj.tableto){
              FinalObject["tablemax"] = parseFloat(obj.tableto)
            }
            if(obj.depthfrom){
              FinalObject["depthmin"] = parseFloat(obj.depthfrom)
            }
            if(obj.depthto){
              FinalObject["depthmax"] = parseFloat(obj.depthto)
            }
            if(obj.ratiofrom){
              FinalObject["ratiomin"] = parseFloat(obj.ratiofrom)
            }
            if(obj.ratioto){
              FinalObject["ratiomax"] = parseFloat(obj.ratioto)
            }
            if(obj.crownheightfrom){
              FinalObject["crheightmin"] = parseFloat(obj.crownheightfrom)
            }
            if(obj.crownheightto){
              FinalObject["crheightmax"] = parseFloat(obj.crownheightto)
            }
            if(obj.crownanglefrom){
              FinalObject["cranglemin"] = parseFloat(obj.crownanglefrom)
            }
            if(obj.crownangleto){
              FinalObject["cranglemax"] = parseFloat(obj.crownangleto)
            }
            if(obj.pavheightfrom){
              FinalObject["pavheightmin"] = parseFloat(obj.pavheightfrom)
            }
            if(obj.pavheightto){
              FinalObject["pavheightmax"] = parseFloat(obj.pavheightto)
            }
            if(obj.pavanglefrom){
              FinalObject["pavanglemin"] = parseFloat(obj.pavanglefrom)
            }
            if(obj.pavangleto){
              FinalObject["pavanglemax"] = parseFloat(obj.pavangleto)
            }
            if(obj.brand && obj.brand.length && Array.isArray(obj.brand)){
              FinalObject["brand"] = obj.brand.toString()
            }
            if(obj.origin && obj.origin.length && Array.isArray(obj.origin)){
              FinalObject["origin"] = obj.origin.toString()
            }
            if(obj.treatment && obj.treatment.length && Array.isArray(obj.treatment)){
              FinalObject["treatment"] = obj.treatment.toString()
            }
            if(obj.keytosymbol && obj.keytosymbol.length && Array.isArray(obj.keytosymbol)){
              FinalObject["keytosymbol"] = obj.keytosymbol.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["diamondfancy_color"] = obj.diamondfancy_color.toString()
              }
              if(obj.diamondfancy_intensity && obj.diamondfancy_intensity.length && Array.isArray(obj.diamondfancy_intensity)){
                FinalObject["diamondfancy_intensity"] = obj.diamondfancy_intensity.toString()
              }
              if(obj.diamondfancy_overtone && obj.diamondfancy_overtone.length && Array.isArray(obj.diamondfancy_overtone)){
                FinalObject["diamondfancy_overtone"] = obj.diamondfancy_overtone.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["lab_shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_cuts && obj.lab_cuts.length && Array.isArray(obj.lab_cuts)){
            FinalObject["lab_cut"] = obj.lab_cuts.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["lab_clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["lab_min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["lab_max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab_lab"] = obj.lab_lab.toString()
          }
          if(obj.lab_symmetries && obj.lab_symmetries.length && Array.isArray(obj.lab_symmetries)){
            FinalObject["lab_symmetry"] = obj.lab_symmetries.toString()
          }
          if(obj.lab_flourselect && obj.lab_flourselect.length && Array.isArray(obj.lab_flourselect)){
            FinalObject["lab_fluorescence"] = obj.lab_flourselect.toString()
          }
          if(obj.lab_polishes && obj.lab_polishes.length && Array.isArray(obj.lab_polishes)){
            FinalObject["lab_polish"] = obj.lab_polishes.toString()
          }
          if(obj.lab_milky && obj.lab_milky.length && Array.isArray(obj.lab_milky)){
            FinalObject["lab_milky"] = obj.lab_milky.toString()
          }
          if(obj.lab_eyeclean && obj.lab_eyeclean.length && Array.isArray(obj.lab_eyeclean)){
            FinalObject["lab_eyeclean"] = obj.lab_eyeclean.toString()
          }
          if(obj.lab_shadeselect && obj.lab_shadeselect.length && Array.isArray(obj.lab_shadeselect)){
            FinalObject["lab_shade"] = obj.lab_shadeselect.toString()
          }
          if(obj.lab_min_dollarperct){
            FinalObject["lab_min_dollarperct"] = parseFloat(obj.lab_min_dollarperct)
          }
          if(obj.lab_max_dollarperct){
            FinalObject["lab_max_dollarperct"] = parseFloat(obj.lab_max_dollarperct)
          }
          if(obj.lab_total_price_from){
            FinalObject["lab_total_price_from"] = parseFloat(obj.lab_total_price_from)
          }
          if(obj.lab_total_price_to){
            FinalObject["lab_total_price_to"] = parseFloat(obj.lab_total_price_to)
          }
          if(obj.lab_all_media && obj.lab_all_media.length && Array.isArray(obj.lab_all_media)){
            FinalObject["lab_media"] = obj.lab_all_media.toString()
          }
          if(obj.labmeasurementlengthfrom){
            FinalObject["labminlength"] = parseFloat(obj.labmeasurementlengthfrom)
          }
          if(obj.labmeasurementlengthto){
            FinalObject["labmaxlength"] = parseFloat(obj.labmeasurementlengthto)
          }
          if(obj.labmeasurementwidthfrom){
            FinalObject["labminwidth"] = parseFloat(obj.labmeasurementwidthfrom)
          }
          if(obj.labmeasurementwidthto){
            FinalObject["labmaxwidth"] = parseFloat(obj.labmeasurementwidthto)
          }
          if(obj.labmeasurementheightfrom){
            FinalObject["labminheight"] = parseFloat(obj.labmeasurementheightfrom)
          }
          if(obj.labmeasurementheightto){
            FinalObject["labmaxheight"] = parseFloat(obj.labmeasurementheightto)
          }
          if(obj.labtablefrom){
            FinalObject["labtablemin"] = parseFloat(obj.labtablefrom)
          }
          if(obj.labtableto){
            FinalObject["labtablemax"] = parseFloat(obj.labtableto)
          }
          if(obj.labdepthfrom){
            FinalObject["labdepthmin"] = parseFloat(obj.labdepthfrom)
          }
          if(obj.labdepthto){
            FinalObject["labdepthmax"] = parseFloat(obj.labdepthto)
          }
          if(obj.labratiofrom){
            FinalObject["labratiomin"] = parseFloat(obj.labratiofrom)
          }
          if(obj.labratioto){
            FinalObject["labratiomax"] = parseFloat(obj.labratioto)
          }
          if(obj.labcrownheightfrom){
            FinalObject["labcrheightmin"] = parseFloat(obj.labcrownheightfrom)
          }
          if(obj.labcrownheightto){
            FinalObject["labcrheightmax"] = parseFloat(obj.labcrownheightto)
          }
          if(obj.labcrownanglefrom){
            FinalObject["labcranglemin"] = parseFloat(obj.labcrownanglefrom)
          }
          if(obj.labcrownangleto){
            FinalObject["labcranglemax"] = parseFloat(obj.labcrownangleto)
          }
          if(obj.labpavheightfrom){
            FinalObject["labpavheightmin"] = parseFloat(obj.labpavheightfrom)
          }
          if(obj.labpavheightto){
            FinalObject["labpavheightmax"] = parseFloat(obj.labpavheightto)
          }
          if(obj.labpavanglefrom){
            FinalObject["labpavanglemin"] = parseFloat(obj.labpavanglefrom)
          }
          if(obj.labpavangleto){
            FinalObject["labpavanglemax"] = parseFloat(obj.labpavangleto)
          }
            // FinalObject["brand"] = obj.brand
            if(obj.laborigin && obj.laborigin.length && Array.isArray(obj.laborigin)){
              FinalObject["laborigin"] = obj.laborigin.toString()              
            }
            if(obj.labtreatment && obj.labtreatment.length && Array.isArray(obj.labtreatment)){
              FinalObject["labtreatment"] = obj.labtreatment.toString()
            }
            if(obj.labkeytosymbol && obj.labkeytosymbol.length && Array.isArray(obj.labkeytosymbol)){
              FinalObject["labkeytosymbol"] = obj.labkeytosymbol.toString()
            }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["lab_color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["lab_fancy_color"] = obj.lab_fancy_color.toString()
            }
            if(obj.lab_fancy_intensity && obj.lab_fancy_intensity.length && Array.isArray(obj.lab_fancy_intensity)){
              FinalObject["lab_fancy_intensity"] = obj.lab_fancy_intensity.toString()
            }
            if(obj.lab_fancy_overtone && obj.lab_fancy_overtone.length && Array.isArray(obj.lab_fancy_overtone)){
              FinalObject["lab_fancy_overtone"] = obj.lab_fancy_overtone.toString()
            }
          }
        }
        console.log(FinalObject,"FinalObject")
        console.log(customerapi,"customerapi")
        const OverlapValidation = (rules,newrule) => {
          let finaloverlap = true
          let validationarray = []
          for(let i = 0; i < rules.length; i++){
              let overlapsets = {}
              for(let key in newrule){
                  if(newrule[key]){
                  newrule[key] = newrule[key].toString()    
                   let newrulekey = newrule[key].split(',')
                  for(let j = 0; j < newrulekey.length; j++){
                      if(rules[i][key]){
                      rules[i][key] = rules[i][key].toString()    
                      let checkincludes = rules[i][key].includes(newrulekey[j])
                      overlapsets[`${key}${[j]}`] = checkincludes   
                      }
                  }   
                  }
              }
              validationarray.push(overlapsets)
          }
          let finalvalidationarray = []
          for(let k = 0 ; k < validationarray.length; k++){
              let currentoverlap = true
              for(let keys in newrule){
                  let regex = new RegExp(keys)
                  let keysets = Object.keys(validationarray[k]).filter(key => regex.test(key))
                  let checkoverlap = []
                  for(let l = 0; l < keysets.length; l++){
                      checkoverlap.push(validationarray[k][keysets[l]])
                  }
                  let checker = arr => arr.every(v => v === false);
                  if(checker(checkoverlap)){
                      currentoverlap = false
                  }
              }
              finalvalidationarray.push(currentoverlap)
          }
          let checkerfinal = arr => arr.every(v => v === false);
          return !checkerfinal(finalvalidationarray)
      }
      function areObjectsOverlapping(obj1, obj2) {
        // Check if the objects have the same keys.
        const commonKeys = Object.keys(obj1).filter(key => Object.keys(obj2).includes(key));
        
         
        
         
        
         
        
        // Check if the values of the common keys are overlapping.
        for (const key of commonKeys) {
        if (Array.isArray(obj1[key])) {
        // If the value is an array, check if the arrays have any overlapping elements.
        if (!obj1[key].some(element => obj2[key].includes(element))) {
        return false;
        }
        } else if (obj1[key] !== obj2[key]) {
        // If the value is a number or a string, and the values are not equal, then the objects are not overlapping.
        // Check for overlapping min_carat and max_carat values.
        //Carat
        if(key === "min_carat" && parseFloat(obj2["min_carat"]) >= parseFloat(obj1["min_carat"]) && parseFloat(obj2["max_carat"]) <= parseFloat(obj1["max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["min_carat"]) < parseFloat(obj1["min_carat"]) && parseFloat(obj2["max_carat"]) > parseFloat(obj1["max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["min_carat"]) >= parseFloat(obj1["min_carat"]) && parseFloat(obj2["min_carat"]) <= parseFloat(obj1["max_carat"])){
           return true;
        }
        else if(parseFloat(obj2["max_carat"]) >= parseFloat(obj1["min_carat"]) && parseFloat(obj2["max_carat"]) <= parseFloat(obj1["max_carat"])){
           return true;
        } else if (key === "min_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        } else if (key === "max_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        }
        //$/CT
        else if(key === "min_dollarperct" && parseFloat(obj2["min_dollarperct"]) >= parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["max_dollarperct"]) <= parseFloat(obj1["max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["min_dollarperct"]) < parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["max_dollarperct"]) > parseFloat(obj1["max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["min_dollarperct"]) >= parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["min_dollarperct"]) <= parseFloat(obj1["max_dollarperct"])){
           return true;
        }
        else if(parseFloat(obj2["max_dollarperct"]) >= parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["max_dollarperct"]) <= parseFloat(obj1["max_dollarperct"])){
           return true;
        } else if (key === "min_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "max_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
         //Price
         else if(key === "total_price_from" && parseFloat(obj2["total_price_from"]) >= parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_to"]) <= parseFloat(obj1["total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["total_price_from"]) < parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_to"]) > parseFloat(obj1["total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["total_price_from"]) >= parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_from"]) <= parseFloat(obj1["total_price_to"])){
           return true;
        }
        else if(parseFloat(obj2["total_price_to"]) >= parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_to"]) <= parseFloat(obj1["total_price_to"])){
           return true;
        } else if (key === "total_price_from" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "total_price_to" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
          //Length
          else if(key === "minlength" && parseFloat(obj2["minlength"]) >= parseFloat(obj1["minlength"]) && parseFloat(obj2["maxlength"]) <= parseFloat(obj1["maxlength"])){
            return true;
          }
          else if(parseFloat(obj2["minlength"]) < parseFloat(obj1["minlength"]) && parseFloat(obj2["maxlength"]) > parseFloat(obj1["maxlength"])){
            return true;
          }
          else if(parseFloat(obj2["minlength"]) >= parseFloat(obj1["minlength"]) && parseFloat(obj2["minlength"]) <= parseFloat(obj1["maxlength"])){
             return true;
          }
          else if(parseFloat(obj2["maxlength"]) >= parseFloat(obj1["minlength"]) && parseFloat(obj2["maxlength"]) <= parseFloat(obj1["maxlength"])){
             return true;
          }else if (key === "minlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            } else if (key === "maxlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            }
            //Width
            else if(key === "minwidth" && parseFloat(obj2["minwidth"]) >= parseFloat(obj1["minwidth"]) && parseFloat(obj2["maxwidth"]) <= parseFloat(obj1["maxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["minwidth"]) < parseFloat(obj1["minwidth"]) && parseFloat(obj2["maxwidth"]) > parseFloat(obj1["maxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["minwidth"]) >= parseFloat(obj1["minwidth"]) && parseFloat(obj2["minwidth"]) <= parseFloat(obj1["maxwidth"])){
               return true;
            }
            else if(parseFloat(obj2["maxwidth"]) >= parseFloat(obj1["minwidth"]) && parseFloat(obj2["maxwidth"]) <= parseFloat(obj1["maxwidth"])){
               return true;
            } else if (key === "minwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "maxwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              }
             //Height
             else if(key === "minheight" && parseFloat(obj2["minheight"]) >= parseFloat(obj1["minheight"]) && parseFloat(obj2["maxheight"]) <= parseFloat(obj1["maxheight"])){
              return true;
            }
            else if(parseFloat(obj2["minheight"]) < parseFloat(obj1["minheight"]) && parseFloat(obj2["maxheight"]) > parseFloat(obj1["maxheight"])){
              return true;
            }
            else if(parseFloat(obj2["minheight"]) >= parseFloat(obj1["minheight"]) && parseFloat(obj2["minheight"]) <= parseFloat(obj1["maxheight"])){
               return true;
            }
            else if(parseFloat(obj2["maxheight"]) >= parseFloat(obj1["minheight"]) && parseFloat(obj2["maxheight"]) <= parseFloat(obj1["maxheight"])){
               return true;
            }  else if (key === "minheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "maxheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } 
              //Table
              else if(key === "tablemin" && parseFloat(obj2["tablemin"]) >= parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemax"]) <= parseFloat(obj1["tablemax"])){
                return true;
              }
              else if(parseFloat(obj2["tablemin"]) < parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemax"]) > parseFloat(obj1["tablemax"])){
                return true;
              }
              else if(parseFloat(obj2["tablemin"]) >= parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemin"]) <= parseFloat(obj1["tablemax"])){
                 return true;
              }
              else if(parseFloat(obj2["tablemax"]) >= parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemax"]) <= parseFloat(obj1["tablemax"])){
                 return true;
              }  else if (key === "tablemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                } else if (key === "tablemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                }
                //Depth
                else if(key === "depthmin" && parseFloat(obj2["depthmin"]) >= parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmax"]) <= parseFloat(obj1["depthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["depthmin"]) < parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmax"]) > parseFloat(obj1["depthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["depthmin"]) >= parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmin"]) <= parseFloat(obj1["depthmax"])){
                   return true;
                }
                else if(parseFloat(obj2["depthmax"]) >= parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmax"]) <= parseFloat(obj1["depthmax"])){
                   return true;
                } else if (key === "depthmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  } else if (key === "depthmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  }
                  //Ratio
                  else if(key === "ratiomin" && parseFloat(obj2["ratiomin"]) >= parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomax"]) <= parseFloat(obj1["ratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["ratiomin"]) < parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomax"]) > parseFloat(obj1["ratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["ratiomin"]) >= parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomin"]) <= parseFloat(obj1["ratiomax"])){
                     return true;
                  }
                  else if(parseFloat(obj2["ratiomax"]) >= parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomax"]) <= parseFloat(obj1["ratiomax"])){
                     return true;
                  }else if (key === "ratiomin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    } else if (key === "ratiomax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    }
                    //Cr Height
                    else if(key === "crheightmin" && parseFloat(obj2["crheightmin"]) >= parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmax"]) <= parseFloat(obj1["crheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["crheightmin"]) < parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmax"]) > parseFloat(obj1["crheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["crheightmin"]) >= parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmin"]) <= parseFloat(obj1["crheightmax"])){
                       return true;
                    }
                    else if(parseFloat(obj2["crheightmax"]) >= parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmax"]) <= parseFloat(obj1["crheightmax"])){
                       return true;
                    } else if (key === "crheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      } else if (key === "crheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      }
                      //Cr Angle
                      else if(key === "cranglemin" && parseFloat(obj2["cranglemin"]) >= parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemax"]) <= parseFloat(obj1["cranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["cranglemin"]) < parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemax"]) > parseFloat(obj1["cranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["cranglemin"]) >= parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemin"]) <= parseFloat(obj1["cranglemax"])){
                         return true;
                      }
                      else if(parseFloat(obj2["cranglemax"]) >= parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemax"]) <= parseFloat(obj1["cranglemax"])){
                         return true;
                      } else if (key === "cranglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        } else if (key === "cranglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        }
                        //Pav Height
                        else if(key === "pavheightmin" && parseFloat(obj2["pavheightmin"]) >= parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmax"]) <= parseFloat(obj1["pavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["pavheightmin"]) < parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmax"]) > parseFloat(obj1["pavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["pavheightmin"]) >= parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmin"]) <= parseFloat(obj1["pavheightmax"])){
                           return true;
                        }
                        else if(parseFloat(obj2["pavheightmax"]) >= parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmax"]) <= parseFloat(obj1["pavheightmax"])){
                           return true;
                        }  else if (key === "pavheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          } else if (key === "pavheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          }
                          //Pav Angle
                          else if(key === "pavanglemin" && parseFloat(obj2["pavanglemin"]) >= parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemax"]) <= parseFloat(obj1["pavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["pavanglemin"]) < parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemax"]) > parseFloat(obj1["pavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["pavanglemin"]) >= parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemin"]) <= parseFloat(obj1["pavanglemax"])){
                             return true;
                          }
                          else if(parseFloat(obj2["pavanglemax"]) >= parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemax"]) <= parseFloat(obj1["pavanglemax"])){
                             return true;
                          } else if (key === "pavanglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            } else if (key === "pavanglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            }
        else {
        return false;
        }
        }
        }
        
         
        
         
        
         
        
        // If all of the common keys have overlapping values, then the objects are overlapping.
        return true;
        }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = areObjectsOverlapping(customerexisting,customercurrent)
          console.log(checkoverlap,"checkoverlap")
          console.log(customerexisting,"customerexisting")
          console.log(customercurrent,"customercurrent")
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  if(values.naturalfancydiamond){
    // customerapi = customerapi.filter(val => val.naturalfancydiamond && val.naturalfancydiamond.toString() === values.naturalfancydiamond.toString())
    diamondparamsvalidation = Yup.object().shape({
      [diamondshape.name]: Yup.array().min(1,`${diamondshape.requiredErrorMsg}`).required(`${diamondshape.requiredErrorMsg}`),
      [diamondfancy_color.name]: Yup.array().min(1,`${diamondfancy_color.requiredErrorMsg}`).required(`${diamondfancy_color.requiredErrorMsg}`),
    [diamondfancy_intensity.name]: Yup.array().min(1,`${diamondfancy_intensity.requiredErrorMsg}`).required(`${diamondfancy_intensity.requiredErrorMsg}`),
    [diamondfancy_overtone.name]: Yup.array().min(1,`${diamondfancy_overtone.requiredErrorMsg}`).required(`${diamondfancy_overtone.requiredErrorMsg}`),
      [diamondclarity.name]: Yup.array().min(1,`${diamondclarity.requiredErrorMsg}`).required(`${diamondclarity.requiredErrorMsg}`),
      // [cuts.name]: Yup.array().min(1,`${cuts.requiredErrorMsg}`).required(`${cuts.requiredErrorMsg}`),
      [carat_from.name]: Yup.string().required(`${carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
    [carat_to.name]: Yup.string().required(`${carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
    }).test(
      'overlap',
      null,
      (obj) => {
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.cuts && obj.cuts.length && Array.isArray(obj.cuts)){
              FinalObject["cut"] = obj.cuts.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(obj.symmetries && obj.symmetries.length && Array.isArray(obj.symmetries)){
              FinalObject["symmetry"] = obj.symmetries.toString()
            }
            if(obj.diamondflour && obj.diamondflour.length && Array.isArray(obj.diamondflour)){
              FinalObject["fluorescence"] = obj.diamondflour.toString()
            }
            if(obj.polishes && obj.polishes.length && Array.isArray(obj.polishes)){
              FinalObject["polish"] = obj.polishes.toString()
            }
            if(obj.milky && obj.milky.length && Array.isArray(obj.milky)){
              FinalObject["milky"] = obj.milky.toString()
            }
            if(obj.eyeclean && obj.eyeclean.length && Array.isArray(obj.eyeclean)){
              FinalObject["eyeclean"] = obj.eyeclean.toString()
            }
            if(obj.diamondshade && obj.diamondshade.length && Array.isArray(obj.diamondshade)){
              FinalObject["shade"] = obj.diamondshade.toString()
            }
            if(obj.price_from){
              FinalObject["min_dollarperct"] = parseFloat(obj.price_from)
            }
            if(obj.price_to){
              FinalObject["max_dollarperct"] = parseFloat(obj.price_to)
            }
            if(obj.total_price_from){
              FinalObject["total_price_from"] = parseFloat(obj.total_price_from)
            }
            if(obj.total_price_to){
              FinalObject["total_price_to"] = parseFloat(obj.total_price_to)
            }
            if(obj.media && obj.media.length && Array.isArray(obj.media)){
              FinalObject["media"] = obj.media.toString()
            }
            if(obj.measurementlengthfrom){
              FinalObject["minlength"] = parseFloat(obj.measurementlengthfrom)
            }
            if(obj.measurementlengthto){
            FinalObject["maxlength"] = parseFloat(obj.measurementlengthto)              
            }
            if(obj.measurementwidthfrom){
            FinalObject["minwidth"] = parseFloat(obj.measurementwidthfrom)
            }
            if(obj.measurementwidthto){
              FinalObject["maxwidth"] = parseFloat(obj.measurementwidthto)
            }
            if(obj.measurementheightfrom){
              FinalObject["minheight"] = parseFloat(obj.measurementheightfrom)
            }
            if(obj.measurementheightto){
              FinalObject["maxheight"] = parseFloat(obj.measurementheightto)
            }
            if(obj.tablefrom){
              FinalObject["tablemin"] = parseFloat(obj.tablefrom)
            }
            if(obj.tableto){
              FinalObject["tablemax"] = parseFloat(obj.tableto)
            }
            if(obj.depthfrom){
              FinalObject["depthmin"] = parseFloat(obj.depthfrom)
            }
            if(obj.depthto){
              FinalObject["depthmax"] = parseFloat(obj.depthto)
            }
            if(obj.ratiofrom){
              FinalObject["ratiomin"] = parseFloat(obj.ratiofrom)
            }
            if(obj.ratioto){
              FinalObject["ratiomax"] = parseFloat(obj.ratioto)
            }
            if(obj.crownheightfrom){
              FinalObject["crheightmin"] = parseFloat(obj.crownheightfrom)
            }
            if(obj.crownheightto){
              FinalObject["crheightmax"] = parseFloat(obj.crownheightto)
            }
            if(obj.crownanglefrom){
              FinalObject["cranglemin"] = parseFloat(obj.crownanglefrom)
            }
            if(obj.crownangleto){
              FinalObject["cranglemax"] = parseFloat(obj.crownangleto)
            }
            if(obj.pavheightfrom){
              FinalObject["pavheightmin"] = parseFloat(obj.pavheightfrom)
            }
            if(obj.pavheightto){
              FinalObject["pavheightmax"] = parseFloat(obj.pavheightto)
            }
            if(obj.pavanglefrom){
              FinalObject["pavanglemin"] = parseFloat(obj.pavanglefrom)
            }
            if(obj.pavangleto){
              FinalObject["pavanglemax"] = parseFloat(obj.pavangleto)
            }
            if(obj.brand && obj.brand.length && Array.isArray(obj.brand)){
              FinalObject["brand"] = obj.brand.toString()
            }
            if(obj.origin && obj.origin.length && Array.isArray(obj.origin)){
              FinalObject["origin"] = obj.origin.toString()
            }
            if(obj.treatment && obj.treatment.length && Array.isArray(obj.treatment)){
              FinalObject["treatment"] = obj.treatment.toString()
            }
            if(obj.keytosymbol && obj.keytosymbol.length && Array.isArray(obj.keytosymbol)){
              FinalObject["keytosymbol"] = obj.keytosymbol.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["diamondfancy_color"] = obj.diamondfancy_color.toString()
              }
              if(obj.diamondfancy_intensity && obj.diamondfancy_intensity.length && Array.isArray(obj.diamondfancy_intensity)){
                FinalObject["diamondfancy_intensity"] = obj.diamondfancy_intensity.toString()
              }
              if(obj.diamondfancy_overtone && obj.diamondfancy_overtone.length && Array.isArray(obj.diamondfancy_overtone)){
                FinalObject["diamondfancy_overtone"] = obj.diamondfancy_overtone.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["lab_shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_cuts && obj.lab_cuts.length && Array.isArray(obj.lab_cuts)){
            FinalObject["lab_cut"] = obj.lab_cuts.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["lab_clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["lab_min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["lab_max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab_lab"] = obj.lab_lab.toString()
          }
          if(obj.lab_symmetries && obj.lab_symmetries.length && Array.isArray(obj.lab_symmetries)){
            FinalObject["lab_symmetry"] = obj.lab_symmetries.toString()
          }
          if(obj.lab_flourselect && obj.lab_flourselect.length && Array.isArray(obj.lab_flourselect)){
            FinalObject["lab_fluorescence"] = obj.lab_flourselect.toString()
          }
          if(obj.lab_polishes && obj.lab_polishes.length && Array.isArray(obj.lab_polishes)){
            FinalObject["lab_polish"] = obj.lab_polishes.toString()
          }
          if(obj.lab_milky && obj.lab_milky.length && Array.isArray(obj.lab_milky)){
            FinalObject["lab_milky"] = obj.lab_milky.toString()
          }
          if(obj.lab_eyeclean && obj.lab_eyeclean.length && Array.isArray(obj.lab_eyeclean)){
            FinalObject["lab_eyeclean"] = obj.lab_eyeclean.toString()
          }
          if(obj.lab_shadeselect && obj.lab_shadeselect.length && Array.isArray(obj.lab_shadeselect)){
            FinalObject["lab_shade"] = obj.lab_shadeselect.toString()
          }
          if(obj.lab_min_dollarperct){
            FinalObject["lab_min_dollarperct"] = parseFloat(obj.lab_min_dollarperct)
          }
          if(obj.lab_max_dollarperct){
            FinalObject["lab_max_dollarperct"] = parseFloat(obj.lab_max_dollarperct)
          }
          if(obj.lab_total_price_from){
            FinalObject["lab_total_price_from"] = parseFloat(obj.lab_total_price_from)
          }
          if(obj.lab_total_price_to){
            FinalObject["lab_total_price_to"] = parseFloat(obj.lab_total_price_to)
          }
          if(obj.lab_all_media && obj.lab_all_media.length && Array.isArray(obj.lab_all_media)){
            FinalObject["lab_media"] = obj.lab_all_media.toString()
          }
          if(obj.labmeasurementlengthfrom){
            FinalObject["labminlength"] = parseFloat(obj.labmeasurementlengthfrom)
          }
          if(obj.labmeasurementlengthto){
            FinalObject["labmaxlength"] = parseFloat(obj.labmeasurementlengthto)
          }
          if(obj.labmeasurementwidthfrom){
            FinalObject["labminwidth"] = parseFloat(obj.labmeasurementwidthfrom)
          }
          if(obj.labmeasurementwidthto){
            FinalObject["labmaxwidth"] = parseFloat(obj.labmeasurementwidthto)
          }
          if(obj.labmeasurementheightfrom){
            FinalObject["labminheight"] = parseFloat(obj.labmeasurementheightfrom)
          }
          if(obj.labmeasurementheightto){
            FinalObject["labmaxheight"] = parseFloat(obj.labmeasurementheightto)
          }
          if(obj.labtablefrom){
            FinalObject["labtablemin"] = parseFloat(obj.labtablefrom)
          }
          if(obj.labtableto){
            FinalObject["labtablemax"] = parseFloat(obj.labtableto)
          }
          if(obj.labdepthfrom){
            FinalObject["labdepthmin"] = parseFloat(obj.labdepthfrom)
          }
          if(obj.labdepthto){
            FinalObject["labdepthmax"] = parseFloat(obj.labdepthto)
          }
          if(obj.labratiofrom){
            FinalObject["labratiomin"] = parseFloat(obj.labratiofrom)
          }
          if(obj.labratioto){
            FinalObject["labratiomax"] = parseFloat(obj.labratioto)
          }
          if(obj.labcrownheightfrom){
            FinalObject["labcrheightmin"] = parseFloat(obj.labcrownheightfrom)
          }
          if(obj.labcrownheightto){
            FinalObject["labcrheightmax"] = parseFloat(obj.labcrownheightto)
          }
          if(obj.labcrownanglefrom){
            FinalObject["labcranglemin"] = parseFloat(obj.labcrownanglefrom)
          }
          if(obj.labcrownangleto){
            FinalObject["labcranglemax"] = parseFloat(obj.labcrownangleto)
          }
          if(obj.labpavheightfrom){
            FinalObject["labpavheightmin"] = parseFloat(obj.labpavheightfrom)
          }
          if(obj.labpavheightto){
            FinalObject["labpavheightmax"] = parseFloat(obj.labpavheightto)
          }
          if(obj.labpavanglefrom){
            FinalObject["labpavanglemin"] = parseFloat(obj.labpavanglefrom)
          }
          if(obj.labpavangleto){
            FinalObject["labpavanglemax"] = parseFloat(obj.labpavangleto)
          }
            // FinalObject["brand"] = obj.brand
            if(obj.laborigin && obj.laborigin.length && Array.isArray(obj.laborigin)){
              FinalObject["laborigin"] = obj.laborigin.toString()              
            }
            if(obj.labtreatment && obj.labtreatment.length && Array.isArray(obj.labtreatment)){
              FinalObject["labtreatment"] = obj.labtreatment.toString()
            }
            if(obj.labkeytosymbol && obj.labkeytosymbol.length && Array.isArray(obj.labkeytosymbol)){
              FinalObject["labkeytosymbol"] = obj.labkeytosymbol.toString()
            }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["lab_color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["lab_fancy_color"] = obj.lab_fancy_color.toString()
            }
            if(obj.lab_fancy_intensity && obj.lab_fancy_intensity.length && Array.isArray(obj.lab_fancy_intensity)){
              FinalObject["lab_fancy_intensity"] = obj.lab_fancy_intensity.toString()
            }
            if(obj.lab_fancy_overtone && obj.lab_fancy_overtone.length && Array.isArray(obj.lab_fancy_overtone)){
              FinalObject["lab_fancy_overtone"] = obj.lab_fancy_overtone.toString()
            }
          }
        }
        console.log(FinalObject,"FinalObject")
        console.log(customerapi,"customerapi")
        const OverlapValidation = (rules,newrule) => {
          let finaloverlap = true
          let validationarray = []
          for(let i = 0; i < rules.length; i++){
              let overlapsets = {}
              for(let key in newrule){
                  if(newrule[key]){
                  newrule[key] = newrule[key].toString()    
                   let newrulekey = newrule[key].split(',')
                  for(let j = 0; j < newrulekey.length; j++){
                      if(rules[i][key]){
                      rules[i][key] = rules[i][key].toString()    
                      let checkincludes = rules[i][key].includes(newrulekey[j])
                      overlapsets[`${key}${[j]}`] = checkincludes   
                      }
                  }   
                  }
              }
              validationarray.push(overlapsets)
          }
          let finalvalidationarray = []
          for(let k = 0 ; k < validationarray.length; k++){
              let currentoverlap = true
              for(let keys in newrule){
                  let regex = new RegExp(keys)
                  let keysets = Object.keys(validationarray[k]).filter(key => regex.test(key))
                  let checkoverlap = []
                  for(let l = 0; l < keysets.length; l++){
                      checkoverlap.push(validationarray[k][keysets[l]])
                  }
                  let checker = arr => arr.every(v => v === false);
                  if(checker(checkoverlap)){
                      currentoverlap = false
                  }
              }
              finalvalidationarray.push(currentoverlap)
          }
          let checkerfinal = arr => arr.every(v => v === false);
          return !checkerfinal(finalvalidationarray)
      }
      function areObjectsOverlapping(obj1, obj2) {
        // Check if the objects have the same keys.
        const commonKeys = Object.keys(obj1).filter(key => Object.keys(obj2).includes(key));
        
         
        
         
        
         
        
        // Check if the values of the common keys are overlapping.
        for (const key of commonKeys) {
        if (Array.isArray(obj1[key])) {
        // If the value is an array, check if the arrays have any overlapping elements.
        if (!obj1[key].some(element => obj2[key].includes(element))) {
        return false;
        }
        } else if (obj1[key] !== obj2[key]) {
        // If the value is a number or a string, and the values are not equal, then the objects are not overlapping.
        // Check for overlapping min_carat and max_carat values.
        //Carat
        if(key === "min_carat" && parseFloat(obj2["min_carat"]) >= parseFloat(obj1["min_carat"]) && parseFloat(obj2["max_carat"]) <= parseFloat(obj1["max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["min_carat"]) < parseFloat(obj1["min_carat"]) && parseFloat(obj2["max_carat"]) > parseFloat(obj1["max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["min_carat"]) >= parseFloat(obj1["min_carat"]) && parseFloat(obj2["min_carat"]) <= parseFloat(obj1["max_carat"])){
           return true;
        }
        else if(parseFloat(obj2["max_carat"]) >= parseFloat(obj1["min_carat"]) && parseFloat(obj2["max_carat"]) <= parseFloat(obj1["max_carat"])){
           return true;
        } else if (key === "min_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        } else if (key === "max_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        }
        //$/CT
        else if(key === "min_dollarperct" && parseFloat(obj2["min_dollarperct"]) >= parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["max_dollarperct"]) <= parseFloat(obj1["max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["min_dollarperct"]) < parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["max_dollarperct"]) > parseFloat(obj1["max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["min_dollarperct"]) >= parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["min_dollarperct"]) <= parseFloat(obj1["max_dollarperct"])){
           return true;
        }
        else if(parseFloat(obj2["max_dollarperct"]) >= parseFloat(obj1["min_dollarperct"]) && parseFloat(obj2["max_dollarperct"]) <= parseFloat(obj1["max_dollarperct"])){
           return true;
        } else if (key === "min_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "max_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
         //Price
         else if(key === "total_price_from" && parseFloat(obj2["total_price_from"]) >= parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_to"]) <= parseFloat(obj1["total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["total_price_from"]) < parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_to"]) > parseFloat(obj1["total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["total_price_from"]) >= parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_from"]) <= parseFloat(obj1["total_price_to"])){
           return true;
        }
        else if(parseFloat(obj2["total_price_to"]) >= parseFloat(obj1["total_price_from"]) && parseFloat(obj2["total_price_to"]) <= parseFloat(obj1["total_price_to"])){
           return true;
        } else if (key === "total_price_from" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "total_price_to" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
          //Length
          else if(key === "minlength" && parseFloat(obj2["minlength"]) >= parseFloat(obj1["minlength"]) && parseFloat(obj2["maxlength"]) <= parseFloat(obj1["maxlength"])){
            return true;
          }
          else if(parseFloat(obj2["minlength"]) < parseFloat(obj1["minlength"]) && parseFloat(obj2["maxlength"]) > parseFloat(obj1["maxlength"])){
            return true;
          }
          else if(parseFloat(obj2["minlength"]) >= parseFloat(obj1["minlength"]) && parseFloat(obj2["minlength"]) <= parseFloat(obj1["maxlength"])){
             return true;
          }
          else if(parseFloat(obj2["maxlength"]) >= parseFloat(obj1["minlength"]) && parseFloat(obj2["maxlength"]) <= parseFloat(obj1["maxlength"])){
             return true;
          }else if (key === "minlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            } else if (key === "maxlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            }
            //Width
            else if(key === "minwidth" && parseFloat(obj2["minwidth"]) >= parseFloat(obj1["minwidth"]) && parseFloat(obj2["maxwidth"]) <= parseFloat(obj1["maxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["minwidth"]) < parseFloat(obj1["minwidth"]) && parseFloat(obj2["maxwidth"]) > parseFloat(obj1["maxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["minwidth"]) >= parseFloat(obj1["minwidth"]) && parseFloat(obj2["minwidth"]) <= parseFloat(obj1["maxwidth"])){
               return true;
            }
            else if(parseFloat(obj2["maxwidth"]) >= parseFloat(obj1["minwidth"]) && parseFloat(obj2["maxwidth"]) <= parseFloat(obj1["maxwidth"])){
               return true;
            } else if (key === "minwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "maxwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              }
             //Height
             else if(key === "minheight" && parseFloat(obj2["minheight"]) >= parseFloat(obj1["minheight"]) && parseFloat(obj2["maxheight"]) <= parseFloat(obj1["maxheight"])){
              return true;
            }
            else if(parseFloat(obj2["minheight"]) < parseFloat(obj1["minheight"]) && parseFloat(obj2["maxheight"]) > parseFloat(obj1["maxheight"])){
              return true;
            }
            else if(parseFloat(obj2["minheight"]) >= parseFloat(obj1["minheight"]) && parseFloat(obj2["minheight"]) <= parseFloat(obj1["maxheight"])){
               return true;
            }
            else if(parseFloat(obj2["maxheight"]) >= parseFloat(obj1["minheight"]) && parseFloat(obj2["maxheight"]) <= parseFloat(obj1["maxheight"])){
               return true;
            }  else if (key === "minheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "maxheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } 
              //Table
              else if(key === "tablemin" && parseFloat(obj2["tablemin"]) >= parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemax"]) <= parseFloat(obj1["tablemax"])){
                return true;
              }
              else if(parseFloat(obj2["tablemin"]) < parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemax"]) > parseFloat(obj1["tablemax"])){
                return true;
              }
              else if(parseFloat(obj2["tablemin"]) >= parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemin"]) <= parseFloat(obj1["tablemax"])){
                 return true;
              }
              else if(parseFloat(obj2["tablemax"]) >= parseFloat(obj1["tablemin"]) && parseFloat(obj2["tablemax"]) <= parseFloat(obj1["tablemax"])){
                 return true;
              }  else if (key === "tablemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                } else if (key === "tablemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                }
                //Depth
                else if(key === "depthmin" && parseFloat(obj2["depthmin"]) >= parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmax"]) <= parseFloat(obj1["depthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["depthmin"]) < parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmax"]) > parseFloat(obj1["depthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["depthmin"]) >= parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmin"]) <= parseFloat(obj1["depthmax"])){
                   return true;
                }
                else if(parseFloat(obj2["depthmax"]) >= parseFloat(obj1["depthmin"]) && parseFloat(obj2["depthmax"]) <= parseFloat(obj1["depthmax"])){
                   return true;
                } else if (key === "depthmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  } else if (key === "depthmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  }
                  //Ratio
                  else if(key === "ratiomin" && parseFloat(obj2["ratiomin"]) >= parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomax"]) <= parseFloat(obj1["ratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["ratiomin"]) < parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomax"]) > parseFloat(obj1["ratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["ratiomin"]) >= parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomin"]) <= parseFloat(obj1["ratiomax"])){
                     return true;
                  }
                  else if(parseFloat(obj2["ratiomax"]) >= parseFloat(obj1["ratiomin"]) && parseFloat(obj2["ratiomax"]) <= parseFloat(obj1["ratiomax"])){
                     return true;
                  }else if (key === "ratiomin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    } else if (key === "ratiomax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    }
                    //Cr Height
                    else if(key === "crheightmin" && parseFloat(obj2["crheightmin"]) >= parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmax"]) <= parseFloat(obj1["crheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["crheightmin"]) < parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmax"]) > parseFloat(obj1["crheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["crheightmin"]) >= parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmin"]) <= parseFloat(obj1["crheightmax"])){
                       return true;
                    }
                    else if(parseFloat(obj2["crheightmax"]) >= parseFloat(obj1["crheightmin"]) && parseFloat(obj2["crheightmax"]) <= parseFloat(obj1["crheightmax"])){
                       return true;
                    } else if (key === "crheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      } else if (key === "crheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      }
                      //Cr Angle
                      else if(key === "cranglemin" && parseFloat(obj2["cranglemin"]) >= parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemax"]) <= parseFloat(obj1["cranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["cranglemin"]) < parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemax"]) > parseFloat(obj1["cranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["cranglemin"]) >= parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemin"]) <= parseFloat(obj1["cranglemax"])){
                         return true;
                      }
                      else if(parseFloat(obj2["cranglemax"]) >= parseFloat(obj1["cranglemin"]) && parseFloat(obj2["cranglemax"]) <= parseFloat(obj1["cranglemax"])){
                         return true;
                      } else if (key === "cranglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        } else if (key === "cranglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        }
                        //Pav Height
                        else if(key === "pavheightmin" && parseFloat(obj2["pavheightmin"]) >= parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmax"]) <= parseFloat(obj1["pavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["pavheightmin"]) < parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmax"]) > parseFloat(obj1["pavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["pavheightmin"]) >= parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmin"]) <= parseFloat(obj1["pavheightmax"])){
                           return true;
                        }
                        else if(parseFloat(obj2["pavheightmax"]) >= parseFloat(obj1["pavheightmin"]) && parseFloat(obj2["pavheightmax"]) <= parseFloat(obj1["pavheightmax"])){
                           return true;
                        }  else if (key === "pavheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          } else if (key === "pavheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          }
                          //Pav Angle
                          else if(key === "pavanglemin" && parseFloat(obj2["pavanglemin"]) >= parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemax"]) <= parseFloat(obj1["pavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["pavanglemin"]) < parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemax"]) > parseFloat(obj1["pavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["pavanglemin"]) >= parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemin"]) <= parseFloat(obj1["pavanglemax"])){
                             return true;
                          }
                          else if(parseFloat(obj2["pavanglemax"]) >= parseFloat(obj1["pavanglemin"]) && parseFloat(obj2["pavanglemax"]) <= parseFloat(obj1["pavanglemax"])){
                             return true;
                          } else if (key === "pavanglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            } else if (key === "pavanglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            }
        else {
        return false;
        }
        }
        }
        
         
        
         
        
         
        
        // If all of the common keys have overlapping values, then the objects are overlapping.
        return true;
        }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = areObjectsOverlapping(customerexisting,customercurrent)
          console.log(checkoverlap,"checkoverlap")
          console.log(customerexisting,"customerexisting")
          console.log(customercurrent,"customercurrent")
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  if(values.lgwhitediam){
    // customerapi = customerapi.filter(val => val.labdiamond && val.labdiamond.toString() === values.lgwhitediam.toString())
    diamondparamsvalidation = Yup.object().shape({
      [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
      [lab_color.name]: Yup.array().min(1,`${lab_color.requiredErrorMsg}`).required(`${lab_color.requiredErrorMsg}`),
      [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
      // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
      [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
    [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
    }).test(
      'overlap',
      null,
      (obj) => {
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.cuts && obj.cuts.length && Array.isArray(obj.cuts)){
              FinalObject["cut"] = obj.cuts.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(obj.symmetries && obj.symmetries.length && Array.isArray(obj.symmetries)){
              FinalObject["symmetry"] = obj.symmetries.toString()
            }
            if(obj.diamondflour && obj.diamondflour.length && Array.isArray(obj.diamondflour)){
              FinalObject["fluorescence"] = obj.diamondflour.toString()
            }
            if(obj.polishes && obj.polishes.length && Array.isArray(obj.polishes)){
              FinalObject["polish"] = obj.polishes.toString()
            }
            if(obj.milky && obj.milky.length && Array.isArray(obj.milky)){
              FinalObject["milky"] = obj.milky.toString()
            }
            if(obj.eyeclean && obj.eyeclean.length && Array.isArray(obj.eyeclean)){
              FinalObject["eyeclean"] = obj.eyeclean.toString()
            }
            if(obj.diamondshade && obj.diamondshade.length && Array.isArray(obj.diamondshade)){
              FinalObject["shade"] = obj.diamondshade.toString()
            }
            if(obj.price_from){
              FinalObject["min_dollarperct"] = parseFloat(obj.price_from)
            }
            if(obj.price_to){
              FinalObject["max_dollarperct"] = parseFloat(obj.price_to)
            }
            if(obj.total_price_from){
              FinalObject["total_price_from"] = parseFloat(obj.total_price_from)
            }
            if(obj.total_price_to){
              FinalObject["total_price_to"] = parseFloat(obj.total_price_to)
            }
            if(obj.media && obj.media.length && Array.isArray(obj.media)){
              FinalObject["media"] = obj.media.toString()
            }
            if(obj.measurementlengthfrom){
              FinalObject["minlength"] = parseFloat(obj.measurementlengthfrom)
            }
            if(obj.measurementlengthto){
            FinalObject["maxlength"] = parseFloat(obj.measurementlengthto)              
            }
            if(obj.measurementwidthfrom){
            FinalObject["minwidth"] = parseFloat(obj.measurementwidthfrom)
            }
            if(obj.measurementwidthto){
              FinalObject["maxwidth"] = parseFloat(obj.measurementwidthto)
            }
            if(obj.measurementheightfrom){
              FinalObject["minheight"] = parseFloat(obj.measurementheightfrom)
            }
            if(obj.measurementheightto){
              FinalObject["maxheight"] = parseFloat(obj.measurementheightto)
            }
            if(obj.tablefrom){
              FinalObject["tablemin"] = parseFloat(obj.tablefrom)
            }
            if(obj.tableto){
              FinalObject["tablemax"] = parseFloat(obj.tableto)
            }
            if(obj.depthfrom){
              FinalObject["depthmin"] = parseFloat(obj.depthfrom)
            }
            if(obj.depthto){
              FinalObject["depthmax"] = parseFloat(obj.depthto)
            }
            if(obj.ratiofrom){
              FinalObject["ratiomin"] = parseFloat(obj.ratiofrom)
            }
            if(obj.ratioto){
              FinalObject["ratiomax"] = parseFloat(obj.ratioto)
            }
            if(obj.crownheightfrom){
              FinalObject["crheightmin"] = parseFloat(obj.crownheightfrom)
            }
            if(obj.crownheightto){
              FinalObject["crheightmax"] = parseFloat(obj.crownheightto)
            }
            if(obj.crownanglefrom){
              FinalObject["cranglemin"] = parseFloat(obj.crownanglefrom)
            }
            if(obj.crownangleto){
              FinalObject["cranglemax"] = parseFloat(obj.crownangleto)
            }
            if(obj.pavheightfrom){
              FinalObject["pavheightmin"] = parseFloat(obj.pavheightfrom)
            }
            if(obj.pavheightto){
              FinalObject["pavheightmax"] = parseFloat(obj.pavheightto)
            }
            if(obj.pavanglefrom){
              FinalObject["pavanglemin"] = parseFloat(obj.pavanglefrom)
            }
            if(obj.pavangleto){
              FinalObject["pavanglemax"] = parseFloat(obj.pavangleto)
            }
            if(obj.brand && obj.brand.length && Array.isArray(obj.brand)){
              FinalObject["brand"] = obj.brand.toString()
            }
            if(obj.origin && obj.origin.length && Array.isArray(obj.origin)){
              FinalObject["origin"] = obj.origin.toString()
            }
            if(obj.treatment && obj.treatment.length && Array.isArray(obj.treatment)){
              FinalObject["treatment"] = obj.treatment.toString()
            }
            if(obj.keytosymbol && obj.keytosymbol.length && Array.isArray(obj.keytosymbol)){
              FinalObject["keytosymbol"] = obj.keytosymbol.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["diamondfancy_color"] = obj.diamondfancy_color.toString()
              }
              if(obj.diamondfancy_intensity && obj.diamondfancy_intensity.length && Array.isArray(obj.diamondfancy_intensity)){
                FinalObject["diamondfancy_intensity"] = obj.diamondfancy_intensity.toString()
              }
              if(obj.diamondfancy_overtone && obj.diamondfancy_overtone.length && Array.isArray(obj.diamondfancy_overtone)){
                FinalObject["diamondfancy_overtone"] = obj.diamondfancy_overtone.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["lab_shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_cuts && obj.lab_cuts.length && Array.isArray(obj.lab_cuts)){
            FinalObject["lab_cut"] = obj.lab_cuts.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["lab_clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["lab_min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["lab_max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab_lab"] = obj.lab_lab.toString()
          }
          if(obj.lab_symmetries && obj.lab_symmetries.length && Array.isArray(obj.lab_symmetries)){
            FinalObject["lab_symmetry"] = obj.lab_symmetries.toString()
          }
          if(obj.lab_flourselect && obj.lab_flourselect.length && Array.isArray(obj.lab_flourselect)){
            FinalObject["lab_fluorescence"] = obj.lab_flourselect.toString()
          }
          if(obj.lab_polishes && obj.lab_polishes.length && Array.isArray(obj.lab_polishes)){
            FinalObject["lab_polish"] = obj.lab_polishes.toString()
          }
          if(obj.lab_milky && obj.lab_milky.length && Array.isArray(obj.lab_milky)){
            FinalObject["lab_milky"] = obj.lab_milky.toString()
          }
          if(obj.lab_eyeclean && obj.lab_eyeclean.length && Array.isArray(obj.lab_eyeclean)){
            FinalObject["lab_eyeclean"] = obj.lab_eyeclean.toString()
          }
          if(obj.lab_shadeselect && obj.lab_shadeselect.length && Array.isArray(obj.lab_shadeselect)){
            FinalObject["lab_shade"] = obj.lab_shadeselect.toString()
          }
          if(obj.lab_min_dollarperct){
            FinalObject["lab_min_dollarperct"] = parseFloat(obj.lab_min_dollarperct)
          }
          if(obj.lab_max_dollarperct){
            FinalObject["lab_max_dollarperct"] = parseFloat(obj.lab_max_dollarperct)
          }
          if(obj.lab_total_price_from){
            FinalObject["lab_total_price_from"] = parseFloat(obj.lab_total_price_from)
          }
          if(obj.lab_total_price_to){
            FinalObject["lab_total_price_to"] = parseFloat(obj.lab_total_price_to)
          }
          if(obj.lab_all_media && obj.lab_all_media.length && Array.isArray(obj.lab_all_media)){
            FinalObject["lab_media"] = obj.lab_all_media.toString()
          }
          if(obj.labmeasurementlengthfrom){
            FinalObject["labminlength"] = parseFloat(obj.labmeasurementlengthfrom)
          }
          if(obj.labmeasurementlengthto){
            FinalObject["labmaxlength"] = parseFloat(obj.labmeasurementlengthto)
          }
          if(obj.labmeasurementwidthfrom){
            FinalObject["labminwidth"] = parseFloat(obj.labmeasurementwidthfrom)
          }
          if(obj.labmeasurementwidthto){
            FinalObject["labmaxwidth"] = parseFloat(obj.labmeasurementwidthto)
          }
          if(obj.labmeasurementheightfrom){
            FinalObject["labminheight"] = parseFloat(obj.labmeasurementheightfrom)
          }
          if(obj.labmeasurementheightto){
            FinalObject["labmaxheight"] = parseFloat(obj.labmeasurementheightto)
          }
          if(obj.labtablefrom){
            FinalObject["labtablemin"] = parseFloat(obj.labtablefrom)
          }
          if(obj.labtableto){
            FinalObject["labtablemax"] = parseFloat(obj.labtableto)
          }
          if(obj.labdepthfrom){
            FinalObject["labdepthmin"] = parseFloat(obj.labdepthfrom)
          }
          if(obj.labdepthto){
            FinalObject["labdepthmax"] = parseFloat(obj.labdepthto)
          }
          if(obj.labratiofrom){
            FinalObject["labratiomin"] = parseFloat(obj.labratiofrom)
          }
          if(obj.labratioto){
            FinalObject["labratiomax"] = parseFloat(obj.labratioto)
          }
          if(obj.labcrownheightfrom){
            FinalObject["labcrheightmin"] = parseFloat(obj.labcrownheightfrom)
          }
          if(obj.labcrownheightto){
            FinalObject["labcrheightmax"] = parseFloat(obj.labcrownheightto)
          }
          if(obj.labcrownanglefrom){
            FinalObject["labcranglemin"] = parseFloat(obj.labcrownanglefrom)
          }
          if(obj.labcrownangleto){
            FinalObject["labcranglemax"] = parseFloat(obj.labcrownangleto)
          }
          if(obj.labpavheightfrom){
            FinalObject["labpavheightmin"] = parseFloat(obj.labpavheightfrom)
          }
          if(obj.labpavheightto){
            FinalObject["labpavheightmax"] = parseFloat(obj.labpavheightto)
          }
          if(obj.labpavanglefrom){
            FinalObject["labpavanglemin"] = parseFloat(obj.labpavanglefrom)
          }
          if(obj.labpavangleto){
            FinalObject["labpavanglemax"] = parseFloat(obj.labpavangleto)
          }
            // FinalObject["brand"] = obj.brand
            if(obj.laborigin && obj.laborigin.length && Array.isArray(obj.laborigin)){
              FinalObject["laborigin"] = obj.laborigin.toString()              
            }
            if(obj.labtreatment && obj.labtreatment.length && Array.isArray(obj.labtreatment)){
              FinalObject["labtreatment"] = obj.labtreatment.toString()
            }
            if(obj.labkeytosymbol && obj.labkeytosymbol.length && Array.isArray(obj.labkeytosymbol)){
              FinalObject["labkeytosymbol"] = obj.labkeytosymbol.toString()
            }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["lab_color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["lab_fancy_color"] = obj.lab_fancy_color.toString()
            }
            if(obj.lab_fancy_intensity && obj.lab_fancy_intensity.length && Array.isArray(obj.lab_fancy_intensity)){
              FinalObject["lab_fancy_intensity"] = obj.lab_fancy_intensity.toString()
            }
            if(obj.lab_fancy_overtone && obj.lab_fancy_overtone.length && Array.isArray(obj.lab_fancy_overtone)){
              FinalObject["lab_fancy_overtone"] = obj.lab_fancy_overtone.toString()
            }
          }
        }
        console.log(FinalObject,"FinalObject")
        console.log(customerapi,"customerapi")
        const OverlapValidation = (rules,newrule) => {
          let finaloverlap = true
          let validationarray = []
          for(let i = 0; i < rules.length; i++){
              let overlapsets = {}
              for(let key in newrule){
                  if(newrule[key]){
                  newrule[key] = newrule[key].toString()    
                   let newrulekey = newrule[key].split(',')
                  for(let j = 0; j < newrulekey.length; j++){
                      if(rules[i][key]){
                      rules[i][key] = rules[i][key].toString()    
                      let checkincludes = rules[i][key].includes(newrulekey[j])
                      overlapsets[`${key}${[j]}`] = checkincludes   
                      }
                  }   
                  }
              }
              validationarray.push(overlapsets)
          }
          let finalvalidationarray = []
          for(let k = 0 ; k < validationarray.length; k++){
              let currentoverlap = true
              for(let keys in newrule){
                  let regex = new RegExp(keys)
                  let keysets = Object.keys(validationarray[k]).filter(key => regex.test(key))
                  let checkoverlap = []
                  for(let l = 0; l < keysets.length; l++){
                      checkoverlap.push(validationarray[k][keysets[l]])
                  }
                  let checker = arr => arr.every(v => v === false);
                  if(checker(checkoverlap)){
                      currentoverlap = false
                  }
              }
              finalvalidationarray.push(currentoverlap)
          }
          let checkerfinal = arr => arr.every(v => v === false);
          return !checkerfinal(finalvalidationarray)
      }
      function areObjectsOverlapping(obj1, obj2) {
        // Check if the objects have the same keys.
        const commonKeys = Object.keys(obj1).filter(key => Object.keys(obj2).includes(key));
        
         
        
         
        
         
        
        // Check if the values of the common keys are overlapping.
        for (const key of commonKeys) {
        if (Array.isArray(obj1[key])) {
        // If the value is an array, check if the arrays have any overlapping elements.
        if (!obj1[key].some(element => obj2[key].includes(element))) {
        return false;
        }
        } else if (obj1[key] !== obj2[key]) {
        // If the value is a number or a string, and the values are not equal, then the objects are not overlapping.
        // Check for overlapping min_carat and max_carat values.
        //Carat
        if(key === "lab_min_carat" && parseFloat(obj2["lab_min_carat"]) >= parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_max_carat"]) <= parseFloat(obj1["lab_max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_carat"]) < parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_max_carat"]) > parseFloat(obj1["lab_max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_carat"]) >= parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_min_carat"]) <= parseFloat(obj1["lab_max_carat"])){
           return true;
        }
        else if(parseFloat(obj2["lab_max_carat"]) >= parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_max_carat"]) <= parseFloat(obj1["lab_max_carat"])){
           return true;
        } else if (key === "lab_min_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        } else if (key === "lab_max_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        }
        //$/CT
        else if(key === "lab_min_dollarperct" && parseFloat(obj2["lab_min_dollarperct"]) >= parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_max_dollarperct"]) <= parseFloat(obj1["lab_max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_dollarperct"]) < parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_max_dollarperct"]) > parseFloat(obj1["lab_max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_dollarperct"]) >= parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_min_dollarperct"]) <= parseFloat(obj1["lab_max_dollarperct"])){
           return true;
        }
        else if(parseFloat(obj2["lab_max_dollarperct"]) >= parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_max_dollarperct"]) <= parseFloat(obj1["lab_max_dollarperct"])){
           return true;
        } else if (key === "lab_min_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "lab_max_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
         //Price
         else if(key === "lab_total_price_from" && parseFloat(obj2["lab_total_price_from"]) >= parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_to"]) <= parseFloat(obj1["lab_total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["lab_total_price_from"]) < parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_to"]) > parseFloat(obj1["lab_total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["lab_total_price_from"]) >= parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_from"]) <= parseFloat(obj1["lab_total_price_to"])){
           return true;
        }
        else if(parseFloat(obj2["lab_total_price_to"]) >= parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_to"]) <= parseFloat(obj1["lab_total_price_to"])){
           return true;
        } else if (key === "lab_total_price_from" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "lab_total_price_to" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
          //Length
          else if(key === "labminlength" && parseFloat(obj2["labminlength"]) >= parseFloat(obj1["labminlength"]) && parseFloat(obj2["labmaxlength"]) <= parseFloat(obj1["labmaxlength"])){
            return true;
          }
          else if(parseFloat(obj2["labminlength"]) < parseFloat(obj1["labminlength"]) && parseFloat(obj2["labmaxlength"]) > parseFloat(obj1["labmaxlength"])){
            return true;
          }
          else if(parseFloat(obj2["labminlength"]) >= parseFloat(obj1["labminlength"]) && parseFloat(obj2["labminlength"]) <= parseFloat(obj1["labmaxlength"])){
             return true;
          }
          else if(parseFloat(obj2["labmaxlength"]) >= parseFloat(obj1["labminlength"]) && parseFloat(obj2["labmaxlength"]) <= parseFloat(obj1["labmaxlength"])){
             return true;
          }else if (key === "labminlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            } else if (key === "labmaxlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            }
            //Width
            else if(key === "labminwidth" && parseFloat(obj2["labminwidth"]) >= parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labmaxwidth"]) <= parseFloat(obj1["labmaxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["labminwidth"]) < parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labmaxwidth"]) > parseFloat(obj1["labmaxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["labminwidth"]) >= parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labminwidth"]) <= parseFloat(obj1["labmaxwidth"])){
               return true;
            }
            else if(parseFloat(obj2["labmaxwidth"]) >= parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labmaxwidth"]) <= parseFloat(obj1["labmaxwidth"])){
               return true;
            } else if (key === "labminwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "labmaxwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              }
             //Height
             else if(key === "labminheight" && parseFloat(obj2["labminheight"]) >= parseFloat(obj1["labminheight"]) && parseFloat(obj2["labmaxheight"]) <= parseFloat(obj1["labmaxheight"])){
              return true;
            }
            else if(parseFloat(obj2["labminheight"]) < parseFloat(obj1["labminheight"]) && parseFloat(obj2["labmaxheight"]) > parseFloat(obj1["labmaxheight"])){
              return true;
            }
            else if(parseFloat(obj2["labminheight"]) >= parseFloat(obj1["labminheight"]) && parseFloat(obj2["labminheight"]) <= parseFloat(obj1["labmaxheight"])){
               return true;
            }
            else if(parseFloat(obj2["labmaxheight"]) >= parseFloat(obj1["labminheight"]) && parseFloat(obj2["labmaxheight"]) <= parseFloat(obj1["labmaxheight"])){
               return true;
            }  else if (key === "labminheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "labmaxheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } 
              //Table
              else if(key === "labtablemin" && parseFloat(obj2["labtablemin"]) >= parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemax"]) <= parseFloat(obj1["labtablemax"])){
                return true;
              }
              else if(parseFloat(obj2["labtablemin"]) < parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemax"]) > parseFloat(obj1["labtablemax"])){
                return true;
              }
              else if(parseFloat(obj2["labtablemin"]) >= parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemin"]) <= parseFloat(obj1["labtablemax"])){
                 return true;
              }
              else if(parseFloat(obj2["labtablemax"]) >= parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemax"]) <= parseFloat(obj1["labtablemax"])){
                 return true;
              }  else if (key === "labtablemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                } else if (key === "labtablemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                }
                //Depth
                else if(key === "labdepthmin" && parseFloat(obj2["labdepthmin"]) >= parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmax"]) <= parseFloat(obj1["labdepthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["labdepthmin"]) < parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmax"]) > parseFloat(obj1["labdepthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["labdepthmin"]) >= parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmin"]) <= parseFloat(obj1["labdepthmax"])){
                   return true;
                }
                else if(parseFloat(obj2["labdepthmax"]) >= parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmax"]) <= parseFloat(obj1["labdepthmax"])){
                   return true;
                } else if (key === "labdepthmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  } else if (key === "labdepthmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  }
                  //Ratio
                  else if(key === "labratiomin" && parseFloat(obj2["labratiomin"]) >= parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomax"]) <= parseFloat(obj1["labratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["labratiomin"]) < parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomax"]) > parseFloat(obj1["labratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["labratiomin"]) >= parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomin"]) <= parseFloat(obj1["labratiomax"])){
                     return true;
                  }
                  else if(parseFloat(obj2["labratiomax"]) >= parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomax"]) <= parseFloat(obj1["labratiomax"])){
                     return true;
                  }else if (key === "labratiomin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    } else if (key === "labratiomax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    }
                    //Cr Height
                    else if(key === "labcrheightmin" && parseFloat(obj2["labcrheightmin"]) >= parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmax"]) <= parseFloat(obj1["labcrheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["labcrheightmin"]) < parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmax"]) > parseFloat(obj1["labcrheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["labcrheightmin"]) >= parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmin"]) <= parseFloat(obj1["labcrheightmax"])){
                       return true;
                    }
                    else if(parseFloat(obj2["labcrheightmax"]) >= parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmax"]) <= parseFloat(obj1["labcrheightmax"])){
                       return true;
                    } else if (key === "labcrheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      } else if (key === "labcrheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      }
                      //Cr Angle
                      else if(key === "labcranglemin" && parseFloat(obj2["labcranglemin"]) >= parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemax"]) <= parseFloat(obj1["labcranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["labcranglemin"]) < parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemax"]) > parseFloat(obj1["labcranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["labcranglemin"]) >= parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemin"]) <= parseFloat(obj1["labcranglemax"])){
                         return true;
                      }
                      else if(parseFloat(obj2["labcranglemax"]) >= parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemax"]) <= parseFloat(obj1["labcranglemax"])){
                         return true;
                      } else if (key === "cranglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        } else if (key === "labcranglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        }
                        //Pav Height
                        else if(key === "labpavheightmin" && parseFloat(obj2["labpavheightmin"]) >= parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmax"]) <= parseFloat(obj1["labpavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["labpavheightmin"]) < parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmax"]) > parseFloat(obj1["labpavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["labpavheightmin"]) >= parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmin"]) <= parseFloat(obj1["labpavheightmax"])){
                           return true;
                        }
                        else if(parseFloat(obj2["labpavheightmax"]) >= parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmax"]) <= parseFloat(obj1["labpavheightmax"])){
                           return true;
                        }  else if (key === "labpavheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          } else if (key === "labpavheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          }
                          //Pav Angle
                          else if(key === "labpavanglemin" && parseFloat(obj2["labpavanglemin"]) >= parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemax"]) <= parseFloat(obj1["labpavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["labpavanglemin"]) < parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemax"]) > parseFloat(obj1["labpavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["labpavanglemin"]) >= parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemin"]) <= parseFloat(obj1["labpavanglemax"])){
                             return true;
                          }
                          else if(parseFloat(obj2["labpavanglemax"]) >= parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemax"]) <= parseFloat(obj1["labpavanglemax"])){
                             return true;
                          } else if (key === "labpavanglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            } else if (key === "labpavanglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            }
        else {
        return false;
        }
        }
        }
        
         
        
         
        
         
        
        // If all of the common keys have overlapping values, then the objects are overlapping.
        return true;
        }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = areObjectsOverlapping(customerexisting,customercurrent)
          console.log(checkoverlap,"checkoverlap")
          console.log(customerexisting,"customerexisting")
          console.log(customercurrent,"customercurrent")
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  if(values.lgfancydiam){
    // customerapi = customerapi.filter(val => val.labfancydiamond && val.labfancydiamond.toString() === values.lgfancydiam.toString())
    diamondparamsvalidation = Yup.object().shape({
      [lab_shape.name]: Yup.array().min(1,`${lab_shape.requiredErrorMsg}`).required(`${lab_shape.requiredErrorMsg}`),
      [lab_fancy_color.name]: Yup.array().min(1,`${lab_fancy_color.requiredErrorMsg}`).required(`${lab_fancy_color.requiredErrorMsg}`),
      [lab_fancy_intensity.name]: Yup.array().min(1,`${lab_fancy_intensity.requiredErrorMsg}`).required(`${lab_fancy_intensity.requiredErrorMsg}`),
      [lab_fancy_overtone.name]: Yup.array().min(1,`${lab_fancy_overtone.requiredErrorMsg}`).required(`${lab_fancy_overtone.requiredErrorMsg}`),
      [lab_clarity.name]: Yup.array().min(1,`${lab_clarity.requiredErrorMsg}`).required(`${lab_clarity.requiredErrorMsg}`),
      // [lab_cuts.name]: Yup.array().min(1,`${lab_cuts.requiredErrorMsg}`).required(`${lab_cuts.requiredErrorMsg}`),
      [lab_carat_from.name]: Yup.string().required(`${lab_carat_from.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
    [lab_carat_to.name]: Yup.string().required(`${lab_carat_to.requiredErrorMsg}`).matches(range, 'The carat must be between 0 to 99').nullable(),
    }).test(
      'overlap',
      null,
      (obj) => {
        let diamondtype = "N"
        let naturaldiamond = 0
        let naturalfancydiamond = 0
        let lgwhitediam = 0
        let lgfancydiam = 0
        let diamond_type = [obj.naturaldiamond?1:0,obj.naturalfancydiamond?1:0,obj.lgwhitediam?1:0,obj.lgfancydiam?1:0]
        // console.log(diamond_type,"diamond_type")
        if(obj.naturaldiamond){
          diamondtype = "N"
          naturaldiamond = 1
        }
        if(obj.naturalfancydiamond){
          diamondtype = "N"
          naturalfancydiamond = 1
        }
        if(obj.lgwhitediam){
          diamondtype = "L"
          lgwhitediam = 1
        }
        if(obj.lgfancydiam){
          diamondtype = "L"
          lgfancydiam = 1
        }
        let FinalObject = {}
        if(diamondtype === "N"){
            if(obj.diamondshape && Array.isArray(obj.diamondshape) && obj.diamondshape.length){
              FinalObject["shape"] = obj.diamondshape.toString()
            }
            if(obj.cuts && obj.cuts.length && Array.isArray(obj.cuts)){
              FinalObject["cut"] = obj.cuts.toString()
            }
            if(obj.diamondclarity && obj.diamondclarity.length && Array.isArray(obj.diamondclarity)){
              FinalObject["clarity"] = obj.diamondclarity.toString()
            }
            if(obj.carat_from){
              FinalObject["min_carat"] = parseFloat(obj.carat_from)
            }
            if(obj.carat_to){
              FinalObject["max_carat"] = parseFloat(obj.carat_to)
            }
            if(obj.lab_val && obj.lab_val.length && Array.isArray(obj.lab_val)){
              FinalObject["lab"] = obj.lab_val.toString()
            }
            if(obj.symmetries && obj.symmetries.length && Array.isArray(obj.symmetries)){
              FinalObject["symmetry"] = obj.symmetries.toString()
            }
            if(obj.diamondflour && obj.diamondflour.length && Array.isArray(obj.diamondflour)){
              FinalObject["fluorescence"] = obj.diamondflour.toString()
            }
            if(obj.polishes && obj.polishes.length && Array.isArray(obj.polishes)){
              FinalObject["polish"] = obj.polishes.toString()
            }
            if(obj.milky && obj.milky.length && Array.isArray(obj.milky)){
              FinalObject["milky"] = obj.milky.toString()
            }
            if(obj.eyeclean && obj.eyeclean.length && Array.isArray(obj.eyeclean)){
              FinalObject["eyeclean"] = obj.eyeclean.toString()
            }
            if(obj.diamondshade && obj.diamondshade.length && Array.isArray(obj.diamondshade)){
              FinalObject["shade"] = obj.diamondshade.toString()
            }
            if(obj.price_from){
              FinalObject["min_dollarperct"] = parseFloat(obj.price_from)
            }
            if(obj.price_to){
              FinalObject["max_dollarperct"] = parseFloat(obj.price_to)
            }
            if(obj.total_price_from){
              FinalObject["total_price_from"] = parseFloat(obj.total_price_from)
            }
            if(obj.total_price_to){
              FinalObject["total_price_to"] = parseFloat(obj.total_price_to)
            }
            if(obj.media && obj.media.length && Array.isArray(obj.media)){
              FinalObject["media"] = obj.media.toString()
            }
            if(obj.measurementlengthfrom){
              FinalObject["minlength"] = parseFloat(obj.measurementlengthfrom)
            }
            if(obj.measurementlengthto){
            FinalObject["maxlength"] = parseFloat(obj.measurementlengthto)              
            }
            if(obj.measurementwidthfrom){
            FinalObject["minwidth"] = parseFloat(obj.measurementwidthfrom)
            }
            if(obj.measurementwidthto){
              FinalObject["maxwidth"] = parseFloat(obj.measurementwidthto)
            }
            if(obj.measurementheightfrom){
              FinalObject["minheight"] = parseFloat(obj.measurementheightfrom)
            }
            if(obj.measurementheightto){
              FinalObject["maxheight"] = parseFloat(obj.measurementheightto)
            }
            if(obj.tablefrom){
              FinalObject["tablemin"] = parseFloat(obj.tablefrom)
            }
            if(obj.tableto){
              FinalObject["tablemax"] = parseFloat(obj.tableto)
            }
            if(obj.depthfrom){
              FinalObject["depthmin"] = parseFloat(obj.depthfrom)
            }
            if(obj.depthto){
              FinalObject["depthmax"] = parseFloat(obj.depthto)
            }
            if(obj.ratiofrom){
              FinalObject["ratiomin"] = parseFloat(obj.ratiofrom)
            }
            if(obj.ratioto){
              FinalObject["ratiomax"] = parseFloat(obj.ratioto)
            }
            if(obj.crownheightfrom){
              FinalObject["crheightmin"] = parseFloat(obj.crownheightfrom)
            }
            if(obj.crownheightto){
              FinalObject["crheightmax"] = parseFloat(obj.crownheightto)
            }
            if(obj.crownanglefrom){
              FinalObject["cranglemin"] = parseFloat(obj.crownanglefrom)
            }
            if(obj.crownangleto){
              FinalObject["cranglemax"] = parseFloat(obj.crownangleto)
            }
            if(obj.pavheightfrom){
              FinalObject["pavheightmin"] = parseFloat(obj.pavheightfrom)
            }
            if(obj.pavheightto){
              FinalObject["pavheightmax"] = parseFloat(obj.pavheightto)
            }
            if(obj.pavanglefrom){
              FinalObject["pavanglemin"] = parseFloat(obj.pavanglefrom)
            }
            if(obj.pavangleto){
              FinalObject["pavanglemax"] = parseFloat(obj.pavangleto)
            }
            if(obj.brand && obj.brand.length && Array.isArray(obj.brand)){
              FinalObject["brand"] = obj.brand.toString()
            }
            if(obj.origin && obj.origin.length && Array.isArray(obj.origin)){
              FinalObject["origin"] = obj.origin.toString()
            }
            if(obj.treatment && obj.treatment.length && Array.isArray(obj.treatment)){
              FinalObject["treatment"] = obj.treatment.toString()
            }
            if(obj.keytosymbol && obj.keytosymbol.length && Array.isArray(obj.keytosymbol)){
              FinalObject["keytosymbol"] = obj.keytosymbol.toString()
            }
            if(naturaldiamond){
              if(obj.diamondcolor && obj.diamondcolor.length && Array.isArray(obj.diamondcolor)){
                FinalObject["color"] = obj.diamondcolor.toString()
              }
            }
            else{
              if(obj.diamondfancy_color && obj.diamondfancy_color.length && Array.isArray(obj.diamondfancy_color)){
                FinalObject["diamondfancy_color"] = obj.diamondfancy_color.toString()
              }
              if(obj.diamondfancy_intensity && obj.diamondfancy_intensity.length && Array.isArray(obj.diamondfancy_intensity)){
                FinalObject["diamondfancy_intensity"] = obj.diamondfancy_intensity.toString()
              }
              if(obj.diamondfancy_overtone && obj.diamondfancy_overtone.length && Array.isArray(obj.diamondfancy_overtone)){
                FinalObject["diamondfancy_overtone"] = obj.diamondfancy_overtone.toString()
              }
            }
        }
        else{
          if(obj.lab_shape && obj.lab_shape.length && Array.isArray(obj.lab_shape)){
            FinalObject["lab_shape"] = obj.lab_shape.toString()
          }
          if(obj.lab_cuts && obj.lab_cuts.length && Array.isArray(obj.lab_cuts)){
            FinalObject["lab_cut"] = obj.lab_cuts.toString()
          }
          if(obj.lab_clarity && obj.lab_clarity.length && Array.isArray(obj.lab_clarity)){
            FinalObject["lab_clarity"] = obj.lab_clarity.toString()
          }
          if(obj.lab_carat_from){
            FinalObject["lab_min_carat"] = parseFloat(obj.lab_carat_from)
          }
          if(obj.lab_carat_to){
            FinalObject["lab_max_carat"] = parseFloat(obj.lab_carat_to)
          }
          if(obj.lab_lab && obj.lab_lab.length && Array.isArray(obj.lab_lab)){
            FinalObject["lab_lab"] = obj.lab_lab.toString()
          }
          if(obj.lab_symmetries && obj.lab_symmetries.length && Array.isArray(obj.lab_symmetries)){
            FinalObject["lab_symmetry"] = obj.lab_symmetries.toString()
          }
          if(obj.lab_flourselect && obj.lab_flourselect.length && Array.isArray(obj.lab_flourselect)){
            FinalObject["lab_fluorescence"] = obj.lab_flourselect.toString()
          }
          if(obj.lab_polishes && obj.lab_polishes.length && Array.isArray(obj.lab_polishes)){
            FinalObject["lab_polish"] = obj.lab_polishes.toString()
          }
          if(obj.lab_milky && obj.lab_milky.length && Array.isArray(obj.lab_milky)){
            FinalObject["lab_milky"] = obj.lab_milky.toString()
          }
          if(obj.lab_eyeclean && obj.lab_eyeclean.length && Array.isArray(obj.lab_eyeclean)){
            FinalObject["lab_eyeclean"] = obj.lab_eyeclean.toString()
          }
          if(obj.lab_shadeselect && obj.lab_shadeselect.length && Array.isArray(obj.lab_shadeselect)){
            FinalObject["lab_shade"] = obj.lab_shadeselect.toString()
          }
          if(obj.lab_min_dollarperct){
            FinalObject["lab_min_dollarperct"] = parseFloat(obj.lab_min_dollarperct)
          }
          if(obj.lab_max_dollarperct){
            FinalObject["lab_max_dollarperct"] = parseFloat(obj.lab_max_dollarperct)
          }
          if(obj.lab_total_price_from){
            FinalObject["lab_total_price_from"] = parseFloat(obj.lab_total_price_from)
          }
          if(obj.lab_total_price_to){
            FinalObject["lab_total_price_to"] = parseFloat(obj.lab_total_price_to)
          }
          if(obj.lab_all_media && obj.lab_all_media.length && Array.isArray(obj.lab_all_media)){
            FinalObject["lab_media"] = obj.lab_all_media.toString()
          }
          if(obj.labmeasurementlengthfrom){
            FinalObject["labminlength"] = parseFloat(obj.labmeasurementlengthfrom)
          }
          if(obj.labmeasurementlengthto){
            FinalObject["labmaxlength"] = parseFloat(obj.labmeasurementlengthto)
          }
          if(obj.labmeasurementwidthfrom){
            FinalObject["labminwidth"] = parseFloat(obj.labmeasurementwidthfrom)
          }
          if(obj.labmeasurementwidthto){
            FinalObject["labmaxwidth"] = parseFloat(obj.labmeasurementwidthto)
          }
          if(obj.labmeasurementheightfrom){
            FinalObject["labminheight"] = parseFloat(obj.labmeasurementheightfrom)
          }
          if(obj.labmeasurementheightto){
            FinalObject["labmaxheight"] = parseFloat(obj.labmeasurementheightto)
          }
          if(obj.labtablefrom){
            FinalObject["labtablemin"] = parseFloat(obj.labtablefrom)
          }
          if(obj.labtableto){
            FinalObject["labtablemax"] = parseFloat(obj.labtableto)
          }
          if(obj.labdepthfrom){
            FinalObject["labdepthmin"] = parseFloat(obj.labdepthfrom)
          }
          if(obj.labdepthto){
            FinalObject["labdepthmax"] = parseFloat(obj.labdepthto)
          }
          if(obj.labratiofrom){
            FinalObject["labratiomin"] = parseFloat(obj.labratiofrom)
          }
          if(obj.labratioto){
            FinalObject["labratiomax"] = parseFloat(obj.labratioto)
          }
          if(obj.labcrownheightfrom){
            FinalObject["labcrheightmin"] = parseFloat(obj.labcrownheightfrom)
          }
          if(obj.labcrownheightto){
            FinalObject["labcrheightmax"] = parseFloat(obj.labcrownheightto)
          }
          if(obj.labcrownanglefrom){
            FinalObject["labcranglemin"] = parseFloat(obj.labcrownanglefrom)
          }
          if(obj.labcrownangleto){
            FinalObject["labcranglemax"] = parseFloat(obj.labcrownangleto)
          }
          if(obj.labpavheightfrom){
            FinalObject["labpavheightmin"] = parseFloat(obj.labpavheightfrom)
          }
          if(obj.labpavheightto){
            FinalObject["labpavheightmax"] = parseFloat(obj.labpavheightto)
          }
          if(obj.labpavanglefrom){
            FinalObject["labpavanglemin"] = parseFloat(obj.labpavanglefrom)
          }
          if(obj.labpavangleto){
            FinalObject["labpavanglemax"] = parseFloat(obj.labpavangleto)
          }
            // FinalObject["brand"] = obj.brand
            if(obj.laborigin && obj.laborigin.length && Array.isArray(obj.laborigin)){
              FinalObject["laborigin"] = obj.laborigin.toString()              
            }
            if(obj.labtreatment && obj.labtreatment.length && Array.isArray(obj.labtreatment)){
              FinalObject["labtreatment"] = obj.labtreatment.toString()
            }
            if(obj.labkeytosymbol && obj.labkeytosymbol.length && Array.isArray(obj.labkeytosymbol)){
              FinalObject["labkeytosymbol"] = obj.labkeytosymbol.toString()
            }
          if(lgwhitediam){
            if(obj.lab_color && obj.lab_color.length && Array.isArray(obj.lab_color)){
              FinalObject["lab_color"] = obj.lab_color.toString()
            }
          }
          else{
            if(obj.lab_fancy_color && obj.lab_fancy_color.length && Array.isArray(obj.lab_fancy_color)){
              FinalObject["lab_fancy_color"] = obj.lab_fancy_color.toString()
            }
            if(obj.lab_fancy_intensity && obj.lab_fancy_intensity.length && Array.isArray(obj.lab_fancy_intensity)){
              FinalObject["lab_fancy_intensity"] = obj.lab_fancy_intensity.toString()
            }
            if(obj.lab_fancy_overtone && obj.lab_fancy_overtone.length && Array.isArray(obj.lab_fancy_overtone)){
              FinalObject["lab_fancy_overtone"] = obj.lab_fancy_overtone.toString()
            }
          }
        }
        console.log(FinalObject,"FinalObject")
        console.log(customerapi,"customerapi")
        const OverlapValidation = (rules,newrule) => {
          let finaloverlap = true
          let validationarray = []
          for(let i = 0; i < rules.length; i++){
              let overlapsets = {}
              for(let key in newrule){
                  if(newrule[key]){
                  newrule[key] = newrule[key].toString()    
                   let newrulekey = newrule[key].split(',')
                  for(let j = 0; j < newrulekey.length; j++){
                      if(rules[i][key]){
                      rules[i][key] = rules[i][key].toString()    
                      let checkincludes = rules[i][key].includes(newrulekey[j])
                      overlapsets[`${key}${[j]}`] = checkincludes   
                      }
                  }   
                  }
              }
              validationarray.push(overlapsets)
          }
          let finalvalidationarray = []
          for(let k = 0 ; k < validationarray.length; k++){
              let currentoverlap = true
              for(let keys in newrule){
                  let regex = new RegExp(keys)
                  let keysets = Object.keys(validationarray[k]).filter(key => regex.test(key))
                  let checkoverlap = []
                  for(let l = 0; l < keysets.length; l++){
                      checkoverlap.push(validationarray[k][keysets[l]])
                  }
                  let checker = arr => arr.every(v => v === false);
                  if(checker(checkoverlap)){
                      currentoverlap = false
                  }
              }
              finalvalidationarray.push(currentoverlap)
          }
          let checkerfinal = arr => arr.every(v => v === false);
          return !checkerfinal(finalvalidationarray)
      }
      function areObjectsOverlapping(obj1, obj2) {
        // Check if the objects have the same keys.
        const commonKeys = Object.keys(obj1).filter(key => Object.keys(obj2).includes(key));
        
         
        
         
        
         
        
        // Check if the values of the common keys are overlapping.
        for (const key of commonKeys) {
        if (Array.isArray(obj1[key])) {
        // If the value is an array, check if the arrays have any overlapping elements.
        if (!obj1[key].some(element => obj2[key].includes(element))) {
        return false;
        }
        } else if (obj1[key] !== obj2[key]) {
        // If the value is a number or a string, and the values are not equal, then the objects are not overlapping.
        // Check for overlapping min_carat and max_carat values.
        //Carat
        if(key === "lab_min_carat" && parseFloat(obj2["lab_min_carat"]) >= parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_max_carat"]) <= parseFloat(obj1["lab_max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_carat"]) < parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_max_carat"]) > parseFloat(obj1["lab_max_carat"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_carat"]) >= parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_min_carat"]) <= parseFloat(obj1["lab_max_carat"])){
           return true;
        }
        else if(parseFloat(obj2["lab_max_carat"]) >= parseFloat(obj1["lab_min_carat"]) && parseFloat(obj2["lab_max_carat"]) <= parseFloat(obj1["lab_max_carat"])){
           return true;
        } else if (key === "lab_min_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        } else if (key === "lab_max_carat" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
        return true;
        }
        //$/CT
        else if(key === "lab_min_dollarperct" && parseFloat(obj2["lab_min_dollarperct"]) >= parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_max_dollarperct"]) <= parseFloat(obj1["lab_max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_dollarperct"]) < parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_max_dollarperct"]) > parseFloat(obj1["lab_max_dollarperct"])){
          return true;
        }
        else if(parseFloat(obj2["lab_min_dollarperct"]) >= parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_min_dollarperct"]) <= parseFloat(obj1["lab_max_dollarperct"])){
           return true;
        }
        else if(parseFloat(obj2["lab_max_dollarperct"]) >= parseFloat(obj1["lab_min_dollarperct"]) && parseFloat(obj2["lab_max_dollarperct"]) <= parseFloat(obj1["lab_max_dollarperct"])){
           return true;
        } else if (key === "lab_min_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "lab_max_dollarperct" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
         //Price
         else if(key === "lab_total_price_from" && parseFloat(obj2["lab_total_price_from"]) >= parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_to"]) <= parseFloat(obj1["lab_total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["lab_total_price_from"]) < parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_to"]) > parseFloat(obj1["lab_total_price_to"])){
          return true;
        }
        else if(parseFloat(obj2["lab_total_price_from"]) >= parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_from"]) <= parseFloat(obj1["lab_total_price_to"])){
           return true;
        }
        else if(parseFloat(obj2["lab_total_price_to"]) >= parseFloat(obj1["lab_total_price_from"]) && parseFloat(obj2["lab_total_price_to"]) <= parseFloat(obj1["lab_total_price_to"])){
           return true;
        } else if (key === "lab_total_price_from" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          } else if (key === "lab_total_price_to" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
          return true;
          }
          //Length
          else if(key === "labminlength" && parseFloat(obj2["labminlength"]) >= parseFloat(obj1["labminlength"]) && parseFloat(obj2["labmaxlength"]) <= parseFloat(obj1["labmaxlength"])){
            return true;
          }
          else if(parseFloat(obj2["labminlength"]) < parseFloat(obj1["labminlength"]) && parseFloat(obj2["labmaxlength"]) > parseFloat(obj1["labmaxlength"])){
            return true;
          }
          else if(parseFloat(obj2["labminlength"]) >= parseFloat(obj1["labminlength"]) && parseFloat(obj2["labminlength"]) <= parseFloat(obj1["labmaxlength"])){
             return true;
          }
          else if(parseFloat(obj2["labmaxlength"]) >= parseFloat(obj1["labminlength"]) && parseFloat(obj2["labmaxlength"]) <= parseFloat(obj1["labmaxlength"])){
             return true;
          }else if (key === "labminlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            } else if (key === "labmaxlength" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
            return true;
            }
            //Width
            else if(key === "labminwidth" && parseFloat(obj2["labminwidth"]) >= parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labmaxwidth"]) <= parseFloat(obj1["labmaxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["labminwidth"]) < parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labmaxwidth"]) > parseFloat(obj1["labmaxwidth"])){
              return true;
            }
            else if(parseFloat(obj2["labminwidth"]) >= parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labminwidth"]) <= parseFloat(obj1["labmaxwidth"])){
               return true;
            }
            else if(parseFloat(obj2["labmaxwidth"]) >= parseFloat(obj1["labminwidth"]) && parseFloat(obj2["labmaxwidth"]) <= parseFloat(obj1["labmaxwidth"])){
               return true;
            } else if (key === "labminwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "labmaxwidth" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              }
             //Height
             else if(key === "labminheight" && parseFloat(obj2["labminheight"]) >= parseFloat(obj1["labminheight"]) && parseFloat(obj2["labmaxheight"]) <= parseFloat(obj1["labmaxheight"])){
              return true;
            }
            else if(parseFloat(obj2["labminheight"]) < parseFloat(obj1["labminheight"]) && parseFloat(obj2["labmaxheight"]) > parseFloat(obj1["labmaxheight"])){
              return true;
            }
            else if(parseFloat(obj2["labminheight"]) >= parseFloat(obj1["labminheight"]) && parseFloat(obj2["labminheight"]) <= parseFloat(obj1["labmaxheight"])){
               return true;
            }
            else if(parseFloat(obj2["labmaxheight"]) >= parseFloat(obj1["labminheight"]) && parseFloat(obj2["labmaxheight"]) <= parseFloat(obj1["labmaxheight"])){
               return true;
            }  else if (key === "labminheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } else if (key === "labmaxheight" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
              return true;
              } 
              //Table
              else if(key === "labtablemin" && parseFloat(obj2["labtablemin"]) >= parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemax"]) <= parseFloat(obj1["labtablemax"])){
                return true;
              }
              else if(parseFloat(obj2["labtablemin"]) < parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemax"]) > parseFloat(obj1["labtablemax"])){
                return true;
              }
              else if(parseFloat(obj2["labtablemin"]) >= parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemin"]) <= parseFloat(obj1["labtablemax"])){
                 return true;
              }
              else if(parseFloat(obj2["labtablemax"]) >= parseFloat(obj1["labtablemin"]) && parseFloat(obj2["labtablemax"]) <= parseFloat(obj1["labtablemax"])){
                 return true;
              }  else if (key === "labtablemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                } else if (key === "labtablemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                return true;
                }
                //Depth
                else if(key === "labdepthmin" && parseFloat(obj2["labdepthmin"]) >= parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmax"]) <= parseFloat(obj1["labdepthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["labdepthmin"]) < parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmax"]) > parseFloat(obj1["labdepthmax"])){
                  return true;
                }
                else if(parseFloat(obj2["labdepthmin"]) >= parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmin"]) <= parseFloat(obj1["labdepthmax"])){
                   return true;
                }
                else if(parseFloat(obj2["labdepthmax"]) >= parseFloat(obj1["labdepthmin"]) && parseFloat(obj2["labdepthmax"]) <= parseFloat(obj1["labdepthmax"])){
                   return true;
                } else if (key === "labdepthmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  } else if (key === "labdepthmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                  return true;
                  }
                  //Ratio
                  else if(key === "labratiomin" && parseFloat(obj2["labratiomin"]) >= parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomax"]) <= parseFloat(obj1["labratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["labratiomin"]) < parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomax"]) > parseFloat(obj1["labratiomax"])){
                    return true;
                  }
                  else if(parseFloat(obj2["labratiomin"]) >= parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomin"]) <= parseFloat(obj1["labratiomax"])){
                     return true;
                  }
                  else if(parseFloat(obj2["labratiomax"]) >= parseFloat(obj1["labratiomin"]) && parseFloat(obj2["labratiomax"]) <= parseFloat(obj1["labratiomax"])){
                     return true;
                  }else if (key === "labratiomin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    } else if (key === "labratiomax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                    return true;
                    }
                    //Cr Height
                    else if(key === "labcrheightmin" && parseFloat(obj2["labcrheightmin"]) >= parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmax"]) <= parseFloat(obj1["labcrheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["labcrheightmin"]) < parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmax"]) > parseFloat(obj1["labcrheightmax"])){
                      return true;
                    }
                    else if(parseFloat(obj2["labcrheightmin"]) >= parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmin"]) <= parseFloat(obj1["labcrheightmax"])){
                       return true;
                    }
                    else if(parseFloat(obj2["labcrheightmax"]) >= parseFloat(obj1["labcrheightmin"]) && parseFloat(obj2["labcrheightmax"]) <= parseFloat(obj1["labcrheightmax"])){
                       return true;
                    } else if (key === "labcrheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      } else if (key === "labcrheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                      return true;
                      }
                      //Cr Angle
                      else if(key === "labcranglemin" && parseFloat(obj2["labcranglemin"]) >= parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemax"]) <= parseFloat(obj1["labcranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["labcranglemin"]) < parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemax"]) > parseFloat(obj1["labcranglemax"])){
                        return true;
                      }
                      else if(parseFloat(obj2["labcranglemin"]) >= parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemin"]) <= parseFloat(obj1["labcranglemax"])){
                         return true;
                      }
                      else if(parseFloat(obj2["labcranglemax"]) >= parseFloat(obj1["labcranglemin"]) && parseFloat(obj2["labcranglemax"]) <= parseFloat(obj1["labcranglemax"])){
                         return true;
                      } else if (key === "cranglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        } else if (key === "labcranglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                        return true;
                        }
                        //Pav Height
                        else if(key === "labpavheightmin" && parseFloat(obj2["labpavheightmin"]) >= parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmax"]) <= parseFloat(obj1["labpavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["labpavheightmin"]) < parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmax"]) > parseFloat(obj1["labpavheightmax"])){
                          return true;
                        }
                        else if(parseFloat(obj2["labpavheightmin"]) >= parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmin"]) <= parseFloat(obj1["labpavheightmax"])){
                           return true;
                        }
                        else if(parseFloat(obj2["labpavheightmax"]) >= parseFloat(obj1["labpavheightmin"]) && parseFloat(obj2["labpavheightmax"]) <= parseFloat(obj1["labpavheightmax"])){
                           return true;
                        }  else if (key === "labpavheightmin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          } else if (key === "labpavheightmax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                          return true;
                          }
                          //Pav Angle
                          else if(key === "labpavanglemin" && parseFloat(obj2["labpavanglemin"]) >= parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemax"]) <= parseFloat(obj1["labpavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["labpavanglemin"]) < parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemax"]) > parseFloat(obj1["labpavanglemax"])){
                            return true;
                          }
                          else if(parseFloat(obj2["labpavanglemin"]) >= parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemin"]) <= parseFloat(obj1["labpavanglemax"])){
                             return true;
                          }
                          else if(parseFloat(obj2["labpavanglemax"]) >= parseFloat(obj1["labpavanglemin"]) && parseFloat(obj2["labpavanglemax"]) <= parseFloat(obj1["labpavanglemax"])){
                             return true;
                          } else if (key === "labpavanglemin" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            } else if (key === "labpavanglemax" && obj1[key] === null || obj2[key] === null || obj1[key] === undefined || obj2[key] === undefined) {
                            return true;
                            }
        else {
        return false;
        }
        }
        }
        
         
        
         
        
         
        
        // If all of the common keys have overlapping values, then the objects are overlapping.
        return true;
        }
      let allvalidations = []
      for(let i = 0 ; i < customerapi.length; i++){
        let customerexisting = {...customerapi[i]}
        let rulename = customerexisting.rule_name
        delete customerexisting.rule_name
        let customercurrent = {...FinalObject}
        for(let key in customerexisting){
          if(customerexisting[key] && typeof(customerexisting[key]) === "string"){
            customerexisting[key] = customerexisting[key].toString().split(',')
          }
          else if(typeof(customerexisting[key]) === "number"){
            customerexisting[key] = customerexisting[key]
          }
          else{
            delete customerexisting[key]
          }
        }
        for(let keys in customercurrent){
          if(customercurrent[keys] && typeof(customercurrent[keys]) !== "number"){
            customercurrent[keys] = customercurrent[keys].toString().split(',')
          }
        }
          let checkoverlap = areObjectsOverlapping(customerexisting,customercurrent)
          console.log(checkoverlap,"checkoverlap")
          console.log(customerexisting,"customerexisting")
          console.log(customercurrent,"customercurrent")
          if(Object.keys(customercurrent).length !== 0 && checkoverlap === true){
            allvalidations.push(rulename)
          }
      }
      // console.log(allvalidations,"allvalidations")
      // let isoverlap = OverlapValidation(customerapi,FinalObject)
      // console.log(isoverlap,"FINALLLLL")
      if(allvalidations.length){
        let rulesoverlapped = ""
        for(let l = 0; l < allvalidations.length;l++){
          if(l + 1 === allvalidations.length){
            rulesoverlapped += ` & ${allvalidations[l]}`
          }
          else{
            rulesoverlapped += `${allvalidations[l]}`
            if(l + 2 !== allvalidations.length){
              rulesoverlapped += ","
            }
          }
        }
        console.log(rulesoverlapped,"rulesoverlapped")
        if(allvalidations.length === 1){
          rulesoverlapped = allvalidations.toString()
        }
        return new Yup.ValidationError(`Your Rules are Overlapping with ${rulesoverlapped}.`,true,"overlap");
      }
      }
    )
  }
  validationarray.splice(2,0,diamondparamsvalidation)
  console.log(validationarray,"validationarray")
  console.log(validationarray.length,"validationarray.length")
  return validationarray;
};