/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link,useHistory } from "react-router-dom";
export const GridCard = (props) => {
    const [newprops,setProps] = useState(props)
    const history = useHistory();
    useEffect(() => {
    },[])    

    function handelDetails(props){
        history.push({  pathname: '/api-frame-detail', state:props})
        window.scrollTo(0, 0)
    }

    return (
        <>
            <div
                className={`col-sm-6 col-md-4 col-lg-3 mb-5 natTab`}
                key={props.CERTIFICATE_NO}
                id={props.CERTIFICATE_NO}
            >
                <div className="row m-0 card gridData">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200" style={{height:"220px"}}>
                        {props.IMAGE ? (
                           
                            <LazyLoadImage
                                className="img-fluid cursor-pointer"
                                src={ props.IMAGE}
                                onClick={() => handelDetails(props)}
                                alt={props.SHAPE}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${props.SHAPE.toLowerCase()}-no.png`
                                        )
                                    }}
                                effect="blur" 
                            />
                        ) : (
                            <img
                                className="img-fluid cursor-pointer"
                                alt="shape"
                                onClick={() => handelDetails(props)}
                                src={toAbsoluteUrl(
                                    `/media/shape/${props.SHAPE.toLowerCase()}-no.png`
                                )}
                            />
                            
                        )}
                    </div>
                    <div className="col-12 text-truncate mt-5 mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {parseFloat(props.CARAT).toFixed(2)} {props.SHAPE}{" "}
                                    {(!props.COLOR) ? (<>{props.FANCY_INTENSITY} {props.FANCY_OVERTONE} {props.FANCY_COLOR}</>): props.COLOR}{" "}
                                    {props.CLARITY} {props.CUT}{" "}
                                    {props.POLISH} {props.SYMMETRY} {props.FLUORESCENCE}
                                </Tooltip>
                            }
                        >
                            <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                <span className="pr-2">
                                    {parseFloat(props.CARAT).toFixed(2)}
                                </span>
                                <span className="pr-2">{props.SHAPE}</span>
                                { (!props.COLOR) ?
                                    (
                                        <>
                                             <span className="pr-2">{props.FANCY_INTENSITY}</span>
                                             <span className="pr-2">{props.FANCY_OVERTONE}</span>
                                             <span className="pr-2">{props.FANCY_COLOR}</span>
                                        </>
                                    ) : (
                                        <>
                                             <span className="pr-2">{props.COLOR}</span>
                                        </>
                                    )
                                }
                                <span className="pr-2">{props.CLARITY}</span>
                                <span className="pr-2">{props.CUT}</span>
                                <span className="pr-2">{props.POLISH}</span>
                                <span className="pr-2">{props.SYMMETRY}</span>
                                <span className="pr-2">{props.FLUORESCENCE}</span>
                            </span>
                        </OverlayTrigger>
                        <hr className="w-100 mt-4 mb-3" />
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-between mb-5">
                        <div className="font-size-h2 font-weight-bolder text-primary">${parseFloat(props.TOTAL_PRICE).toFixed(2)}</div>
                        <button className="btn btn-primary px-8 btn-square font-weight-bold" onClick={() => handelDetails(props)}>Details</button>
                    </div>
                </div>
            </div>
        </>
    );
};
