import React, { useState,useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
import { NODE_API_URL } from '../../../../env_config';
import PropTypes from "prop-types";
import { Formik, Form } from 'formik';
import axios from "axios"
import DimondType from '../../DiamondFeed/IFrameSetup/FormEdit/DimondType';
import NaturalDiam from '../../DiamondFeed/IFrameSetup/FormEdit/NaturalDiam';
import LabDiam from '../../DiamondFeed/IFrameSetup/FormEdit/LabDiam';
import ColorMarkup from '../../DiamondFeed/IFrameSetup/FormEdit/ColorMarkup';
import EmbededCode from '../../DiamondFeed/IFrameSetup/FormEdit/EmbededCode';
import AdditionalSetting from '../../DiamondFeed/IFrameSetup/FormEdit/AdditionalSetting';
import FormSuccess from '../../DiamondFeed/IFrameSetup/FormSuccess/FormSuccess';
// import { NODE_API_URL } from '../../../../env_config';
import validationSchemaEdit from '../../DiamondFeed/IFrameSetup/FormModel/validationSchemaEdit';
import kycFormModel from '../../DiamondFeed/IFrameSetup/FormModel/kycFormModel';
import {fetchData} from '../../DiamondFeed/IFrameSetup/FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';
import useStyles from '../../DiamondFeed/IFrameSetup/styles';

const steps = ['Choose Diamond', 'Natural Diamond Parameters', 'Lab-Grown Diamond Parameters', 'Color & Price Markup'];
const { formId, formField } = kycFormModel;

// function _renderStepContent(step,setFieldValue,prev,setPrev,values,userinfo) {

//   switch (step) {
//     case 0:
//       return <DimondType formField={formField} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev} values={values}/>;
//     case 1:
//       return <NaturalDiam setFieldValue={setFieldValue} formField={formField} values={values} />;
//     case 2:
//       return <LabDiam setFieldValue={setFieldValue} formField={formField} values={values} />;
//     case 3:
//       return <ColorMarkup formField={formField} values={values} setFieldValue={setFieldValue} userinfo={userinfo}/>;
//       // return <ReviewOrder />;
//     default:
//       return <div>Not Found</div>;
//   }
// }
function TabContainer(props) {
  return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
          {props.children}
      </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


export default function IframeSetting(props) {

  const [value, setValue] = React.useState(0); // for tab
  const [selectallfields,setSelectAllFields] = useState(false)
  const [selectallfieldslab,setSelectAllFieldsLab] = useState(false)
  function handleTabChange(event, newValue) {
    setValue(newValue);
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaEdit[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [initialValues,setInitialValues] = useState({})
  useEffect(() => {
    fetchData(props.userinfo).then(values => {
      setInitialValues(values)
    })
  },[])
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    actions.setSubmitting(false);
    let diamond_type = [values.naturaldiamond?1:0,values.naturalfancydiamond?1:0,values.lgwhitediam?1:0,values.lgfancydiam?1:0]
    const userData = JSON.parse(localStorage.getItem("userData"))

    const FinalValues = {
      ...values,
      diamond_type:diamond_type,
      "user_id":userData.CustomerId,
      fancycolor:values.diamondfancy_color,
      fancyintensity:values.diamondfancy_intensity,
      fancyovertone:values.diamondfancy_overtone,
      labfancycolor:values.lab_fancy_color,
      labfancyintensity:values.lab_fancy_intensity,
      labfancyovertone:values.lab_fancy_overtone,
      lab_flour:values.lab_flourselect,
      lab_shade:values.lab_shadeselect,
      secondarycolor:values.secondary,
      min_dollarperct:values.price_from,
      max_dollarperct:values.price_to,
      "embeded_code_option":"Separate",
      lab_media:values.lab_all_media,
      no_price:values.noprice
    }
    axios({
      method: "POST",
      url: `${NODE_API_URL}/TESTMODE/insertIframeData`,
      headers: { "Content-Type": "application/json" },
      data: FinalValues,
  }).then(res => {
      if(res && res.data){
          // setData(res.data)
          // console.log( res.data,"NOICE")
          window.location.reload(true)
      }
  })
  }

  function _handleSubmit(values, actions) {
    _submitForm(values, actions);
  }
 const [prev,setPrev] = useState(false)
  function _handleBack() {
    setActiveStep(activeStep - 1);
    setPrev(true)
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mb-4 setting-title">{props.language.SETTING_REQUEST_IFRAME}</div>
        <div className="col-12">
      {/* {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      <React.Fragment>
        {activeStep === steps.length ? (
          <FormSuccess />
        ) : ( */}
          <Paper className="shadow-none border-bottom">
              <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange} variant="scrollable" scrollButtons="auto" >
                  <Tab label={props.language.SETTING_EMBEDDED} />
                  <Tab label={props.language.SETTING_DIAMOND_TYPE} />
                  <Tab label={props.language.CCMODE_SETUP_NATURAL_DIAMOND} />
                  <Tab label={props.language.SIDEBAR_LAB_GROWN_DIAMOND} />
                  <Tab label={props.language.DIAMOND_FEED_NATURAL_COLORMARKUP} />
                  <Tab label={props.language.SETTING_DIAMOND_ADDSET}/>
              </Tabs>
          </Paper>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values }) => (
              <Form id={formId}>
                {/* {_renderStepContent(activeStep,setFieldValue,prev,setPrev,values,props.userinfo)} */}
                {value === 0 && (
                  <TabContainer >
                      <EmbededCode formField={formField} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev} values={values} customeriframe={props.customeriframe} setUpdateKey={props.setUpdateKey} language={props.language}/>
                  </TabContainer>
                )}
                {value === 1 && (
                  <TabContainer >
                      <DimondType formField={formField} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev} values={values} language={props.language}/>
                  </TabContainer>
                )}
                {value === 2 && (
                  <TabContainer >
                      <NaturalDiam setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfields} language={props.language}/>
                  </TabContainer>
                )}
                {value === 3 && (
                  <TabContainer >
                      <LabDiam setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfieldslab} language={props.language}/>
                  </TabContainer>
                )}
                {value === 4 && (
                  <TabContainer >
                      <ColorMarkup formField={formField} values={values} setFieldValue={setFieldValue} language={props.language}/>
                  </TabContainer>
                )}
                {value === 5 && (
                  <TabContainer >
                     <AdditionalSetting formField={formField} values={values} setFieldValue={setFieldValue} language={props.language}/>
                  </TabContainer>
                )}
                <Grid className="mt-10 sticky-top bg-gray-100" container justify={"flex-end"} alignItems="center"  style={{bottom:"0"}}>
                  <div className={classes.wrapper}>
                  {value === 2?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFields(true)}>{props.language.DIAMOND_FEED_IFRAMESETUP_SELECTALL}</Button>:""}
                        {value === 3?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFieldsLab(true)}>{props.language.DIAMOND_FEED_IFRAMESETUP_SELECTALL}</Button>:""}
                    {value !==0?<Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {props.language.SETTING_PASSWORD_SAVE}
                    </Button>:""}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        {/* )} */}
      {/* </React.Fragment> */}
        </div>
      </div>
    </React.Fragment>
  );
}
