import React from "react";
import { Field } from "formik";

export const Price = ({language}) => {
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">{language.SEARCHDIAMOND_PREFERENCE_PRICE}</p>
                </div>
                <div className="col-lg-12 d-flex align-items-end">
                    <div className="form-group mb-0 w-100 mr-2">
                        <label>$/CT</label>
                        <div className="input-group">
                            <Field
                                className="form-control form-control-sm"
                                type="number"
                                name="Min_Rate"
                                min="0"
                                id="Min_Rate"
                                placeholder="From"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="form-group mb-0 w-100">
                        <div className="input-group">
                            <Field
                                className="form-control form-control-sm"
                                type="number"
                                name="Max_Rate"
                                min="0"
                                id="Max_Rate"
                                placeholder="To"
                                autoComplete="off"
                            />
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-12 d-flex align-items-end mt-5">
                    <div className="form-group mb-0 w-100 mr-2">
                        <label>Price</label>
                        <div className="input-group">
                            <Field
                                className="form-control form-control-sm"
                                type="number"
                                name="Min_Price"
                                min="0"
                                step="0.01"
                                id="Min_Price"
                                placeholder="From"
                                autoComplete="off"
                            />
                            
                        </div>
                    </div>
                    <div className="form-group mb-0 w-100">
                        <div className="input-group">
                            <Field
                                className="form-control form-control-sm"
                                type="number"
                                name="priceTo"
                                id="priceTo"
                                placeholder="To"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
