import React, { Suspense,useState,useEffect} from "react";
import { useIdleTimer } from 'react-idle-timer'

import { Redirect, Switch, } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import {PreviewIframe} from "../_metronic/_partials/settings/Iframe/PreviewIframe"
import "../assets/css/BackendStyle.css"
import { SearchDiamond , LabSearchDiamond, SearchResult, Profile, ConfirmGoods, HoldDiamonds,Invoice,InviceDetail,ProformaDetails,Settings,Contact,TransparentPricing,Ccmode,DiamondFeed,ApiDocumentationVip,ApiDocumentation,Checkout,Comparison,MatchingPair,ParcelDiamSearch} from "../_metronic/_partials";
import SearchGrid from "../_metronic/_partials/tables/SearchGrid";
import DetailPage from "../_metronic/_partials/detailPage/DetailPage";
import { Wishlist } from "../_metronic/_partials/tables/WishlistPage";
import { BASE_URL } from "../env_config";
import IframeAPI from "../_metronic/_partials/widgets/tiles/IframeAPI";
import IframeAPILab from "../_metronic/_partials/widgets/tiles/IframeAPILab";
import { ToastContainer, toast } from 'react-toastify';
import { PaymentFailed } from "../_metronic/_partials/checkout/PaymentFailed";
import { PaymentSuccess } from "../_metronic/_partials/checkout/PaymentSuccess";
import WishlistGrid from "../_metronic/_partials/tables/WishlistGrid";
import SubscriptionPage from "../_metronic/_partials/apiv3/SubscriptionPage";
import ApiRequested from "../_metronic/_partials/apiv3/ApiRequested";
import ApiUpdateForm from "../_metronic/_partials/DiamondFeed/ApiSetup/ApiUpdateForm";
import ApiSetupFrom from "../_metronic/_partials/DiamondFeed/ApiSetup/ApiSetupFrom";
import ApiCopyForm from "../_metronic/_partials/DiamondFeed/ApiSetup/ApiCopyForm";
import { FeedApproved } from "../_metronic/_partials/DiamondFeed";
import { AuthPage } from "./modules/Auth";
import { Search } from "../_metronic/_partials/search/Search";
import {ProductDetailMain} from "../_metronic/_partials/productDetail/productDetailMain"
export default function BasePage({language,conversionrate,symbol,isprofilecomplete,userinfo,localstored,dataFetchedRef,symbolname,logStoredRef}) {

const [state, setState] = useState('Active')
const [count, setCount] = useState(0)
const [remaining, setRemaining] = useState(0)

const onIdle = () => {
  // setState('Idle')
  localStorage.clear()
    var cookies = document.cookie.split(";");
for(var i=0; i < cookies.length; i++) {
    var equals = cookies[i].indexOf("=");
    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
}
    window.location.replace(`${BASE_URL}/login/logout`);
}

const onActive = () => {
}

const onAction = () => {
}

const { getRemainingTime } = useIdleTimer({
  onIdle,
  onActive,
  onAction,
  timeout: 3600000,
  throttle: 500
})

// useEffect(() => {
//   const interval = setInterval(() => {
//     setRemaining(Math.ceil(getRemainingTime() / 1000))
//   }, 500)

//   return () => {
//     clearInterval(interval)
//   }
// })
  return (
    <>
    {/* <button type="button" className="btn btn-primary px-10 mr-3 btn-pill font-weight-bold" onClick={() => fullscreen()}>
      Enter/Exit Full Screen
    </button> */}
    {localstored?<Suspense fallback={<LayoutSplashScreen />}>
    <Switch>
      {
        /* Redirect from root URL to /dashboard. */
        <Redirect exact from="/" to="/dashboard" />
      }
      
      <ContentRoute path="/dashboard" component={() => <DashboardPage language={language} symbol={symbol} conversionrate={conversionrate} isprofilecomplete={isprofilecomplete} userinfo={userinfo}/>} />
      <ContentRoute path="/searchdiamond" component={() => <SearchDiamond language={language} userinfo={userinfo}/>} />
      {/* <ContentRoute path="/parcel-diamond" component={() => <ParcelDiamSearch language={language} userinfo={userinfo}/>} /> */}
      <ContentRoute path="/labsearchdiamond" component={() => <LabSearchDiamond language={language} userinfo={userinfo}/>}/>
      <ContentRoute path="/search-result" component={() => <SearchResult language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname} userinfo={userinfo}/>} isprofilecomplete={isprofilecomplete}/>
      {/* <ContentRoute path="/wishlist" component={() => <Wishlist language={language} conversionrate={conversionrate} symbol={symbol}/>} isprofilecomplete={isprofilecomplete}/> */}
      <ContentRoute path="/search-grid" component={() => <SearchGrid language={language} conversionrate={conversionrate} symbol={symbol}/>} isprofilecomplete={isprofilecomplete}/>
      <ContentRoute path="/detail/:ProductId"  component={() => <DetailPage language={language} conversionrate={conversionrate} symbol={symbol} symbolname={symbolname} userinfo={userinfo} logStoredRef={logStoredRef}/>}/>
      {/* <ContentRoute path="/profile"  component={() => <Profile language={language} isprofilecomplete={isprofilecomplete} userinfo={userinfo}/> }/> */}
      <ContentRoute path="/confirmgoods"  component={() => <ConfirmGoods userinfo={userinfo} language={language} conversionrate={conversionrate} symbol={symbol}/>}/>
      <ContentRoute path="/hold-diamonds"  component={() => <HoldDiamonds language={language} conversionrate={conversionrate} symbol={symbol}/>}/>
      {/* <ContentRoute path="/invoices"  component={() => <Invoice language={language}/>}/> */}
      {/* <ContentRoute path="/invoice-detail/:OrderId"  component={() => <InviceDetail language={language} userinfo={userinfo}/>}/> */}
      {/* <ContentRoute path="/proforma-detail"  component={() => <ProformaDetails language={language} userinfo={userinfo}/>}/> */}
      <ContentRoute path="/settings"  component={() => <Settings language={language} userinfo={userinfo}/>}/>
      {/* <ContentRoute path="/contact"  component={() => <Contact language={language}/>}/> */}
      {/* <ContentRoute path="/transparentpricing" component={() => <TransparentPricing language={language} userinfo={userinfo}/>} /> */}
      {/* <ContentRoute path="/ccmode" component={() => <Ccmode language={language} userinfo={userinfo}/>}/> */}
      <ContentRoute path="/diamondfeed" component={() => <DiamondFeed language={language} userinfo={userinfo}/>}/>
      {/* <ContentRoute path="/iframe-preview" component={() => <PreviewIframe language={language}/>}/> */}
      {/* <ContentRoute path="/api-documentation-vip" component={() => <ApiDocumentationVip language={language}/>}/> */}
      {/* <ContentRoute path="/api-documentation" component={() => <ApiDocumentation language={language}/>}/> */}
      {/* <ContentRoute path="/iframe-natural" component={() => <IframeAPI language={language}/>}/> */}
      {/* <ContentRoute path="/iframe-lab" component={() => <IframeAPILab language={language}/>}/> */}
      {/* <ContentRoute path="/checkout" component={() => <Checkout language={language} userinfo={userinfo}/>}/> */}
      {/* <ContentRoute path="/payment-failed" component={() => <PaymentFailed language={language} userinfo={userinfo}/>}/> */}
      {/* <ContentRoute path="/payment-success" component={() => <PaymentSuccess language={language} userinfo={userinfo} dataFetchedRef={dataFetchedRef}/>}/> */}
      {/* <ContentRoute path="/comparison" component={() => <Comparison language={language} userinfo={userinfo} conversionrate={conversionrate} symbol={symbol}/>}/> */}
      {/* <ContentRoute path="/matching-pair" component={() => <MatchingPair language={language} userinfo={userinfo} conversionrate={conversionrate} symbol={symbol} />}/> */}
      {/* <ContentRoute path="/wishlist-grid" component={() => <WishlistGrid language={language} conversionrate={conversionrate} symbol={symbol}/>} isprofilecomplete={isprofilecomplete}/> */}
      {/* <ContentRoute path="/api-v3" component={() => <ApiRequested/>}/> */}
      <ContentRoute path="/update-rule" component={() => <ApiUpdateForm language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/create-rule" component={() => <ApiSetupFrom language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      <ContentRoute path="/copy-rule" component={() => <ApiCopyForm language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/>
      {/* <ContentRoute path="/supplier-management" component={() => <FeedApproved language={language} conversionrate={conversionrate} symbol={symbol} userinfo={userinfo}/>}/> */}
      <ContentRoute path="/login" component={() => <AuthPage/>}/>
      <ContentRoute path="/api-frame" component={() => <Search userinfo={userinfo}/>}/>      
      <ContentRoute path="/api-frame-detail" component={() => <ProductDetailMain userinfo={userinfo}/>}/>
      <Redirect to="error/error-v4" />
    </Switch>
  </Suspense>:""}
  <ToastContainer />
  </>
  );
}
