import React ,{useEffect,useState} from "react"
import axios from "axios"
import { MenuItem, FormControl, Select, makeStyles, InputLabel } from "@material-ui/core"
import { GetCookies,SetCookies } from "../../../../_helpers/Cookies"
import { IVPCURRENCY } from "../../../../../env_config"
import { FaInfoCircle } from 'react-icons/fa';
import {OverlayTrigger,Tooltip} from "react-bootstrap";


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 110,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
export default function MultiCurrency(props){
    const classes = useStyles();
    const [currValueInr,setCurrValueInr] = React.useState(81.36);
    const [currValueUsd,setCurrValueUsd] = React.useState(1);
    const [currency, setCurrency] = React.useState('cur_usd');
    const [currencies,setCurrencies] = React.useState([])
    const [disabledropdown,setDisableDropdown] = useState(false)
    const handleChange = (event) => {
        setCurrency(event.target.value)
        SetCookies("currency",event.target.value)
        window.location.reload(false);

    }
    useEffect(() => {
      // console.log("EFFECT in Action")
        GetCookies("currency").then(response => {
            // console.log(response,"LANGUAGECOOKIES")
            axios({
                method: "POST",
                url: IVPCURRENCY,
                headers: { "Content-Type": "application/json" },
            }).then(response => {
                if(response && response.data && response.data.data){
                   //  console.log(response.data.data,"response.data.data")
                     setCurrValueInr( Math.round(response.data.data[0].cur_inr*100)/100);
                    response.data.data[0].cur_usd = 1
                    SetCookies("conversionrates",JSON.stringify(response.data.data))
                    let currs = []
                    delete response.data.data[0].base_currency
                    delete response.data.data[0].bus_date
                    delete response.data.data[0].updated_dt
                    delete response.data.data[0].timestamp_val
                    for(let keys in response.data.data[0]){
                      currs.push({
                        currencyname:keys,
                        currencyvalue:response.data.data[0][keys]
                      })
                      console.log(keys.split("_")[1].toUpperCase())
                      console.log(props.userinfo.api_currency,"props.userinfo.api_currency")
                      if(keys.split("_")[1].toUpperCase() === props.userinfo.api_currency){
                        SetCookies("currency",keys)
                        setCurrency(keys)
                        props.setUpdateCurrency(keys)
                      }
                    }
                    setCurrencies(currs)
                }
            })
            // if(response){
            //     setCurrency(response);
               
            // }
            // else{
            //     setCurrency("cur_usd")
            //     SetCookies("currency","cur_usd")
            // }
        })
    },[props.userinfo])
    useEffect(() => {
      // console.log(window.location.pathname,"window.location.href")
      // if(window.location.pathname === '/searchdiamond/white' || window.location.pathname === '/searchdiamond/fancy' || window.location.pathname === '/labsearchdiamond/white' || window.location.pathname === '/labsearchdiamond/fancy'){
      //   setDisableDropdown(false)
      // }
      // else{
      //   setDisableDropdown(true)
      // }
      setDisableDropdown(true)
    },[window.location.href])

    // console.log(currencies,"currencies")
    const getsymbol = (responsecurrency) => {
      let symbol = "$"
      if(responsecurrency === "cur_inr"){
        symbol = "₹"
      }
      if(responsecurrency === "cur_usd"){
        symbol = "$"
      }
      if(responsecurrency === "cur_aud"){
        symbol = "$"
      }
      if(responsecurrency === "cur_cad"){
        symbol = "$"
      }
      if(responsecurrency === "cur_hkd"){
        symbol = "$"
      }
      if(responsecurrency === "cur_cny"){
        symbol = "¥"
      }
      if(responsecurrency === "cur_eur"){
        symbol = "€"
      }
      if(responsecurrency === "cur_gbp"){
        symbol = "£"
      }
      if(responsecurrency === "cur_nzd"){
        symbol = "$"
      }
      if(responsecurrency === "cur_jpy"){
        symbol = "¥" 
      }
      if(responsecurrency === "cur_chf"){
        symbol = "₣"
      }
      return symbol
    }
    return(
        <div className="align-self-center mr-2">
           <div className="border px-3 py-2 d-flex align-items-center">
              <FormControl className={`${classes.formControl} multi_currency_dropdown m-0`}>
               {/* <InputLabel id="multi_currency">Currency</InputLabel> */}
                <Select
                  labelId="multi_currency"
                  id="multi_currency"
                  value={currency}
                  onChange={handleChange}
                  disabled={disabledropdown}
                >
                  {/* <MenuItem value={"cur_inr"}>INR-{currValueInr + 0.25}</MenuItem>  
                  <MenuItem value={"cur_usd"}>USD-{currValueUsd}</MenuItem> */}
                  {currencies.map(((values) => (
                    <MenuItem value={values.currencyname}>{values.currencyname.split('_')[1].toUpperCase()} {getsymbol(values.currencyname)}- {values.currencyname !== "cur_inr"?(Math.round(values.currencyvalue * 100)/100).toFixed(2):(Math.round((values.currencyvalue) * 100)/100).toFixed(2)}</MenuItem>
                  )))}
                </Select>
              </FormControl>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip" >Exchange rate shown here are for reference, All purchase and payments will be in US$</Tooltip>} >
                  <span><FaInfoCircle className="ml-3 text-danger cursor-pointer text-hover-primary"/></span>
              </OverlayTrigger>
           </div>
        </div>
    )
}