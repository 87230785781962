/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ Component } from 'react'
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../_helpers";
import{FaCalendarAlt,FaUserCircle,FaUndo} from "react-icons/fa";
import {GiCutDiamond} from "react-icons/gi"

export default class ExpandedDetails extends Component {
    render() {
        return (
            <>
                <div className="expandedDetails">
                    <div className="row pt-2">
                        <div className="col-3 d-flex">
                            <ul className="img_thumb_box mt-4 ml-5 pl-0">
                                <li className="img_thumb">
                                    <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/round-no.png`)}/>
                                </li>
                                <li className="img_thumb">
                                    <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/heart-no.png`)}/>
                                </li>
                                <li className="img_thumb">
                                    <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/pear-no.png`)}/>
                                </li>
                                <li className="img_thumb">
                                    <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/princess-no.png`)}/>
                                </li>
                                <li className="img_thumb">
                                    <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/radiant-no.png`)}/>
                                </li>
                            </ul>
                            <div className="img_box mt-4">
                                <img  alt="Main img" src={toAbsoluteUrl(`/media/shape/round-no.png`)}/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <div className="col-12 my-3 d-flex justify-content-lg-start align-items-center">
                                    <span className={`Instock_label mr-4`}>InStock</span>
                                    <OverlayTrigger placement="left" className="text-capitalize" overlay={<Tooltip id="tooltip" >India</Tooltip>} >
                                        <img className="img-fluid flagIco mr-4" alt="profile" src={toAbsoluteUrl(`/media/map/india.png`)}/>
                                    </OverlayTrigger>
                                    <span className="font-size-xs text-primary mr-4">GIA:1234567890</span>
                                    <div className="font-size-sm mr-1 text-dark-50">Stock No: <div className="font-size-sm text-dark-50 font-weight-bold d-inline-block">RK-1234567890</div></div>
                                </div>
                                <div className="col-12">
                                    <span className="text-dark font-size-h4 font-weight-bold text-capitalize">PEAR 0.50CT FANCY BROWNISH-PURPLISH PINK VS2 EX GD MED</span>
                                </div>
                                <div className="col-12">
                                    <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">3EX</div>
                                    <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">EC</div>
                                    <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">LUSTER</div>
                                    <div className="label label-outline-warning label-pill label-inline bg-warning-o-20">NO SHADE</div>
                                </div>
                                <div className="col-12 d-flex align-items-end mt-5">
                                    <span>
                                        <div className="font-size-xs text-dark-25">$/CT $1113,025.15</div>
                                        <div className="font-size-h1 font-weight-bold text-primary">$1113,025.15</div>
                                    </span>
                                    <span className="text-danger ml-3 mb-2 small">-45%</span>
                                </div>
                                <div className="col-12 mt-3 d-flex align-items-end">
                                    <button type="button" className="btn btn-primary px-6 mr-3 font-rubik font-weight-bold">Buy Diamond</button>
                                    <button type="button" className="btn btn-secondary px-6 font-rubik font-weight-bold">Add to</button>
                                </div>
                                
                                <div className="col-12 mt-2 d-flex">
                                    <span className="d-flex align-items-center font-size-sm mr-1">
                                        <span className="text-dark-25 mr-1"><FaUserCircle /></span> Supplier :  
                                        <span className="font-size-sm text-dark ml-1 font-weight-bold">Vru Star HK Limited</span>
                                    </span>
                                    <span className="small text-muted mr-1">(Stock Id :124563)</span>
                                    <span className="label label-success text-dark font-size-sm font-weight-bold label-pill label-inline bg-success ml-2">4.0</span>
                                </div>
                                <div className="col-12 mt-3">
                                    <span className="d-flex align-items-center font-size-sm">
                                        <span className="text-dark-25 mr-1"><FaUndo /></span> Buy with Returns 15 Days Return Policy @ 
                                        <span className="font-size-sm text-warning ml-1">$111,5005. </span>
                                    </span>
                                </div>
                                <div className="col-12 mt-5">
                                    <div className="row border-top">
                                        <div className="col-3 border-right text-center d-flex flex-column justify-content-center align-items-center py-3">
                                            <GiCutDiamond className="display-4" />
                                            <a href="#" className="text-primary font-size-sm">View Details</a>
                                        </div>
                                        <div className="col-3 border-right text-center d-flex flex-column justify-content-center align-items-center py-3">
                                            <GiCutDiamond className="display-4" />
                                            <a href="#" className="text-primary font-size-sm">Verify Report</a>
                                        </div>
                                        <div className="col-3 border-right text-center d-flex flex-column justify-content-center align-items-center py-3">
                                            <GiCutDiamond className="display-4" />
                                            <a href="#" className="text-primary font-size-sm">Hold Diamond</a>
                                        </div>
                                        <div className="col-3 text-center d-flex flex-column justify-content-center align-items-center py-3">
                                            <GiCutDiamond className="display-4" />
                                            <a href="#" className="text-primary font-size-sm">Find Pair</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-6 border-left pb-10">
                                    <h5 className="pt-4">Fancy Color Details</h5>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Intensity
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Fancy
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Overtone
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Brownish-Pinkish
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Color
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Pink
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Color
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Pink
                                        </div>
                                    </div>
                                    <h5 className="pt-4">Parameter Details</h5>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                        Measurement
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            6.40 X 6.43 X 3.40
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Table
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            61.9%
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Depth
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            55%
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Ratio
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            1:3
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Crown
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            21%, 34°
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Pavalion
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            43%, 40.8°
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Girdle
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                        Extremlt Thin-thin (67%)
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Girdle Condition
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            None
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Culet
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            None
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 border-left">
                                    <h5 className="pt-4">Additional Details</h5>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Shade
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Brown
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Milky
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            No Milky
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Eye Clean
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            YES
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Brand
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Canada mark
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Origin
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Argyle
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Type II
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            A
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Treatement
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            None
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-4 font-size-md">
                                            Key to Symbol
                                        </div>
                                        <div className="col-8 font-weight-bold font-size-md">
                                            Crystel, Cloud, Feather
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}