import React from "react";
import { Field } from "formik";
// import { Slider,makeStyles } from "@material-ui/core";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";


export const FancyColor =({formik}) =>{

    const whitecolorData = ([
        {
            id: 0,
            wcolor:"D"
        },
        {
            id: 1,
            wcolor:"E"
        },
        {
            id: 2,
            wcolor:"F"
        },
        {
            id: 3,
            wcolor:"G"
        },
        {
            id: 4,
            wcolor:"H"
        },
        {
            id: 5,
            wcolor:"I"
        },
        {
            id: 6,
            wcolor:"J"
        },
        {
            id: 7,
            wcolor:"K"
        },
        {
            id: 8,
            wcolor:"L"
        },
        {
            id: 9,
            wcolor:"M"
        },
        {
            id: 10,
            wcolor:"N"
        },
        {
            id: 11,
            wcolor:"OP"
        },
        {
            id: 12,
            wcolor:"QR"
        },
        {
            id: 13,
            wcolor:"ST"
        },
        {
            id: 14,
            wcolor:"UV"
        },
        {
            id: 15,
            wcolor:"WX"
        },
        {
            id: 16,
            wcolor:"YZ"
        }
    ]);
    const IntensityData = ([
        {
            id: "int_1",
            value:"FAINT",
            intensity:"FAINT"
        },
        {
            id: "int_2",
            value:"VERY LIGHT",
            intensity:"VERY LIGHT"
        },
        {
            id: "int_3",
            value:"LIGHT",
            intensity:"LIGHT"
        },
        {
            id: "int_4",
            value:"FANCY LIGHT",
            intensity:"FANCY LIGHT"
        },
        {
            id: "int_5",
            value:"FANCY",
            intensity:"FANCY"
        },
        {
            id: "int_6",
            value:"FANCY DARK",
            intensity:"FANCY DARK"
        },
        {
            id: "int_7",
            value:"FANCY INTENSE",
            intensity:"FANCY INTENSE"
        },
        {
            id: "int_8",
            value:"FANCY VIVID",
            intensity:"FANCY VIVID"
        },
        {
            id: "int_9",
            value:"FANCY DEEP",
            intensity:"FANCY DEEP"
        }
    ]);
    const ColorData = ([
        {
            id: 1,
            value:"YELLOW",
            color:"YELLOW"
        },
        {
            id: 2,
            value:"PINK",
            color:"PINK"
        },
        {
            id: 3,
            value:"BLUE",
            color:"BLUE"
        },
        {
            id: 4,
            value:"RED",
            color:"RED"
        },
        {
            id: 5,
            value:"GREEN",
            color:"GREEN"
        },
        {
            id: 6,
            value:"PURPLE",
            color:"PURPLE"
        },
        {
            id: 7,
            value:"ORANGE",
            color:"ORANGE"
        },
        {
            id: 8,
            value:"VIOLET",
            color:"VIOLET"
        },
        {
            id: 10,
            value:"GREY",
            color:"GREY"
        },
        {
            id: 11,
            value:"BLACK",
            color:"BLACK"
        },
        {
            id: 12,
            value:"BROWN",
            color:"BROWN"
        },
        {
            id: 13,
            value:"Champagne",
            color:"Champagne"
        },
        {
            id: 14,
            value:"Cognac",
            color:"Cognac"
        },
        {
            id: 15,
            value:"Chameleon",
            color:"Chameleon"
        },
        {
            id: 16,
            value:"WHITE",
            color:"WHITE"
        },
        {
            id: 17,
            value:"Other",
            color:"Other"
        }
    ]);
    const OvertoneData = ([
        {
            id: 1,
            value:"NONE",
            overtone:"NONE"
        },
        {
            id: 0,
            value:"YELLOW",
            overtone:"YELLOW"
        },
        {
            id: 2,
            value:"Yellowish",
            overtone:"Yellowish"
        },
        {
            id: 3,
            value:"Pink",
            overtone:"Pink"
        },
        {
            id: 4,
            value:"Pinkish",
            overtone:"Pinkish"
        },
        {
            id: 5,
            value:"Blue",
            overtone:"Blue"
        },
        {
            id: 6,
            value:"Blueish",
            overtone:"Blueish"
        },
        {
            id: 7,
            value:"Red",
            overtone:"Red"
        },
        {
            id: 8,
            value:"Reddish",
            overtone:"Reddish"
        },
        {
            id: 9,
            value:"Green",
            overtone:"Green"
        },
        {
            id: 10,
            value:"Greenish",
            overtone:"Greenish"
        },
        {
            id: 11,
            value:"Purple",
            overtone:"Purple"
        },
        {
            id: 12,
            value:"Purplish",
            overtone:"Purplish"
        },
        {
            id: 13,
            value:"Orange",
            overtone:"Orange"
        },
        {
            id: 14,
            value:"Orangy",
            overtone:"Orangy"
        },
        {
            id: 15,
            value:"VIOLET",
            overtone:"VIOLET"
        },
        {
            id: 16,
            value:"Violetish",
            overtone:"Violetish"
        },
        {
            id: 17,
            value:"Gray",
            overtone:"Gray"
        },
        {
            id: 18,
            value:"Grayish",
            overtone:"Grayish"
        },
        {
            id: 19,
            value:"Black",
            overtone:"Black"
        },
        {
            id: 20,
            value:"Brown",
            overtone:"Brown"
        },
        {
            id: 21,
            value:"BROWNISH",
            overtone:"BROWNISH"
        },
        {
            id: 22,
            value:"Champagne",
            overtone:"Champagne"
        },
        {
            id: 23,
            value:"Cognac",
            overtone:"Cognac"
        },
        {
            id: 24,
            value:"Chameleon",
            overtone:"Chameleon"
        },
        {
            id: 25,
            value:"WHITE",
            overtone:"WHITE"
        }
        ,
        {
            id: 26,
            value:"Other",
            overtone:"Other"
        }
    ]);
    const ResetColor = () => {
        // formik.setFieldValue("Color",[])
    }
    return (
        <>
        <div className="col-md-4" onClick={() => ResetColor()}>
        <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Fancy Color</div>
                <div className="col-12" >
                {ColorData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Fancy_Color" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox API">
                                        <span>{item.color}</span>
                                    </div>
                                </label>
                            ))}
                </div>
            </div>
            </div>
            <div className="col-md-4" onClick={() => ResetColor()}>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Fancy Intensity</div>
                <div className="col-12" >
                {IntensityData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Fancy_Color_Intensity" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox API">
                                        <span>{item.intensity}</span>
                                    </div>
                                </label>
                            ))}
                </div>
            </div>
            </div>
            <div className="col-md-4" onClick={() => ResetColor()}>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">Fancy Overtone</div>
                <div className="col-12" >
                {OvertoneData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Fancy_Color_Overtone" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox API">
                                        <span>{item.overtone}</span>
                                    </div>
                                </label>
                            ))}
                </div>
            </div>
            </div>
        </>
    );
}

// export const Color = ({formik}) => {
//     const classes = useStyles();
//     const [minColor, setMinColor] = useState(0);
//     const [maxColor, setMaxColor] = useState(100);
//     const [colorRangeValue, setColorRangeValue] = useState([0, 100]);
//     const minColorRange = 0;
//     const maxColorRange = 100;

//     useEffect(() => {
//         formik.setFieldValue("minColor", minColor);
//         formik.setFieldValue("maxColor", maxColor);
//     }, []);

//     const marks = [
//         {
//           value: 0,
//           label: 'D',
//         },
//         {
//           value: 10,
//           label: 'E',
//         },
//         {
//           value: 20,
//           label: 'F',
//         },
//         {
//           value: 30,
//           label: 'G',
//         },
//         {
//             value: 40,
//             label: 'H',
//         },
//         {
//             value: 50,
//             label: 'I',
//         },
//         {
//             value: 60,
//             label: 'J',
//         },
//         {
//             value: 70,
//             label: 'K',
//         },
//         {
//             value: 80,
//             label: 'L',
//         },
//         {
//             value: 90,
//             label: 'M',
//         },
//         {
//             value: 100,
//             label: 'N',
//         },
//       ];

//     const handleColorRangeChange = (event, newValue) => {
//         setMinColor(newValue[0]);
//         setMaxColor(newValue[1]);
//         setColorRangeValue(newValue);
//         formik.setFieldValue("minColor", newValue[0]);
//         formik.setFieldValue("maxColor", newValue[1]);
//     };

//     return (
//         <>
//             <div className="row m-0">
//                 <div className="col-12 font-size-lg font-weight-bold mb-3">Color</div>
//                 <div className="col-12">
//                     <div className="row m-0">
//                         <div className="col-md-12">
//                             <Slider
//                                 getAriaLabel={() => "Color Range"}
//                                 value={colorRangeValue}
//                                 onChange={handleColorRangeChange}
//                                 step={10}
//                                 // valueLabelDisplay="auto"
//                                 marks={marks}
//                                 // valueLabelDisplay="on"
//                                 // getAriaValueText={colorRangeValue}
//                                 min={minColorRange}
//                                 max={maxColorRange}
//                                 color="secondary"
//                             />
//                         </div>
//                     </div>
//                     {/* <div className="row">
//                         <div className="col-md-12 d-flex justify-content-between">
//                             <TextField
//                                 className={classes.TextField}
//                                 margin="dense"
//                                 name="minColor"
//                                 variant="outlined"
//                                 InputLabelProps={{ shrink: true }}
//                                 value={colorRangeValue[0]}
//                                 color="secondary"
//                                 inputProps={{
//                                     style: {
//                                       padding: 5,
//                                       textAlign:"center"
//                                     }
//                                  }}
//                                 onChange={(e) => {
//                                     setMinColor(Number(e.target.value));
//                                     setColorRangeValue([
//                                         Number(e.target.value),
//                                         colorRangeValue[1],
//                                     ]);
//                                     formik.setFieldValue("minColor", Number(e.target.value));
//                                 }}
//                             />
                                
//                             <TextField
//                                 className={classes.TextField}
//                                 margin="dense"
//                                 variant="outlined"
//                                 name="maxColor"
//                                 InputLabelProps={{ shrink: true }}
//                                 value={ colorRangeValue[1]}
//                                 color="secondary"
//                                 inputProps={{
//                                     style: {
//                                       padding: 5,
//                                       textAlign:"center"
//                                     }
//                                  }}
//                                 onChange={(e) => {
//                                     setMaxColor(Number(e.target.value));
//                                     setColorRangeValue([
//                                         colorRangeValue[0],
//                                         Number(e.target.value),
//                                     ]);
//                                     formik.setFieldValue("maxColor", Number(e.target.value));
//                                 }}
//                             />
//                         </div>
//                     </div> */}
//                 </div>
//             </div>
//         </>
//     );
// };
