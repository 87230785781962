import React,{useEffect} from "react";
import { Depth } from "./Advanced/Depth";
import { Polish } from "./Advanced/Polish";
import { Ratio } from "./Advanced/Ratio";
import { Fluorescence } from "./Advanced/Fluorescence";
import {Table} from "./Advanced/Table";
import { Symmetry } from "./Advanced/Symmetry";
import { Lab } from "./Advanced/Lab";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";
export const Advanced = ({formik,setSearchData,diamond_type,custompage,setLoader}) => {
    // useEffect(() => {
    //     let FinalObject = {
    //         page:custompage
    //     }
    //     console.log(formik.values,"formik.values")
    //     if(formik.values.Shape && formik.values.Shape.length){
    //         FinalObject["shape"] = formik.values.Shape
    //     }
    //     if(formik.values.Cut && formik.values.Cut.length){
    //         FinalObject["cut"] = formik.values.Cut
    //     }
    //     if(formik.values.Clarity && formik.values.Clarity.length){
    //         FinalObject["clarity"] = formik.values.Clarity
    //     }
    //     if(formik.values.Color && formik.values.Color.length){
    //         FinalObject["color"] = formik.values.Color
    //     }
    //     if(formik.values.Fancy_Color && formik.values.Fancy_Color.length){
    //         FinalObject["fancy_color_diamond"] = "YES"
    //         FinalObject["fancy_color"] = formik.values.Fancy_Color
    //     }
    //     if(formik.values.Fancy_Color_Overtone && formik.values.Fancy_Color_Overtone.length){
    //         FinalObject["fancy_color_diamond"] = "YES"
    //         FinalObject["fancy_overtone"] = formik.values.Fancy_Color_Overtone
    //     }
    //     if(formik.values.Fancy_Color_Intensity && formik.values.Fancy_Color_Intensity.length){
    //         FinalObject["fancy_color_diamond"] = "YES"
    //         FinalObject["fancy_intensity"] = formik.values.Fancy_Color_Intensity
    //     }
    //     if(formik.values.Lab && formik.values.Lab.length){
    //         FinalObject["lab"] = formik.values.Lab
    //     }
    //     if(formik.values.Polish && formik.values.Polish.length){
    //         FinalObject["polish"] = formik.values.Polish
    //     }
    //     if(formik.values.Symmetry && formik.values.Symmetry.length){
    //         FinalObject["symmetry"] = formik.values.Symmetry
    //     }
    //     if(formik.values.Fluorescence_Intensity && formik.values.Fluorescence_Intensity.length){
    //         FinalObject["fluorescence"] = formik.values.Fluorescence_Intensity
    //     }
    //     if(formik.values.minCarat >= 0){
    //         FinalObject["min_carat"] = formik.values.minCarat
    //     }
    //     if(formik.values.maxCarat >= 0){
    //         FinalObject["max_carat"] = formik.values.maxCarat
    //     }
    //     if(formik.values.minPrice >= 0){
    //         FinalObject["total_price_from"] = formik.values.minPrice
    //     }
    //     if(formik.values.maxPrice >= 0){
    //         FinalObject["total_price_to"] = formik.values.maxPrice
    //     }

    //     if(formik.values.minDepth >= 0){
    //         FinalObject["depthmin"] = formik.values.minDepth
    //     }
    //     if(formik.values.maxDepth >= 0){
    //         FinalObject["depthmax"] = formik.values.maxDepth
    //     }
    //     if(formik.values.minTable >= 0){
    //         FinalObject["tablemin"] = formik.values.minTable
    //     }
    //     if(formik.values.maxTable >= 0){
    //         FinalObject["tablemax"] = formik.values.maxTable
    //     }
    //     if(formik.values.minRatio >= 0){
    //         FinalObject["ratiomin"] = formik.values.minRatio
    //     }
    //     if(formik.values.maxRatio >= 0){
    //         FinalObject["ratiomax"] = formik.values.maxRatio
    //     }
    //     setLoader(true)
    //     if(diamond_type === "N"){
    //         axios({
    //             method: "POST",
    //             url: `${NODE_API_URL}/feed/naturaldiamonds`,
    //             headers: { 
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
    //              },
    //             data: FinalObject,
    //         }).then(res => {
    //             setLoader(false)
    //             if(res && res.data && res.data.success){
    //                 setSearchData(res.data)
    //             }
    //             else{
    //                 setSearchData([])
    //             }
    //         }).catch(err => {
    //             setLoader(false)
    //         })
    //     }
    //     else{
    //         axios({
    //             method: "POST",
    //             url: `${NODE_API_URL}/feed/labgrowndiamonds`,
    //             headers: { 
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
    //              },
    //             data: FinalObject,
    //         }).then(res => {
    //             setLoader(false)
    //             if(res && res.data && res.data.success){
    //                 setSearchData(res.data)
    //             }
    //             else{
    //                 setSearchData([])
    //             }
    //         }).catch(err => {
    //             setLoader(false)
    //         })
    //     }
    // },[formik.values,custompage,diamond_type])
       return (
        <>
           <div className="row m-0">
                <div className="col-md-4">
                    <Fluorescence formik={formik} />
                </div>
                <div className="col-md-4">
                    <Polish formik={formik} />
                </div>
                <div className="col-md-4">
                    <Symmetry formik={formik} />
                </div>
                <div className="col-md-4">
                    <Depth formik={formik} />
                </div>
                <div className="col-md-4">
                    <Table formik={formik} />
                </div>
                <div className="col-md-4">
                    <Ratio formik={formik} />
                </div>
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                    <Lab formik={formik} />
                </div>
                <div className="col-md-4">
                </div>
            </div>
        </>
    );
};
