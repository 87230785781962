import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-4.scss";
import { Link } from "react-router-dom";

export function ErrorPage4() {
  return (
    <div className="container my-5 notFoundvh">
        <h2 className="m-4 display-1 bold text-center">404</h2>
        <div className="row">
            <div className="col-md-12 col-md-12 col-xs-12 text-center">
                <p className="text-center text-secondary">Page not found</p>
                <p className="m-0">We're sorry, the page you requested could not be found.</p>
                <p className="m-0">Please go back to home-page or contact us at  <a href="mailto:info@diamondsoncall.com">info@diamondsoncall.com</a></p>
                <div className="text-center">
                <Link to="/" className="btn btn-primary mt-4" >Go to Home</Link>
                </div>
            </div>
        </div>
    </div>
  );
}
