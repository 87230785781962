import React from "react";
import {  Switch,Redirect, Route } from 'react-router-dom';
// import Home from "./FrontComponent/Home";
// import About from "./FrontComponent/About";
// import Whyus from "./FrontComponent/Whyus";
// import Contact from "./FrontComponent/Contact";
// import Faqs from "./FrontComponent/Faqs";
// import PrivacyPolicy from "./FrontComponent/PrivacyPolicy";
// import Terms from "./FrontComponent/Terms";
import Login from "./Login";
import NotFound from "./FrontComponent/NotFound";
// import Requestdemo from "./FrontComponent/Homepage/Requestdemo";
// import Notfound from "./Notfound";
// import { ContentRoute } from "../../../../_metronic/layout";
// import Register from "./FrontComponent/Register";

const HomeRoutes = () =>{
  return(
    <>
      <Switch>
        {/* <Route path="/auth/Home" component={Home} /> */}
        <Route exact path="/" component={Login} />
        {/* <Route exact path="/about" component={About} />
        <Route exact path="/whyus" component={Whyus} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/registration" component={Register} /> */}
        {/* <Route component={Notfound}/> */}
        <Route exact path="/auth/login" component={Login} />
        <Route path="/error" component={NotFound} />
        {/* <Route exact path="/demo-request" component={Requestdemo}/> */}
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/error" />
      </Switch>
    </>
  )
}
export default HomeRoutes;